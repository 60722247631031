import React from 'react';
import {Modal, Form, Input, Row, Col, InputNumber} from 'antd'

const ExternalChildAgeModalForm = (props) => {
    const onOK = () => {
        props.form.submit();
    }
    return (
        <Modal visible={props.isVisible} title={props.title} closable={false} onOk={onOK} onCancel={props.onCancel}>
            <Form form={props.form} layout="vertical" onFinish={(values) => props.onFinish(values, props.data?.id)}>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Form.Item
                            name="age_type"
                            label="Yaş Tanımı"
                            rules={[{
                                required: true,
                                message: 'Yaş Tanımı boş bırakılamaz!'
                            }]}
                        >
                            <Input
                                placeholder="Yaş Tanımı"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="minimum_age"
                            label="Minimum Yaş"
                            rules={[{
                                required: true,
                                message: 'Minimum Yaş boş bırakılamaz!'
                            }]}
                        >
                            <InputNumber
                                style={{width: '100%'}}
                                placeholder="Minimum Yaş"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="maximum_age"
                            label="Maksimum Yaş"
                            rules={[{
                                required: true,
                                message: 'Maksimum Yaş boş bırakılamaz!'
                            }]}
                        >
                            <InputNumber
                                style={{width: '100%'}}
                                placeholder="Maksimum Yaş"
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
};

export default ExternalChildAgeModalForm;