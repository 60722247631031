import axios from '../axios';

const ContractGroupLanguageService = {
  async create(forms,contract_group_id) {
    return await axios.post(`/v1/contractGroupLanguage/${contract_group_id}`,forms);
  },

  async show(contract_group_id) {
    return await axios.get(`/v1/contractGroupLanguage/${contract_group_id}`);
  }


};

export default ContractGroupLanguageService;