import React, { useEffect, useState } from 'react';
import { Modal, Form, Row, Col, Select, Input } from 'antd';
import {useTranslation} from "react-i18next";

const { Option } = Select;

const ConditionModalForm = (props) => {
  const { t } = useTranslation()
  const ages = [];
  for (let age = 0; age < 19; age++) {
    ages.push(<Option key={age} value={age}>{age}</Option>);
  }

  const [fields, setFields] = useState([]);

  useEffect(() => {
    if (props.data && props.data.fields) {
      setFields([
        ...fields,
        ...props.data.fields
      ]);
    }
  }, []);
  console.log(fields)
  const onOK = () => {
    props.form.submit();
  }

  return (
    <Modal visible={props.isVisible} title={props.title} closable={false} onOk={onOK} onCancel={props.onCancel}>
      <Form form={props.form} layout="vertical" onFinish={(values) => props.onFinish(values, props.data?.id)} fields={fields}>
        <Row gutter={[16]}>
          <Col span={24}>
            <Form.Item name="age_type" label={t("general.age_definition")}>
              <Input key="ageType" placeholder={t("general.age_definition")}/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16]}>
          <Col span={12}>
            <Form.Item name="minimum_age" label={t("general.start")}>
              <Select>{ages}</Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name="maximum_age" label={t("general.end")}>
              <Select>{ages}</Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
};

export default ConditionModalForm;