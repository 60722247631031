import axios from '../axios';

const ExternalHotelCardService = {
    async get(hotelId) {
        return await axios.get(`/v1/external/hotels/${hotelId}/bg-match`);
    },
    async create(hotelId, values) {
        return await axios.post(`/v1/external/hotels/${hotelId}/bg-match`, values);
    },
    async matchAllHotels() {
        return await axios.get(`/v1/external/hotels/match-all-bg`);
    }
};

export default ExternalHotelCardService;