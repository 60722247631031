import axios from '../axios';

const AgencyWebLanguageService = {
    async getByHotel(hotelId) {
        return await axios.get(`/v1/site-manager/languages/${hotelId}`);
    },
    async create(hotelId,formData) {
        return await axios.post(`/v1/site-manager/languages/${hotelId}`,formData);
    },
    async delete(id) {
        return await axios.delete(`/v1/site-manager/languages/${id}`);
    },
};

export default AgencyWebLanguageService;