import React from 'react';
import {Col, Form, Input, InputNumber, Modal, Row, Tabs} from "antd";
import {useTranslation} from "react-i18next";

const { TabPane } = Tabs;

const VehicleModalForm = (props) => {
    const { t } = useTranslation()
    return (
        <Modal visible={props.isVisible} title={props.title} closable={false} onOk={() => props.form.submit()} onCancel={props.onCancel}>
            <Form form={props.form} layout="vertical" onFinish={(values) => props.onFinish(values, props.data?.id)} fields={props.data?.fields}>
                <Tabs>
                    {props.supportedLanguages?.map((language, index) => (
                        <TabPane tab={t(`general.${language}`)} key={index} forceRender>
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        name={['vehicles', 'translations', language, 'name']}
                                        label="Araç Adı"
                                        rules={[{ required: true, message: 'Lütfen araç adını giriniz!' }]}
                                    >
                                        <Input placeholder="Araç Adı" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </TabPane>
                    ))}
                </Tabs>
                <Row gutter={[16,16]}>
                    <Col span={12}>
                        <Form.Item
                            name={['vehicles', 'min_capacity']}
                            label="Minimum Kapasite"
                            rules={[{ required: true, message: 'Lütfen kapasite giriniz!' }]}
                        >
                            <InputNumber min={1} placeholder="Minimum Kapasite" style={{width: '100%'}} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name={['vehicles', 'max_capacity']}
                            label="Maksimum Kapasite"
                            rules={[{ required: true, message: 'Lütfen kapasite giriniz!' }]}
                        >
                            <InputNumber placeholder="Maksimum Kapasite" style={{width: '100%'}} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default VehicleModalForm;