import axios from '../axios';

const ReservationService = {
    async get(page = 1, pageSize = 10, searchParams = {}) {
        return await axios.get(`/v1/reservations?page=${page}&per_page=${pageSize}&${new URLSearchParams(searchParams).toString()}`);
    },
    async export(searchParams = {}) {
        return await axios.get(`/v1/reservations/export?${new URLSearchParams(searchParams).toString()}`, {
            responseType: 'blob'
        });
    },
    async getAccounting(page = 1, pageSize = 10, searchParams = {}) {
        return await axios.get(`/v1/accounting-reservations?page=${page}&per_page=${pageSize}&${new URLSearchParams(searchParams).toString()}`);
    },
    async getAccountingExport(page = 1, pageSize = 10, searchParams = {}, type) {
        axios.defaults.responseType = 'blob';
        let data = await axios.post(`/v1/accounting-reservations/export/${type}?page=${page}&per_page=${pageSize}`, searchParams);
        axios.defaults.responseType = 'json';
        return data;
    },
    async getCheckInForecast(params) {
        return await axios.get(`/v1/check-in-guests`,{
            params: params
        });
    },
    async exportCheckInForecast(params) {
        axios.defaults.responseType = 'blob';
        let data = await axios.post(`/v1/check-in-guests/export/excel`,params);
        axios.defaults.responseType = 'json';
        return data;
    },
    async updateAccounting(uuid,formData) {
        return await axios.post(`/v1/accounting-reservations/${uuid}`,formData);
    },
    async transferPayment(paymentId, formData) {
        return await axios.post(`/v1/reservation-payments/${paymentId}/transfer`,formData);
    },
    async getTemp(page = 1, pageSize = 10) {
        return await axios.get(`/v1/temp-reservations?page=${page}&per_page=${pageSize}`);
    },
    async getDeferred(page = 1, pageSize = 10, searchParams = {}) {
        return await axios.get(`/v1/deferred-reservations?page=${page}&per_page=${pageSize}&${new URLSearchParams(searchParams).toString()}`);
    },
    async updateReservationNumber(uuid) {
        return await axios.post(`/v1/reservations/${uuid}/update-reservation-number`);
    },
    async exportDeferred(searchParams = {}) {
        axios.defaults.responseType = 'blob';
        let data = await axios.get(`/v1/deferred-reservations/export?${new URLSearchParams(searchParams).toString()}`);
        axios.defaults.responseType = 'json';
        return data;
    },
    async delete(id) {
        return await axios.delete(`/v1/reservations/${id}`);
    },
    async changeLock(id) {
        return await axios.post(`/v1/reservations/${id}/change-lock`);
    },
    async listReservation(id) {
        return await axios.post(`/v1/reservations/${id}/list-reservation`);
    },
    async getConfirms(page, pageSize, filters) {
        return await axios.get(`/v1/confirm-reservations?page=${page}&per_page=${pageSize}`, {
            params: {
                filters: filters
            }
        });
    },
    async updateConfirms(id,formData) {
        return await axios.post(`/v1/confirm-reservations/${id}`,formData);
    },
    async cancelConfirmedReservation(id) {
        return await axios.post(`/v1/confirm-reservations/${id}/cancel-reservation`);
    },
    async updateGuests(id,formData) {
        return await axios.post(`/v1/reservations/${id}/guests`,formData);
    },
    async updateReservation(id,formData) {
        return await axios.post(`/v1/reservations/${id}/reservation`,formData);
    },
    async create(formData) {
        return await axios.post(`/v1/reservation`,formData);
    },
    async cancel(id, formData) {
        return await axios.post(`/v1/reservations/${id}/cancel`,formData);
    },
    async restoreCancel(id, status) {
        return await axios.post(`/v1/reservations/${id}/restore-cancel`,{
            status: status
        });
    },
    async parseAndValidate(formData) {
        return await axios.post(`/v1/parseAndValidate`,formData);
    }
};

export default ReservationService;