import React,{useState,useEffect} from 'react';
import {
    Modal,
    Switch,
    Upload,
    Row,
    Col,
    Button,
    message,
    Drawer,
    Image,
    Space,
    Card,
    Typography,
    notification
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Authentication,HotelCoverImageService} from '@services'
import {faInbox, faUpload} from "@fortawesome/free-solid-svg-icons";
import {useParams} from "react-router-dom";

const UploadRoomImageModalForm = (props) => {
    const params = useParams();
    const [fileList, setFileList] = useState([]);
    const [active,setActive] = useState();
    const [loading, setLoading] = useState(true);
    const [uploadDrawerVisible, setUploadDrawerVisible] = useState(false);
    const onOK = () => {
        props.form.submit();
    }
    return (
        <Modal visible={props.isVisible} title={props.title} closable={false} onCancel={props.onCancel}>
            <Space direction="vertical">
            <Row>
                <Col span={24}>
                    <Button onClick={() => setUploadDrawerVisible(true)}>
                        <FontAwesomeIcon icon={faUpload} />
                        Yükle
                    </Button>
                </Col>
            </Row>
            <Row gutter={[16]}>
                {props.images && props?.images.data?.filter(image=>image.room_id === props.selectedRoom).map((image) => (
                    <Col key={image.id} xs={12} md={8}>
                        <Card>
                            <Image src={`http://`+image.url+params.hotelId+`/`+props.selectedRoom+'/'+image.hashed_name} />
                        </Card>
                    </Col>
                ))}
            </Row>
            </Space>
            <Drawer title="Fotoğraf Yükle" placement="bottom" size="large" visible={uploadDrawerVisible} onClose={() => {setUploadDrawerVisible(false);props.getImages()}}>
                <Upload.Dragger
                    name="file"
                    multiple={true}
                    action={`${process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_API_URL : process.env.REACT_APP_DEV_API_URL}/v1/hotels/${params.hotelId}/rooms/${props.selectedRoom}/image`}
                    headers={{
                        Authorization: `Bearer ${Authentication.getToken()}`
                    }}
                    beforeUpload={(file) => {
                        const isImage = ['image/jpeg', 'image/jpg', 'image/png'].includes(file.type);
                        const isOptimized = file.size < 4194304;
                        if (!isImage) {
                            message.error(`${file.name} adlı dosya bir resim değildir!`);
                            return false;
                        }
                        if (!isOptimized) {
                            message.error(`${file.name} adlı dosyanız 4MB'dan büyük!`);
                            return false;
                        }
                        return true;
                    }}
                >
                    <p className="ant-upload-drag-icon">
                        <FontAwesomeIcon icon={faInbox} />
                    </p>
                    <p className="ant-upload-text">Tıkla veya sürükle!</p>
                </Upload.Dragger>
            </Drawer>
        </Modal>
    )
};

export default UploadRoomImageModalForm;