import React from 'react';
import {Col, DatePicker, Form, InputNumber, Modal, Row} from "antd";
import UserSelect from "@components/utils/UserSelect";

const UserCallModalForm = (props) => {

    return (
        <Modal visible={props.isVisible} title={props.title} closable={false} onOk={() => props.form.submit()} onCancel={props.onCancel} okText="Kaydet" cancelText="İptal">
            <Form form={props.form} layout="vertical" onFinish={(values) => props.onFinish(values)}>
                <Row gutter={[16,16]}>
                    <Col span={12}>
                        <Form.Item
                            name="user_id"
                            label="Agent"
                            rules={[{required: true, message: 'Agent alanı boş bırakılamaz!'}]}
                        >
                            <UserSelect />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="call_count"
                            label="Çağrı Sayısı"
                            rules={[{required: true, message: 'Çağrı sayısı alanı boş bırakılamaz!'}]}
                        >
                            <InputNumber style={{
                                width: '100%',
                            }} min={0} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="start_date"
                            label="Başlangıç Tarihi"
                            rules={[{required: true, message: 'Başlangıç alanı boş bırakılamaz!'}]}
                        >
                            <DatePicker style={{
                                width: '100%',
                            }} format="DD.MM.YYYY"/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="end_date"
                            label="Bitiş Tarihi"
                            rules={[{required: true, message: 'Bitiş tarihi alanı boş bırakılamaz!'}]}
                        >
                            <DatePicker style={{
                                width: '100%',
                            }} format="DD.MM.YYYY"/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default UserCallModalForm;