import React from 'react';
import {Modal, Form, Row, Col, DatePicker, Select, InputNumber, Space} from 'antd';

const { RangePicker } = DatePicker;
const { Option } = Select;

const OpenSaleModalForm = (props) => {
    const [selectedContract, setSelectedContract] = React.useState(null);

    const handleSelectAll = (value, name) => {
        if(value.includes("all") && name === "contract_concepts") {
            const allValues = selectedContract?.map((contract) => (
                contract?.concepts?.map((contract_concept) => (
                    contract_concept.id
                ))
            ));
            props.form.setFieldsValue({[name]: allValues.flat()});
        }
    }

    return (
        <Modal visible={props.isVisible} title={props.title} closable={false} onOk={() => props.form.submit()} okText="Satışa Aç" cancelText="İptal" onCancel={props.onCancel}>
            <Form form={props.form} layout="vertical" onFinish={(values) => props.onFinish(values, props.data?.id)} fields={props.data?.fields}>
                <Row gutter={[16,16]}>
                    <Col span={12}>
                        <Form.Item
                            name="hotel"
                            label="Otel"
                            rules={[{ required: true, message: 'Lütfen otel seçiniz!' }]}
                        >
                            <Select
                                placeholder="Otel Seçiniz"
                                onSelect={(id) => {props.getContractGroups(id); props.form.setFieldsValue({contract_group: undefined, contracts: undefined, contract_concepts: undefined})}}
                            >
                                {props.hotels.map((hotel,i) => (
                                    <Option key={i} value={hotel.id}>{hotel.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="contract_group"
                            label="Kontrat Grubu"
                            rules={[{ required: true, message: 'Lütfen kontrat grubu seçiniz!' }]}
                        >
                            <Select
                                placeholder="Kontrat Grubu Seçiniz"
                                onSelect={(id) => {props.getContracts(id);props.form.setFieldsValue({contracts: undefined, contract_concepts: undefined})}}
                            >
                                {props.contractGroups.map((contractGroup,i) => (
                                    <Option key={i} value={contractGroup.id}>{contractGroup.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="contract"
                            label="Kontratlar"
                            rules={[{ required: true, message: 'Lütfen kontrat seçiniz!' }]}
                        >
                            <Select
                                mode="multiple"
                                placeholder="Kontrat Seçiniz"
                                onChange={(value) => {
                                    setSelectedContract(props.contracts.filter(contract => value.includes(contract.id)));
                                    props.form.setFieldsValue({contract_concepts: undefined});
                                }}
                            >
                                {props.contracts.map((contract,i) => (
                                    <Option key={i} value={contract.id}>{contract.title}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="contract_concepts"
                            label="Kontrat Konseptleri"
                            rules={[{ required: true, message: 'Lütfen kontrat konseptleri seçiniz!' }]}
                        >
                            <Select
                                placeholder="Kontrat Konsepti Seçiniz"
                                mode="multiple"
                                onChange={value => handleSelectAll(value, 'contract_concepts')}
                            >
                                <Option key="all" value="all">Tümünü Seç</Option>
                                {selectedContract?.map((contract) => (
                                    contract?.concepts?.map((contract_concept,i) => (
                                        <Option key={contract_concept.id} value={contract_concept.id}>
                                            <Space direction="vertical">
                                                {contract_concept?.concept?.active_language?.name}
                                                <span style={{fontSize:"12px"}}>
                                                    ({contract.title})
                                                </span>
                                            </Space>
                                        </Option>
                                    ))
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="quota"
                            label="Kontenjan Sayısı"
                            rules={[
                                {
                                    required: true,
                                    message: 'Lütfen kontenjan sayısı giriniz!'
                                },
                                {
                                    type: 'number',
                                    min: 1,
                                    message: 'Kontenjan sayısı 1\'den küçük olamaz!'
                                }]}
                        >
                            <InputNumber
                                placeholder="Kontenjan Sayısı"
                                min={1}
                                style={{width:"100%"}}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="min_night"
                            label="Min. Konaklama"
                            rules={[
                                {
                                    type: 'number',
                                    min: 1,
                                    message: 'Kontenjan sayısı 1\'den küçük olamaz!'
                                }]}
                        >
                            <InputNumber
                                placeholder="Min. Konaklama"
                                min={0}
                                style={{width:"100%"}}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="dates"
                            label="Tarih Aralığı"
                            rules={[{ required: true, message: 'Lütfen tarih aralığı seçiniz!' }]}
                        >
                            <RangePicker
                                onFocus={() => props.form.setFieldsValue({dates: [undefined,undefined]})}
                                format="DD.MM.YYYY"
                                placeholder={["Başlangıç Tarihi", "Bitiş Tarihi"]}
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
};

export default OpenSaleModalForm;