import React, {useEffect} from 'react';
import {UserService} from "@/services";
import {notification, Select} from "antd";

const UserSelect = (props) => {
    const [isLoading, setIsLoading] = React.useState(false);
    const [users, setUsers] = React.useState({
        data: [],
        meta: {}
    });
    useEffect(() => {
        getUsers();
    },[])

    const getUsers = async (page) => {
        page = page ?? 1;
        page = props?.all ? "all": page;
        setIsLoading(true);
        try {
            const { data: response } = await UserService.getUsers(page, props.value);
            setUsers({
                data: [...users.data, ...response.data],
                meta: response.meta
            });
            setIsLoading(false);
        }catch (e){
            if(e.response){
                notification.error({
                    message:e.response.data.message
                })
            }
        }finally {
            setIsLoading(false);
        }
    }

    const onScroll = (e) => {
        const { target } = e;
        if (! isLoading && users.meta.last_page > users.meta.current_page && (target.scrollTop + target.offsetHeight) >= (target.scrollHeight * 0.8) && props?.all !== true) {
            setIsLoading(true);
            getUsers(users.meta.current_page + 1);
        }
    };
    return (
        <Select loading={isLoading} dropdownRender={menu => <div onScrollCapture={onScroll}>{menu}</div>} {...props}>
            {props.children ? props.children : null}
            {users?.data?.map(user => {
                if(user.id === props.initial) return null
                return (
                    <Select.Option key={user.id} value={user.id}>
                        {user.full_name}
                    </Select.Option>
                )
            })}
        </Select>
    );
};

export default UserSelect;