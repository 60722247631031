import axios from '../axios';

const ContractDetailsService = {
    async get(hotelId,contractId) {
        return await axios.get(`/v1/contracts/${hotelId}/details/${contractId}`);
    },
    async create(hotelId,contractId,contractConceptId,formData) {
        return await axios.post(`/v1/contracts/${hotelId}/details/${contractId}/concept/${contractConceptId}`, formData);
    }
};

export default ContractDetailsService;