import React, {useEffect} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHouse} from "@fortawesome/free-solid-svg-icons";
import {Breadcrumb, Col, Layout, notification, Row, Table, Tabs, Tag} from "antd";
import Wrapper from "@components/Wrapper";
import { SystemLogService } from "@services";
import moment from "moment";

const {Content} = Layout;

const SystemLog = () => {
    const columns = [
        {
            title: 'Level',
            dataIndex: 'level_name',
            key: 'level_name',
            render: (text,record) => {
                if(record.level === 'info'){
                    return <Tag color="green">{text}</Tag>
                }
                return <Tag color="red">{text}</Tag>
            }
        },
        {
            title: 'Tarih',
            dataIndex: 'datetime',
            key: 'date',
            render: (text) => moment(text).format('DD.MM.YYYY HH:mm:ss')
        },
        {
            title: 'Env',
            dataIndex: 'environment',
            key: 'env'
        },
        {
            title: 'Description',
            dataIndex: 'text',
            key: 'text',
            className: 'cell-overflow-ellipsis'
        }

    ];
    const [isLoading, setIsLoading] = React.useState(true);
    const [folders, setFolders] = React.useState([]);
    const [logs, setLogs] = React.useState([]);
    const getSystemLogFolders = async () => {
        try {
            let {data: response} = await SystemLogService.folders();
            setFolders(response);
            getSystemLog(response?.[0]?.files?.[0]?.identifier)
        }catch (e){
            if(e.response){
                notification.error({
                    message: e.response.data.message,
                })
            }
        }
    }

    const getSystemLog = async (file,page = 1,perPage = 10) => {
        setIsLoading(true)
        try {
            let {data: response} = await SystemLogService.logs(file, page, perPage);
            setLogs(response);
            setIsLoading(false)
        }catch (e){
            if(e.response){
                notification.error({
                    message: e.response.data.message,
                })
            }
        }
    }
    useEffect(() => {
        getSystemLogFolders()
    },[])
    return (
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-20">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse} />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        Sistem Logları
                    </Breadcrumb.Item>
                </Breadcrumb>

                <div className="site-layout-background">
                    <Tabs onChange={(key) => getSystemLog(key)}>
                        {folders?.[0]?.files?.map((folder,index) => (
                            <Tabs.TabPane key={folder.identifier} tab={folder.name + " - " + folder.size_formatted}>
                                <Row>
                                    <Col span={24}>
                                        <Table
                                            loading={isLoading}
                                            dataSource={logs.logs}
                                            rowKey={record => record.index}
                                            columns={columns}
                                            pagination={{
                                                total: logs?.pagination?.total,
                                                pageSize: logs?.pagination?.per_page,
                                                current: logs?.pagination?.current_page,
                                                onChange: (page, pageSize) => getSystemLog(folder.identifier,page,pageSize)
                                            }}
                                            expandable={{
                                                expandedRowRender: (record) => (
                                                    <div style={{
                                                        width: '100%',
                                                        display: 'block',
                                                        maxHeight: '300px',
                                                        overflowY: 'scroll',
                                                    }}>
                                                        {record.full_text}
                                                    </div>
                                                ),
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Tabs.TabPane>
                        ))}
                    </Tabs>
                </div>
            </Content>
        </Wrapper>
    );
};

export default SystemLog;