import React, { useEffect } from 'react';
import {
    Button, Card,
    Checkbox,
    Col,
    Collapse,
    Form,
    Image,
    Input,
    Modal,
    notification,
    Popconfirm,
    Radio,
    Row,
    Space,
    Tabs
} from 'antd';
import IconSelect from "../utils/IconSelect";
import { faTrash, faPlus, faArrowsTurnToDots } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {IconService} from '@services';

const { Panel } = Collapse

const SectionSettingsModalForm = (props) => {
    let { images, documents } = props;
    let initialValues = []
    let fields = props?.sections?.fields && JSON.parse(props?.sections?.fields)
    props?.initialValues?.hotel_section_detail?.translations.map((item) => {
        initialValues[item.language] = JSON.parse(item.values)
    })
    const [icons, setIcons] = React.useState([])
    const [isIconsLoading, setIsIconsLoading] = React.useState(true)
    const getIcons = async () => {
        try {
            let { data: { data: response } } = await IconService.get();
            setIcons(response)
            setIsIconsLoading(false)
        } catch (e) {
            console.log(e);
        }
    };
    useEffect(() => {
        getIcons()
    },[])
    useEffect(() => {
        props.form.resetFields()
    }, [props.initialValues])
    const applyToFields = (type, selectedLang) => {
        let values = props.form.getFieldsValue().values[selectedLang.code]
        let keys = Object.keys(values).filter(key => key.endsWith(type))
        let fieldValues = []
        props?.languages.filter(filter => filter.code !== selectedLang.code).map((lang) => {
            keys.map(color => {
                fieldValues.push({
                    name: ['values', lang.code, color],
                    value: values[color],
                })
            })
        })
        props.form.setFields(fieldValues)
    }
    function FieldItem(props) {
        let initial = initialValues;
        if (props?.type === "array" && initialValues[props?.lang.code]) {
            for (let i = 1; i < props?.name.length; i++) {
                let key = props?.name[i]
                initial = initial[key]
            }
        }
        switch (props.type) {
            case "text":
                return (
                    <Col span={props?.span}>
                        <Form.Item name={props?.array ? props?.name : ['values', props?.lang.code, props?.name]} rules={props?.rules} label={props?.label} initialValue={initialValues?.[props?.lang.code]?.[props?.name]}>
                            <Input type={props?.type} placeholder={props?.placeholder} />
                        </Form.Item>
                    </Col>
                )
            case "array":
                return (
                    <Col span={24}>
                        <Form.List
                            name={props?.name}
                            initialValue={initial}
                            rules={[
                                {
                                    validator: async (_, names) => {
                                        if (!names || names.length < 1) {
                                            return Promise.reject(new Error('Minimum 1 adet giriş yapılmalı!'));
                                        }
                                    },
                                },
                            ]}>
                            {(fields, { add, remove }, { errors }) => (
                                <>
                                    <Collapse style={{ display: fields.length < 1 && 'none' }}>
                                        {fields.map(({ key, name, ...restField }) => (
                                            <Panel forceRender={true} key={key} extra={<Space direction="horizontal" align="center">
                                                <FontAwesomeIcon onClick={() => remove(name)} icon={faTrash} />
                                            </Space>}>
                                                {props?.items.map((item, i) => (
                                                    <FieldItem key={i} {...item} name={[name, item.name]} lang={props?.lang} array={true} />
                                                ))}
                                            </Panel>
                                        ))}
                                    </Collapse>
                                    <Row style={{ marginTop: "12px" }}>
                                        <Col span={24}>
                                            <Form.Item>
                                                <Button htmlType="button" type="dashed" onClick={() => add()} block>
                                                    <Space align="center" direction="horizontal">
                                                        <FontAwesomeIcon icon={faPlus} />
                                                        {props?.add}
                                                    </Space>
                                                </Button>
                                                <Form.ErrorList errors={errors} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </>
                            )}
                        </Form.List>
                    </Col >
                )
            case "icon":
                return (
                    <Col span={props?.span}>
                        <Form.Item name={props?.array ? props?.name : ['values', props?.lang.code, props?.name]} rules={props?.rules} label={props?.label} initialValue={initialValues?.[props?.lang.code]?.[props?.name]}>
                            <IconSelect icons={icons} loading={isIconsLoading} data="source" />
                        </Form.Item>
                    </Col>
                )
            case "color":
                return (
                    <Col span={props?.span}>
                        <Form.Item name={props?.array ? props?.name : ['values', props?.lang.code, props?.name]} rules={props?.rules} label={props?.label} initialValue={initialValues?.[props?.lang.code]?.[props?.name] ? initialValues?.[props?.lang.code]?.[props?.name] : '#ffffff'}>
                            <Input type={props?.type} />
                        </Form.Item>
                    </Col>
                )
            case "document":
                return (
                    <Col span={props?.span}>
                        <div className="horizontal-scrollbar-1" style={{ width: "100%", overflowX: "auto", display: "flex", columnGap: "12px" }}>
                            <Form.Item name={props?.array ? props?.name : ['values', props?.lang.code, props?.name]} rules={props?.rules} label={props?.label} initialValue={initialValues?.[props?.lang.code]?.[props?.name] && initialValues?.[props?.lang.code]?.[props?.name]}>
                                <Radio.Group style={{ display: "flex", columnGap: "1rem" }}>
                                    {documents?.map((image, index) => (
                                        <div key={index} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                            <Card width={100} height={100} style={{ objectFit: "cover" }}>
                                                <p style={{fontSize:"12px"}}>{image.name}</p>
                                            </Card>
                                            <Radio value={image.url} />
                                        </div>
                                    ))}
                                </Radio.Group>
                            </Form.Item>
                        </div>
                    </Col>
                )
            case "image":
                return (
                    <Col span={props?.span}>
                        <div className="horizontal-scrollbar-1" style={{ width: "100%", overflowX: "auto", display: "flex", columnGap: "12px" }}>
                            <Form.Item name={props?.array ? props?.name : ['values', props?.lang.code, props?.name]} rules={props?.rules} label={props?.label} initialValue={initialValues?.[props?.lang.code]?.[props?.name] && initialValues?.[props?.lang.code]?.[props?.name]}>
                                <Radio.Group style={{ display: "flex", columnGap: "1rem" }}>
                                    {images?.map((image, index) => (
                                        <div key={index} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                            <Image width={100} height={100} style={{ objectFit: "cover" }} src={image.url} />
                                            <Radio value={image.url} />
                                        </div>
                                    ))}
                                </Radio.Group>
                            </Form.Item>
                        </div>
                    </Col>
                )
            case "images":
                return (
                    <Col span={props?.span}>
                        <div className="horizontal-scrollbar-1" style={{ width: "100%", overflowX: "auto", display: "flex", columnGap: "12px" }}>
                            <Form.Item name={props?.array ? props?.name : ['values', props?.lang.code, props?.name]} rules={props?.rules} label={props?.label} style={{ marginBottom: "10px" }} tooltip={"Performans açısından maksimum 3 adet seçilmesi önerilmektedir!"} initialValue={initialValues?.[props?.lang.code]?.[props?.name] && initialValues?.[props?.lang.code]?.[props?.name]}>
                                <Checkbox.Group style={{ display: "flex", columnGap: "1rem" }}>
                                    {images?.map((image, index) => (
                                        <div key={index} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                            <Image width={100} height={100} style={{ objectFit: "cover" }} src={image.url} />
                                            <Checkbox value={image.url} />
                                        </div>
                                    ))}
                                </Checkbox.Group>
                            </Form.Item>
                        </div>
                    </Col>
                )
            default:
                return false;
        }
    }
    const applyToAll = (code) => {
        let values = props.form.getFieldsValue().values[code]
        let keys = Object.keys(values)
        if(values) {
            keys?.map(key => {
                props?.languages.map((lang)=>{
                    props.form.setFields([
                        {
                            name: ['values',lang.code,key],
                            value: values[key],
                        }
                    ])
                })
            })
            notification.success({
                message:"Tüm veriler diğer tüm dilleri uygulandı!"
            })
        }
    }
    return (
        <Modal visible={props.isVisible} title={props.title} closable={false} onCancel={props.onCancel} onOk={() => props.form.submit()}>
            <Form form={props.form} layout="vertical" onFinish={(values) => props.onFinish(values, props.sections?.id)} fields={props.data?.fields}>
                <Tabs>
                    {
                        props?.languages.map((lang) => (
                            <Tabs.TabPane key={lang.code} tab={lang.native_name} forceRender={true}>
                                <Row style={{ marginBottom: "0.8rem", display: "flex", justifyContent: "flex-end" }}>
                                    <Space>
                                        <Popconfirm title="Tümünü uygulamak istediğinize emin misiniz?" okText="Uygula" cancelText="İptal" onConfirm={() => applyToAll(lang.code)}>
                                            <Button size="small" style={{ fontSize: "12px" }}>Tümüne uygula</Button>
                                        </Popconfirm>
                                    </Space>
                                </Row>
                                <Row gutter={[16, 8]}>
                                    {
                                        fields && fields.map((field, index) => (
                                            <FieldItem {...field} key={index} lang={lang} name={field.type !== "array" ? field.name : ['values', lang.code, field.name]} />
                                        ))
                                    }
                                </Row>
                            </Tabs.TabPane>
                        ))
                    }
                </Tabs>
            </Form>
        </Modal>
    );
};

export default SectionSettingsModalForm;