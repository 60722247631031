import React, {useEffect} from "react";
import {Modal, Row, Col, Form, notification} from "antd";
import {useTranslation} from "react-i18next";
import UserSelect from "@/components/utils/UserSelect";
import {TargetService} from "@/services";

const TargetUsersModalForm = (props) => {
    const {t} = useTranslation();
    const [form] = Form.useForm();
    useEffect(() => {
        console.log(props.selectedTarget)
        form.setFieldsValue({
            users: props.selectedTarget?.users ?? []
        })
    },[props.selectedTarget])
    const onCreate = async (values) => {
        try {
            let {data: response} = await TargetService.appendTargetUsers(props.selectedTarget.id, values)
            if (response.success){
                notification.success({
                    message: t('general.success'),
                    description: response.message
                })
                props.onFinish();
            }
        }catch (e){
            if (e.response){
                notification.error({
                    message: t('general.error'),
                    description: e.response.data.message
                })
            }
        }
    }
    return (
        <Modal visible={props.isVisible} title={props.title} closable={false} onOk={() => form.submit()} onCancel={props.onCancel}>
            <Form form={form} layout="vertical" onFinish={(values) => onCreate(values)}>
                <Row gutter={[16,16]}>
                    <Col span={24}>
                        <Form.Item
                            name="users"
                            label={t('general.users')}
                        >
                            <UserSelect all={true} mode="multiple" placeholder={t("general.users")} style={{ width: "100%" }}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default TargetUsersModalForm;