import React, {useEffect} from 'react';
import {
    Modal,
    Form,
    Input,
    Tabs,
    Row,
    Col,
    DatePicker,
    Button,
    Radio,
    Select,
    InputNumber,
    Space,
    notification, Popconfirm
} from 'antd';
import moment from 'moment';
import UserSelect from "../utils/UserSelect";
import {useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import {ReservationService} from "@services";

const { TabPane } = Tabs;
const { Option } = Select;

const ReservationModalForm = (props) => {
    const user = useSelector((state) => state.user.user);
    const [isReservationNumberUpdating, setIsReservationNumberUpdating] = React.useState(false)
    const { t } = useTranslation()
    const permissions = useSelector((state) => state.user.user.role.permissions);
    useEffect(() => {
        let reservationFields = [];
        let adults = [];
        let children = [];
        reservationFields.push(
            {name:"check_in", value:moment(props.reservation?.check_in_raw)},
            {name:"check_out", value:moment(props.reservation?.check_out_raw)},
            {name:"created_at", value:moment(props.reservation?.created_at_raw)},
            {name:"total_fee", value:props.reservation?.total_fee},
            {name:"reservation_provider", value:props.reservation?.users?.id ?? 0},
            {name:"contract_concept_id", value:props.reservation?.concept?.id ?? undefined},
        )
        props?.reservation?.reservationPeople?.map((person,i) => {
            if (person.type === 1){
                adults.push(
                    {
                        "first_name": person.first_name,
                        "last_name": person.last_name,
                        "birth_date": person.birth_date ? moment(person.birth_date) : null,
                        "email": person.email,
                        "phone_number": person.phone_number,
                        "identity": person.identity,
                        "gender": person.gender,
                        "passport_number": person.passport_number,
                        "passport_country": person.passport_country?.num_code,
                        "country_of_birth": person.country_of_birth?.num_code,
                        "passport_date": person.passport_date ? moment(person.passport_date) : null
                    }
                )
            }else{
                children.push(
                    {
                        "first_name": person.first_name,
                        "last_name": person.last_name,
                        "birth_date": person.birth_date ? moment(person.birth_date) : null,
                        "email": person.email,
                        "phone_number": person.phone_number,
                        "identity": person.identity,
                        "gender": person.gender,
                        "passport_number": person.passport_number,
                        "passport_country": person.passport_country?.num_code,
                        "country_of_birth": person.country_of_birth?.num_code,
                        "passport_date": person.passport_date ? moment(person.passport_date) : null
                    }
                )
            }
        })
        props.reservationForm.setFields(reservationFields)
        props.guestForm.setFields([
            {name:"adults", value:adults},
            {name:"children", value:children}
        ])
    },[props.reservation])
    const onUpdateReservationNumber = async (id) => {
        setIsReservationNumberUpdating(true)
        try {
            let {data: response} = await ReservationService.updateReservationNumber(id)
            if (response.success){
                notification.success({
                    message: 'Başarılı',
                    description: response.message,
                })
                setIsReservationNumberUpdating(false)
                props.getReservations()
            }
        }catch (e){
            if (e.response){
                notification.error({
                    message: 'Hata',
                    description: e.response.data.message,
                })
            }
            setIsReservationNumberUpdating(false)
        }
    }
    return (
        <Modal
            visible={props.isVisible}
            title={props.title}
            closable={false}
            onOk={() => props.onCancel()}
            onCancel={() => props.onCancel()}
            okText="Kapat"
            cancelButtonProps={{ style: { display: 'none' } }}
            bodyStyle={{ overflowY: 'auto', maxHeight: 'calc(100vh - 300px)'}}
            footer={[
                <>
                    {user?.is_super_admin && (
                        <Popconfirm title={t("general.are_you_sure")} disabled={isReservationNumberUpdating} onConfirm={() => onUpdateReservationNumber(props.reservation?.uuid)}>
                            <Button key="back" type="primary" loading={isReservationNumberUpdating}>
                                {t("general.assign_an_updated_reservation_number")}
                            </Button>
                        </Popconfirm>
                    )}
                </>,
                <Button key="back" type="primary" onClick={() => props.onCancel()}>
                    Kapat
                </Button>,
            ]}
        >
            <Tabs>
                <TabPane key="1" tab="Rezervasyon Bilgileri">
                    <Form form={props.reservationForm} layout="vertical" onFinish={(values) => props.onFinishReservationEdit(values, props.reservation?.uuid)}>
                        <Row gutter={[16,4]}>
                            <Col span={12}>
                                <Form.Item label="Rezervasyon Tarihi" name="created_at">
                                    <DatePicker
                                        format="DD.MM.YYYY HH:mm:ss"
                                        style={{width:"100%"}}
                                        showTime
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Giriş Tarihi" name="check_in">
                                    <DatePicker
                                        format="DD.MM.YYYY"
                                        style={{width:"100%"}}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Çıkış Tarihi" name="check_out">
                                    <DatePicker
                                        format="DD.MM.YYYY"
                                        style={{width:"100%"}}
                                    />
                                </Form.Item>
                            </Col>
                            {permissions.includes('reservation.total_fee.edit') && (
                                <Col span={12}>
                                    <Form.Item label="Toplam Ücret" name="total_fee">
                                        <InputNumber addonAfter={props?.reservation?.currency_id?.symbol} style={{width:"100%"}} min={0}/>
                                    </Form.Item>
                                </Col>
                            )}
                            {permissions.includes('reservation.provider.edit') && (
                                <Col span={12}>
                                    <Form.Item label="Rezervasyonu Oluşturan" name="reservation_provider">
                                        <UserSelect allowClear placeholder="Kullanıcı seçiniz" initial={props.reservation.users?.id ?? 0}>
                                            <Option value={0}>Widget</Option>
                                            {props.reservation.users && (
                                                <Option value={props.reservation.users.id}>{props.reservation?.users?.full_name}</Option>
                                            )}
                                        </UserSelect>
                                    </Form.Item>
                                </Col>
                            )}
                            {permissions.includes('reservation.contract_concept.edit') && (
                                <Col span={12}>
                                    <Form.Item
                                        label="Oda Tipi"
                                        name="contract_concept_id"
                                    >
                                        <Select>
                                            {props?.contractConcepts?.map((contractConcept,index) => (
                                                <Option key={index} value={contractConcept.id}>
                                                    {contractConcept?.room_name} <br/>
                                                    <span style={{ fontSize: "12px"}}>{contractConcept?.concept?.active_language?.name}</span>
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            )}
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Button type="primary" htmlType="submit" style={{width:"100%"}}>Rezervasyon Bilgilerini Kaydet</Button>
                            </Col>
                        </Row>
                    </Form>
                </TabPane>
                <TabPane key="2" tab="Müşteri Bilgileri">
                    <Form form={props.guestForm} layout="vertical" onFinish={(values) => props.onFinishGuestEdit(values, props.reservation?.uuid)}>
                        <Tabs>
                            <TabPane tab="Yetişkin Bilgileri" key="1" forceRender={true}>
                                <Form.List
                                    name={['adults']}
                                    rules={[{
                                        validator: async (_, mails) => {
                                            if (!mails || mails.length < 1) {
                                                return Promise.reject(new Error('En az bir yetişkin girmelisiniz.!'));
                                            }
                                        }
                                    }]}
                                >
                                    {(fields, { add, remove }, { errors }) => (
                                        <>
                                            {fields.map((field, index) => (
                                                <div style={{ position: "relative" }} key={field.key}>
                                                    <Form.Item
                                                        label={<h4>{index + 1}. Yetişkin</h4>}
                                                        required={false}
                                                    >
                                                        <Row gutter={[16,0]}>
                                                            <Col span={24}>
                                                                <Form.Item
                                                                    name={[field.name, 'gender']}
                                                                    validateTrigger={['onChange', 'onBlur']}
                                                                    label="Cinsiyet"
                                                                    rules={[{
                                                                        required: true,
                                                                        message: "Cinsiyet seçiniz!"
                                                                    }]}
                                                                >
                                                                    <Radio.Group>
                                                                        <Radio value="Erkek">Erkek</Radio>
                                                                        <Radio value="Kadin">Kadın</Radio>
                                                                    </Radio.Group>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={12}>
                                                                <Form.Item
                                                                    name={[field.name, 'first_name']}
                                                                    validateTrigger={['onChange', 'onBlur']}
                                                                    label="Ad"
                                                                    rules={[{
                                                                        required: true,
                                                                        message: "Ad giriniz!"
                                                                    }]}
                                                                >
                                                                    <Input
                                                                        placeholder="Ad"
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={12}>
                                                                <Form.Item
                                                                    name={[field.name,'last_name']}
                                                                    label="Soyad"
                                                                    validateTrigger={['onChange', 'onBlur']}
                                                                    rules={[{
                                                                        required: true,
                                                                        message: "Soyad giriniz!"
                                                                    }]}
                                                                >
                                                                    <Input
                                                                        placeholder="Soyad"
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={12}>
                                                                <Form.Item
                                                                    name={[field.name,'birth_date']}
                                                                    label="Doğum Tarihi"
                                                                    validateTrigger={['onChange', 'onBlur']}
                                                                    rules={[{
                                                                        required: true,
                                                                        message: "Doğum tarihi giriniz!"
                                                                    }]}
                                                                >
                                                                    <DatePicker
                                                                        format="DD.MM.YYYY"
                                                                        style={{width:"100%"}}
                                                                        placeholder="Doğum Tarihi"
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={12}>
                                                                <Form.Item name={[field.name,'email']} label="E-Posta Adresi">
                                                                    <Input
                                                                        placeholder="E-Posta Adresi"
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={12}>
                                                                <Form.Item name={[field.name,'phone_number']} label="Telefon Numarası">
                                                                    <Input
                                                                        placeholder="Telefon Numarası"
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={12}>
                                                                <Form.Item name={[field.name,'identity']} label="Kimlik Numarası">
                                                                    <Input
                                                                        placeholder="Kimlik Numarası"
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={12}>
                                                                <Form.Item name={[field.name,'passport_date']} label="Pasaport Son Kul. Tarih">
                                                                    <DatePicker
                                                                        format="DD.MM.YYYY"
                                                                        style={{width:"100%"}}
                                                                        placeholder="Pasaport Son Kul. Tarih"
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={12}>
                                                                <Form.Item name={[field.name,'passport_country']} label="Pasaportun Verildiği Ülke">
                                                                    <Select
                                                                        showSearch
                                                                        placeholder="Uyruk"
                                                                        filterOption={(input, option) =>
                                                                            option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                                            option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                        }
                                                                    >
                                                                        {props.countries?.map((country, index) => (
                                                                            <Option key={country.num_code} value={country.num_code}>{country.nationality}</Option>
                                                                        ))}
                                                                    </Select>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={12}>
                                                                <Form.Item name={[field.name,'passport_number']} label="Pasaport Numarası">
                                                                    <Input
                                                                        placeholder="Pasaport Numarası"
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={12}>
                                                                <Form.Item name={[field.name,'country_of_birth']} label="Uyruk">
                                                                    <Select
                                                                        showSearch
                                                                        placeholder="Uyruk"
                                                                        filterOption={(input, option) =>
                                                                            option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                                            option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                        }
                                                                    >
                                                                        {props.countries?.map((country, index) => (
                                                                            <Option key={country.num_code} value={country.num_code}>{country.nationality}</Option>
                                                                        ))}
                                                                    </Select>
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </Form.Item>
                                                    <Button type="dashed" size="small" style={{
                                                        position: 'absolute',
                                                        right: '0',
                                                        top: '0',
                                                    }} onClick={() => remove(field.name)}>
                                                        <FontAwesomeIcon icon={faMinus}/>
                                                    </Button>
                                                </div>
                                            ))}
                                            <Form.Item>
                                                <Button
                                                    type="dashed"
                                                    onClick={() => add()}
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                    icon={<FontAwesomeIcon icon={faPlus}/>}
                                                >
                                                    Yetişkin Ekle
                                                </Button>
                                                <Form.ErrorList errors={errors} />
                                            </Form.Item>
                                        </>
                                    )}
                                </Form.List>
                            </TabPane>
                            <TabPane tab="Çocuk Bilgileri" key="2" forceRender={true}>
                                <Form.List
                                    name={['children']}
                                >
                                    {(fields, { add, remove }, { errors }) => (
                                        <>
                                            {fields.map((field, index) => (
                                                <div style={{ position: "relative" }} key={field.key}>
                                                    <Form.Item
                                                        label={<h4>{index + 1}. Çocuk</h4>}
                                                        required={false}
                                                    >
                                                        <Row gutter={[16,0]}>
                                                            <Col span={24}>
                                                                <Form.Item
                                                                    name={[field.name, 'gender']}
                                                                    validateTrigger={['onChange', 'onBlur']}
                                                                    label="Cinsiyet"
                                                                    rules={[{
                                                                        required: true,
                                                                        message: "Cinsiyet giriniz!"
                                                                    }]}
                                                                >
                                                                    <Radio.Group>
                                                                        <Radio value="Erkek">Erkek</Radio>
                                                                        <Radio value="Kadin">Kadın</Radio>
                                                                    </Radio.Group>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={12}>
                                                                <Form.Item
                                                                    name={[field.name, 'first_name']}
                                                                    validateTrigger={['onChange', 'onBlur']}
                                                                    label="Ad"
                                                                    rules={[{
                                                                        required: true,
                                                                        message: "Ad giriniz!"
                                                                    }]}
                                                                >
                                                                    <Input
                                                                        placeholder="Ad"
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={12}>
                                                                <Form.Item
                                                                    name={[field.name,'last_name']}
                                                                    label="Soyad"
                                                                    validateTrigger={['onChange', 'onBlur']}
                                                                    rules={[{
                                                                        required: true,
                                                                        message: "Soyad giriniz!"
                                                                    }]}
                                                                >
                                                                    <Input
                                                                        placeholder="Soyad"
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={12}>
                                                                <Form.Item
                                                                    name={[field.name,'birth_date']}
                                                                    label="Doğum Tarihi"
                                                                    validateTrigger={['onChange', 'onBlur']}
                                                                    rules={[{
                                                                        required: true,
                                                                        message: "Doğum Tarihi giriniz!"
                                                                    }]}
                                                                >
                                                                    <DatePicker
                                                                        format="DD.MM.YYYY"
                                                                        style={{width:"100%"}}
                                                                        placeholder="Doğum Tarihi"
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </Form.Item>
                                                    <Button type="dashed" size="small" style={{
                                                        position: 'absolute',
                                                        right: '0',
                                                        top: '0',
                                                    }} onClick={() => remove(field.name)}>
                                                        <FontAwesomeIcon icon={faMinus}/>
                                                    </Button>
                                                </div>
                                            ))}
                                            <Form.Item>
                                                <Button
                                                    type="dashed"
                                                    onClick={() => add()}
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                    icon={<FontAwesomeIcon icon={faPlus}/>}
                                                >
                                                    Çocuk Ekle
                                                </Button>
                                                <Form.ErrorList errors={errors} />
                                            </Form.Item>
                                        </>
                                    )}
                                </Form.List>
                            </TabPane>
                        </Tabs>
                        <Row>
                            <Col span={24}>
                                <Button type="primary" htmlType="submit" style={{width:"100%"}}>Rezervasyon Bilgilerini Kaydet</Button>
                            </Col>
                        </Row>
                    </Form>
                </TabPane>
            </Tabs>
        </Modal>
    );
};

export default ReservationModalForm;