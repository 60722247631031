import axios from '../axios';

const ReservationPaymentsService = {
    async get() {
        return await axios.get(`/v1/reservation-payments`);
    },
    async getByReservation(reservationId) {
        return await axios.get(`/v1/reservation-payments/reservation/${reservationId}`);
    },
    async createPaymentLink(reservationId, formData) {
        return await axios.post(`/v1/reservation-payments/reservation/${reservationId}/payment-link`,formData);
    },
    async checkStatusPaymentLink(reservationId, paymentLinkId) {
        return await axios.get(`/v1/reservation-payments/reservation/${reservationId}/payment-link/${paymentLinkId}/check-status`);
    },
    async getPaymentLink(reservationId) {
        return await axios.get(`/v1/reservation-payments/reservation/${reservationId}/payment-link`);
    },
    async createBalanceCompletion(reservationId, paymentId, formData) {
        return await axios.post(`/v1/reservation-payments/reservation/${reservationId}/payment/${paymentId}/balance-completion`,formData);
    },
    async deleteBalanceCompletion(reservationId,paymentId, balanceCompletionId) {
        return await axios.delete(`/v1/reservation-payments/reservation/${reservationId}/payment/${paymentId}/balance-completion/${balanceCompletionId}`);
    },
    async approve(paymentId,formData) {
        return await axios.post(`/v1/reservation-payments/${paymentId}/approve`,formData);
    },
    async cancel(paymentId) {
        return await axios.post(`/v1/reservation-payments/${paymentId}/cancel`);
    },
    async delete(reservationPaymentId) {
        return await axios.delete(`/v1/reservation-payments/${reservationPaymentId}`);
    },
    async changeInvoiceVisibility(reservationPaymentId) {
        return await axios.post(`/v1/reservation-payments/${reservationPaymentId}/change-invoice-visibility`);
    },
    async create(formData) {
        return await axios.post(`/v1/reservation-payments`,formData);
    },
    async deleteDocument(reservationPaymentId, documentId) {
        return await axios.delete(`/v1/reservation-payments/${reservationPaymentId}/document/${documentId}`);
    }
};

export default ReservationPaymentsService;