import React, {useEffect, useState} from 'react';
import Wrapper from '@components/Wrapper';
import {Layout, Breadcrumb, Button, Table, notification, Form, Space, Popconfirm, Tag} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faHouse, faEdit, faTrash, faPlus} from '@fortawesome/free-solid-svg-icons';
import {ConceptService, WebLanguageService} from '@services';
import ConceptModalForm from '@components/concepts/ConceptModalForm';
import {useSelector} from 'react-redux';
import {useParams} from "react-router-dom";
import conceptModalForm from "../../components/concepts/ConceptModalForm";

const {Content} = Layout;

const Concept = () => {
    const params = useParams()
    const [concepts, setConcepts] = useState([]);
    const [webLanguages, setWebLanguages] = useState([]);
    const [isLoadingTable, setIsLoadingTable] = useState(true);
    const columns = [
        {
            title: '#',
            render: (text, record, index) => index + 1
        },
        {
            title: 'Konsept Adı',
            dataIndex: 'name',
        },
        {
            title: 'Durum',
            key: 'is_active',
            render: (text, record) => (
                <Tag color={record.is_active ? 'green' : 'red'}>{record.is_active ? 'Aktif' : 'Pasif'}</Tag>
            )
        },
        {
            title: 'İşlemler',
            key: 'actions',
            render: (text, record) => (
                <Space align="middle">
                    <Button type="primary" onClick={() => onEdit(record.id)}>
                        <FontAwesomeIcon icon={faEdit}/>
                    </Button>

                    <Popconfirm title="Silmek istediğine emin misin?" onConfirm={() => onDelete(record.id)} okText="Sil"
                                cancelText="İptal">
                        <Button type="danger">
                            <FontAwesomeIcon icon={faTrash}/>
                        </Button>
                    </Popconfirm>
                </Space>
            )
        }
    ];

    /* create modal form states */
    const [createModalForm] = Form.useForm();
    const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);

    /* edit modal form states */
    const [editModalForm] = Form.useForm();
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [selectedConcept, setSelectedConcept] = useState({});

    useEffect(() => {
        getConcepts();
        getWebLanguages();
    }, []);

    const getWebLanguages = async () => {
        try {
            let { data: { data: response } } = await WebLanguageService.getHotelLanguage(params.hotelId);
            setWebLanguages(response);
        } catch (e) {
            console.log(e);
        }
    };

    const getConcepts = async () => {
        try {
            let {data: {data: concepts}} = await ConceptService.get(params.hotelId);
            setConcepts(concepts);
            setIsLoadingTable(false);
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    };

    const onSave = async (values) => {
        try {
            await ConceptService.create(params.hotelId, values);
            setIsLoadingTable(true);
            setIsCreateModalVisible(false);
            getConcepts();
            createModalForm.resetFields();
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    };

    const onEdit = async (conceptId) => {
        try {
            let {data: {data: concept}} = await ConceptService.findById(params.hotelId, conceptId);
            let preparedInputs = webLanguages.map((language) => {
                    editModalForm.setFields([
                        {
                            name: ['concepts', language.web_language["iso_639-1"], 'features'],
                            value: concept.names.filter((item) => item.language === language.web_language["iso_639-1"])[0]?.features || [],
                        }
                    ]);
                    return {
                        name: ['concepts', language.web_language["iso_639-1"], 'name'],
                        value: concept.names.filter((item) => item.language === language.web_language["iso_639-1"])[0]?.name || '',
                    };
                }
            );

            setSelectedConcept({
                ...concept,
                fields: preparedInputs
            });

            setIsEditModalVisible(true);
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    };

    const onUpdate = async (values, conceptId) => {
        try {
            let response = await ConceptService.update(params.hotelId, conceptId, values);
            getConcepts();
            setIsEditModalVisible(false);
            editModalForm.resetFields();
            notification.success({
                message: response.data.message
            });
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.message
                });
            }
        }
    };

    const onDelete = async (conceptId) => {
        try {
            let {data: response} = await ConceptService.delete(params.hotelId, conceptId);
            if (response.success) {
                notification.success({
                    message: response.message
                });
                setIsLoadingTable(true);
                getConcepts();
            }
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.message
                });
            }
        }
    };

    return (
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-20">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse}/>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        Konseptler
                    </Breadcrumb.Item>
                </Breadcrumb>

                <div className="site-layout-background">
                    <Button type="primary" className="mb-10" onClick={() => setIsCreateModalVisible(true)}>
                        <FontAwesomeIcon icon={faPlus} className="mr-10"/>
                        Yeni Konsept Tanımla
                    </Button>

                    <Table
                        loading={isLoadingTable}
                        columns={columns}
                        dataSource={concepts}
                        rowKey="id"
                        bordered
                        pagination={{
                            position: ['none']
                        }}
                    >
                    </Table>
                </div>
            </Content>

            <ConceptModalForm
                title="Konsept Oluştur"
                isVisible={isCreateModalVisible}
                form={createModalForm}
                onFinish={onSave}
                onCancel={() => setIsCreateModalVisible(false)}
                languages={webLanguages}
            />

            <ConceptModalForm
                title="Konsept Düzenle"
                isVisible={isEditModalVisible}
                form={editModalForm}
                onFinish={onUpdate}
                onCancel={() => setIsEditModalVisible(false)}
                data={selectedConcept}
                languages={webLanguages}
            />
        </Wrapper>
    );
};

export default Concept;