import { configureStore } from "@reduxjs/toolkit";
import appReducer from '@features/appSlice';
import userReducer from '@features/userSlice';
import hotelReducer from '@features/hotelSlice';
import notificationReducer from '@features/notificationSlice';

export default configureStore({
  reducer: {
    app: appReducer,
    user: userReducer,
    hotel: hotelReducer,
    notifications: notificationReducer
  }
});