import React, {useEffect} from 'react';
import {
    Modal,
    Form,
    Row,
    Col,
    Button,
    Tooltip,
    DatePicker,
    Select,
    InputNumber,
    notification,
    Radio,
    Input
} from 'antd';
import {useTranslation} from "react-i18next";
import {ContractGroupsService} from "@services";
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";

const {RangePicker} = DatePicker;
const {Option} = Select;

const ContractGroupPaymentPeriodModalForm = ({
    onSuccess = () => {},
    onCancel = () => {},
    title = "",
    visible = false,
    contractGroup = {}
}) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const onSubmit = async (values) => {
        try {
            let {data: response} = await ContractGroupsService.updatePaymentPeriod(contractGroup.id, values);
            if (response.success){
                notification.success({
                    message: response.message
                });
                onSuccess();
            }
        }catch (e){
            if (e.response){
                notification.error({
                    message: e.response.data.message
                })
            }
        }
    }
    useEffect(() => {
        if (contractGroup.payment_periods){
            form.setFields([
                {
                    name: "periods",
                    value: contractGroup.payment_periods.map((period) => {
                        return {
                            sale_date_range: [moment(period.sale_date_start), moment(period.sale_date_end)],
                            payment_date: period.payment_date ? moment(period.payment_date): undefined,
                            value: period.value,
                            type: period.type ? period.type : undefined,
                            day: (period.day || period.day === 0) ? period.day : undefined
                        }
                    })
                }
            ]);
        }
    },[contractGroup])
    return (
        <>
            <Modal visible={visible} title={title} closable={false} onCancel={() => {
                form.resetFields();
                onCancel();
            }} onOk={() => form.submit()} width={1200}>
                <Form form={form} layout="vertical" onFinish={onSubmit}>
                    <Form.List
                        name={['periods']}
                    >
                        {(fields, { add, remove }, { errors }) => (
                            <>
                                {fields.map((field, index) => (
                                    <div style={{ position: "relative" }} key={field.key}>
                                        <Form.Item
                                            label={<h4>{index + 1}. Periyot</h4>}
                                            required={false}
                                        >
                                            <Row gutter={[16,0]}>
                                                <Col span={8}>
                                                    <Form.Item
                                                        name={[field.name, "sale_date_range"]}
                                                        label={t("general.sale_date_range")}
                                                        rules={[{required: true, message: t("general.required")}]}
                                                    >
                                                        <RangePicker
                                                            style={{width: "100%"}}
                                                            placeholder={[t("general.start_date"), t("general.end_date")]}
                                                            format="DD.MM.YYYY"
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={8}>
                                                    <Form.Item
                                                        name={[field.name, "payment_date"]}
                                                        label={t("general.deferred_payment_date")}
                                                    >
                                                        <DatePicker
                                                            style={{width: "100%"}}
                                                            placeholder={t("general.deferred_payment_date")}
                                                            format="DD.MM.YYYY"
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={8}>
                                                    <Form.Item
                                                        name={[field.name, "value"]}
                                                        label={t("general.value")}
                                                        rules={[{required: true, message: t("general.required")}]}
                                                    >
                                                        <InputNumber
                                                            style={{width: "100%"}}
                                                            placeholder={t("general.value")}
                                                            addonBefore="%"
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item
                                                        name={[field.name, "type"]}
                                                        label={t("general.type")}
                                                    >
                                                        <Select
                                                            placeholder={t("general.choose")}
                                                        >
                                                            <Option value="before_check_in">{t("general.before_check_in")}</Option>
                                                            <Option value="after_check_out">{t("general.after_check_out")}</Option>
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item
                                                        name={[field.name, "day"]}
                                                        label={t("general.day")}
                                                    >
                                                        <InputNumber
                                                            style={{width: "100%"}}
                                                            placeholder={t("general.day")}
                                                            min={0}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Form.Item>
                                        <Button type="dashed" size="small" style={{
                                            position: 'absolute',
                                            right: '0',
                                            top: '0',
                                        }} onClick={() => remove(field.name)}>
                                            <FontAwesomeIcon icon={faMinus}/>
                                        </Button>
                                    </div>
                                ))}
                                <Form.Item>
                                    <Button
                                        type="dashed"
                                        onClick={() => add()}
                                        style={{
                                            width: '100%',
                                        }}
                                        icon={<FontAwesomeIcon icon={faPlus} style={{marginRight:"6px"}}/>}
                                    >
                                        {t("general.add_period")}
                                    </Button>
                                    <Form.ErrorList errors={errors} />
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                </Form>
            </Modal>
        </>
    )
};

export default ContractGroupPaymentPeriodModalForm;