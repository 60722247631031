import React from 'react';
import {Col, Form, Input, Modal, Row} from "antd";

const CmConditionGroupCreateModalForm = (props) => {
    return (
        <Modal visible={props.isVisible} title={props.title} closable={false} onOk={() => props.form.submit()} onCancel={props.onCancel}>
            <Form form={props.form} layout="vertical" onFinish={(values) => props.onFinish(values, props.data?.id)} initialValues={{ is_active: true }} fields={props.data?.fields}>
                <Row gutter={[16,0]}>
                    <Col span={24}>
                        <Form.Item
                            name="title"
                            label="Kondüsyon Grup Adı"
                            rules={[{ required: true, message: 'Lütfen kondüsyon grup adını giriniz.' }]}
                        >
                            <Input placeholder="Kondüsyon grup adını giriniz"/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default CmConditionGroupCreateModalForm;