import React from "react";
import {Modal, Row, Col, Form, Input, notification} from "antd";
import MainRegionDefinitionSelect from "../utils/MainRegionDefinitionSelect";
import {MainRegionService} from "../../services";


const CityModalForm = (props) => {

    return (
        <Modal visible={props.isVisible} title={props.title} closable={false} onOk={() => props.form.submit()} onCancel={props.onCancel}>
            <Form form={props.form} layout="vertical" onFinish={(values) => props.onFinish(values, props.data?.id)} fields={props.data?.fields}>
                <Row gutter={[16,16]}>
                    <Col span={12}>
                        <Form.Item name="main_region_id" label="Ana Bölge" rules={[{ required: true, message: 'Ana Bölge seçimi boş bırakılamaz.' }]}>
                            <MainRegionDefinitionSelect placeholder="Ana Bölge seçimi yapınız."/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="name" label="Şehir Adı" rules={[{ required: true, message: 'Şehir adı alanı boş bırakılamaz.' }]}>
                            <Input placeholder="Şehir adını giriniz." />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="alternative_names" label="Alternatif Adlar">
                            <Input placeholder="Alternatif adlar giriniz." />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default CityModalForm;