import React, {useEffect} from 'react';
import {Col, DatePicker, Form, Input, InputNumber, Modal, Row, Select} from "antd";
import TextArea from "antd/es/input/TextArea";

const { Option } = Select;

const ServiceInput = ({ field, fields, onChange, setFormFields, formFields }) => {
    const onSelectChange = (value) => {
        if (field.conditionalFields?.[value]?.length > 0){
            return setFormFields([...fields, ...field.conditionalFields[value]])
        }
        return setFormFields(fields)
    }
    switch (field.type) {
        case 'string':
            return <Input style={{ width: "100%" }} onChange={onChange} placeholder="Bilgiyi giriniz."/>
        case 'number':
            return <InputNumber style={{ width: "100%" }} onChange={onChange} placeholder="Bilgiyi giriniz."/>
        case 'select':
            return (
                <Select style={{ width: "100%" }} onSelect={onSelectChange} onChange={onChange} placeholder="Bilgiyi seçiniz.">
                    {field.options.map((option, index) => (
                        <Option key={index} value={option.value}>{option.label}</Option>
                    ))}
                </Select>
            )
        case 'date':
            return <DatePicker style={{ width: "100%" }} format="DD.MM.YYYY" onChange={(value) => onChange(value.format('YYYY-MM-DD'))} placeholder="Bilgiyi giriniz."/>
        case 'datetime':
            return <DatePicker showTime style={{ width: "100%" }} format="DD.MM.YYYY HH:mm:ss" onChange={(value) => onChange(value.format('YYYY-MM-DD HH:mm:ss'))} placeholder="Bilgiyi giriniz."/>
        case 'textarea':
            return <TextArea style={{ width: "100%" }} onChange={onChange} placeholder="Bilgiyi giriniz."/>
        default:
            return <Input style={{ width: "100%" }} onChange={onChange} placeholder="Bilgiyi giriniz."/>
    }
}

const AdditionalServiceProperties = ({ onFinish, children, fields = [] }) => {
    const [form] = Form.useForm()
    const [isVisible, setIsVisible] = React.useState(false)
    const [formFields, setFormFields] = React.useState(fields)
    useEffect(() => {
        setFormFields(fields)
    },[fields])
    return (
        <>
            {React.cloneElement(children, {
                onClick: () => setIsVisible(true)
            })}
            <Modal visible={isVisible} title="Ek Hizmet Bilgileri" closable={false} onOk={() => form.submit()} onCancel={() => setIsVisible(false)}>
                <Form form={form} layout="vertical" onFinish={(values) => {
                    onFinish(values)
                    setIsVisible(false)
                }}>
                    <Row gutter={[16,0]}>
                        {formFields.map((field, index) => (
                            <Col span={24} key={index}>
                                <Form.Item name={field.key} label={field.label} rules={[{ required: field.required, message: 'Bu alan zorunludur.' }]}>
                                    <ServiceInput field={field} setFormFields={setFormFields} formFields={formFields} fields={fields}/>
                                </Form.Item>
                            </Col>
                        ))}
                    </Row>
                </Form>
            </Modal>
        </>
    );
};

export default AdditionalServiceProperties;