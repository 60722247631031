import React from "react";
import {Modal, Row, Col, Form, Input} from "antd";
import CountryDefinitionSelect from "@components/utils/CountryDefinitionSelect";
import CityDefinitionSelect from "@components/utils/CityDefinitionSelect";

const AirportModalForm = (props) => {
    return (
        <Modal visible={props.isVisible} title={props.title} closable={false} onOk={() => props.form.submit()} onCancel={props.onCancel}>
            <Form form={props.form} layout="vertical" onFinish={(values) => props.onFinish(values, props.data?.id)} fields={props.data?.fields}>
                <Row gutter={[16,16]}>
                    <Col span={12}>
                        <Form.Item name="country_id" label="Ülke" rules={[{ required: true, message: 'Ülke seçim alanı boş bırakılamaz.' }]}>
                            <CountryDefinitionSelect placeholder="Ülke seçimi yapınız"/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="city_id" label="Şehir" rules={[{ required: true, message: 'Şehir seçim alanı boş bırakılamaz.' }]}>
                            <CityDefinitionSelect placeholder="Şehir seçimi yapınız"/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="name" label="Havalimanı Adı" rules={[{ required: true, message: 'Havalimanı adı alanı boş bırakılamaz.' }]}>
                            <Input placeholder="Havalimanı adını giriniz." />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="short_name" label="Havalimanı Kısa Ad" rules={[{ required: true, message: 'Havalimanı kısa ad alanı boş bırakılamaz.' }]}>
                            <Input placeholder="Havalimanı kısa adını giriniz." />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default AirportModalForm;