import axios from '../axios';

const ContractMobileDiscountsService = {
    async getAllByAgency(params) {
        return await axios.get(`/v1/contract-mobile-discounts`, { params });
    },
    async getById(id) {
        return await axios.get(`/v1/contract-mobile-discounts/${id}`);
    },
    async create(formData) {
        return await axios.post(`/v1/contract-mobile-discounts`, formData);
    },
    async update(formData, id) {
        return await axios.post(`/v1/contract-mobile-discounts/${id}`, formData);
    },
    async delete(contractMobileDiscountId) {
        return await axios.delete(`/v1/contract-mobile-discounts/${contractMobileDiscountId}`);
    }
};

export default ContractMobileDiscountsService;