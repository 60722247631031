import React, { useEffect, useState } from 'react';
import {Select} from 'antd';
import { ExternalHotelService } from '@services';

const { Option } = Select;

const ExternalConditionGroupSelect = ({ value = null,  onChange, hotelId, placeholder = "" }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [conditions, setConditions] = useState({
        current_page: 1,
        next_page: 1,
        previous_page: 1,
        data: []
    });

    useEffect(() => {
        if (!hotelId) return null;
        getConditions(hotelId);
    }, []);

    const getConditions = async (hotelId, page = 1) => {
        try {
            let { data: response } = await ExternalHotelService.getConditionGroups(hotelId, page);
            setConditions(response);
        } catch (e) {
            console.log(e);
        }
    };

    const onScroll = (e) => {
        let target = e.target;

        if (! isLoading && conditions.next_page > conditions.current_page && (target.scrollTop + target.offsetHeight) >= (target.scrollHeight * 0.8)) {
            setIsLoading(true);
            getConditions(hotelId, conditions.next_page);
        }
    };

    const handleChange = (value) => {
        if (onChange) {
            onChange(value);
        }
    };

    return (
        <Select placeholder={placeholder} value={value} onScroll={onScroll} onChange={handleChange} loading={isLoading}>
            {conditions.data.map((condition, index) => <Option key={index} value={condition.id}>{condition.title}</Option>)}
        </Select>
    );
};

export default ExternalConditionGroupSelect;