import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    Layout,
    Breadcrumb,
    Button,
    notification,
    Form,
    Row,
    Col,
    Input,
    InputNumber,
    Table
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse, faPlus, faSave } from '@fortawesome/free-solid-svg-icons';
import Wrapper from '@components/Wrapper';
import { ExternalHotelService } from '@services';
import {useTranslation} from "react-i18next";

const { Content } = Layout;

const ExternalHotelConditionList = () => {
    const params = useParams();
    const { t } = useTranslation();
    const [createForm] = Form.useForm();
    const [conditions, setConditions] = useState({
        data: [],
        meta: {},
        loading: true
    });
    const [columns, setColumns] = useState([
        {
            title: 'Kondüsyon Adı',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Yetişkin Sayısı',
            dataIndex: 'adult',
            key: 'adult',
        },
        {
            title: 'İşlemler',
            dataIndex: 'actions',
            key: 'actions',
        }
    ])

    const [childAges, setChildAges] = useState({
        data: [],
        meta: {},
        loading: true
    });
    const getConditions = async (hotelId, conditionGroupId, page = 1) => {
        try {
            let { data: response } = await ExternalHotelService.getConditions(hotelId, conditionGroupId, page);
            setConditions({
                data: response.data,
                meta: response.meta,
                loading: false
            })
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    };

    const onCreate = async (values) => {
        try {
            let { data: response } = await ExternalHotelService.createCondition(params.hotelId, params.conditionGroupId, values);
            if (response.success) {
                notification.success({
                    message: response.message
                });
                getConditions(params.hotelId, params.conditionGroupId);
                createForm.resetFields();
            }
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.message
                });
            }
            console.log(e);
        }
    };
    const getChildAges = async (hotelId, page = 1) => {
        setColumns([
            {
                title: 'Kondüsyon Adı',
                dataIndex: 'title',
                key: 'title',
            },
            {
                title: 'Yetişkin Sayısı',
                dataIndex: 'adult',
                key: 'adult',
            },
            {
                title: 'İşlemler',
                dataIndex: 'actions',
                key: 'actions',
            }
        ])
        try {
            let { data: response } = await ExternalHotelService.getChildAges(hotelId, page);
            // eslint-disable-next-line array-callback-return
            response.data.map((item) => {
                setColumns(prev => [
                    ...prev.filter(column => column.key !== `actions`),
                    {
                        title: item.age_type,
                        dataIndex: `children-${item.id}`,
                        key: `children-${item.id}`,
                        render: (text) => text ? text : 0
                    },
                    {
                        title: 'İşlemler',
                        dataIndex: 'actions',
                        key: 'actions',
                    }
                ])
            })
            setChildAges({
                data: response.data,
                meta: response.meta,
                loading: false
            })
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    };
    useEffect(() => {
        getChildAges(params.hotelId).then(() => getConditions(params.hotelId, params.conditionGroupId));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-20">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse} />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        Otel Kondüsyonları
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background">
                    <Form form={createForm} onFinish={(values) => onCreate(values)} layout="vertical" style={{
                        marginBottom: '20px'
                    }}>
                        <Row gutter={16} justify="start">
                            <Col>
                                <Form.Item
                                    name="title"
                                    label={t("general.condition_name")}
                                    rules={[{
                                        required: true,
                                        message: "Kondüsyon adı zorunludur!"
                                    }]}
                                >
                                    <Input placeholder={t("general.condition_name")} />
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item
                                    name="adult"
                                    label={t("general.adult_count")}
                                    rules={[{
                                        required: true,
                                        message: "Yetişkin sayısı zorunludur!"
                                    }]}
                                >
                                    <InputNumber placeholder="Yetişkin Sayısı" />
                                </Form.Item>
                            </Col>
                            {childAges.data?.map((item, index) => (
                                <Col key={index}>
                                    <Form.Item name={`children-${item.id}`} label={item.age_type}>
                                        <InputNumber placeholder={item.age_type} />
                                    </Form.Item>
                                </Col>
                            ))}
                            <Col span={24}>
                                <Button type="primary" htmlType="submit" style={{
                                    width: '100%'
                                }}>
                                    <FontAwesomeIcon icon={faSave} className="mr-10" />
                                    {t("general.save")}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                    <Table
                        columns={columns}
                        dataSource={conditions.data}
                        loading={conditions.loading || childAges.loading}
                    />
                </div>
            </Content>
        </Wrapper >
    )
};

export default ExternalHotelConditionList;