import React, {useEffect, useState} from "react";
import {Col, Form, Input, InputNumber, Modal, notification, Row, Select} from "antd";
import {useTranslation} from "react-i18next";

const {Option, OptGroup} = Select;



function ContractCurrencyModalForm(props){
    const { t } = useTranslation()
    const [selectedInitialValues, setSelectedInitialValues] = useState(null);
    const onSelectAll = (option) => {
        const allValues = props?.contracts?.data.map((contract) => (
            contract.id
        ))
        if(option === "all"){
            return props.form.setFieldsValue({
                contracts:allValues
            })
        }
        return false;
    }


    useEffect(()=>{
        if(props.currency_id){
            let initialValues = props.currency_id?.data?.currency_id;
            setSelectedInitialValues(initialValues)
            props.form.setFieldsValue({currency_id:initialValues})
        }
    },[props.currency_id])
    return(
        <Modal visible={props.isVisible} title={props.title} closable={false} onOk={() => props.form.submit()} onCancel={props.onCancel}>
            <Form form={props.form} layout="vertical" onFinish={(values) => props.onFinish(values, props.contracts?.data?.[0].contract_group_id)} fields={props.data?.fields}>
                <Row gutter={[16,16]}>
                    <Col span={24}>
                        <Form.Item name="currency_id" label={t("general.currencies")} initialValue={selectedInitialValues}>
                            <Select placeholder={t("general.currencies")} showSearch filterOption={
                                (input, option) => option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                            } onSelect={(option) => onSelectAll(option)}>
                                {props?.currencies?.data?.sort(function (a,b){return b.common - a.common})?.map((contract,i)=>(
                                    <Option key={i} value={contract.id}>{contract.currency} ({contract?.country })</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}
export default ContractCurrencyModalForm