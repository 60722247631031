import axios from '../../axios';

const VehicleService = {
    async get(page = 1, per_page = 10) {
        return await axios.get(`/lara-beach/vehicles?page=${page}&per_page=${per_page}`);
    },
    async find(id){
        return await axios.get(`/lara-beach/vehicles/${id}`);
    },
    async update(id, formData){
        return await axios.post(`/lara-beach/vehicles/${id}`, formData);
    },
    async create(formData){
        return await axios.post('/lara-beach/vehicles', formData);
    },
    async updateFiles(id, formData){
        return await axios.post(`/lara-beach/vehicles/${id}/files`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }
};

export default VehicleService;