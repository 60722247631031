import React from 'react';
import {Col, Form, Input, Modal, Row, Select} from "antd";
import {useTranslation} from "react-i18next";
import HotelRoomSelect from "@/components/utils/HotelRoomSelect";

const {Option} = Select

const ContractModalForm = (props) => {
    const { t } = useTranslation()
    return (
        <Modal visible={props.isVisible} title={props.title} closable={false} onCancel={props.onCancel} onOk={() => props.form.submit()} okText={t("general.save")} cancelText={t("general.cancel")}>
            <Form form={props.form} layout="vertical" onFinish={(values) => props.onFinish(values, props.data?.id)}>
                <Row gutter={[16,0]}>
                    <Col span={12}>
                        <Form.Item name="title" label={t("general.title")}>
                            <Input
                                placeholder={t("general.title")}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="room_id" label={t("general.room")}>
                            <HotelRoomSelect
                                hotelId={props.hotelId}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="calculating_policy" label={t("general.calculating_policy")} tooltip={t("general.multiply_not_support_integration")}>
                            <Select placeholder={t("general.calculating_policy")}>
                                <Option value="multiply">
                                    {t("general.multiply")}
                                </Option>
                                <Option value="fixed">
                                    {t("general.fixed_price")}
                                </Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            {props.hotelId + " id'li otel için kontrat işlevi yapılmaktadır."}
        </Modal>
    );
};

export default ContractModalForm;