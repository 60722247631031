import axios from '../axios';

const ExternalPropertyService = {
    async get() {
        return await axios.get(`/v1/external/properties`);
    },
    async getPaginate(page, per_page) {
        return await axios.get(`/v1/external/properties?page=${page}&per_page=${per_page}`);
    },
    async update(values, id) {
        return await axios.post(`/v1/external/properties/${id}`, values);
    },
    async delete(id) {
        return await axios.delete(`/v1/external/properties/${id}`);
    },
    async create(values) {
        return await axios.post(`/v1/external/properties`, values);
    },
};

export default ExternalPropertyService;