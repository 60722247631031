import React, {useEffect} from 'react';
import {Modal, Form, Row, Col, Select} from 'antd';
import {CurrentDeficitService} from "@services";
import {useTranslation} from "react-i18next";

const {Option} = Select

const ContractGroupCariModalForm = (props) => {
    const { t } = useTranslation()
    const [isCariSearchLoading, setIsCariSearchLoading] = React.useState(false)
    const [cariSearchValue, setCariSearchValue] = React.useState('')
    const [caris, setCaris] = React.useState([])
    const onSearchCari = async (value) => {
        try {
            let {data: response} = await CurrentDeficitService.search(value, props?.currency?.data?.currency_id)
            setCaris(response.data)
            setIsCariSearchLoading(false)
        }catch (e){
            //
        }
    }
    useEffect(() => {
        if(cariSearchValue === '') return
        setIsCariSearchLoading(true)
        const timer = setTimeout(() => {
            onSearchCari(cariSearchValue)
        }, 600);
        return () => clearTimeout(timer);
    },[cariSearchValue])
    return (
        <Modal visible={props.isVisible} title={props.title} closable={false} onOk={() => props.form.submit()} onCancel={props.onCancel}>
            <Form form={props.form} layout="vertical" onFinish={props.onFinish}>
                <Row gutter={[16,8]}>
                    <Col span={24}>
                        <Form.Item
                            name="cari"
                            label={t("general.cari")}
                        >
                            <Select
                                showSearch
                                defaultActiveFirstOption={false}
                                loading={isCariSearchLoading}
                                filterOption={false}
                                value={cariSearchValue}
                                onSearch={(value) => setCariSearchValue(value)}
                                placeholder={t("general.search")}
                                notFoundContent={t("general.not_found")}
                                allowClear
                            >
                                {caris.map(cari => (
                                    <Option key={cari.id} value={cari.id}>{cari?.cari?.name} | {cari?.company_title} - {cari?.bill_title}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
};

export default ContractGroupCariModalForm;