import axios from '../axios';

const RegionService = {
    async get(){
        return await axios.get(`/v1/definitions/regions`);
    },
    async getPaginate(page) {
        return await axios.get(`/v1/definitions/regions?page=${page}`);
    },
    async create(formData) {
        return await axios.post(`/v1/definitions/regions`, formData);
    },
    async delete(id) {
        return await axios.delete(`/v1/definitions/regions/${id}`);
    },
    async search(search){
        return await axios.get(`/v1/definitions/regions?search=${search}`);
    },
    async hotelRegion(formData){
        return await axios.post(`/v1/definitions/regions/hotels`, formData);
    }
};

export default RegionService;