import React from 'react';
import {Col, Form, Input, Modal, Row} from "antd";

const { TextArea } = Input;

const CmConceptCreateModalForm = (props) => {
    return (
        <Modal visible={props.isVisible} title={props.title} closable={false} onOk={() => props.form.submit()} onCancel={props.onCancel}>
            <Form form={props.form} layout="vertical" onFinish={(values) => props.onFinish(values)} initialValues={{ is_active: true }} fields={props.data?.fields}>
                <Row gutter={[16,0]}>
                    <Col span={24}>
                        <Form.Item
                            name="name"
                            label="Konsept Adı"
                            rules={[{ required: true, message: 'Lütfen konsept adını giriniz.' }]}
                        >
                            <Input placeholder="Konsept adını giriniz"/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="description"
                            label="Konsept Açıklaması"
                            rules={[{ required: true, message: 'Lütfen konsept açıklamasını giriniz.' }]}
                        >
                            <TextArea
                                autoSize={{ minRows: 3, maxRows: 5 }}
                                placeholder="Konsept açıklamasını giriniz"/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default CmConceptCreateModalForm;