import React, {useEffect, useMemo, useRef, useState} from 'react';
import {IconServiceV2} from "@services";
import {Select, Space, Spin} from "antd";
import debounce from 'lodash/debounce';

const { Option } = Select;

const IconSelect2 = ({ onChange = (value) => {}, placeholder = "Choose", value }) => {
    async function getIcons(search) {
        try {
            let {data: response} = await IconServiceV2.get(search)
            return response.data.map((item) => ({
                id: item.id,
                value: item.content,
            }));
        }catch (e){
            return [];
        }
    }
    return (
        <DebounceSelect
            value={value}
            onChange={(value) => onChange(value)}
            placeholder={placeholder}
            fetchOptions={getIcons}
            style={{
                width: '100%',
            }}
        />
    );
};

const DebounceSelect = ({ fetchOptions, debounceTimeout = 800, ...props }) => {
    const [fetching, setFetching] = useState(false);
    const [options, setOptions] = useState([]);
    const fetchRef = useRef(0);
    const debounceFetcher = useMemo(() => {
        const loadOptions = (value) => {
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            setOptions([]);
            setFetching(true);
            fetchOptions(value).then((newOptions) => {
                if (fetchId !== fetchRef.current) {
                    return;
                }
                setOptions(newOptions);
                setFetching(false);
            });
        };
        return debounce(loadOptions, debounceTimeout);
    }, [fetchOptions, debounceTimeout]);
    useEffect(() => {
        return () => {
            fetchRef.current += 1;
        };
    }, []);
    return (
        <Select
            {...props}
            filterOption={false}
            showSearch={true}
            onSearch={debounceFetcher}
            notFoundContent={fetching ? <Spin size="small" /> : null}
            optionLabelProp="label"
            onChange={(newValue, option) => props.onChange(newValue, option)}
        >
            {options.map((option) => (
                <Option key={option.id} value={option.id} title={option.id}>
                    <Space>
                        <div dangerouslySetInnerHTML={{__html: option.value}} className="icon-selector-icon" />
                        {option.id}
                    </Space>
                </Option>
            ))}
        </Select>
    );
}

export default IconSelect2;