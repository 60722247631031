import axios from '../axios';

const CountriesService = {
    async get() {
        return await axios.get(`/v1/countries`);
    },
    async getSelectedCountry(contract_group_id) {
        return await axios.get(`/v1/contract-countries/${contract_group_id}`);
    },
    async getNationality(hotelId) {
        return await axios.get(`/v1/hotels/${hotelId}/rooms`);
    },
    async getNationalities() {
        return await axios.get(`/v1/nationalities`);
    },
    async search(formData) {
        console.log(formData);
        return await axios.post(`/v1/searchAdmin`, formData);
    },

    async findById(hotelId) {
        return await axios.get(`/v1/hotels/${hotelId}`);
    },

    async create(formData) {
        return await axios.post('/v1/contract-countries', formData);
    },

    async update(hotelId, formData) {
        return await axios.post(`/v1/hotels/${hotelId}`, formData);
    },

    async delete(hotelId) {
        return await axios.delete(`/v1/hotels/${hotelId}`);
    }
}

export default CountriesService;