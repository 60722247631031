import React from 'react';
import {Button, Col, Form, Input, Modal, Row, Space, Spin} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";

const ReservationConfirmSendModal = (props) => {
    return (
        <Modal visible={props.isVisible} title={props.title} closable={false} onOk={() => props.form.submit()} onCancel={props.onCancel}>
            {props.loading && <div style={{
                position: "absolute",
                inset: 0,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "rgba(0,0,0,0.2)",
                zIndex: 9999
            }}>
                <Spin />
            </div>}
            <Form form={props.form} layout="vertical" onFinish={props.onFinish}>
                <Row gutter={[16,16]}>
                    <Col>
                        Rezervasyon Maliyet Tutarı: <b>{props?.cost ?? 0} {props?.currency?.symbol}</b> <br/>
                    </Col>
                    <Col span={24}>
                        <Form.List
                            name={['mails']}
                            rules={[{
                                validator: async (_, mails) => {
                                    if (!mails || mails.length < 1) {
                                        return Promise.reject(new Error('En az bir e-posta adresi giriniz!'));
                                    }
                                }
                            }]}
                        >
                            {(fields, { add, remove }, { errors }) => (
                                <>
                                    {fields.map((field, index) => (
                                        <Form.Item
                                            label={index === 0 ? 'E-Posta Adresi' : ''}
                                            required={false}
                                            key={field.key}
                                        >
                                            <div style={{display:"flex", alignItems:"center", columnGap:10}}>
                                                <Form.Item
                                                    name={[field.name, 'mail']}
                                                    validateTrigger={['onChange', 'onBlur']}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Lütfen E-Posta Adresi Giriniz!",
                                                        },
                                                        {
                                                            type: 'email',
                                                            message: "Lütfen Geçerli Bir E-Posta Adresi Giriniz!",
                                                        }
                                                    ]}
                                                    noStyle
                                                >
                                                    <Input
                                                        placeholder="E-Posta Adresi"
                                                        type="email"
                                                        style={{ width: '100%' }}
                                                    />
                                                </Form.Item>
                                                <Button
                                                    icon={<FontAwesomeIcon icon={faMinus}/>}
                                                    type="dashed"
                                                    onClick={() => remove(field.name)}
                                                />
                                            </div>
                                        </Form.Item>
                                    ))}
                                    <Form.Item>
                                        <Button
                                            type="dashed"
                                            onClick={() => add()}
                                            style={{
                                                width: '100%',
                                            }}
                                            icon={<FontAwesomeIcon icon={faPlus}/>}
                                        >
                                            E-Posta Ekle
                                        </Button>
                                        <Form.ErrorList errors={errors} />
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default ReservationConfirmSendModal;