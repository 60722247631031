import axios from '../axios';

const ReservationNotesService = {
    async get(id) {
        return await axios.get(`/v1/reservations/${id}/notes`);
    },
    async create(id, formData) {
        return await axios.post(`/v1/reservations/${id}/notes`,formData);
    },
};

export default ReservationNotesService;