import React from 'react';
import { Modal, Form, Input, Row, Col, DatePicker } from 'antd';
import HotelSelect from '@components/utils/HotelSelect';


const { RangePicker } = DatePicker;


const WidgetsModalForm = (props) => {
    const onOK = () => {
        props.form.submit();
    }

    return (
        <Modal visible={props.isVisible} title={props.title} closable={false} onOk={onOK} onCancel={props.onCancel}>
            <Form form={props.form} layout="vertical" onFinish={(values) => props.onFinish(values, props.data?.id)} fields={props.data?.fields}>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item name="hotel_id" label="Hotel Adı">
                            <HotelSelect />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="widget_name" label="Widget Adı">
                            <Input placeholder="Widget Adı" />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name="subdomain_name" label="Subdomain Adı">
                            <Input addonAfter=".paymyres.com" addonBefore="https://" placeholder="Subdomain Adı" />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
};

export default WidgetsModalForm;