import React from "react";
import {
    Modal,
    Tabs,
    Input,
    Row,
    Col,
    Tag,
    Select,
    Form,
    DatePicker,
    InputNumber, Switch, Checkbox,
} from "antd";
import CountrySelect from "../utils/CountrySelect";
import moment from "moment";
import {useTranslation} from "react-i18next";
const { Option } = Select;
const { TabPane } = Tabs;

const ReservationAddModal = (props) => {
    const { t } = useTranslation();
    const onLastNameChange = (e, index) => {
        if(index !== 1) return;
        const { value } = e.target;
        let adult = props?.selectedHotelInfo?.adultNumber;
        let child = props?.selectedHotelInfo?.childNumber;
        for (let i = 1; i <= adult; i++) {
          if (i !== index) {
            props.form.setFieldsValue({
              ["adult-last_name" + i]: value,
            });
          }
        }
        for (let i = 1; i <= child; i++) {
            props.form.setFieldsValue({
                ["child-last_name" + i]: value,
            });
        }

    }

  const formAdult = [];
  for (let i = 1; i <= props.selectedHotelInfo?.adultNumber; i++) {
    formAdult.push(
      <Row gutter={[16,0]} key={i}>
        <h1 style={{ width: "100%" }}>{i}.Yetişkin Bilgisi</h1>
        <Col span={12}>
          <Form.Item
            rules={[
              {
                required: true,
                message: "Bu kısım zorunludur!",
              },
            ]}
            name={"adult-first_name" + i}
            label="İsim"
          >
            <Input placeholder="İsim" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            rules={[
              {
                required: true,
                message: "Bu kısım zorunludur!",
              },
            ]}
            name={"adult-last_name" + i}
            label="Soyisim"
          >
            <Input
                onChange={(e) => onLastNameChange(e, i)}
                placeholder="Soyisim" />
          </Form.Item>
        </Col>
        <Col span={12}>
            <Form.Item
                  rules={[
                      {
                          required: true,
                          message: "Bu kısım zorunludur!",
                      },
                  ]}
                  name={"adult-gender" + i}
                  label="Cinsiyet"
              >
                  <Select placeholder="Cinsiyet seçiniz">
                      <Option value={"Erkek"}>Erkek</Option>
                      <Option value={"Kadin"}>Kadın</Option>
                  </Select>
            </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
              initialValue={moment("01.01.1988", "DD.MM.YYYY")}
            name={"adult-birth_date" + i}
            label="Doğum Tarihi"
          >
            <DatePicker
                format="DD.MM.YYYY"
                style={{ width: "100%" }}
            ></DatePicker>
          </Form.Item>
        </Col>
      </Row>
    );

    if (i === 1) {
      formAdult.push(
        <Row gutter={[16,0]} key={i}>
          <Col span={12}>
            <Form.Item rules={[]} name={"adult-email" + i} label="Mail Adresi">
              <Input placeholder="Mail Adresi" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              rules={[{
                  required: true,
                  message: "Bu kısım zorunludur!",
              }]}
              name={"adult-phone_number" + i}
              label="Telefon Numarası"
            >
              <InputNumber
                style={{ width: "100%" }}
                controls={false}
                placeholder="Telefon Numarası"
              />
            </Form.Item>
          </Col>
        </Row>
      );
    }
    if (props.selectedLanguage !== 122) {
      formAdult.push(
        <Row gutter={[16,0]} key={i}>
          <Col span={12}>
            <Form.Item
              rules={[]}
              name={"passport_date" + i}
              label="Pasaport Geçerlilik Tarihi"
            >
              <DatePicker style={{ width: "100%" }}></DatePicker>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              rules={[]}
              name={"passport_number" + i}
              label="Pasaport Numarası"
            >
              <Input
                style={{ width: "100%" }}
                controls={false}
                placeholder="Pasaport Numarası"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              rules={[]}
              name={"country_of_birth" + i}
              label="Doğum Ülkesi"
            >
              <CountrySelect
                showSearch
                filterOption={(input, option) =>
                  (option?.children ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              rules={[]}
              name={"passport_country" + i}
              label="Pasaportun Verildiği Ülke"
            >
              <CountrySelect
                showSearch
                filterOption={(input, option) =>
                  (option?.children ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              />
            </Form.Item>
          </Col>
        </Row>
      );
    } else {
      formAdult.push(
        <Row gutter={[16,0]} key={i}>
          <Col span={24}>
            <Form.Item
              rules={[
                {
                  pattern: /^(?:\d*)$/,
                  message: "Bu kısıma sadece rakam yazabilirsiniz!",
                },
                {
                  pattern: /^[\d]{11,11}$/,
                  message: "Girdiğiniz değer 11 karakterden oluşmalı!",
                },
              ]}
              name={"adult-identity" + i}
              label="Kimlik Numarası"
            >
              <Input
                style={{ width: "100%" }}
                controls={false}
                placeholder="Kimlik Numarası"
              />
            </Form.Item>
          </Col>
        </Row>
      );
    }
  }

  const formChild = [];

  for (let i = 1; i <= props.selectedHotelInfo?.childNumber; i++) {
    if (props.selectedLanguage !== 122) {
      formChild.push(
        <Row gutter={[16,0]} key={"child" + i}>
            <h1 style={{ width: "100%" }}>{i}. Çocuk Bilgisi</h1>
            <Col span={12}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Bu kısım zorunludur!",
                },
              ]}
              name={"child-first_name" + i}
              label="İsim"
            >
              <Input placeholder="İsim" />
            </Form.Item>
          </Col>
            <Col span={12}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Bu kısım zorunludur!",
                },
              ]}
              name={"child-last_name" + i}
              label="Soyisim"
            >
              <Input placeholder="Soyisim" />
            </Form.Item>
          </Col>
            <Col span={12}>
                <Form.Item
                    rules={[
                        {
                            required: true,
                            message: "Bu kısım zorunludur!",
                        },
                    ]}
                    name={"child-gender" + i}
                    label="Cinsiyet"
                >
                    <Select placeholder="Cinsiyet seçiniz">
                        <Option value={"Erkek"}>Erkek</Option>
                        <Option value={"Kadin"}>Kadın</Option>
                    </Select>
                </Form.Item>
            </Col>
          <Col span={12}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Bu kısım zorunludur!",
                },
              ]}
              name={"child-birth_date" + i}
              label="Doğum Tarihi"
            >
              <DatePicker
                  format="DD.MM.YYYY"
                  style={{ width: "100%" }}
                  placeholder="Doğum Tarihi"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              rules={[]}
              name={"child-passport_date" + i}
              label="Pasaport Geçerlilik Tarihi"
            >
              <DatePicker style={{ width: "100%" }}></DatePicker>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              rules={[]}
              name={"child-passport_number" + i}
              label="Pasaport Numarası"
            >
              <Input
                style={{ width: "100%" }}
                controls={false}
                placeholder="Pasaport Numarası"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              rules={[]}
              name={"child-country_of_birth" + i}
              label="Doğum Ülkesi"
            >
              <CountrySelect
                showSearch
                filterOption={(input, option) =>
                  (option?.children ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              rules={[]}
              name={"child-passport_country" + i}
              label="Pasaportun Verildiği Ülke"
            >
              <CountrySelect
                showSearch
                filterOption={(input, option) =>
                  (option?.children ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              />
            </Form.Item>
          </Col>
        </Row>
      );
    } else {
      formChild.push(
        <Row gutter={[16,0]} key={"child" + i}>
          <h1 style={{ width: "100%" }}>{i}. Çocuk Bilgisi</h1>
          <Col span={12}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Bu kısım zorunludur!",
                },
              ]}
              name={"child-gender" + i}
              label="Cinsiyet"
            >
              <Select placeholder="Cinsiyet seçiniz">
                <Option value={"Erkek"}>Erkek</Option>
                <Option value={"Kadin"}>Kadın</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Bu kısım zorunludur!",
                },
              ]}
              name={"child-first_name" + i}
              label="İsim"
            >
              <Input placeholder="İsim" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Bu kısım zorunludur!",
                },
              ]}
              name={"child-last_name" + i}
              label="Soyisim"
            >
              <Input placeholder="Soyisim" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Bu kısım zorunludur!",
                },
              ]}
              name={"child-birth_date" + i}
              label="Doğum Tarihi"
            >
              <DatePicker
                  format="DD.MM.YYYY"
                  placeholder="Doğum Tarihi"
                  style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              rules={[
                {
                  pattern: /^(?:\d*)$/,
                  message: "Bu kısıma sadece rakam yazabilirsiniz!",
                },
                {
                  pattern: /^[\d]{11,11}$/,
                  message: "Girdiğiniz değer 11 karakterden oluşmalı!",
                },
              ]}
              name={"child-identity" + i}
              label="Kimlik Numarası"
            >
              <Input
                style={{ width: "100%" }}
                controls={false}
                placeholder="Kimlik Numarası"
              />
            </Form.Item>
          </Col>
        </Row>
      );
    }
  }
  const insurance = Form.useWatch('need_insurance', props.form);

  return (
    <Modal
      visible={props.isVisible}
      width={1024}
      height={768}
      style={{ top: 0 }}
      title={props.selectedReservation?.room.definition.active_language.name + ` (${props.selectedReservation?.concept.concept.active_language.name})`}
      okText="Oluştur"
      onOk={() => props.form.submit()}
      cancelText="İptal"
      onCancel={props.onCancel}
    >
      <Form
        layout={"vertical"}
        form={props.form}
        onFinish={(values) => props.onFinish(values, props.data?.id)}
        fields={props.data?.fields}
      >
        <div className="card-container">
          <Tabs type="card">
            <TabPane tab={t("general.guest_information")} key="1">
              {formAdult?.map((forms, count) => forms)}
              {formChild?.map((forms, count) => forms)}
                <Row gutter={[16,16]}>
                    <Col span={12}>
                        <Form.Item
                            label={t("general.additional_services")}
                            name='additional_services'
                        >
                            <Select
                                mode="multiple"
                                placeholder="Ek Hizmet Seçiniz"
                                style={{ width: '100%' }}
                            >
                                {props?.selectedReservation?.allowedAdditionalServices?.map((service, index) => (
                                    <Option value={service.service_id} key={index}>
                                        {service.name} ({service?.price} {props?.selectedReservation?.currency?.currency?.symbol})
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </TabPane>
              <TabPane tab={t("general.policy_information")} key="3">
                    <Row gutter={[16, 0]}>
                        <Col span={24}>
                            <Form.Item
                                name="need_insurance"
                                label="Poliçe İstiyor musunuz?"
                                valuePropName="checked"
                            >
                                <Switch />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name={['insurance', 'CografiSinirlar']}
                                label="Çoğrafi Sınırlar"
                            >
                                <Select
                                    placeholder="Çoğrafi Sınırlar"
                                >
                                    <Option value="1">Tüm Dünya (Türkiye Hariç)</Option>
                                    <Option value="2">Tüm Dünya</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name={['insurance', '_CografiBolge']}
                                label="Coğrafi Bölge"
                            >
                                <Select
                                    placeholder="Coğrafi Bölge"
                                >
                                    <Option value="1">Avrupa Ülkeleri</Option>
                                    <Option value="2">Tüm Dünya (Türkiye Hariç)</Option>
                                    <Option value="3">Türkiye (Yurtiçi)</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name={['insurance', '_TurOp']}
                                label="Tur Oper. Sorumluluk Teminatı"
                            >
                                <Select
                                    placeholder="Tur Oper. Sorumluluk Teminatı"
                                >
                                    <Option value="1">Hayır</Option>
                                    <Option value="2">Evet</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name={['insurance', '_SorumlulukLimiti']}
                                label="Sorumluluk Limiti"
                            >
                                <Select
                                    placeholder="Sorumluluk Limiti"
                                >
                                    <Option value="0">Yok</Option>
                                    <Option value="1">1.000 €</Option>
                                    <Option value="2">1.500 €</Option>
                                    <Option value="3">2.000 €</Option>
                                    <Option value="4">3.750 €</Option>
                                    <Option value="5">4.000 €</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name={['insurance', 'MusteriVergiNo']}
                                label="Müşteri Vergi No"
                            >
                                <Input
                                    placeholder="Müşteri Vergi No"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name={['insurance', 'SigortaliTcKimlikNo']}
                                label="Sigortalı T.C. Kimlik No"
                            >
                                <Input
                                    placeholder="Sigortalı T.C. Kimlik No"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name={['insurance', 'SigortaliDogumTarihi']}
                                label="Sigortalı Doğum Tarihi"
                            >
                                <DatePicker
                                    placeholder="Sigortalı Doğum Tarihi"
                                    format="DD.MM.YYYY"
                                    style={{ width: "100%" }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name={['insurance', '_Covid19Isteniyormu']}
                                label="Covid 19 İsteniyor mu?"
                            >
                                <Select
                                    placeholder="Covid 19 İsteniyor mu?"
                                >
                                    <Option value="1">Hayır</Option>
                                    <Option value="2">Evet</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name={['insurance', '_TeminatTipi']}
                                label="Teminat Tipi"
                            >
                                <Select
                                    placeholder="Teminat Tipi"
                                >
                                    <Option value="0">Yok</Option>
                                    <Option value="1">Dar Kapsam</Option>
                                    <Option value="2">Geniş Kapsam</Option>
                                    <Option value="3">Erasmus</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name={['insurance', '_MucbirSebeptenIptalLimiti']}
                                label="Mucbir Sebepten İptal Limiti"
                            >
                                <Select
                                    placeholder="Mucbir Sebepten İptal Limiti"
                                >
                                    <Option value="0">Yok</Option>
                                    <Option value="1">1.000 €</Option>
                                    <Option value="2">2.000 €</Option>
                                    <Option value="3">3.000 €</Option>
                                    <Option value="4">5.000 €</Option>
                                    <Option value="5">7.500 €</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
              </TabPane>
            <TabPane tab="Rezerve Edilen Oda Detayları" key="2">
              <h4>
                <strong>Otel Adı:</strong> {props.selectedHotelInfo?.hotelName}
              </h4>
              <h4>
                <strong>Oda Tipi:</strong> {props.selectedReservation?.name}
              </h4>
              <h4>
                <strong>Konsept: </strong>
                <Tag color={"red"}>
                  {props.selectedReservation?.concept.concept.active_language.name}
                </Tag>
              </h4>
              <h4>
                <strong>Giriş Tarihi:</strong>{" "}
                {props.selectedHotelInfo?.checkIn}
              </h4>
                <h4>
                    <strong>Çıkış Tarihi:</strong>{" "}
                    {props.selectedHotelInfo?.checkOut}
                </h4>
              <h4>
                <strong>Yetişkin Sayısı:</strong>{" "}
                {props.selectedHotelInfo?.adultNumber}
              </h4>
              <h4>
                <strong>Çocuk Sayısı:</strong>{" "}
                {props.selectedHotelInfo?.childNumber}
              </h4>
              <h4>
                <strong>Toplam Ücret:</strong>{" "}
                {props.selectedReservation?.totalFee} ₺
              </h4>
            </TabPane>
          </Tabs>
        </div>
      </Form>
    </Modal>
  );
};

export default ReservationAddModal;
