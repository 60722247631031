import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { HotelService } from '@services';

const { Option } = Select;

const HotelSelect = ({ value = [], onChange, nullable = false, multiple = false, placeholder = "", maxTagCount = 1 }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [hotels, setHotels] = useState({
    current_page: 1,
    next_page: 1,
    previous_page: 1,
    data: []
  });

  useEffect(() => {
    getHotels();
  }, []);

  const getHotels = async (page = 1) => {
    try {
      let { data: response } = await HotelService.get(page);
      setHotels(response);
    } catch (e) {
      console.log(e);
    }
  };

  const onScroll = (e) => {
    let target = e.target;

    if (!isLoading && hotels.next_page > hotels.current_page && (target.scrollTop + target.offsetHeight) >= (target.scrollHeight * 0.8)) {
      setIsLoading(true);
      getHotels(hotels.next_page);
    }
  };

  const handleChange = (value) => {
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <Select value={value} maxTagCount={maxTagCount} placeholder={placeholder} onScroll={onScroll} onChange={handleChange} loading={isLoading} mode={multiple ? 'multiple' : 'default'}>
      {nullable && <Option value={null}>Seçim yapılmadı</Option>}
      {hotels.data.map((hotel, index) => <Option key={index} value={hotel.id}>{hotel.name}</Option>)}
    </Select>
  );
};

export default HotelSelect;