import axios from '../axios';

const ProgressPaymentService = {
    async get(params) {
        return await axios.get(`/v1/progress-payments`,{
            params: {
                start_date: params.start_date,
                end_date: params.end_date,
                page: params.page,
                per_page: params.pageSize
            }
        });
    },
    async export(params) {
        return await axios.get(`/v1/progress-payments/export`,{
            params: {
                start_date: params.start_date,
                end_date: params.end_date
            },
            responseType: 'blob'
        });
    }
};

export default ProgressPaymentService;