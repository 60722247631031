import React, {useState} from 'react';
import {
    Breadcrumb,
    Button,
    Col,
    Form,
    Input,
    Layout,
    notification,
    Row, Select,
    Table,
} from "antd";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHouse, faSearch} from "@fortawesome/free-solid-svg-icons";
import Wrapper from "@components/Wrapper";
import {ExternalHotelService} from "@services";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import HotelCreateOptionModal from "@components/external/hotel/HotelCreateOptionModal";
import {useTranslation} from "react-i18next";

const {Content} = Layout;
const {Option} = Select;

const ExternalContractHotels = () => {
    const { t } = useTranslation()
    const [hotelSearchForm] = Form.useForm();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const page = searchParams.get('page') || 1;
    const per_page = searchParams.get('per_page') || 10;

    const [hotels, setHotels] = React.useState({
        data: [],
        meta: {},
        loading: true,
        error: false
    });
    const [searchParameters, setSearchParameters] = useState({
        name: "",
        type: ""
    });
    const columns = [
        {
            title: 'Otel ID',
            dataIndex: 'real_id',
            width: 100,
            key: 'id',
        },
        {
            title: 'Otel Adı',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => <Link
                style={!record.is_pool ? {color: 'rgb(190, 24, 93)', fontWeight: 'bold'}: {}}
                state={{prevPath: `/external-management/hotels?page=${page}&per_page=${per_page}`}}
                to={`/external-management/contract-hotels/${record.real_id}/contract-groups`}>{text}
            </Link>,
        }
    ];


    const handleTableChange = (pagination, filters, sorter) => {
        navigate(`/external-management/hotels?page=${pagination.current}&per_page=${pagination.pageSize}`)
        getHotels(pagination.current, pagination.pageSize, filters);
    }

    const getHotels = async (page, per_page, filters = {}) => {
        let currentPage = page || (searchParams.get('page') || 1);
        let currentPerPage = per_page || (searchParams.get('per_page') || 10);
        setHotels({
            ...hotels,
            loading: true
        })
        try {
            let {data: response} = await ExternalHotelService.get(currentPage, currentPerPage, searchParameters);
            setHotels({
                ...hotels,
                data: response.data,
                meta: response.meta,
                loading: false,
            })
            response.data.map((hotel)=>{
                let region = hotel.regions
                let regions = [];
                region.map((region)=>
                    regions.push({
                        label: region.name,
                        value: region.id,
                    })
                )
            })

        }catch (e){
            if(e.response){
                notification.error({
                    message: 'Hata',
                    description: e.response.data.message
                })
            }
            setHotels({
                ...hotels,
                loading: false
            })
        }
    }

    React.useEffect(() => {
        getHotels();
    },[])
    return (
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-20">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse} />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        Kontrat Otelleri
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background">
                    <Form form={hotelSearchForm} onFinish={() => getHotels()} layout="vertical">
                        <Row gutter={[16,0]}>
                            <Col span={6}>
                                <Form.Item label={t("general.hotel_name")}>
                                    <Input placeholder={t("general.hotel_name")} onChange={(e) => setSearchParameters(prevState => {
                                        return {
                                            ...prevState,
                                            name: e.target.value
                                        }
                                    })}/>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label={t("general.type")}>
                                    <Select allowClear onClear={() => {
                                        setSearchParameters(prevState => {
                                            return {
                                                ...prevState,
                                                type: ""
                                            }
                                        })
                                    }} placeholder={t("general.choose")} onSelect={(e) => {
                                        setSearchParameters(prevState => {
                                            return {
                                                ...prevState,
                                                type: e
                                            }
                                        })
                                    }}>
                                        <Option value="">{t("general.all")}</Option>
                                        <Option value="pool">{t("general.pool_hotels")}</Option>
                                        <Option value="user">{t("general.user_hotels")}</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item>
                                    <Button style={{width:"100%"}} type="primary" htmlType="submit">
                                        <FontAwesomeIcon icon={faSearch}/>
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    <Table
                        columns={columns}
                        dataSource={hotels.data}
                        loading={hotels.loading}
                        rowKey={record => record.id}
                        pagination={{
                            total: hotels.meta.total,
                            current: hotels.meta.current_page,
                            pageSize: hotels.meta.per_page,
                        }}
                        onChange={handleTableChange}
                    />
                </div>
            </Content>
        </Wrapper>
    );
};

export default ExternalContractHotels;