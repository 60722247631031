import React, { useEffect, useState } from 'react';
import { Layout, Breadcrumb, Table, notification } from 'antd';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse } from '@fortawesome/free-solid-svg-icons';
import { ContractGroupService, HotelService } from '@services';
import Wrapper from '@components/Wrapper';
import {useTranslation} from "react-i18next";

const { Content } = Layout;

const ContractGroup = () => {
  const { t } = useTranslation()
  const columns = [
    {
      title: '#',
      render: (text, record, index) => index + 1
    },
    {
      title: `${t('general.hotel_name')}`,
      dataIndex: 'name',
      render: (text, record) => (<Link to={`/contract-hotels/${record.id}/contract-groups`}>{record.name}</Link>)
    }
  ];

  const [isLoadingTable, setIsLoadingTable] = useState(true);
  const [contracts, setContracts] = useState({});
  const [hotels, setHotels] = useState({});
  useEffect(() => {
    getContracts();
    getHotels();
  }, []);
  const getContracts = async (page = 1) => {
    try {
      let { data: response } = await ContractGroupService.get(page);
      setContracts(response);
    } catch (e) {
      if (e.response) {
        notification.error({
          message: e.response.data.message
        });
      }
    }
  };
  const getHotels = async (page = 1) => {
    try {
      let { data: response } = await HotelService.get(page);
      setHotels(response);
      console.log(response)
      setIsLoadingTable(false);
    } catch (e) {
      if (e.response) {
        notification.error({
          message: e.response.data.message
        });
      }
    }
  };

  return (
    <Wrapper>
      <Content className="m-25">
        <Breadcrumb className="mb-20">
          <Breadcrumb.Item href="/">
            <FontAwesomeIcon icon={faHouse} />
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            {t('general.hotels')}
          </Breadcrumb.Item>
        </Breadcrumb>

        <div className="site-layout-background">
          <Table
            loading={isLoadingTable}
            columns={columns}
            dataSource={hotels.data}
            rowKey="id"
            bordered
            pagination={{
              total: hotels.meta?.total,
              defaultPageSize: hotels.meta?.per_page,
              onChange: (page, pageSize) => {
                setIsLoadingTable(true);
                getHotels(page);
              }
            }}
          >
          </Table>
        </div>
      </Content>
    </Wrapper>
  );
};

export default ContractGroup;