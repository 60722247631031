import React from 'react';
import {Button, Col, Form, InputNumber, Modal, Row, Select} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";

const InstallmentCreateModalForm = (props) => {
    const [selectedBank, setSelectedBank] = React.useState({});
    const { isVisible, title, form, data, onCancel, onFinish, banks } = props;
    return (
        <Modal visible={isVisible} title={title} closable={false} onOk={() => form.submit()} onCancel={onCancel}>
            <Form form={form} layout="vertical" onFinish={(values) => onFinish(values, data?.id)}>
                <Form.List
                    name={['installment_options']}
                >
                    {(fields, { add, remove }, { errors }) => (
                        <>
                            {fields.map((field, index) => (
                                <div style={{ position: "relative" }} key={field.key}>
                                    <Form.Item
                                        label={<h4>{index + 1}. Taksit Seçeneği</h4>}
                                        required={false}
                                    >
                                        <Row gutter={[16,16]}>
                                            <Col span={12}>
                                                <Form.Item
                                                    label="Banka"
                                                    name={[field.name, 'bank_id']}
                                                    rules={[{ required: true, message: 'Lütfen banka seçiniz!' }]}
                                                >
                                                    <Select
                                                        loading={banks.loading}
                                                        placeholder="Banka seçiniz."
                                                        onChange={(value) => {
                                                            setSelectedBank(banks.data.find(bank => bank.id === value))
                                                        }}
                                                    >
                                                        {banks.data?.map(bank => (
                                                            <Select.Option key={bank.id} value={bank.id}>{bank.name}</Select.Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    label="Kart"
                                                    name={[field.name, 'card_id']}
                                                    rules={[{ required: true, message: 'Lütfen kart seçiniz!' }]}
                                                >
                                                    <Select
                                                        disabled={!selectedBank.id}
                                                        placeholder="Kart seçiniz."
                                                    >
                                                        {selectedBank.cards?.map(card => (
                                                            <Select.Option key={card.id} value={card.id}>{card.name}</Select.Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={24}>
                                                <Form.Item
                                                    label="Taksit Sayısı"
                                                    name={[field.name, 'installment']}
                                                    rules={[{ required: true, message: 'Lütfen taksit sayısı giriniz!' }]}
                                                >
                                                    <InputNumber
                                                        min={1}
                                                        style={{width: '100%'}}
                                                        placeholder="Taksit sayısı giriniz."
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Form.Item>
                                    <Button type="dashed" size="small" style={{
                                        position: 'absolute',
                                        right: '0',
                                        top: '0',
                                    }} onClick={() => remove(field.name)}>
                                        <FontAwesomeIcon icon={faMinus}/>
                                    </Button>
                                </div>
                            ))}
                            <Form.Item>
                                <Button
                                    type="dashed"
                                    onClick={() => add()}
                                    style={{
                                        width: '100%',
                                    }}
                                    icon={<FontAwesomeIcon icon={faPlus}/>}
                                >
                                    Taksit Seçeneği Ekle
                                </Button>
                                <Form.ErrorList errors={errors} />
                            </Form.Item>
                        </>
                    )}
                </Form.List>
            </Form>
        </Modal>
    );
};

export default InstallmentCreateModalForm;