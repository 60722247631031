import axios from '../axios';

const CMHotelService = {
    async create(formData) {
        return await axios.post(`/v1/channel-management/hotels/create`, formData);
    },
    async get(page = 1) {
        return await axios.get(`/v1/channel-management/hotels/list?page=${page}`);
    }
};

export default CMHotelService;