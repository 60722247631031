import React from 'react';
import {
    Alert,
    Button,
    Card,
    Col,
    Divider,
    Form,
    Input, InputNumber,
    message,
    Row,
    Spin,
    Tabs,
    Upload
} from "antd";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FileService} from "@services";
import TextArea from "antd/es/input/TextArea";

const { TabPane } = Tabs;

const HotelCreateRoomsStep = ({ form }) => {
    const { t } = useTranslation();
    const [roomAttributeModalVisible, setRoomAttributeModalVisible] = React.useState(false);
    const rooms = Form.useWatch('hotel_rooms', form) || [];
    const hotel_languages = Form.useWatch('hotel_languages', form) || [];
    const [isUploading, setIsUploading] = React.useState(false);
    const onUpload = async (data, index) => {
        if (data.file?.status === "removed") return;
        const formData = new FormData();
        formData.append('file', data.file);
        setIsUploading(true);
        try {
            let {data: response} = await FileService.uploadTempFile(formData);
            if (response.success){
                let newRooms = [...rooms];
                newRooms[index].images = [
                    ...newRooms[index].images || [],
                    {
                        uid: response.data.uid,
                        name: response.data.name,
                        hashed_name: response.data.hash,
                        url: response.data.url,
                        status: 'done',
                        expires_at: response.data.expires_at
                    }
                ];
                form.setFieldsValue({
                    hotel_rooms: newRooms
                });
            }
            setIsUploading(false);
        }catch (e){
            if (e.response){
                message.error(e.response.data.message);
            }
        }
    }
    const onRemove = (index, file) => {
        form.setFieldsValue({
            hotel_rooms: rooms.map((room, roomIndex) => {
                if (index === roomIndex){
                    return {
                        ...room,
                        images: room.images.filter((image) => image.uid !== file.uid)
                    }
                }
                return room;
            })
        });
    }
    return (
        <>
            <Form.List
                name="hotel_rooms"
                rules={[{
                    validator: async (_, hotel_rooms) => {
                        if (!hotel_rooms || hotel_rooms.length < 1) {
                            return Promise.reject(new Error(`${t("general.hotel_rooms")} ${t("general.cannot_be_empty")}`));
                        }
                    }
                }]}
            >
                {(fields, { add, remove }, { errors }) => {
                    if (hotel_languages.length < 1) return <Alert
                        message={t("general.warning")}
                        description={t("general.choose_hotel_languages_first")}
                        type="warning"
                        showIcon
                    />;
                    return (
                        <>
                            {fields.map((field, index) => (
                                <Card title={<div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                                    <span>{t("general.room")} {index + 1}</span>
                                    <Button
                                        icon={<FontAwesomeIcon icon={faTrash}/>}
                                        type="danger"
                                        disabled={rooms.length === 1}
                                        onClick={() => remove(field.name)}
                                    />
                                </div>} key={field.key} type="inner" style={{ marginBottom: "12px" }}>
                                    <Card.Grid style={{ width: "100%" }} hoverable={false}>
                                        <Tabs tabPosition="top">
                                            {hotel_languages.map((language) => (
                                                <TabPane tab={language.label} key={language.value} forceRender>
                                                    <Row gutter={[16,2]}>
                                                        <Col span={24}>
                                                            <Form.Item
                                                                label="Oda Adı"
                                                                name={[field.name, "translations", language.value, "room_name"]}
                                                                rules={[{ required: true, message: `${t("general.room_name")} ${t("general.cannot_be_empty")}` }]}
                                                            >
                                                                <Input
                                                                    placeholder={t("general.room_name")}
                                                                    style={{ width: "100%" }}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={24}>
                                                            <Form.Item
                                                                label="Oda Açıklaması"
                                                                name={[field.name, "translations", language.value, "room_description"]}
                                                                rules={[{ required: true, message: `${t("general.room_description")} ${t("general.cannot_be_empty")}` }]}
                                                            >
                                                                <TextArea
                                                                    placeholder={t("general.room_description")}
                                                                    style={{ width: "100%" }}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                    <Divider />
                                                    <Row gutter={[16,2]}>
                                                        <Col span={6}>
                                                            <Form.Item
                                                                label="Minimum Ziyaretçi Sayısı"
                                                                name={[field.name, "min_guest_count"]}
                                                                rules={[{ required: true, message: `${t("general.min_guest_count")} ${t("general.cannot_be_empty")}` }]}
                                                            >
                                                                <InputNumber
                                                                    min={0}
                                                                    placeholder={t("general.min_guest_count")}
                                                                    style={{ width: "100%" }}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={6}>
                                                            <Form.Item
                                                                label="Maksimum Ziyaretçi Sayısı"
                                                                name={[field.name, "max_guest_count"]}
                                                                rules={[{ required: true, message: `${t("general.max_guest_count")} ${t("general.cannot_be_empty")}` }]}
                                                            >
                                                                <InputNumber
                                                                    min={0}
                                                                    placeholder={t("general.max_guest_count")}
                                                                    style={{ width: "100%" }}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={6}>
                                                            <Form.Item
                                                                label="Banyo Sayısı"
                                                                name={[field.name, "bathroom_count"]}
                                                                rules={[{ required: true, message: `${t("general.bathroom_count")} ${t("general.cannot_be_empty")}` }]}
                                                            >
                                                                <InputNumber
                                                                    min={0}
                                                                    placeholder={t("general.bathroom_count")}
                                                                    style={{ width: "100%" }}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={6}>
                                                            <Form.Item
                                                                label="Salon Sayısı"
                                                                name={[field.name, "living_room_count"]}
                                                                rules={[{ required: true, message: `${t("general.living_room_count")} ${t("general.cannot_be_empty")}` }]}
                                                            >
                                                                <InputNumber
                                                                    min={0}
                                                                    placeholder={t("general.living_room_count")}
                                                                    style={{ width: "100%" }}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={6}>
                                                            <Form.Item
                                                                label="Oda Boyutu"
                                                                name={[field.name, "m2"]}
                                                                rules={[{ required: true, message: `${t("general.room_size")} ${t("general.cannot_be_empty")}` }]}
                                                            >
                                                                <InputNumber
                                                                    addonAfter="m²"
                                                                    min={0}
                                                                    placeholder={t("general.room_size")}
                                                                    style={{ width: "100%" }}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                </TabPane>
                                            ))}
                                        </Tabs>
                                    </Card.Grid>
                                    <Card.Grid style={{ width: "100%" }} hoverable={false}>
                                        <h1>Oda Fotoğrafları</h1>
                                        <Upload
                                            beforeUpload={() => false}
                                            onChange={(data) => onUpload(data, index)}
                                            listType="picture-card"
                                            fileList={rooms[index]?.images || []}
                                            itemRender={(originNode, file) => React.cloneElement(originNode, {
                                                title: file.status === 'error' ? t('general.file_expire') : file.name,
                                            })}
                                            /*onPreview={(file) => onPreview(file)}*/
                                            onRemove={(file) => onRemove(index, file)}
                                        >
                                            <div>
                                                {isUploading ? (
                                                    <Spin />
                                                ): (<FontAwesomeIcon icon={faPlus} />)}
                                                <div style={{ marginTop: 8 }}>
                                                    {t('general.upload')}
                                                </div>
                                            </div>
                                        </Upload>
                                    </Card.Grid>
                                </Card>
                            ))}
                            <Col span={24} style={{ marginTop: "12px" }}>
                                <Form.Item>
                                    <Button
                                        type="dashed"
                                        onClick={() => add()}
                                        style={{
                                            width: '100%',
                                        }}
                                        icon={<FontAwesomeIcon icon={faPlus} style={{ marginRight: "12px" }}/>}
                                    >
                                        {t("general.add_room")}
                                    </Button>
                                    <Form.ErrorList errors={errors} />
                                </Form.Item>
                            </Col>
                        </>
                    )
                }}
            </Form.List>
        </>
    )
};

export default HotelCreateRoomsStep;