import { createSlice } from "@reduxjs/toolkit";

export const appSlice = createSlice({
  name: 'app',
  initialState: {
    languages: []
  },
  reducers: {
    setLanguages: (state, action) => {
      state.languages = action.payload;
    }
  }
});

export const { setLanguages } = appSlice.actions;

export default appSlice.reducer;