import axios from '../axios';

const AgencyWebSubHotelService = {
    async getByHotel(hotelId) {
        return await axios.get(`/v1/site-manager/hotel-sub-hotels/${hotelId}`);
    },
    async create(hotelId,formData) {
        return await axios.post(`/v1/site-manager/hotel-sub-hotels/${hotelId}`,formData);
    },
    async delete(hotelId,subHotelId) {
        return await axios.delete(`/v1/site-manager/hotel-sub-hotels/${hotelId}/${subHotelId}`);
    },
};

export default AgencyWebSubHotelService;