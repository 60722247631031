import axios from '../axios';

const ExternalDescriptionService = {
    async get() {
        return await axios.get(`/v1/external/descriptions`);
    },
    async getPaginate(page, per_page) {
        return await axios.get(`/v1/external/descriptions?page=${page}&per_page=${per_page}`);
    },
    async update(values, id) {
        return await axios.post(`/v1/external/descriptions/${id}`, values);
    },
    async create(values) {
        return await axios.post(`/v1/external/descriptions`, values);
    },
    async delete(id) {
        return await axios.delete(`/v1/external/descriptions/${id}`);
    }
};

export default ExternalDescriptionService;