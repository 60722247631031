import {useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";

const useRouteHistory = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const notAllowedRoutes = ['/login', '/register', '/forgot-password', '/reset-password'];
    const goBack = () => {
        const ROUTE_HISTORY_KEY = 'routeHistory';
        let routeHistory = JSON.parse(sessionStorage.getItem(ROUTE_HISTORY_KEY)) || [];
        routeHistory = routeHistory.filter(route => !notAllowedRoutes.includes(route));
        if (routeHistory.length === 0) {
            return;
        }
        routeHistory.pop();
        sessionStorage.setItem(ROUTE_HISTORY_KEY, JSON.stringify(routeHistory));
        navigate(getLatestRoute());
    }
    const getLatestRoute = () => {
        const ROUTE_HISTORY_KEY = 'routeHistory';
        let routeHistory = JSON.parse(sessionStorage.getItem(ROUTE_HISTORY_KEY)) || [];
        if (routeHistory.length === 0) {
            return '/';
        }
        routeHistory = routeHistory.filter(route => !notAllowedRoutes.includes(route));
        return routeHistory[routeHistory.length - 1];
    }
    useEffect(() => {
        const ROUTE_HISTORY_KEY = 'routeHistory';
        let routeHistory = JSON.parse(sessionStorage.getItem(ROUTE_HISTORY_KEY)) || [];
        routeHistory.push(location.pathname);
        if (routeHistory.length > 5) {
            routeHistory = routeHistory.slice(-5);
        }
        sessionStorage.setItem(ROUTE_HISTORY_KEY, JSON.stringify(routeHistory));
    }, [location]);
    return {getLatestRoute, goBack};
}



export default useRouteHistory;