import axios from '../../axios';

const HotelService = {
    async create(formData) {
        return await axios.post(`/v2/dashboard/hotels`, formData);
    },
    async update(id, formData) {
        return await axios.post(`/v2/dashboard/hotels/${id}`, formData);
    },
    async show(id) {
        return await axios.get(`/v2/dashboard/hotels/${id}`);
    },
}

export default HotelService;