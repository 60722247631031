import React, {useEffect} from 'react';
import {Breadcrumb, Button, Col, Form, Layout, notification, Row, Space, Table, Tag, Tooltip} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faHouse, faStar} from "@fortawesome/free-solid-svg-icons";
import Wrapper from "@components/Wrapper";
import {CMRoomService} from "@services";
import {useParams} from "react-router-dom";
import CMRoomCreateModalForm from "@components/channel-management/CMRoomCreateModalForm";
import CMRoomConceptAppendModalForm from "../../components/channel-management/CMRoomConceptAppendModalForm";

const {Content} = Layout;

const CmRoomList = () => {
    const columns = [
        {
            title: 'Oda ID',
            dataIndex: 'id',
            key: 'id',
            width: 200,
        },
        {
            title: 'Oda Adı',
            dataIndex: 'name',
            key: 'name',
            width: "50%",
            render: (text) => text
        },
        {
            title: 'Aktif mi?',
            dataIndex: 'is_active',
            key: 'is_active',
            render: (text) => text ? <Tag color="green">Evet</Tag> : <Tag color="red">Hayır</Tag>
        },
        {
            title: 'İşlemler',
            dataIndex: 'id',
            key: 'id',
            render:(text, record) =>
                <Space>
                    <Button type="primary"><FontAwesomeIcon icon={faEdit}/></Button>
                    <Tooltip title="Konsept Ata">
                        <Button type="primary" onClick={() => getRoomConcepts(params.hotelId, record.id)}><FontAwesomeIcon icon={faStar}/></Button>
                    </Tooltip>
                </Space>
        }
    ];
    const [isLoading, setIsLoading] = React.useState(true);
    const [isModalVisible, setIsModalVisible] = React.useState(false);
    const [selectedRoom, setSelectedRoom] = React.useState({});
    const [isRoomConceptAppendModalVisible, setIsRoomConceptAppendModalVisible] = React.useState(false);
    const [rooms, setRooms] = React.useState({
        data: [],
        meta: {},
        hotel: {}
    });
    const [form] = Form.useForm();
    const [appendForm] = Form.useForm();
    const params = useParams()
    const getRooms = async (hotelId, page) => {
        setIsLoading(true)
        try{
            let {data: response} = await CMRoomService.get(hotelId, page);
            setRooms({
                data: response.data,
                meta: response.meta,
                hotel: response.hotel
            });
            setIsLoading(false);
        }catch (e){
            if(e.response){
                notification.error({
                    message: e.response.data.message,
                });
            }
        }
    }

    const getRoomConcepts = async (hotelId, roomId) => {
        setSelectedRoom(roomId)
        try{
            let {data: response} = await CMRoomService.getConcepts(hotelId, roomId);
            console.log(response)
            appendForm.setFieldsValue({
                concepts: response?.data?.map((item) => item.concept_id)
            })
            setIsRoomConceptAppendModalVisible(true)
        }catch (e){
            if(e.response){
                notification.error({
                    message: e.response.data.message,
                });
            }
        }
    }

    const onCreate = async (values) => {
        values = {...values, hotel_id: params.hotelId, is_active: values?.is_active ?? false}
        try {
            let response = await CMRoomService.create(values);
            setRooms({
                ...rooms,
                data: [...rooms.data, response.data.data]
            });
            setIsModalVisible(false);
            form.resetFields();
            notification.success({
                message: response.data.message,
            });
        }catch (e){
            if(e.response){
                notification.error({
                    message: e.response.data.message,
                });
            }
        }
    }

    const onConceptAppend = async (values, id) => {
        values = {...values, hotel_id: params.hotelId, room_id: id}
        try {
            let response = await CMRoomService.appendConcept(values);
            form.resetFields();
            setIsRoomConceptAppendModalVisible(false);
            notification.success({
                message: response.data.message,
            });
        }catch (e){
            if(e.response){
                notification.error({
                    message: e.response.data.message,
                });
            }
        }
    }

    useEffect(() => {
        getRooms(params.hotelId);
    },[params.hotelId])

    return (
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-20">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse}/>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href="/channel-management/hotels">
                        Kanal Yönetimi - Tesisler
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {rooms?.hotel?.name ?? '...'} - Oda Listesi
                    </Breadcrumb.Item>
                </Breadcrumb>

                <div className="site-layout-background">
                    <Row gutter={[16,16]}>
                        <Col span={24}>
                            <Button type="primary" className="mb-10" onClick={() => setIsModalVisible(true)}>Yeni Oda Ekle</Button>
                        </Col>
                        <Col span={24}>
                            <Table
                                columns={columns}
                                rowKey={record => record.id}
                                dataSource={rooms.data}
                                loading={isLoading}
                                pagination={{
                                    pageSize: rooms?.meta?.per_page,
                                    total: rooms?.meta?.total,
                                    current: rooms?.meta?.current_page,
                                }}
                                onChange={(pagination) => getRooms(params.hotelId, pagination.current)}
                            />
                        </Col>
                    </Row>
                </div>
            </Content>
            <CMRoomCreateModalForm
                isVisible={isModalVisible}
                form={form}
                title="Yeni Oda Ekle"
                onFinish={onCreate}
                onCancel={() => setIsModalVisible(false)}
            />
            <CMRoomConceptAppendModalForm
                isVisible={isRoomConceptAppendModalVisible}
                form={appendForm}
                title="Konsept Ata"
                data={selectedRoom}
                onCancel={() => setIsRoomConceptAppendModalVisible(false)}
                onFinish={onConceptAppend}
            />
        </Wrapper>
    );
};

export default CmRoomList;