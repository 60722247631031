import axios from '../axios';

const HotelHeaderService = {
    async getByHotel(hotelId) {
        return await axios.get(`/v1/site-manager/hotel-headers/${hotelId}`);
    },
    async create(headerId,formData) {
        return await axios.post(`/v1/site-manager/hotel-header-details/${headerId}`,formData);
    },

    async changeHeader(hotelId,formData) {
        return await axios.post(`/v1/site-manager/hotel-headers/${hotelId}`,formData);
    },
};

export default HotelHeaderService;