import React from 'react';
import {Col, Form, InputNumber, Modal, Row, Select} from "antd";
import {useTranslation} from "react-i18next";

const {Option} = Select;

const TransferModalForm = (props) => {
    const { t } = useTranslation()
    return (
        <Modal visible={props.isVisible} title={props.title} closable={false} onOk={() => props.form.submit()} onCancel={props.onCancel}>
            <Form form={props.form} layout="vertical" onFinish={(values) => props.onFinish(values, props.data?.id)} initialValues={{
                currency_id: "TRY",
            }}>
                <Row gutter={[16,16]}>
                    <Col span={12}>
                        <Form.Item
                            name="start_point_id"
                            label="Başlangıç Noktası"
                            rules={[{ required: true, message: 'Lütfen başlangıç noktasını seçiniz!' }]}
                        >
                            <Select placeholder={t("general.select")} loading={props?.points.loading}>
                                {props?.points?.data?.map((point) => (
                                    <Option key={point.id} value={point.id}>{point.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="end_point_id"
                            label="Bitiş Noktası"
                            rules={[
                                { required: true, message: 'Lütfen bitiş noktasını seçiniz!' },
                                { validator: (rule, value) => {
                                    if (value === props?.form.getFieldValue(['start_point_id'])) {
                                        return Promise.reject('Başlangıç ve bitiş noktası aynı olamaz!')
                                    }
                                    return Promise.resolve()
                                }}
                            ]}
                        >
                            <Select placeholder={t("general.select")} loading={props?.points.loading}>
                                {props?.points?.data?.map((point) => (
                                    <Option key={point.id} value={point.id}>{point.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="vehicle_id"
                            label="Araç Tipi"
                            rules={[{ required: true, message: 'Lütfen araç tipi seçiniz!' }]}
                        >
                            <Select placeholder={t("general.select")} loading={props?.vehicles.loading}>
                                {props?.vehicles?.data?.map((vehicle) => (
                                    <Option key={vehicle.id} value={vehicle.id}>{vehicle.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="price"
                            label="Fiyat"
                            rules={[{ required: true, message: 'Lütfen fiyat giriniz!' }]}
                        >
                            <InputNumber
                                min={0}
                                placeholder="Fiyat"
                                addonAfter="TRY"
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default TransferModalForm;