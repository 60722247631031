import React from 'react';
import {
    Breadcrumb, Button, Form,
    Layout,
    notification,
    Space,
    Table
} from "antd";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHouse} from "@fortawesome/free-solid-svg-icons";
import Wrapper from "@components/Wrapper";
import {ExternalBankService} from "@services";
import {useTranslation} from "react-i18next";
import CreateBankModalForm from "./components/CreateBankModalForm";

const {Content} = Layout;

const PoolBankList = () => {
    const [createForm] = Form.useForm();
    const [createFormVisible, setCreateFormVisible] = React.useState(false);
    const { t } = useTranslation()
    const [banks, setBanks] = React.useState({
        data: [],
        meta: {},
        loading: true,
        error: false
    });
    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            width: 100,
            key: 'id',
        },
        {
            title: 'Banka',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: "İşlemler",
            key: 'action',
            render: (text, record) => (
                <Space align="middle">

                </Space>
            ),

        }
    ];

    const getBanks = async (page = 1, per_page = 10) => {
        setBanks({
            ...banks,
            loading: true
        })
        try {
            let {data: response} = await ExternalBankService.get(page, per_page);
            setBanks({
                data: response.data,
                meta: response.meta,
                loading: false
            })
        }catch (e){
            if(e.response){
                notification.error({
                    message: 'Hata',
                    description: e.response.data.message
                })
            }
            setBanks({
                ...banks,
                loading: false
            })
        }
    }

    const onCreate = async (values) => {
        try {
            await ExternalBankService.create(values);
            notification.success({
                message: 'Başarılı',
                description: 'Banka başarıyla oluşturuldu'
            })
            setCreateFormVisible(false);
            getBanks();
        }catch (e){
            if(e.response){
                notification.error({
                    message: 'Hata',
                    description: e.response.data.message
                })
            }
        }
    }


    React.useEffect(() => {
        getBanks();
    },[])
    return (
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-20">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse} />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {t('general.bank')}
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background">
                    <Button type="primary" htmlType="button" onClick={() => setCreateFormVisible(true)}>
                        {t('general.add')}
                    </Button>
                    <Table
                        columns={columns}
                        dataSource={banks.data}
                        loading={banks.loading}
                        rowKey={record => record.id}
                        pagination={{
                            total: banks.meta.total,
                            current: banks.meta.current_page,
                            pageSize: banks.meta.per_page,
                        }}
                        onChange={(pagination) => getBanks(pagination.current, pagination.pageSize)}
                    />
                </div>
                <CreateBankModalForm
                    form={createForm}
                    isVisible={createFormVisible}
                    title={t('general.bank')}
                    onCancel={() => {
                        setCreateFormVisible(false)
                        createForm.resetFields()
                    }}
                    onFinish={onCreate}
                />
            </Content>
        </Wrapper>
    );
};

export default PoolBankList;