import React from 'react';
import {Button, Dropdown, Layout, Menu, Space, Comment, notification} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faBars, faBell, faEnvelopeOpen} from '@fortawesome/free-solid-svg-icons';
import LogOutButton from "@components/utils/LogOutButton";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {UserService} from "@services";
import {useTranslation} from "react-i18next";
import {setNotification} from "../features/notificationSlice";

const { Header } = Layout;

const Notifications = ({ t }) => {
    const dispatch = useDispatch();
    const notifications = useSelector((state) => state.notifications.notifications.data);
    const count = useSelector((state) => state.notifications.notifications.count);
    const setNotificationRead = async (id) => {
        try {
            let {data: response} = await UserService.readNotification(id);
            if(response.success){
                dispatch(setNotification([
                    ...notifications.filter((notification) => notification.id !== id)
                ]))
            }
        }catch (e){
            if(e.response){
                notification.error({
                    message: 'Hata',
                    description: e.response.data.message
                })
            }
        }
    }
    return (
        <Dropdown
            trigger="click"
            overlay={() => {
                return (
                    <Menu>
                        <div style={{
                            padding: 12,
                            borderBottom: '1px solid #000'
                        }}>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}>
                                <h6 style={{
                                    fontSize: 16,
                                    fontWeight: 'bold',
                                    marginBottom: 0
                                }}>
                                    {t("general.notifications")}
                                    <span className="ant-badge ant-badge-not-a-wrapper css-c9qtzy">
                                        <sup data-show="true" className="ant-scroll-number ant-badge-count" title="3" style={{
                                            backgroundColor: 'rgb(255, 226, 226)',
                                            color: 'rgb(255, 108, 108)',
                                            fontSize: 13,
                                            fontWeight: 'bold'
                                        }}>
                                            <bdi>
                                                <span className="ant-scroll-number-only" style={{
                                                    opacity: 1,
                                                    transform: 'scale(1)',
                                                    transition: 'none 0s ease 0s'
                                                }}>
                                                    <span className="ant-scroll-number-only-unit current">{notifications?.length || 0}</span>
                                                </span>
                                            </bdi>
                                        </sup>
                                    </span>
                                </h6>
                            </div>
                        </div>
                        <div style={{
                            height: 300,
                            overflowY: 'auto'
                        }}>
                            {notifications.map((notification) => (
                                <Menu.Item key={notification.id} style={{
                                    maxWidth: 350,
                                    background: "white"
                                }}>
                                    <Link to={notification.content.url ? notification.content.url: '/'}>
                                        <Comment
                                            width={30}
                                            content={
                                                <span>
                                               {notification.content.body}
                                           </span>
                                            }
                                            author={<span>{notification.content.title}</span>}
                                            datetime={
                                                <span>{notification.created_at}</span>
                                            }
                                        />
                                    </Link>
                                    <Button size="small" onClick={(e) => {
                                        e.stopPropagation();
                                        setNotificationRead(notification.id)
                                    }} htmlType="button">
                                        <FontAwesomeIcon icon={faEnvelopeOpen} style={{
                                            marginRight: 12,
                                            width: 12,
                                            height: 12
                                        }}/>
                                        <span className="comment-action" style={{
                                            fontSize: 12
                                        }}>Okundu</span>
                                    </Button>
                                </Menu.Item>
                            ))}
                        </div>
                    </Menu>
                )
            }}
        >
            <Button type="dashed" icon={<FontAwesomeIcon icon={faBell} style={{
                marginRight: 12
            }}/> }>
                {count || 0} {t("general.notification")}
            </Button>
        </Dropdown>
    )
}

const Navbar = (props) => {
    const { t, i18n } = useTranslation()
    const user = useSelector((state) => state.user.user);
    const handleChangeLanguage = async (language) => {
        try {
            await i18n.changeLanguage(language);
            await UserService.changeLanguage(language);
            localStorage.setItem('locale', language);
        }catch (e){
            //
        }
    }
  return (
    <Header className="header" style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
      <Button type="link" htmlType="button" className="navbar-toggle-btn" onClick={props.onToggleSiderbar}>
        <FontAwesomeIcon icon={faBars} />  
      </Button>
        <Space direction="horizontal">
            <div style={{
                color: '#fff',
                display:"flex",
                alignItems:"center",
                fontSize: 14,
                fontWeight: 600
            }}>
                {user?.full_name}
            </div>
            <Notifications t={t} />
            <Dropdown overlay={() => {
                return (
                    <Menu>
                        {i18n.languages
                            .filter((language) => language !== i18n.language)
                            .map((language) => (
                                <Menu.Item key={language} onClick={() => handleChangeLanguage(language)}>
                                    {language.toUpperCase()}
                                </Menu.Item>
                        ))}
                    </Menu>
                )
            }}>
                <Button type="dashed">
                    {i18n.language.toUpperCase()}
                </Button>
            </Dropdown>
            <LogOutButton/>
        </Space>
    </Header>
  );
};

export default Navbar;