import React from 'react';
import {Button, Col, DatePicker, Form, Input, Modal, Radio, Row, Select, Upload} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFile, faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";

const CreateBankModalForm = (props) => {
    const { isVisible, title, form, data, onCancel, onFinish } = props;
    const onOk = async (values) => {
        const formData = new FormData();
        formData.append('name', values.name);
        formData.append('logo', values.logo.file);
        values?.cards.map((card, index) => {
            formData.append(`cards[${index}][name]`, card.name);
            formData.append(`cards[${index}][logo]`, card.logo.file);
        })
        onFinish(formData,data?.id)
    }
    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }
    return (
        <Modal visible={isVisible} title={title} closable={false} onOk={() => form.submit()} onCancel={onCancel}>
            <Form form={form} layout="vertical" onFinish={(values) => onOk(values)}>
                <Row gutter={[16,16]}>
                    <Col span={24}>
                        <Form.Item
                            label="Banka Adı"
                            name="name"
                            rules={[{ required: true, message: 'Lütfen banka adını giriniz!' }]}
                        >
                            <Input
                                placeholder="Banka Adı"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label="Logo"
                            name="logo"
                            rules={[{ required: true, message: 'Lütfen logo yükleyiniz!' }]}
                        >
                            <Upload
                                accept="image/*"
                                beforeUpload={() => false}
                                showUploadList={false}
                                style={{width:"100%"}}
                            >
                                <Button style={{width:"100%"}}>
                                    <FontAwesomeIcon icon={faFile} style={{marginRight: "10px"}}/> Dosya Seç
                                </Button>
                            </Upload>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.List
                            name={['cards']}
                            rules={[{
                                validator: async (_, mails) => {
                                    if (!mails || mails.length < 1) {
                                        return Promise.reject(new Error('En az bir kart eklemelisiniz.!'));
                                    }
                                }
                            }]}
                        >
                            {(fields, { add, remove }, { errors }) => (
                                <>
                                    {fields.map((field, index) => (
                                        <div style={{ position: "relative" }} key={field.key}>
                                            <Form.Item
                                                label={<h4>{index + 1}. Kart</h4>}
                                                required={false}
                                            >
                                                <Row gutter={[16,0]}>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            name={[field.name, 'name']}
                                                            validateTrigger={['onChange', 'onBlur']}
                                                            label="Kart Adı"
                                                            rules={[{
                                                                required: true,
                                                                message: "Kart adını giriniz!"
                                                            }]}
                                                        >
                                                            <Input placeholder="Kart Adı"/>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            label="Logo"
                                                            name={[field.name, 'logo']}
                                                            rules={[{ required: true, message: 'Lütfen logo yükleyiniz!' }]}
                                                        >
                                                            <Upload
                                                                accept="image/*"
                                                                beforeUpload={() => false}
                                                                showUploadList={false}
                                                                style={{width:"100%"}}
                                                            >
                                                                <Button style={{width:"100%"}}>
                                                                    <FontAwesomeIcon icon={faFile} style={{marginRight: "10px"}}/> Dosya Seç
                                                                </Button>
                                                            </Upload>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </Form.Item>
                                            <Button type="dashed" size="small" style={{
                                                position: 'absolute',
                                                right: '0',
                                                top: '0',
                                            }} onClick={() => remove(field.name)}>
                                                <FontAwesomeIcon icon={faMinus}/>
                                            </Button>
                                        </div>
                                    ))}
                                    <Form.Item>
                                        <Button
                                            type="dashed"
                                            onClick={() => add()}
                                            style={{
                                                width: '100%',
                                            }}
                                            icon={<FontAwesomeIcon icon={faPlus}/>}
                                        >
                                            Kart Ekle
                                        </Button>
                                        <Form.ErrorList errors={errors} />
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default CreateBankModalForm;