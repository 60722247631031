import React, {useEffect} from 'react';
import {Breadcrumb, Button, Col, Form, Layout, notification, Row, Table} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHouse} from "@fortawesome/free-solid-svg-icons";
import Wrapper from "@components/Wrapper";
import AdditionalServiceModalForm from "@components/additional-services/AdditionalServiceModalForm";
import { AdditionalService } from "@services";
import {useTranslation} from "react-i18next";
const {Content} = Layout;


const AdditionalServices = () => {
    const { t } = useTranslation()
    const [createAdditionalServiceForm] = Form.useForm();
    const [isCreateModalVisible, setIsCreateModalVisible] = React.useState(false);
    const [data, setData] = React.useState({
        data: [],
        meta: {},
        loading: true
    });

    const columns = [
        {
            key: 'id',
            title: 'ID',
            dataIndex: 'id',
            render: (text) => text
        },
        {
            key: 'name',
            title: `${t('general.service_name')}`,
            dataIndex: 'name',
            render: (text) => text
        }
    ]

    const onCreate = async (values) => {
        try{
            let {data: response} = await AdditionalService.create(values);
            if(response.success){
                notification.success({
                    message: 'Başarılı',
                    description: response.message
                });
                getServices();
                setIsCreateModalVisible(false);
                createAdditionalServiceForm.resetFields();
            }
        }catch (e){
            if(e.response){
                notification.error({
                    message: 'Hata',
                    description: e.response.data.message
                });
            }
        }
    }

    const getServices = async (page = 1, per_page = 10) => {
        setData({
            ...data,
            loading: true
        })
        try{
            let {data: response} = await AdditionalService.get(page, per_page);
            setData({
                data: response.data,
                meta: response.meta,
                loading: false
            })
        }catch (e){
            if(e.response){
                notification.error({
                    message: 'Hata',
                    description: e.response.data.message
                });
            }
            setData({
                ...data,
                loading: false
            })
        }
    }

    useEffect(() => {
        getServices();
    }, []);

    return (
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-20">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse} />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {t('general.additional_services')}
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background">
                    <Row gutter={[16,16]} style={{
                        marginBottom: '10px'
                    }}>
                        <Col span={24}>
                            <Button type="primary" htmlType="button" onClick={() => setIsCreateModalVisible(true)}>
                                {t('general.create_additional_service')}
                            </Button>
                        </Col>
                    </Row>
                    <Table
                        columns={columns}
                        dataSource={data.data}
                        pagination={{
                            total: data.meta?.total,
                            defaultPageSize: data.meta?.per_page
                        }}
                        loading={data.loading}
                        rowKey={record => record.id}
                    />
                </div>
            </Content>
            <AdditionalServiceModalForm
                title={t('general.create_additional_service')}
                form={createAdditionalServiceForm}
                isVisible={isCreateModalVisible}
                onCancel={() => setIsCreateModalVisible(false)}
                onFinish={onCreate}
            />
        </Wrapper>
    );
};

export default AdditionalServices;