import axios from '../axios';

const ContractCommissionService = {
    /*async get(hotelId, languageId) {
        return await axios.get(`/v1/contracts/${hotelId}`);
    },*/
    async getByContractId(contractId) {
        return await axios.get(`/v1/contract-commissions/${contractId}/`);
    },
    async getByContractGroupId(page = 1, per_page = 10 ,contractGroupId) {
        return await axios.get(`/v1/contract-commissions/contract-group/${contractGroupId}?page=${page}&per_page=${per_page}`);
    },
    async create(formData) {
        return await axios.post(`/v1/contract-commissions`, formData);
    },
};

export default ContractCommissionService;