import React, { useState, useEffect } from 'react';
import {Link, useParams, useNavigate} from "react-router-dom";
import {Layout, Breadcrumb, Form, Row, Col, Table, Space, Button, Popconfirm, notification} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faEdit, faHouse, faPlus, faTrash} from '@fortawesome/free-solid-svg-icons';
import Wrapper from '@components/Wrapper';
import { HotelRoomNamesService,RuspoApiService, HotelService } from '@services';
import HotelRoomMatchModalForm from "../../components/hotel-room-match/HotelRoomMatchModalForm";


const { Content } = Layout;

const HotelRoomMatch = () => {
    const [matchForm] = Form.useForm();
    const navigate = useNavigate();
    const params = useParams();
    const [loading, setLoading] = useState(true);
    const [hotels,setHotels] = useState({});
    const [ruspoDataAll,setRuspoDataAll]=useState([])
    const [isRuspoLoading,setIsRuspoLoading]=useState(true);
    const [isSessionLoading,setIsSessionLoading]=useState(true);
    const [hotelRoomNames, setHotelRoomNames] = useState([]);
    const [isLoadingTable, setIsLoadingTable] = useState(true);
    const [isMatchModalVisible, setIsMatchModalVisible] = useState(false);
    const columns = [
        {
            key: 'index',
            title: '#',
            render: (text, record, index) => index + 1
        },
        {
            key: 'hotel_room_name',
            title: 'Oda Adı',
            dataIndex: 'hotel_room_name',
            render: (text, record) => (
                text
            )
        },
        {
            key: 'operator_room_name',
            title: 'Operatör Adı',
            dataIndex: 'operator_room_name',
            render: (text, record) => (
                text
            )
        },
        {
            key: 'actions',
            title: 'İşlemler',
            render: (text, record) => (
                <Space align="middle">
                    <Button type="primary" onClick={() => console.log('Edit')}>
                        <FontAwesomeIcon icon={faEdit} />
                    </Button>
                    <Popconfirm title="Silmek istediğine emin misin?" onConfirm={() => console.log('Delete')} okText="Sil" cancelText="İptal">
                        <Button type="danger">
                            <FontAwesomeIcon icon={faTrash} />
                        </Button>
                    </Popconfirm>
                </Space>
            )
        }
    ];
    const getHotels = async () => {
        try {
            let { data: response } = await HotelService.findById(params.hotelId);
            setHotels(response);
            getRuspoSession(response.data.ruspo_id)
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    };
    const getHotelRoomNames = async () => {
        try {
            let { data: { data: response } } = await HotelRoomNamesService.getByHotelId(params.hotelId);
            setHotelRoomNames(response);
            setIsLoadingTable(false);
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }

    const getRuspoData = async (ruspoId) => {
        let ruspo = ruspoId;
        try {
            let { data: response } = await RuspoApiService.ruspoGetData(ruspoId);
            if(response.percent != "100"){
                getRuspoData(ruspo)
            }else{
                await filterRuspoDatas(response)
                setIsRuspoLoading(false)
            }
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }
    const getRuspoSession = async (ruspoId) => {
        try {
            let { data: response } = await RuspoApiService.ruspoGetSession(ruspoId);
            getRuspoData(response[0])
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }

    const onCreate = async (values) => {
        let newValues = [];
        values.operator_room_name.map((room)=>{
            newValues.push({
                hotel_id:params.hotelId,
                hotel_room_id:values.hotel_room_name.split('-')[0],
                hotel_room_name:values.hotel_room_name.split('-')[1],
                operator_id:room.split('-', 2)[0],
                operator_room_name:room.split('-', 2)[1],
            })
        })
        try {
            let { data: response } = await HotelRoomNamesService.create(params.hotelId,newValues);
            getHotelRoomNames()
            notification.success({
                message: response.message
            });
            setIsMatchModalVisible(false)
            navigate(`/hotel-room/match/${params.hotelId}`);
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }
    const filterRuspoDatas = (ruspoDataRaw)=>{
        let ruspoRooms = []
        setRuspoDataAll([])
        Object.keys(ruspoDataRaw?.operators).map((keys,i)=>{
            Object.keys(ruspoDataRaw.operators[keys]).map((data,index)=>{
                ruspoRooms.push({
                    operator_id: ruspoDataRaw.operators[keys][data].operator_id,
                    operatorsName: ruspoDataRaw.operators[keys][data].operatorsName,
                    roomName: ruspoDataRaw.operators[keys][data].roomName
                })
            })
        })
        let filteredRuspoRooms = ruspoRooms.filter(function(item){
            for (let key in hotelRoomNames) {
                if (item.roomName == hotelRoomNames[key].operator_room_name && item.operator_id == hotelRoomNames[key].operator_id){
                    return false;
                }
            }
            return true;
        })
        setRuspoDataAll(filteredRuspoRooms)
    }

    useEffect(()=>{
        getHotelRoomNames()
        getHotels()
    },[])


    return (
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-20">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse} />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        Oda Düzenle
                    </Breadcrumb.Item>
                </Breadcrumb>

                <div className="site-layout-background">
                    {
                        isRuspoLoading && <p>Yükleniyor Lütfen Bekleyiniz...</p>
                    }
                    {
                        !isRuspoLoading &&
                            <Button type="primary" className="mb-10" onClick={() => setIsMatchModalVisible(true)}>
                                <FontAwesomeIcon icon={faPlus} className="mr-10" />
                                Yeni Eşleştirme
                            </Button>
                    }
                    <Table
                        columns={columns}
                        dataSource={hotelRoomNames}
                        rowKey="id"
                        loading={isLoadingTable}
                        bordered
                    />
                </div>
            </Content>
            {
                !isRuspoLoading &&
                    <HotelRoomMatchModalForm
                        form={matchForm}
                        isVisible={isMatchModalVisible}
                        title="Oda ve Operatör Eşleştirmesi"
                        onFinish={(values) => {
                            onCreate(values);
                            matchForm.resetFields();
                        }}
                        onCancel={() => {
                            setIsMatchModalVisible(false);
                            matchForm.resetFields();
                        }}
                        ruspoData={ruspoDataAll}
                        hotelId={params.hotelId}
                    />
            }
        </Wrapper>
    );
};

export default HotelRoomMatch;