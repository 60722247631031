import axios from "../axios";

const HotelRoomNamesService = {
    async getByHotelId(hotelId) {
        return await axios.get(`/v1/hotelRoomName/${hotelId}`);
    },
    async create(hotelId,formData) {
        return await axios.post(`/v1/hotelRoomName/${hotelId}`,formData);
    },
};

export default HotelRoomNamesService;