import axios from '../axios';

const CMChildAgeService = {
    async create(formData) {
        return await axios.post(`/v1/channel-management/child-ages/create`, formData);
    },
    async get(hotelId, page = 1) {
        return await axios.get(`/v1/channel-management/child-ages/list?page=${page}`,{
            params: {
                hotel_id: hotelId
            }
        });
    }
};

export default CMChildAgeService;