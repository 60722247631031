import React, {useEffect, useState} from "react";
import {Modal, Row, Col, Form, notification, Select, Input, InputNumber, DatePicker} from "antd";
import {useTranslation} from "react-i18next";
import {TargetService} from "@/services";

const { Option } = Select;
const { TextArea } = Input;

const DynamicInput = ({ field, onChange }) => {
    switch (field.type) {
        case 'string':
            return <Input style={{ width: "100%" }} onChange={onChange} placeholder="Bilgiyi giriniz."/>
        case 'number':
            return <InputNumber style={{ width: "100%" }} onChange={onChange} placeholder="Bilgiyi giriniz."/>
        case 'select':
            return (
                <Select style={{ width: "100%" }} onChange={onChange} placeholder="Bilgiyi seçiniz.">
                    {field.options.map((option, index) => (
                        <Option key={index} value={option.value}>{option.label}</Option>
                    ))}
                </Select>
            )
        case 'date':
            return <DatePicker style={{ width: "100%" }} format="DD.MM.YYYY" onChange={(value) => onChange(value.format('YYYY-MM-DD'))} placeholder="Bilgiyi giriniz."/>
        case 'datetime':
            return <DatePicker showTime style={{ width: "100%" }} format="DD.MM.YYYY HH:mm:ss" onChange={(value) => onChange(value.format('YYYY-MM-DD HH:mm:ss'))} placeholder="Bilgiyi giriniz."/>
        case 'textarea':
            return <TextArea style={{ width: "100%" }} onChange={onChange} placeholder="Bilgiyi giriniz."/>
        default:
            return <Input style={{ width: "100%" }} onChange={onChange} placeholder="Bilgiyi giriniz."/>
    }
}

const TargetDefinitionModalForm = (props) => {
    const {t} = useTranslation();
    const [form] = Form.useForm();
    const target = Form.useWatch('target', form);
    const [data, setData] = useState({
        data: [],
        loading: true
    });
    const getTargets = async () => {
        try {
            let {data: response} = await TargetService.getTargets();
            setData({
                data: response.data,
                loading: false
            })
        }catch (e) {
            if(e.response){
                notification.error({
                    message: t('general.error'),
                    description: e.response.data.message
                })
            }
        }
    }
    const onCreate = async (values) => {
        try {
            let {data: response} = await TargetService.createTargetDefinition(values)
            if (response.success){
                notification.success({
                    message: t('general.success'),
                    description: response.message
                })
                props.onFinish();
            }
        }catch (e) {
            if(e.response){
                notification.error({
                    message: t('general.error'),
                    description: e.response.data.message
                })
            }
        }
    }
    useEffect(() => {
        getTargets();
    }, []);
    return (
        <Modal visible={props.isVisible} title={props.title} closable={false} onOk={() => form.submit()} onCancel={props.onCancel}>
            <Form form={form} layout="vertical" onFinish={(values) => onCreate(values)}>
                <Row gutter={[16,16]}>
                    <Col span={12}>
                        <Form.Item name="target" label="Hedef Tipi" rules={[{ required: true, message: 'Hedef seçilmek zorundadır.' }]}>
                            <Select placeholder={t("general.choose")} allowClear={true}>
                                {data.data.map((item) => (
                                    <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="title" label="Hedef Adı" rules={[{ required: true, message: 'Hedef adı girilmelidir.' }]}>
                            <Input placeholder="Hedef adını giriniz."/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="target_start_date" label="Hedef Başlangıç Tarihi" rules={[{ required: true, message: 'Başlangıç tarihi girilmelidir.' }]}>
                            <DatePicker showTime format="DD.MM.YYYY HH:mm:ss" style={{ width: "100%" }} placeholder="Başlangıç tarihini giriniz."/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="target_end_date" label="Hedef Bitiş Tarihi" rules={[{ required: true, message: 'Bitiş tarihi girilmelidir.' }]}>
                            <DatePicker showTime format="DD.MM.YYYY HH:mm:ss" style={{ width: "100%" }} placeholder="Bitiş tarihini giriniz."/>
                        </Form.Item>
                    </Col>
                    {data?.data?.find(item => item.id === target)?.fields.map((item) => (
                        <Col span={12} key={item.key}>
                            <Form.Item name={item.key} label={item.label} rules={[{ required: item.required, message: item.label + ' alanı zorunludur.' }]}>
                                <DynamicInput field={item} />
                            </Form.Item>
                        </Col>
                    ))}
                </Row>
            </Form>
        </Modal>
    );
};

export default TargetDefinitionModalForm;