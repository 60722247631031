import axios from "../axios";

const HotelRoomBedService = {
    async create(hotelId, roomId, formData) {
        return await axios.post(`/v1/hotels/${hotelId}/rooms/${roomId}/beds`,formData);
    },

    async delete(hotelId, roomId, hotelRoomBedId) {
        return await axios.delete(`/v1/hotels/${hotelId}/rooms/${roomId}/beds/${hotelRoomBedId}`);
    },
};

export default HotelRoomBedService;
