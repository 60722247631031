import React from "react";
import {Layout, Menu} from "antd";
import {useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faArrowRightArrowLeft,
  faBell,
  faBriefcase,
  faBuildingColumns,
  faCalculator,
  faCalendarCheck,
  faCalendarDays,
  faCalendarPlus,
  faCar,
  faCheck,
  faChild,
  faCity,
  faCloud,
  faCreditCard,
  faDashboard,
  faEarth,
  faFileCirclePlus,
  faFileContract,
  faFileLines,
  faGears,
  faHotel,
  faListCheck,
  faListUl,
  faMapLocationDot,
  faMobilePhone,
  faMoneyBill,
  faMoneyBillTransfer,
  faPaperPlane,
  faPeopleArrows,
  faPhotoFilm,
  faPlane,
  faPlaneDeparture,
  faShapes,
  faSitemap,
  faSliders,
  faSuitcase,
  faTable,
  faTag,
  faUsers,
  faMap,
  faContactCard,
  faMagnifyingGlass,
  faMagnifyingGlassChart,
  faPhone,
  faSave,
  faUsersGear,
  fa2,
  faLocation,
  faArrowsTurnToDots, faRoute, faSailboat, faShop,
} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

const { Sider } = Layout;

const Sidebar = (props) => {
  const { t } = useTranslation()
  const LaraBeachRoutes = [
    {
      key: "/lara-beach/reservations",
      icon: <FontAwesomeIcon icon={faCalendarDays}/>,
      label: <Link to="/lara-beach-excursions/reservations">{t("general.reservations")}</Link>,
      permission: ['lara-beach-excursions.view'],
    },
    {
      key: "/lara-beach/definitions",
      icon: <FontAwesomeIcon icon={faFileCirclePlus} />,
      label: t("general.definitions"),
      permission: ['lara-beach-excursions.view'],
      children: [
        {
          key: "/lara-beach/points",
          icon: <FontAwesomeIcon icon={faLocation} />,
          label: <Link to="/lara-beach-excursions/points">{t("general.points")}</Link>,
          permission: ['lara-beach-excursions.view']
        },
        {
          key: "/lara-beach/vehicles",
          icon: <FontAwesomeIcon icon={faCar} />,
          label: <Link to="/lara-beach-excursions/vehicles">{t("general.vehicles")}</Link>,
          permission: ['lara-beach-excursions.view']
        }
      ]
    },
    {
      key: "/lara-beach/transfers",
      icon: <FontAwesomeIcon icon={faCar} />,
      label: <Link to="/lara-beach-excursions/transfers">{t("general.transfers")}</Link>,
      permission: ['lara-beach-excursions.view'],
    },
    {
      key: "/lara-beach/tours",
      icon: <FontAwesomeIcon icon={faRoute} />,
      label: <Link to="/lara-beach-excursions/tours">{t("general.tours")}</Link>,
      permission: ['lara-beach-excursions.view'],
    },
    {
      key: "/lara-beach/activities",
      icon: <FontAwesomeIcon icon={faSailboat} />,
      label: <Link to="/lara-beach-excursions/activities">{t("general.activities")}</Link>,
      permission: ['lara-beach-excursions.view'],
    },
    {
      key: "/lara-beach/shopping",
      icon: <FontAwesomeIcon icon={faShop} />,
      label: <Link to="/lara-beach-excursions/shopping">{t("general.shopping")}</Link>,
      permission: ['lara-beach-excursions.view'],
    }
  ]
  const menus = [
    {
      key: "/",
      icon: <FontAwesomeIcon icon={faDashboard} />,
      label: <Link to="/">{t("general.home")}</Link>,
      permission: []
    },
    {
      key: "/reservations-sub",
      icon: <FontAwesomeIcon icon={faCalendarDays} />,
      label: `${t("general.reservations")}`,
      permission: ['reservations.view','reservation.create'],
      children: [
        {
          key: "/new-reservation",
          icon: <FontAwesomeIcon icon={faCalendarPlus} />,
          label: <Link to="/new-reservation">{t("general.new_reservation")}</Link>,
          permission: ['reservation.create']
        },
        {
          key: "/reservations",
          icon: <FontAwesomeIcon icon={faCalendarCheck} />,
          label: <Link to="/reservations">{t("general.reservations")}</Link>,
          permission: ['reservations.view']
        },
        {
          key: "/package-reservations",
          icon: <FontAwesomeIcon icon={faCalendarCheck} />,
          label: <Link to="/package-reservations">{t("general.package_reservation")}</Link>,
          permission: ['package_reservations.view']
        },
        {
          key: "/provider-reservations-parent",
          icon: <FontAwesomeIcon icon={faCalendarCheck} />,
          label: `${t("general.provider_reservations")}`,
          permission: ['provider_reservations.view','provider_reservations.create'],
          children: [
            {
              key: "/provider-reservations/create",
              icon: <FontAwesomeIcon icon={faHotel} />,
              label: <Link to="/provider-reservations/create">{t("general.new_reservation")}</Link>,
              permission: ['provider_reservations.create'],
            },
            {
              key: "/provider-reservations",
              icon: <FontAwesomeIcon icon={faHotel} />,
              label: <Link to="/provider-reservations">{t("general.reservations")}</Link>,
              permission: ['provider_reservations.view'],
            }
          ]
        },
      ],
    },
    {
      key: "/hotels-sub",
      icon: <FontAwesomeIcon icon={faHotel} />,
      label: `${t("general.hotels")}`,
      permission: ['hotel.view','hotel.image.view','hotel.events.view','hotel.periods.view','hotel.condition-groups.view','hotel.child-ages.view','hotel.contract-hotels.view','hotel.widgets.view','hotel.forecast.view','hotel.discounts.view','hotel.night-discounts.view','hotel.mobile-discounts.view','hotel.early-reservation-discounts.view','hotel.action-discounts.view'],
      children: [
        {
          key: "/hotels",
          icon: <FontAwesomeIcon icon={faHotel} />,
          label: <Link to="/hotels">{t("general.hotels")}</Link>,
          permission: ['hotel.view'],
        },
        {
          key: "/photos",
          icon: <FontAwesomeIcon icon={faPhotoFilm} />,
          label: <Link to="/photos">{t("general.images")}</Link>,
          permission: ['hotel.image.view'],
        },
        {
          key: "/events",
          icon: <FontAwesomeIcon icon={faCalendarDays} />,
          label: <Link to="/events">{t("general.events")}</Link>,
          permission: ['hotel.events.view'],
        },
        {
          key: "/periods",
          icon: <FontAwesomeIcon icon={faListUl} />,
          label: <Link to="/periods">{t("general.periods")}</Link>,
          permission: ['hotel.periods.view'],
        },
        {
          key: "/condition-groups",
          icon: <FontAwesomeIcon icon={faPeopleArrows} />,
          label: <Link to="/condition-groups">{t("general.conditions")}</Link>,
          permission: ['hotel.condition-groups.view'],
        },
        {
          key: "/child-ages",
          icon: <FontAwesomeIcon icon={faChild} />,
          label: <Link to="/child-ages">{t("general.child_ages")}</Link>,
          permission: ['hotel.child-ages.view'],
        },
        {
          key: "/contract-hotels",
          icon: <FontAwesomeIcon icon={faFileLines} />,
          label: <Link to="/contract-hotels">{t("general.contracts")}</Link>,
          permission: ['hotel.contract-hotels.view'],
        },
        {
          key: "/widgets",
          icon: <FontAwesomeIcon icon={faShapes} />,
          label: <Link to="/widgets">{t("general.widgets")}</Link>,
          permission: ['hotel.widgets.view'],
        },
        {
          key: "/forecast",
          icon: <FontAwesomeIcon icon={faTable} />,
          label: <Link to="/forecast">{t("general.forecast")}</Link>,
          permission: ['hotel.forecast.view'],
        },
        {
          key: "/discounts-sub",
          icon: <FontAwesomeIcon icon={faTag} />,
          label: `${t("general.discounts")}`,
          permission: ['hotel.discounts.view','hotel.night-discounts.view','hotel.mobile-discounts.view','hotel.early-reservation-discounts.view','hotel.action-discounts.view'],
          children: [
            {
              key: "/discounts",
              icon: <FontAwesomeIcon icon={faTag} />,
              label: <Link to="/discounts">{t("general.all_discounts")}</Link>,
              permission: ['hotel.discounts.view'],
            },
            {
              key: "/night-discounts",
              icon: <FontAwesomeIcon icon={faTag} />,
              label: <Link to="/night-discounts">{t("general.night_discounts")}</Link>,
              permission: ['hotel.night-discounts.view'],
            },
            {
              key: "/mobile-discounts",
              icon: <FontAwesomeIcon icon={faMobilePhone} />,
              label: <Link to="/mobile-discounts">{t("general.mobile_discounts")}</Link>,
              permission: ['hotel.mobile-discounts.view'],
            },
            {
              key: "/early-reservation-discounts",
              icon: <FontAwesomeIcon icon={faBell} />,
              label: <Link to="/early-reservation-discounts">{t("general.early_reservation_discounts")}</Link>,
              permission: ['hotel.early-reservation-discounts.view'],
            },
            {
              key: "/action-discounts",
              icon: <FontAwesomeIcon icon={faBell} />,
              label: <Link to="/action-discounts">{t("general.action_discounts")}</Link>,
              permission: ['hotel.action-discounts.view'],
            }
          ],
        },
      ],
    },
    {
      key: "/muhasebe",
      icon: <FontAwesomeIcon icon={faCalculator} />,
      label: `${t("general.accounting")}`,
      permission: ['accounting.view'],
      children: [
        {
          key: "/current-deficit-transactions",
          icon: <FontAwesomeIcon icon={faMoneyBill} />,
          label: <Link to="/current-deficit-transactions">{t("general.caris")}</Link>,
          permission: ['accounting.current-deficit-transactions.view'],
        },
        {
          key: "/safe-bank-transactions",
          icon: <FontAwesomeIcon icon={faBuildingColumns} />,
          label: <Link to="/safe-bank-transactions">{t("general.safe_bank_transactions")}</Link>,
          permission: ['safe_bank_cari_transactions.view'],
        },
        {
          key: "/accounting-reservations",
          icon: <FontAwesomeIcon icon={faCalendarCheck} />,
          label: <Link to="/accounting-reservations">{t("general.reservations")}</Link>,
          permission: ['accounting.accounting-reservations.view'],
        },
        {
          key: "/deferred-reservations",
          icon: <FontAwesomeIcon icon={faListCheck} />,
          label: <Link to="/deferred-reservations">{t("general.due_payments")}</Link>,
          permission: ['accounting.deferred-reservations.view'],
        },
        {
          key: "/financial-transactions",
          icon: <FontAwesomeIcon icon={faMoneyBillTransfer} />,
          label: `${t("general.financial_transactions")}`,
          permission: ['accounting.virtual-pos-transactions.view'],
          children: [
            {
              key: "/reports/bank-transactions",
              icon: <FontAwesomeIcon icon={faCreditCard} />,
              label: <Link to="/reports/bank-transactions">{t("general.bank_transactions")}</Link>,
              permission: [],
            },
            {
              key: "/virtual-pos-transactions",
              icon: <FontAwesomeIcon icon={faCreditCard} />,
              label: <Link to="/virtual-pos-transactions">{t("general.virtual_pos_transactions")}</Link>,
              permission: ['accounting.virtual-pos-transactions.view'],
            },
            {
              key: "/transfer-transactions",
              icon: <FontAwesomeIcon icon={faCreditCard} />,
              label: <Link to="/transfer-transactions">{t("general.transfer_transactions")}</Link>,
              permission: [],
            }
          ]
        },
        {
          key: "/progress-payment",
          icon: <FontAwesomeIcon icon={faListCheck} />,
          label: <Link to="/progress-payment">{t("general.progress_payment")}</Link>,
          permission: ['accounting.progress-payment.view'],
        },
        {
          key: "/payment-table",
          icon: <FontAwesomeIcon icon={faListCheck} />,
          label: <Link to="/payment-table">{t("general.payment_table")}</Link>,
          permission: ['accounting.payment_periods.view'],
        }
      ],
    },
    {
      key: "/operation",
      icon: <FontAwesomeIcon icon={faGears} />,
      label: `${t("general.operations")}`,
      permission: ['operation.view', 'operation.login-exit.view', 'operation.confirm.view', 'operations.reservation-forecast.view'],
      children: [
        {
          key: "/login-exit",
          icon: <FontAwesomeIcon icon={faListCheck} />,
          label: <Link to="/login-exit">{t("general.check_in_out_list")}</Link>,
          permission: ['operation.login-exit.view'],
        },
        {
          key: "/confirme",
          icon: <FontAwesomeIcon icon={faCheck} />,
          label: <Link to="/confirme">{t("general.confirm")}</Link>,
          permission: ['operation.confirm.view'],
        },
        {
          key: "/operation/reservation-forecast",
          icon: <FontAwesomeIcon icon={faCheck} />,
          label: <Link to="/operation/reservation-forecast">{t("general.forecast")}</Link>,
          permission: ['operations.reservation-forecast.view'],
        }
      ],
    },
    {
      key: "/settings",
      icon: <FontAwesomeIcon icon={faSliders} />,
      label: <Link to="/">{t("general.options")}</Link>,
      permission: ['settings.view'],
    },
    {
      key: "/definitions-sub",
      icon: <FontAwesomeIcon icon={faFileCirclePlus} />,
      label: `${t("general.definitions")}`,
      permission: ['definitions.view', 'definitions.safe-bank.view', 'accounting.current-deficit.view', 'reservation_providers.view'],
      children: [
        {
          key: "/reservation-providers",
          icon: <FontAwesomeIcon icon={faUsersGear} />,
          label: <Link to="/providers">{t("general.providers")}</Link>,
          permission: ['reservation_providers.view'],
        },
        {
          key: "/safe-bank",
          icon: <FontAwesomeIcon icon={faBuildingColumns} />,
          label: <Link to="/safe-bank">{t("general.safe_bank_definitions")}</Link>,
          permission: ['definitions.safe-bank.view'],
        },
        {
          key: "/additional-services",
          icon: <FontAwesomeIcon icon={faBriefcase} />,
          label: <Link to="/additional-services">{t("general.additional_services")}</Link>,
          permission: [],
        },
        {
          key: "/current-deficit",
          icon: <FontAwesomeIcon icon={faMoneyBill} />,
          label: <Link to="/current-deficit">{t("general.caris")}</Link>,
          permission: ['accounting.current-deficit.view'],
        },
        {
          key: "/country",
          icon: <FontAwesomeIcon icon={faEarth} />,
          label: <Link to="/countries">{t("general.countries")}</Link>,
          permission: [],
        },
        {
          key: "/city",
          icon: <FontAwesomeIcon icon={faCity} />,
          label: <Link to="/cities">{t("general.cities")}</Link>,
          permission: [],
        },
        {
          key: "/region",
          icon: <FontAwesomeIcon icon={faMapLocationDot} />,
          label: <Link to="/regions">{t("general.districts")}</Link>,
          permission: [],
        },
        {
          key: "/airport",
          icon: <FontAwesomeIcon icon={faPlaneDeparture} />,
          label: <Link to="/airports">{t("general.airports")}</Link>,
          permission: [],
        },
        {
          key: "/flight",
          icon: <FontAwesomeIcon icon={faPlane} />,
          label: <Link to="/flights">{t("general.airlines")}</Link>,
          permission: [],
        },
        {
          key: "/transfer",
          icon: <FontAwesomeIcon icon={faArrowRightArrowLeft} />,
          label: <Link to="/transfers">{t("general.transfers")}</Link>,
          permission: [],
          disabled: true,
        },
        {
          key: "/vehicle",
          icon: <FontAwesomeIcon icon={faCar} />,
          label: <Link to="/vehicles">{t("general.vehicles")}</Link>,
          permission: [],
          disabled: true,
        },
      ],
    },
    {
      key: "/tour-packages",
      icon: <FontAwesomeIcon icon={faSuitcase} />,
      label: "Tur-Paket",
      permission: ['tour-package.view', 'tour-package-flights.view'],
      children: [
        {
          key: "/tour-package-flights",
          icon: <FontAwesomeIcon icon={faPlane} />,
          label: <Link to="/tour-packages/flights">Uçuşlar</Link>,
          permission: ['tour-package-flights.view'],
        }
      ]
    },
    {
      key: "/reports-1",
      icon: <FontAwesomeIcon icon={faMagnifyingGlass} />,
      label: `${t("general.reports")}`,
      permission: ['reports.sale_report.view'],
      children: [
        {
          key: "/reports/satis-raporu",
          icon: <FontAwesomeIcon icon={faMagnifyingGlassChart} />,
          label: <Link to="/reports/sale-report">{t("general.sale_reports")}</Link>,
          permission: ['reports.sale_report.view'],
        },
        {
          key: "/reports/widget-activities",
          icon: <FontAwesomeIcon icon={faMagnifyingGlassChart} />,
          label: <Link to="/reports/widget-activities">{t("general.widget_activities")}</Link>,
          permission: [],
        },
        {
          key: "/reports/profit-report",
          icon: <FontAwesomeIcon icon={faMagnifyingGlassChart} />,
          label: <Link to="/reports/profit-report">{t("general.profit_report")}</Link>,
          permission: [],
        }
      ]
    },
    {
      key: "users-tab",
      icon: <FontAwesomeIcon icon={faUsers} />,
      label: t("general.users"),
      permission: ['users.view'],
      children: [
        {
          key: "/users",
          icon: <FontAwesomeIcon icon={faUsers} />,
          label: <Link to="/users">{t("general.users")}</Link>,
          permission: ['users.view'],
        },
        {
          key: "/targets",
          icon: <FontAwesomeIcon icon={faUsers} />,
          label: <Link to="/targets">{t("general.targets")}</Link>,
          permission: ['targets.view'],
        }
      ]
    },
    {
      key: "/agent-calls",
      icon: <FontAwesomeIcon icon={faPhone} />,
      label: `${t("general.agent_calls")}`,
      permission: ['agent.call.view'],
      children: [
        {
          key: "/agent-calls/definitions",
          icon: <FontAwesomeIcon icon={faSave} />,
          label: <Link to="/agent-calls">{t("general.definition")}</Link>,
          permission: ['agent.call.view'],
        },
        {
          key: "/agent-calls/reports",
          icon: <FontAwesomeIcon icon={faMagnifyingGlassChart} />,
          label: <Link to="/agent-calls/reports">{t("general.report")}</Link>,
          permission: ['agent.call.view'],
        }
      ]
    },
    {
      key: "/site-management",
      icon: <FontAwesomeIcon icon={faSitemap} />,
      label: <Link to="/site-management">{t("general.web_management")}</Link>,
      permission: ['site-management.view'],
    },
    {
      key: "/channel-management",
      icon: <FontAwesomeIcon icon={faPaperPlane} />,
      label: `${t("general.channel_management")}`,
      permission: ['channel-management.view', 'channel-management.hotels.view', 'channel-management.contracts.view'],
      children: [
        {
          key: "/channel-management/hotels",
          icon: <FontAwesomeIcon icon={faHotel} />,
          label: <Link to="/channel-management/hotels">{t("general.facilities")}</Link>,
          permission: ['channel-management.hotels.view'],
        },
        {
          key: "/channel-management/contracts",
          icon: <FontAwesomeIcon icon={faFileContract} />,
          label: <Link to="/channel-management/contract-hotels">{t("general.contracts")}</Link>,
          permission: ['channel-management.contracts.view'],
        },
        {
          key: "/channel-management/reservations",
          icon: <FontAwesomeIcon icon={faCalendarCheck} />,
          label: <Link to="/channel-management/reservations">{t("general.reservations")}</Link>,
          permission: ['channel-management.reservation.view'],
        },
        {
          key: "/channel-management/forecast",
          icon: <FontAwesomeIcon icon={faTable} />,
          label: <Link to="/channel-management/forecast">{t("general.forecast")}</Link>,
          disabled: true,
          permission: [],
        }
      ]
    },
    {
      key: "/external-management",
      icon: <FontAwesomeIcon icon={faCloud} />,
      label: "Dış Kanal Yönetimi",
      permission: ['external-management.view', 'external-management.hotels.view', 'external-management.definitions.view'],
      children: [
        {
          key: "/external-management/pool-management",
          icon: <FontAwesomeIcon icon={faHotel} />,
          label: t("general.pool_management"),
          permission: [],
          children: [
            {
              key: "/external-management/pool-management/hotels",
              icon: <FontAwesomeIcon icon={faHotel} />,
              label: <Link to="/external-management/pool-management">{t("general.hotels")}</Link>,
              permission: [],
            },
            {
              key: "/external-management/contract-hotels",
              icon: <FontAwesomeIcon icon={faHotel} />,
              label: <Link to="/external-management/contract-hotels">Kontratlar</Link>,
              permission: [],
            },
            {
              key: "/external-management/pool-management/forecast",
              icon: <FontAwesomeIcon icon={faHotel} />,
              label: <Link to="/external-management/pool-management/forecast">Forecast</Link>,
              permission: [],
            },
            {
              key: "/external-management/pool-management/definitions",
              icon: <FontAwesomeIcon icon={faHotel} />,
              label: t("general.definitions"),
              permission: [],
              children: [
                {
                  key: "/external-management/pool-management/definitions/banks",
                  icon: <FontAwesomeIcon icon={faHotel} />,
                  label: <Link to="/external-management/pool-management/banks">{t("general.bank")}</Link>,
                  permission: [],
                },
                {
                  key: "/external-management/pool-management/definitions/operators",
                  icon: <FontAwesomeIcon icon={faHotel} />,
                  label: <Link to="/external-management/pool-management/operators">{t("general.operators")}</Link>,
                  permission: [],
                }
              ]
            },
          ]
        },
        {
          key: "/external-management/definitions",
          icon: <FontAwesomeIcon icon={faFileCirclePlus} />,
          label: "Tanımlamalar",
          children: [
              {
                key: "/external-management/definitions/hotel-cards",
                icon: <FontAwesomeIcon icon={faContactCard} />,
                label: <Link to="/external-management/definitions/hotel-cards">Tesis Kartları</Link>,
              },
              {
                key: "/external-management/definitions/properties",
                icon: <FontAwesomeIcon icon={faHotel} />,
                label: <Link to="/external-management/definitions/properties">Özellikler</Link>,
              },
              {
                key: "/external-management/definitions/descriptions",
                icon: <FontAwesomeIcon icon={faHotel} />,
                label: <Link to="/external-management/definitions/descriptions">Açıklamalar</Link>,
              },
              {
                key: "/external-management/definitions/popular-facilities",
                icon: <FontAwesomeIcon icon={faHotel} />,
                label: <Link to="/external-management/definitions/popular-facilities">Popüler Özellikler</Link>,
              },
              {
                key: "/external-management/definitions/countries",
                icon: <FontAwesomeIcon icon={faEarth} />,
                label: <Link to="/external-management/definitions/countries">Ulkeler</Link>,
              },
              {
                key: "/external-management/definitions/main-regions",
                icon: <FontAwesomeIcon icon={faMap} />,
                label: <Link to="/external-management/definitions/main-regions">Ana Bölgeler</Link>,
              },
              {
                key: "/external-management/definitions/cities",
                icon: <FontAwesomeIcon icon={faCity} />,
                label: <Link to="/external-management/definitions/cities">Şehirler</Link>,
              },
              {
                key: "/external-management/definitions/regions",
                icon: <FontAwesomeIcon icon={faMap} />,
                label: <Link to="/external-management/definitions/regions">Bölgeler</Link>,
              }
          ],
          permission: ['external-management.definitions.view'],
        },
      ],
    },
    {
      key: "/crm",
      icon: <FontAwesomeIcon icon={faUsers} />,
      label: "CRM",
      permission: ['crm.view'],
      children: [
        {
          key: "/offers",
          icon: <FontAwesomeIcon icon={faUsers} />,
          label: <Link to="/external-management/offers">Teklifler</Link>,
          permission: ['crm.offers.view'],
        },
        {
          key: "/customers",
          icon: <FontAwesomeIcon icon={faUsers} />,
          label: <Link to="/external-management/customers">Müşteriler</Link>,
          permission: ['crm.customers.view'],
        }
      ]
    },
    ...LaraBeachRoutes,
    {
      key: "/notifications",
      icon: <FontAwesomeIcon icon={faBell} />,
      label: <Link to="/notifications">{t("general.notifications")}</Link>,
      permission: [],
    },
    {
      key: "/v2",
      icon: <FontAwesomeIcon icon={fa2} />,
      label: "v2",
      permission: ['v2.pages.view'],
      children: [
        {
          key: "/v2/hotels",
          icon: <FontAwesomeIcon icon={faHotel} />,
          label: <Link to="/v2/hotels">Oteller</Link>,
          permission: ['v2.pages.view'],
        },
        {
          key: "/v2/contracts",
          icon: <FontAwesomeIcon icon={faFileLines} />,
          label: <Link to="/v2/contracts">Kontratlar</Link>,
          permission: ['v2.pages.view'],
        }
      ]
    }
  ];
  const filterMenusByPermission = (menus, permissions) => {
    return menus.filter(menu => {
      if (menu.permission && menu.permission.length > 0) {
        // Menü izni varsa, kullanıcının izinleriyle eşleşip eşleşmediğini kontrol edin
        return menu.permission.some(permission => permissions.includes(permission));
      } else {
        // Menü izni yoksa veya izin dizisi boşsa, menüyü gösterin
        return true;
      }
    }).map(menu => {
      // Menüde alt menüler varsa, alt menüleri de filtreleyin ve güncelleyin
      if (menu.children && menu.children.length > 0) {
        menu.children = filterMenusByPermission(menu.children, permissions);
      }
      return menu;
    });
  };
  const user = useSelector((state) => state.user);
  return (
    <Sider
      width={200}
      breakpoint="lg"
      collapsedWidth={0}
      trigger={null}
      collapsed={props.onCollapsed}
    >
      <Menu
        mode="inline"
        items={filterMenusByPermission(menus, user.user.role.permissions)}
        theme="dark"
        defaultSelectedKeys={[window.location.pathname]}
      />
    </Sider>
  );
};

export default Sidebar;
