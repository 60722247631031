import React from "react";
import {Select, notification} from "antd";
import {RegionService} from "@services";
const {Option}=Select;

function RegionDefinitionSelect (props){

    const [cities,setCities] = React.useState([]);
    const [isLoading,setIsLoading] = React.useState(true);

    React.useEffect(() => {
        const getCities = async () => {
            try {
                let {data :{data:response}} = await RegionService.get();
                setCities(response);
                setIsLoading(false);
            } catch (e) {
                if (e.response) {
                    notification.error({
                        message: e.response.data.message
                    });
                }
            }
        };
        getCities();
    }, []);
    return (
        <Select {...props} loading={isLoading}>
            {cities?.map((city,i)=>(
                <Option country_id={ city.city.main_region.country.id}
                        country_name={city.city.main_region.country.name}
                        city_id={city.city.id}
                        city_name={city.city.name}
                        main_region_id={city.city.main_region.id}
                        main_region_name={city.city.main_region.name}
                        value={city.id} key={city.id}>{city.name}</Option>
            ))}
        </Select>
    )
}

export default RegionDefinitionSelect;