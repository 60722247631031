import React, {useEffect} from 'react';
import {Col, Form, Input, Modal, Row} from "antd";

const CMHotelOperatorMatchModalForm = (props) => {
    useEffect(() => {
        if (props?.hotel){
            props?.hotel?.operators?.map((operator) => {
                return props.form.setFieldsValue({
                    id: {
                        [operator.operator_id]: operator.target
                    }
                })
            })
        }
    },[props.hotel])
    return (
        <Modal visible={props.isVisible} title={props.title} closable={false} onOk={() => props.form.submit()} onCancel={props.onCancel}>
            <Form form={props.form} layout="vertical" onFinish={(values) => props.onFinish(values, props.hotel?.id)} initialValues={{ is_active: true }} fields={props.data?.fields}>
                <Row gutter={[16,16]}>
                {props?.operators?.map((operator,index) => (
                    <Col span={12} key={index}>
                        <Form.Item
                            label={operator.name}
                            name={['id', operator.id.toString()]}
                        >
                            <Input placeholder={operator.name}/>
                        </Form.Item>
                    </Col>
                ))}
                </Row>
            </Form>
        </Modal>
    );
};

export default CMHotelOperatorMatchModalForm;