import React, {useEffect, useState} from 'react';
import {Breadcrumb, Button, Form, Layout, notification, Space, Table} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHouse} from "@fortawesome/free-solid-svg-icons";
import Wrapper from "@/components/Wrapper";
import {ExternalHotelService} from "@/services";
import {useParams} from "react-router-dom";
import ExternalChildAgeModalForm from "@/views/external/child-ages/components/ExternalChildAgeModalForm";

const {Content} = Layout;

const ExternalHotelChildAgeList = () => {
    const [hotelChildAgeCreateForm] = Form.useForm()
    const [isExternalHotelChildAgeCreateModalVisible, setIsExternalHotelChildAgeCreateModalVisible] = useState(false)
    const params = useParams();
    const [hotelChildAges, setHotelChildAges] = useState({
        data: [],
        meta: {},
        loading: true,
    })
    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            width: 100,
            key: 'id',
        },
        {
            title: "Çocuk Yaşı Tanımı",
            dataIndex: 'age_type',
            key: 'age_type',
        },
        {
            title: "Min. Yaş",
            dataIndex: 'minimum_age',
            key: 'minimum_age',
        },
        {
            title: "Maks. Yaş",
            dataIndex: 'maximum_age',
            key: 'maximum_age',
        }
    ];
    const getHotelChildAges = async (hotelId, page = 1) => {
        try {
            let {data: response} = await ExternalHotelService.getChildAges(hotelId, page)
            setHotelChildAges({
                data: response.data,
                meta: response.meta,
                loading: false
            })
        }catch (e){
            if (e.response){
                notification.error({
                    message: "Error",
                    description: e.response.data.message
                })
            }
        }
    }
    const onCreateHotelChildAge = async (values) => {
        try {
            let {data: response} = await ExternalHotelService.createChildAge(params.hotelId, values)
            if (response.success){
                notification.success({
                    message: "Success",
                    description: response.message
                })
                getHotelChildAges(params.hotelId)
            }
        }catch (e){
            if (e.response){
                notification.error({
                    message: "Error",
                    description: e.response.data.message
                })
            }
        }
    }
    useEffect(() => {
        getHotelChildAges(params.hotelId)
        // eslint-disable-next-line
    },[])
    return (
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-20">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse} />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        Otel Çocuk Yaşları
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background">
                    <Space direction="horizontal" style={{
                        marginBottom: 16
                    }}>
                        <Button type="primary" htmlType="button" onClick={() => setIsExternalHotelChildAgeCreateModalVisible(true)}>
                            Çocuk Yaşı Oluştur
                        </Button>
                    </Space>
                    <Table
                        columns={columns}
                        dataSource={hotelChildAges.data}
                        rowKey="id"
                        loading={hotelChildAges.loading}
                        /*pagination={{

                        }}*/
                    />
                </div>
                <ExternalChildAgeModalForm
                    title="Çocuk Yaşı Tanımla"
                    isVisible={isExternalHotelChildAgeCreateModalVisible}
                    form={hotelChildAgeCreateForm}
                    onCancel={() => {
                        setIsExternalHotelChildAgeCreateModalVisible(false)
                        hotelChildAgeCreateForm.resetFields();
                    }}
                    onFinish={onCreateHotelChildAge}
                />
            </Content>
        </Wrapper>
    );
};

export default ExternalHotelChildAgeList;