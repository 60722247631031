import axios from '../axios';

const HotelService = {
  async get(page = 1, searchParams = {}) {
    return await axios.get(`/v1/hotels?page=${page}`, { params: searchParams });
  },
  async getRooms(hotelId) {
    return await axios.get(`/v1/hotels/${hotelId}/rooms`);
  },
  async search(formData) {
    return await axios.post(`/v1/searchAdmin`, formData);
  },
  async updateAgencyInfo(formData, hotelId) {
    return await axios.post(`/v1/hotels/${hotelId}/agency-info`, formData);
  },
  async findById(hotelId) {
    return await axios.get(`/v1/hotels/${hotelId}`);
  },

  async create(formData) {
    return await axios.post('/v1/hotels', formData);
  },

  async update(hotelId, formData) {
    return await axios.post(`/v1/hotels/${hotelId}`, formData);
  },
  async delete(hotelId) {
    return await axios.delete(`/v1/hotels/${hotelId}`);
  },
  async updateSmtpSettings(hotelId, formData) {
    return await axios.post(`/v1/hotels/${hotelId}/smtp-settings`, formData);
  },
  async getSmtpSettings(hotelId) {
    return await axios.get(`/v1/hotels/${hotelId}/smtp-settings`);
  },
  async updateFactSheet(hotelId, formData) {
    return await axios.post(`/v1/hotels/${hotelId}/fact-sheet`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
  },
  async getFactSheet(hotelId) {
    return await axios.get(`/v1/hotels/${hotelId}/fact-sheet`);
  },
  async updateVoucher(hotelId, formData) {
    return await axios.post(`/v1/hotels/${hotelId}/voucher`, formData);
  },
  async getVoucher(hotelId) {
    return await axios.get(`/v1/hotels/${hotelId}/voucher`);
  },
  async updateSaleAgreement(hotelId, formData) {
    return await axios.post(`/v1/hotels/${hotelId}/sale-agreement`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
  async getSaleAgreement(hotelId) {
    return await axios.get(`/v1/hotels/${hotelId}/sale-agreement`);
  }
}

export default HotelService;