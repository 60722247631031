import axios from '../../axios';

const FileService = {
    async uploadFiles(files) {
        return await axios.post(`/lara-beach/files/upload`, files, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }
};

export default FileService;