import {Modal, Row, Col, Form, Input, notification} from "antd";
import CountryDefinitionSelect from "@components/utils/CountryDefinitionSelect";
import CityDefinitionSelect from "@components/utils/CityDefinitionSelect";
import RegionDefinitionSelect from "@components/utils/RegionDefinitionSelect";
import {MainRegionService, CityService} from "@services";

import MainRegionDefinitionSelect from "../utils/MainRegionDefinitionSelect";
import React from "react";

const RegionModalForm = (props) => {
    const [form] = Form.useForm();
    const [mainRegion,setMainRegion] = React.useState([]);
    const [isLoadingMainRegion,setIsLoadingMainRegion] = React.useState(true);
    const [cities,setCities] = React.useState([]);
    const [isLoadingCities,setIsLoadingCities] = React.useState(true);

    const getCities = async (value) => {
        try {
            let {data :{data:response}} = await CityService.get(value);
            setCities(response);
            setIsLoadingCities(false);
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    };
    const getMainRegion = async (value) => {
        try {
            let {data :{data:response}} = await MainRegionService.get(value);
            setMainRegion(response);
            setIsLoadingMainRegion(false);
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    };
    const onParentChanged = (value,option) => {
        form.setFieldsValue(
            {
                    main_region_id: option.main_region_id,
                    city_id: option.city_id,
                    country_id: option.country_id
                }
        );
    }

return (
        <Modal visible={props.isVisible} title={props.title} closable={false} onOk={() => form.submit()} onCancel={props.onCancel}>
            <Form form={form} layout="vertical" onFinish={(values) => props.onFinish(values, props.data?.id)} fields={props.data?.fields} ref={props.formValue}>
                <Row gutter={[16,16]}>
                    <Col span={12}>
                        <Form.Item
                            name="country_id"
                            label="Ülke"
                            rules={[{ required: true, message: 'Ülke seçimi boş bırakılamaz.' }]}
                        >
                            <CountryDefinitionSelect
                                onChange={(value)=>{
                                    form.resetFields(['main_region_id','city_id','parent_region_id']);
                                    getMainRegion(value)
                                }}
                                placeholder="Ülke seçiniz." />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="main_region_id" label="Ana Bölge" rules={[{ required: true, message: 'Ana bölge seçimi boş bırakılamaz.' }]}>
                            <MainRegionDefinitionSelect
                                placeholder="Ana bölge seçiniz."
                                country_id= {form.getFieldValue('country_id')}
                                isLoading={isLoadingMainRegion}
                                mainRegion={mainRegion}
                                onChange={(value) => {
                                    form.setFieldsValue({
                                        city_id: null,
                                        parent_region_id: null
                                    })
                                    props.formValues({
                                        main_region_id: form.getFieldValue('main_region_id')
                                    })
                                    getCities(value)
                                }
                                }
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="city_id" label="Şehir" rules={[{ required: true, message: 'Şehir seçimi boş bırakılamaz.' }]}>
                            <CityDefinitionSelect
                                placeholder="Şehir seçiniz."
                                main_region_id={form.getFieldValue('main_region_id')}
                                isLoading={isLoadingCities}
                                cities={cities}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item name="parent_region_id" label="Üst Bölge">
                            <RegionDefinitionSelect
                                onChange={onParentChanged}
                                placeholder="Üst bölge seçiniz." />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="name" label="Bölge Adı" rules={[{ required: true, message: 'Bölge adı alanı boş bırakılamaz.' }]}>
                            <Input placeholder="Bölge adını giriniz." />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="alternative_names" label="Alternatif Adlar">
                            <Input placeholder="Alternatif adlar giriniz." />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default RegionModalForm;