import axios from '../axios';

const ExternalBankService = {
    async get(page, per_page) {
        return await axios.get(`/v1/external/banks?page=${page}&per_page=${per_page}`);
    },
    async create(data) {
        return await axios.post(`/v1/external/banks`, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },
    async all() {
        return await axios.get(`/v1/external/banks/all`);
    },
};

export default ExternalBankService;