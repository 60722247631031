import React from "react";
import {Modal, Row, Col, Form, InputNumber, TimePicker} from "antd";
import AirportDefinitionSelect from "@components/utils/AirportDefinitionSelect";

const FlightModalForm = (props) => {
    return (
        <Modal visible={props.isVisible} title={props.title} closable={false} onOk={() => props.form.submit()} onCancel={props.onCancel}>
            <Form form={props.form} layout="vertical" onFinish={(values) => props.onFinish(values, props.data?.id)}>
                <Row gutter={[16,16]}>
                    <Col span={12}>
                        <Form.Item
                            name="incoming_airport_id"
                            label="Kalkış Havalimanı"
                            rules={[
                                { required: true, message: 'Kalkış havalimanı seçimi boş bırakılamaz.' },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (value === getFieldValue('outgoing_airport_id')) {
                                            return Promise.reject(new Error('Kalkış ve varış havalimanları aynı olamaz.'));
                                        }
                                        return Promise.resolve();
                                    }
                                })
                            ]}>
                            <AirportDefinitionSelect placeholder="Havalimanı seçimi yapınız." />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="outgoing_airport_id"
                            label="Varış Havalimanı"
                            rules={[
                                { required: true, message: 'Varış havalimanı seçimi boş bırakılamaz.' },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (value === getFieldValue('incoming_airport_id')) {
                                            return Promise.reject(new Error('Kalkış ve varış havalimanları aynı olamaz.'));
                                        }
                                        return Promise.resolve();
                                    }
                                })
                            ]}>
                            <AirportDefinitionSelect placeholder="Havalimanı seçimi yapınız." />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="capacity"
                            label="Kapasite"
                            rules={[
                                { required: true, message: 'Kapasite boş bırakılamaz.' },
                            ]}>
                            <InputNumber placeholder="Kapasite sayısını giriniz." min={0} style={{
                                width: "100%"
                            }}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="departure_time"
                            label="Kalkış Saati"
                            rules={[
                                { required: true, message: 'Kalkış saati boş bırakılamaz.' },
                            ]}>
                            <TimePicker placeholder="Saat seçimi yapınız." style={{
                                width: "100%"
                            }}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="arrival_time"
                            label="Varış Saati"
                            rules={[
                                { required: true, message: 'Varış saati boş bırakılamaz.' },
                            ]}>
                            <TimePicker placeholder="Saat seçimi yapınız." style={{
                                width: "100%"
                            }}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default FlightModalForm;