import React from 'react';
import { Modal, Form, Input, Row, Col } from 'antd'
import { useParams } from "react-router-dom";
import ExternalHotelRoomSelect from "@/components/utils/ExternalHotelRoomSelect";
import ExternalConceptSelect from "@/components/utils/ExternalConceptSelect";
import ExternalConditionGroupSelect from "@/components/utils/ExternalConditionGroupSelect";
import ExternalPeriodSelect from "@/components/utils/ExternalPeriodSelect";

const ContractModalForm = (props) => {
    const params = useParams();
    const onOK = () => {
        props.form.submit();
    }
    return (
        <Modal visible={props.isVisible} title={props.title} closable={false} onOk={onOK} onCancel={props.onCancel}>
            <Form form={props.form} layout="vertical" onFinish={(values) => props.onFinish(values, props.data?.id)}>
                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        <Form.Item name="title" label="Kontrat Adı" rules={[{
                            required: true,
                            message: 'Kontrat Adı boş bırakılamaz!'
                        }]}>
                            <Input placeholder="Kontrat Adı" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="hotel_room_id" label="Oda Adı" rules={[{
                            required: true,
                            message: 'Oda boş bırakılamaz!'
                        }]}>
                            <ExternalHotelRoomSelect hotelId={params.hotelId} placeholder="Oda Seçimi"/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="concepts" label="Konsept Adı" rules={[{
                            required: true,
                            message: 'Konsept boş bırakılamaz!'
                        }]}>
                            <ExternalConceptSelect mode="multiple" hotelId={params.hotelId} placeholder="Konsept Seçimi" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="condition_group_id" label="Kondüsyon Adı" rules={[{
                            required: true,
                            message: 'Kondüsyon boş bırakılamaz!'
                        }]}>
                            <ExternalConditionGroupSelect hotelId={params.hotelId} placeholder="Konsüyon Seçimi"/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="period_id" label="Periyot Adı" rules={[{
                            required: true,
                            message: 'Periyot boş bırakılamaz!'
                        }]}>
                            <ExternalPeriodSelect hotelId={params.hotelId} placeholder="Periyot Seçimi"/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
};

export default ContractModalForm;