import React, { useEffect, useState } from 'react';
import {Select} from 'antd';
import { ExternalHotelService } from '@services';

const { Option } = Select;

const ExternalPeriodSelect = ({ value = null,  onChange, hotelId, placeholder = "" }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [periods, setPeriods] = useState({
        data: [],
        meta: {}
    });

    useEffect(() => {
        if(!hotelId) return null;
        getPeriods(hotelId);
    }, []);

    const getPeriods = async (hotelId, page = 1) => {
        try {
            let { data: response } = await ExternalHotelService.getPeriods(hotelId, page);
            setPeriods({
                data: [...periods.data, ...response.data],
                meta: response.meta
            });
            setIsLoading(false);
        } catch (e) {
            console.log(e);
        }
    };

    const onScroll = (e) => {
        const { target } = e;

        if (! isLoading && periods.meta.last_page > periods.meta.current_page && (target.scrollTop + target.offsetHeight) >= (target.scrollHeight * 0.8)) {
            setIsLoading(true);
            getPeriods(hotelId, periods.meta.current_page + 1);
        }
    };

    const handleChange = (value) => {
        if (onChange) {
            onChange(value);
        }
    };

    return (
        <Select placeholder={placeholder} value={value} dropdownRender={menu => <div onScrollCapture={onScroll}>{menu}</div>} onChange={handleChange} loading={isLoading}>
            {periods.data.map((period, index) => <Option key={index} value={period.id}>{period.title}</Option>)}
        </Select>
    );
};

export default ExternalPeriodSelect;