import React, {useEffect, useState} from 'react';
import {Button, Col, Form, Input, Modal, notification, Row, Select} from "antd";
import { ExternalHotelService } from "@services";

const {Option} = Select;

const HotelImportModal = () => {
    const [hotels, setHotels] = useState([]);
    const [form] = Form.useForm();
    const [visible, setVisible] = useState(false);
    const onFinish = async (values) => {
        try {
            let {data: response} = await ExternalHotelService.import(values)
            if (response.success){
                notification.success({
                    message: 'Başarılı',
                    description: 'Tesis başarıyla içe aktarıldı!'
                })
                setVisible(false)
            }
        }catch (e){
            if (e.response){
                notification.error({
                    message: 'Hata',
                    description: e.response.data.message
                })
            }
        }
    }
    useEffect(() => {
        const getHotelList = async () => {
            try {
                let {data: response} = await ExternalHotelService.getPresetHotels()
                setHotels(response.data)
            }catch (e){
                console.log(e)
            }
        }
        getHotelList()
    }, [])
    return (
        <>
            <Button type="primary" onClick={() => setVisible(true)}>
                Hazır Tesis'i İçe Aktar
            </Button>
            <Modal visible={visible} okText="Aktar" cancelText="İptal" onOk={() => form.submit()} onCancel={() => setVisible(false)} title="Oteli İçe Aktar">
                <Form form={form} onFinish={onFinish} layout="vertical">
                    <Row gutter={[16,16]}>
                        <Col span={24}>
                            <Form.Item
                                name="pool_hotel"
                                label="Otel"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Lütfen tesis seçiniz!',
                                    }
                                ]}
                            >
                                <Select
                                    placeholder="İçe aktarılacak tesis seçiniz"
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                                    {hotels.map((hotel) => (
                                        <Option value={hotel.id} key={hotel.id}>{hotel?.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="hotel_name"
                                label="Otel Adı"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Lütfen tesis seçiniz!',
                                    }
                                ]}
                            >
                                <Input
                                    placeholder="Otel adı"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
};

export default HotelImportModal;