import axios from '../axios';

const UserEmailAssignmentService = {
    async getByUser(userId) {
        return await axios.get(`/v1/user-email-assignments/${userId}`);
    },
    async create(formData,userId) {
        return await axios.post(`/v1/user-email-assignments/${userId}`,formData)
    },
    async onChangeActive(id,userId) {
        return await axios.post(`/v1/user-email-assignments/${userId}/${id}/active`)
    },
}

export default UserEmailAssignmentService;