import React from 'react';
import {Button, Col, Form, Input, InputNumber, message, Row, Table} from "antd";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";

const HotelCreateAgePolicyStep = ({ form }) => {
    const { t } = useTranslation();
    const [minAge, setMinAge] = React.useState(0);
    const [maxAge, setMaxAge] = React.useState(3);
    const [ageDefinition, setAgeDefinition] = React.useState("");
    const childAgePolicy = Form.useWatch('child_age_policy', form) || [];
    const onAdd = () => {
        switch (true) {
            case minAge > maxAge:
                return message.error(t("general.min_age_cannot_be_greater_than_max_age"));
            case ageDefinition === "":
                return message.error(t("general.age_definition_cannot_be_empty"))
            case childAgePolicy.some((policy) =>
                (minAge >= policy.min_age && minAge <= policy.max_age) ||
                (maxAge >= policy.min_age && maxAge <= policy.max_age) ||
                (minAge <= policy.min_age && maxAge >= policy.max_age)
            ):
                return message.error(t("general.age_policy_already_exists"));
        }

        form.setFieldsValue({
            child_age_policy: [
                ...childAgePolicy,
                {
                    age_definition: ageDefinition,
                    min_age: minAge,
                    max_age: maxAge
                }
            ]
        });
    }
    const onRemove = (min_age, max_age) => {
        let newChildAgePolicy = childAgePolicy.filter((policy) => policy['min_age'] !== min_age && policy['max_age'] !== max_age);
        form.setFieldsValue({
            child_age_policy: newChildAgePolicy
        });
    }
    const columns = [
        {
            title: t("general.age_definition"),
            dataIndex: "age_definition",
            key: "age_definition"
        },
        {
            title: t("general.minimum_age"),
            dataIndex: "min_age",
            key: "min_age"
        },
        {
            title: t("general.maximum_age"),
            dataIndex: "max_age",
            key: "max_age"
        },
        {
            title: t("general.actions"),
            key: "actions",
            render: (text, record) => (
                <Button type="danger" onClick={() => onRemove(record.min_age, record.max_age)}>
                    <FontAwesomeIcon icon={faTrash} />
                </Button>
            )
        }
    ]
    return (
        <>
            <Row gutter={[16,2]}>
                <Form.Item
                    name="child_age_policy"
                    hidden
                    initialValue={[]}
                    rules={[{
                        required: true,
                        message: `${t("general.age_policy")} ${t("general.cannot_be_empty")}`
                    }]}
                >
                    <Input/>
                </Form.Item>
                <Col span={8}>
                    <Form.Item
                        label={t("general.age_definition")}
                        tooltip="Örneğin: Bebek, Çocuk, Genç"
                    >
                        <Input
                            value={ageDefinition}
                            onChange={(e) => setAgeDefinition(e.target.value)}
                            placeholder={t("general.age_definition")}
                            style={{ width: "100%" }}
                        />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        label={t("general.minimum_age")}
                    >
                        <InputNumber
                            value={minAge}
                            onChange={(value) => setMinAge(value)}
                            min={0}
                            max={17}
                            placeholder={t("general.minimum_age")}
                            style={{ width: "100%" }}
                        />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        label={t("general.maximum_age")}
                    >
                        <InputNumber
                            value={maxAge}
                            onChange={(value) => setMaxAge(value)}
                            min={0}
                            max={17}
                            placeholder={t("general.maximum_age")}
                            style={{ width: "100%" }}
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Button type="primary" htmlType="button" style={{ width: "100%" }} onClick={onAdd}>
                        <FontAwesomeIcon icon={faPlus} style={{ marginRight: "6px" }}/>
                        {t("general.add")}
                    </Button>
                </Col>
            </Row>
            <Row style={{ marginTop: "12px" }}>
                <Col span={24}>
                    <Table
                        size="small"
                        columns={columns}
                        dataSource={childAgePolicy}
                        pagination={false}
                        bordered
                        rowKey={(record) => `${record.min_age}-${record.max_age}`}
                    />
                    <span style={{ color: "red" }}>
                        <Form.ErrorList errors={form.getFieldError('child_age_policy')} />
                    </span>
                </Col>
            </Row>
        </>
    )
};

export default HotelCreateAgePolicyStep;