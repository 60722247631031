import React from 'react';
import {Checkbox, Col, Form, Input, Modal, Row, Select, Tabs} from 'antd';

const PageModalForm = (props) => {
    return (
        <Modal visible={props.isVisible} title={props.title} closable={false} onCancel={props.onCancel} onOk={() => props.form.submit()}>
            <Form form={props.form} layout="vertical" onFinish={(values) => props.onFinish(values, props.data?.id)}>
                <Tabs>
                    {
                        props?.languages.map((lang)=>(
                            <Tabs.TabPane
                                key={lang.code}
                                tab={lang.native_name}
                                forceRender={true}
                            >
                                <Row gutter={[16,16]}>
                                    <Col span={24}>
                                        <Form.Item
                                            name={['pages', lang.code, 'name']}
                                            label="Sayfa Adı"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Lütfen sayfa adı giriniz.',
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Sayfa adı giriniz"/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            name={['pages', lang.code, 'href']}
                                            label="Bağlantı Linki"
                                            rules={[
                                                {
                                                    pattern: new RegExp('^(?!\\/)[A-Za-z0-9]+((\\/|-)[A-Za-z0-9]+)*$'),
                                                    message: 'Lütfen geçerli bir bağlantı adresi giriniz.',
                                                }]}
                                        >
                                            <Input addonBefore="/" placeholder="Bağlantı adresi giriniz. (Ana Sayfa için boş bırakınız!)"/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name={['pages', lang.code, 'description']}
                                            label="Sayfa Açıklaması (SEO)"
                                        >
                                            <Input placeholder="Seo için sayfa açıklaması giriniz."/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name={['pages', lang.code, 'keywords']}
                                            label="Sayfa Anahtar Kelimeler (SEO)"
                                        >
                                            <Select
                                                mode="tags"
                                                style={{ width: '100%' }}
                                                placeholder="Seo için sayfa anahtar kelimelerini giriniz."/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            name="show_in_navbar"
                                            label="Menüde Gösterilsin mi?"
                                            valuePropName="checked"
                                        >
                                            <Checkbox />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Tabs.TabPane>
                        ))
                    }
                </Tabs>
            </Form>
        </Modal>
    );
};

export default PageModalForm;