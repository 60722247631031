import React, { useEffect, useState } from 'react';
import {Layout, Breadcrumb, Button, notification, Input, Row, Skeleton, Tabs} from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse, faSave } from '@fortawesome/free-solid-svg-icons';
import { ExternalContractService } from '@services';
import Wrapper from '@components/Wrapper';
import moment from "moment";
import {useTranslation} from "react-i18next";

const { Content } = Layout;
const { TabPane } = Tabs;

const ExternalContractDetails = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const params = useParams();
    const [contractDetails, setContractDetails] = useState([]);
    const [isLoadingTable, setIsLoadingTable] = useState(true);

    useEffect(() => {
        getContractDetails(params.hotelId, params.contractId)
    }, [params.hotelId, params.contractId]);
    const getContractDetails = async () => {
        try {
            let { data: response } = await ExternalContractService.getDetails(params.hotelId, params.contractGroupId,params.contractId);
            setContractDetails(response)
            setIsLoadingTable(false);
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    };
    const onCreate = async (values,contractConceptId) => {
        try {
            let { data: response } = await ExternalContractService.saveDetails(params.hotelId, params.contractGroupId, params.contractId, contractConceptId, {
                multipliers: values.contractConcepts.find(contractConcept => contractConcept.id === contractConceptId)['multipliers'],
                per_person: values.contractConcepts.find(contractConcept => contractConcept.id === contractConceptId)['per_person']
            });
            if (response.success) {
                setIsLoadingTable(true);
                getContractDetails(params.hotelId, params.contractId)
                notification.success({
                    message: response.message
                });
                navigate(`/external-management/contract-hotels/${params.hotelId}/contract-groups/${params.contractGroupId}/contracts/${params.contractId}/details`);
            }
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.message
                });
            }
            console.log(e);
        }
    }
    const handleChange = (event) => {
        let temp;
        switch (event.target.name) {
            case 'periods':
                temp = parseFloat(event.target.value);
                let indexPp = contractDetails.contractConcepts.find(contractConcept => contractConcept.id == event.target.dataset.conceptId).per_person?.map(function (o) { return o.period_dates_id; }).indexOf(parseFloat(event.target.id));
                if(contractDetails.contractConcepts.find(contractConcept => contractConcept.id == event.target.dataset.conceptId).per_person[indexPp]){
                    setContractDetails({
                        ...contractDetails,
                        ...contractDetails.contractConcepts.find(contractConcept => contractConcept.id == event.target.dataset.conceptId).per_person[indexPp].price = temp,
                    })
                }else{
                    setContractDetails({
                        ...contractDetails,
                        ...contractDetails.contractConcepts.find(contractConcept => contractConcept.id == event.target.dataset.conceptId).per_person.push({
                            period_dates_id: parseFloat(event.target.id),
                            contract_concept_id: parseInt(event.target.dataset.conceptId),
                            price: temp,
                        })
                    })
                }

                break;
            case 'condition':
                temp = parseFloat(event.target.value);
                let indexM = contractDetails.contractConcepts.find(contractConcept => contractConcept.id == event.target.dataset.conceptId).multipliers?.map(function (o) { return o.condition_id; }).indexOf(parseInt(event.target.id));
                if(contractDetails.contractConcepts.find(contractConcept => contractConcept.id == event.target.dataset.conceptId).multipliers[indexM]){
                    setContractDetails({
                        ...contractDetails,
                        ...contractDetails.contractConcepts.find(contractConcept => contractConcept.id == event.target.dataset.conceptId).multipliers[indexM].multiplier = temp,
                    })
                }else{
                    setContractDetails({
                        ...contractDetails,
                        ...contractDetails.contractConcepts.find(contractConcept => contractConcept.id == event.target.dataset.conceptId).multipliers.push({
                            condition_id: parseInt(event.target.id),
                            contract_concept_id: parseInt(event.target.dataset.conceptId),
                            multiplier: temp,
                        })
                    })
                }

                break;
            default:
                return console.log('Tanımsız.');
        }
    }
    return (
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-20">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse} />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href="/contract-hotels">
                        {t("general.hotels")}
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href={`/contract-hotels/${params.hotelId}/contract-groups`}>
                        {t("general.contract_groups")}
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href={`/contract-hotels/${params.hotelId}/contract-groups/${params.contractGroupId}/contracts`}>
                        {t("general.contracts")}
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {t("general.contract_details")}
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background" style={{overflowX:"auto",height:"calc(100vh - 148px)"}}>
                    <Row>
                        <Tabs onEdit={() => console.log('asd')}>
                            {contractDetails.contractConcepts?.map((item, index) => (
                                <TabPane key={index} tab={item.concept.active_language.name}>
                                    {isLoadingTable ? <Skeleton/> :
                                        <div className="table-container">
                                            <table style={{ width: "100%"}}>
                                                <thead className="ant-table-thead">
                                                <tr>
                                                    <th className="ant-table-cell" style={{whiteSpace:"nowrap"}}>{item.concept.active_language.name}</th>
                                                    <th className="ant-table-cell">
                                                        {t("general.multiply")}
                                                    </th>
                                                    {
                                                        contractDetails.periods?.map(item => {
                                                            return (
                                                                <th key={item.id} className="ant-table-cell">{moment(item.start_date).format('DD/MM/YYYY')}-{moment(item.end_date).format('DD/MM/YYYY')}</th>
                                                            )
                                                        })
                                                    }
                                                </tr>
                                                </thead>
                                                <tbody className="ant-table-tbody">
                                                <tr className="ant-table-row ant-table-row-level-0">
                                                    <td className="ant-table-cell">
                                                        {t("general.per_person")}
                                                    </td>
                                                    <td className="ant-table-cell" />
                                                    {
                                                        contractDetails.periods?.map(contractDetail => {
                                                            return (
                                                                <td key={contractDetail.id} className="ant-table-cell">
                                                                    <Input type="text" name="periods" data-concept-id={item.id} id={contractDetail.id} onChange={handleChange} defaultValue={contractDetails.contractConcepts.find(contractConcept => contractConcept.id === item.id).per_person.find(perPerson => perPerson.period_dates_id === contractDetail.id)?.price} />
                                                                </td>
                                                            )
                                                        })
                                                    }
                                                </tr>
                                                {contractDetails?.contract?.commission &&
                                                    <tr className="ant-table-row ant-table-row-level-1">
                                                        <td className="ant-table-cell">{t("general.commission")} <b>(
                                                            {
                                                                contractDetails?.contract?.commission?.commission_type === 1 &&
                                                                `%${contractDetails?.contract?.commission?.commission}`
                                                            }
                                                            )</b></td>
                                                        <td className="ant-table-cell"></td>
                                                        {
                                                            contractDetails.periods?.map(contractDetail => {
                                                                return (
                                                                    <td key={contractDetail.id} className="ant-table-cell">
                                                                        {Number(contractDetails.contractConcepts.find(contractConcept => contractConcept.id === item.id).per_person.find(perPerson => perPerson.period_dates_id === contractDetail.id)?.price / (1 - (contractDetails?.contract?.commission?.commission/100))).toFixed(2)}
                                                                    </td>
                                                                )
                                                            })
                                                        }
                                                    </tr>
                                                }
                                                {
                                                    contractDetails.conditions?.map(contractConditions => {
                                                        return (
                                                            <tr key={contractConditions.id} className="ant-table-row ant-table-row-level-0">
                                                                <td className="ant-table-cell"> {contractConditions.title} </td>
                                                                <td className="ant-table-cell"><Input type="text" name="condition" data-concept-id={item.id} onChange={handleChange} id={contractConditions.id} defaultValue={contractDetails.contractConcepts.find(contractConcept => contractConcept.id === item.id).multipliers.find(multiplier => multiplier.condition_id === contractConditions.id)?.multiplier} /> </td>
                                                                {
                                                                    contractDetails.periods?.map((contractPeriod, index) => {
                                                                        return (
                                                                            <td key={contractPeriod.id} className="ant-table-cell">
                                                                                {
                                                                                    Number(contractDetails.contractConcepts.find(contractConcept => contractConcept.id === item.id).multipliers.find(multiplier => multiplier.condition_id === contractConditions.id)?.multiplier* contractDetails.contractConcepts.find(contractConcept => contractConcept.id === item.id).per_person.find(perPerson => perPerson.period_dates_id === contractPeriod.id)?.price).toFixed(2) !== "NaN" ?
                                                                                        Number(contractDetails.contractConcepts.find(contractConcept => contractConcept.id === item.id).multipliers.find(multiplier => multiplier.condition_id === contractConditions.id).multiplier* contractDetails.contractConcepts.find(contractConcept => contractConcept.id === item.id).per_person.find(perPerson => perPerson.period_dates_id === contractPeriod.id)?.price).toFixed(2):
                                                                                        "-"
                                                                                }
                                                                            </td>
                                                                        )
                                                                    })
                                                                }
                                                            </tr>
                                                        )
                                                    })
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                    }
                                    {!isLoadingTable &&
                                        <Button type="primary" className="mt-10" style={{ width: '100%' }} onClick={() => onCreate(contractDetails,item.id)}>
                                            <FontAwesomeIcon icon={faSave} className="mr-10" />
                                            {t("general.save")} ({item.concept.active_language.name})
                                        </Button>
                                    }
                                </TabPane>
                            ))}
                        </Tabs>
                    </Row>
                </div>
            </Content>
        </Wrapper>
    );
};

export default ExternalContractDetails;