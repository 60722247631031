import axios from "../axios";

const HotelRoomService = {
    async getByRoomId(hotelId, roomId) {
        return await axios.get(`/v1/hotels/${hotelId}/rooms/${roomId}`);
    },
    async update(hotelId,roomId,formData) {
        return await axios.post(`/v1/hotels/${hotelId}/rooms/${roomId}`,formData);
    }
};

export default HotelRoomService;