import axios from "../axios";

const HotelInfoService = {
    async create(hotelId,formData) {
        return await axios.post(`/v1/hotels/${hotelId}/hotelInfo`,formData);
    },
    async getHotelDetails(hotelId){
        return await axios.get(`/v1/hotel-details/${hotelId}`);
    },
    async getWhatsappStatus(hotelId){
        return await axios.get(`/v1/hotel-whatsapp-status/${hotelId}`);
    },
    async getHotelSocialMedia(hotelId){
        return await axios.get(`/v1/hotel-social-media/${hotelId}`);
    },
    async getFooterMenus(hotelId){
        return await axios.get(`/v1/hotel-footer-menus/${hotelId}`);
    },
    async createFooterMenu(hotelId,formData) {
        return await axios.post(`/v1/hotel-footer-menus/${hotelId}`,formData);
    },
    async deleteFooterMenu(hotelId, hotelFooterMenuId) {
        return await axios.delete(`/v1/hotel-footer-menus/${hotelId}/${hotelFooterMenuId}`);
    },
};

export default HotelInfoService;