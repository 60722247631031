import React, { useEffect, useState } from 'react';
import {Select} from 'antd';
import { ConceptService } from '@services';

const { Option } = Select;

const ConceptSelect = ({ value = undefined,  onChange, mode, hotelId }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [concepts, setConcepts] = useState({
        current_page: 1,
        next_page: 1,
        previous_page: 1,
        data: []
    });

    useEffect(() => {
        getConcepts();
    }, []);

    const getConcepts = async () => {
        try {
            let { data: response } = await ConceptService.get(hotelId);
            setConcepts(response);
        } catch (e) {
            console.log(e);
        }
    };

    const onScroll = (e) => {
        let target = e.target;

        if (! isLoading && concepts.next_page > concepts.current_page && (target.scrollTop + target.offsetHeight) >= (target.scrollHeight * 0.8)) {
            setIsLoading(true);
            getConcepts(concepts.next_page);
        }
    };

    const handleChange = (value) => {
        if (onChange) {
            onChange(value);
        }
    };

    return (
        <Select value={value} onScroll={onScroll} onChange={handleChange} mode={mode} loading={isLoading}>
            {concepts.data.map((concept, index) => <Option key={index} value={concept.id}>{concept.name}</Option>)}
        </Select>
    );
};

export default ConceptSelect;