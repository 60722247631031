import axios from '../axios';

const DiscountService = {
    async getByConceptId(contractId, contractConceptId) {
        return await axios.get(`/v1/discounts/${contractId}/${contractConceptId}`);
    },
    async create(contractId, contractConceptId, formData) {
        return await axios.post(`/v1/discounts/${contractId}/${contractConceptId}`, formData);
    },
    async get(page = 1, searchParams = {}){
        return await axios.get(`/v1/contract-discounts?page=${page}`, { params: searchParams });
    }
};

export default DiscountService;