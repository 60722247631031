import axios from '../axios';

const TargetService = {
    async get(page, perPage) {
        return await axios.get(`/v1/target-definitions?page=${page}&per_page=${perPage}`);
    },
    async getTargets() {
        return await axios.get(`/v1/targets`);
    },
    async createTargetDefinition(data) {
        return await axios.post(`/v1/target-definitions`, data);
    },
    async appendTargetUsers(targetId, data) {
        return await axios.post(`/v1/target-definitions/${targetId}/users`, data);
    },
    async getTargetUserProgress(targetId, page, perPage) {
        return await axios.get(`/v1/target-definitions/${targetId}/users?page=${page}&per_page=${perPage}`);
    }
};

export default TargetService;