import axios from '../axios';

const UserCallService = {
    async get(page, perPage) {
        return await axios.get(`/v1/agent-call?page=${page}&per_page=${perPage}`);
    },
    async create(formData) {
        return await axios.post(`/v1/agent-call`, formData);
    },
    async report(formData) {
        return await axios.get(`/v1/agent-call/report`, {
            params: formData
        });
    },
};

export default UserCallService;