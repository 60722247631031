import React, {useEffect} from 'react';
import {Breadcrumb, Descriptions, Layout, notification, Table, Tag} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHouse} from "@fortawesome/free-solid-svg-icons";
import Wrapper from "@/components/Wrapper";
import {useTranslation} from "react-i18next";
import {LaraBeachReservationService} from "@services";
import moment from "moment";

const {Content} = Layout;

const ReservationsPage = () => {
    const { t } = useTranslation()
    const [reservations, setReservations] = React.useState({
        data: [],
        meta: {},
        loading: false
    })
    const columns = [
        {
            title: t("general.id"),
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: t("general.type"),
            dataIndex: 'type',
            key: 'type',
            render: (text) => t(`general.${text}`)
        },
        {
            title: t("general.amount"),
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: t("general.guest_count"),
            dataIndex: 'guest_count',
            key: 'guest_count',
            render: (text, record) => record.adult_count + ` ${t("general.adults")}` + (record.child_count ? ` , ${record.child_count} ${t("general.children")}` : "")
        },
        {
            title: t("general.email"),
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: t("general.phone"),
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: t("general.status"),
            dataIndex: 'status',
            key: 'status',
            render: (text) => <Tag color="green">Onaylandı</Tag>
        },
        {
            title: t("general.payment_status"),
            dataIndex: 'payment_status',
            key: 'payment_status',
            render: (text) => <Tag color="green">{t(`general.${text}`)}</Tag>
        },
        {
            title: t("general.created_at"),
            dataIndex: 'created_at',
            key: 'created_at',
            render: (text) => moment(text).format("DD.MM.YYYY HH:mm:ss")
        }
    ];
    const getReservations = async (page = 1, perPage = 10) => {
        setReservations({
            ...reservations,
            loading: true
        })
        try {
            let {data: response} = await LaraBeachReservationService.get(page, perPage)
            setReservations({
                data: response.data,
                meta: response.meta,
                loading: false
            })
        }catch (e){
            if (e.response){
                notification.error({
                    message: e.response.data.message
                })
            }
            setReservations({
                ...reservations,
                loading: false
            })
        }
    }
    useEffect(() => {
        getReservations()
    }, [])
    return (
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-20">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse} />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {t("general.reservations")}
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background">
                    <Table
                        columns={columns}
                        dataSource={reservations.data}
                        loading={reservations.loading}
                        rowKey="id"
                        pagination={{
                            total: reservations.meta.total,
                            pageSize: reservations.meta.per_page,
                            current: reservations.meta.current_page,
                            onChange: (page, pageSize) => getReservations(page, pageSize),
                        }}
                        expandable={{
                            expandedRowRender: (record) => {
                                if (record.type === "transfer")
                                    return <TransferExpandable record={record} />
                                if (["tour", "activity", "shopping"].includes(record.type))
                                    return <TourExpandable record={record} />
                            },
                            rowExpandable: (record) => record.service,
                        }}
                    />
                </div>
            </Content>
        </Wrapper>
    );
};

const TransferExpandable = ({record}) => {
    return (
        <Descriptions>
            <Descriptions.Item label="Alış Noktası" span={24}>
                {record.service.from}
            </Descriptions.Item>
            <Descriptions.Item label="Dönüş Noktası" span={24}>
                {record.service.to}
            </Descriptions.Item>
            <Descriptions.Item label="Alış Tarihi" span={24}>
                {record.service.pickup_date ? moment(record.service.pickup_date).format("DD.MM.YYYY") : "-"}
            </Descriptions.Item>
            <Descriptions.Item label="Dönüş Tarihi" span={24}>
                {record.service.return_date ? moment(record.service.return_date).format("DD.MM.YYYY") : "-"}
            </Descriptions.Item>
            <Descriptions.Item label="Araç" span={24}>
                {record.service.name}
            </Descriptions.Item>
        </Descriptions>
    )
}

const TourExpandable = ({record}) => {
    return (
        <Descriptions>
            <Descriptions.Item label="Tur Adı" span={24}>
                {record.service.name}
            </Descriptions.Item>
            <Descriptions.Item label="Tarih" span={24}>
                {record.service.date ? moment(record.service.date).format("DD.MM.YYYY") : "-"}
            </Descriptions.Item>
        </Descriptions>
    )
}

export default ReservationsPage;