import React from 'react';
import {Button, Col, Form, InputNumber, Modal, notification, Row, Tooltip} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMoneyBillTransfer} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import TextArea from "antd/es/input/TextArea";
import {ReservationService} from "@services";

const ReservationPaymentTransferModalForm = (props) => {
    const { t } = useTranslation();
    const [visible, setVisible] = React.useState(false);
    const [form] = Form.useForm();
    const onSubmit = async (values) => {
        try {
            let {data: response} = await ReservationService.transferPayment(props.payment.id, values);
            if (response.success){
                notification.success({
                    message: response.message
                })
                setVisible(false);
                props.onSuccess && props.onSuccess();
            }
        }catch (e){
            if (e.response){
                notification.error({
                    message: t("general.error"),
                    description: e.response.data.message
                })
            }
        }
    }
    return (
        <>
            <Tooltip title={t("general.virman")}>
                <Button type="primary" disabled={props.isDisabled || false} onClick={() => setVisible(true)}>
                    <FontAwesomeIcon icon={faMoneyBillTransfer}/>
                </Button>
            </Tooltip>
            <Modal visible={visible} title={props.title} closable={false} onOk={() => form.submit()} onCancel={() => {
                setVisible(false);
            }}>
                <Form form={form} layout="vertical" onFinish={onSubmit}>
                    <Row gutter={[16,16]}>
                        <Col span={12}>
                            <Form.Item
                                name="target_reservation"
                                label={t("general.target_reservation")}
                                rules={[{
                                    required: true,
                                    message: "Required"
                                }]}
                            >
                                <InputNumber
                                    placeholder={t("general.target_reservation")}
                                    style={{width: "100%"}}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="amount"
                                label={t("general.amount")}
                                rules={[{
                                    required: true,
                                    message: "Required"
                                },{
                                    type: "number",
                                    max: props.payment?.amount,
                                    message: t("general.amount_cannot_be_greater_than", {value: props.payment?.amount})
                                }]}
                            >
                                <InputNumber
                                    placeholder={t("general.amount")}
                                    style={{width: "100%"}}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="description"
                                label={t("general.description")}
                            >
                                <TextArea
                                    placeholder={t("general.description")}
                                    style={{width: "100%"}}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
};

export default ReservationPaymentTransferModalForm;