import axios from '../axios';

const FlightService = {
    async get(){
        return await axios.get(`/v1/definitions/flights`);
    },
    async create(formData) {
        return await axios.post(`/v1/definitions/flights`, formData);
    },
    async delete(id) {
        return await axios.delete(`/v1/definitions/flights/${id}`);
    },
};

export default FlightService;