import React, { useEffect, useState } from "react";
import {
  Breadcrumb,
  Form,
  Input,
  Button,
  Layout,
  Row,
  Col,
  Select,
  Tabs,
  Table,
  Space,
  Switch,
  notification,
  InputNumber,
  Spin
} from "antd";
import { faHotel, faHouse, faPlus, faSave, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Wrapper from "@components/Wrapper";
import { HotelCategoryService, RegionService } from "@services";
import { HotelService, RoomDefinitionService } from "@services";
import { useNavigate } from "react-router-dom";
import AddRoomModalForm from '@components/hotels/AddRoomModalForm';
import RegionSelect from "@components/utils/RegionSelect";

const { Content } = Layout;
const { TabPane } = Tabs;
const { Option } = Select;

const HotelCreate = () => {
  const navigate = useNavigate();

  const [hotelForm] = Form.useForm();
  const [newRoomForm] = Form.useForm();
  
  const [rooms, setRooms] = useState([]);
  const [hotelCategories, setHotelCategories] = useState([]);
  const [modalAddRoom, setModalAddRoom] = useState(false);

  useEffect(() => {
    getHotelCategories();
  }, []);

  /**
   * Otel kategorilerini getirir.
   */
  const getHotelCategories = async () => {
    try {
      let response = await HotelCategoryService.get();
      setHotelCategories(response.data.data);
    } catch (e) {
      if (e.response) {
        notification.error({
          message: e.response.data.message
        });
      }
    }
  };

  /**
   * Oteli kayıt eder.
   * 
   * @param {object} values 
   */
  const onCreate = async (values) => {
    try {
      let response = await HotelService.create({
        ...values,
        rooms: {
          ...rooms
        }
      });

      notification.success({
        message: response.data.message
      });

      navigate('/hotels');
    } catch (e) {
      if (e.response) {
        notification.error({
          message: e.response.data.message
        });
      }
    }
  };

  const roomColumns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      render: (text, record, index) => index + 1
    },
    {
      title: 'Oda Adı',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Metrekare (m2)',
      dataIndex: 'm2',
      key: 'm2'
    },
    {
      title: 'Min. Kişi Sayısı',
      dataIndex: 'min_people',
      key: 'min_people'
    },
    {
      title: 'Max. Kişi Sayısı',
      dataIndex: 'max_people',
      key: 'max_people'
    },
    {
      title: 'İşlemler',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record, index) => (
        <Space align="middle">
          <Button type="primary" htmlType="button" onClick={(e) => {
            removeRoomHandle(index, record);
          }} danger>
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </Space>
      )
    }
  ];

  /**
   * Otel odalarını kayıt eder.
   * 
   * @param {object} values 
   */
  const addNewRoomHandle = async (values) => {
    try {
      let { data: { data: roomDefinition } } = await RoomDefinitionService.findById(values.room_definition_id);
      setRooms([
        ...rooms,
        {
          name: roomDefinition.name,
          room_definition_id: values.room_definition_id,
          m2: values.m2,
          min_people: values.min_people,
          max_people: values.max_people,
          quota: values.quota,
          language:values.language,
          description: values.description
        }
      ]);
  
      setModalAddRoom(false);
      newRoomForm.resetFields();
      notification.success({
        message: 'Oda başarıyla kaydedildi.'
      });
    } catch (e) {
      if (e.response) {
        notification.error({
          message: e.response.data.message
        });
      }
    }
  };

  /**
   * Listeden otel odasını kaldırır.
   * 
   * @param {integer} roomIndex 
   * @param {object} room 
   */
  const removeRoomHandle = (roomIndex, room) => {
    setRooms(
      rooms.filter((item, index) => index !== roomIndex)
    );
  };

  return (
    <>
      <Wrapper>
        <Content className="m-25">
          <Breadcrumb className="mb-20">
            <Breadcrumb.Item href="/">
              <FontAwesomeIcon icon={faHouse} />
            </Breadcrumb.Item>
            <Breadcrumb.Item href="/hotels">
              <FontAwesomeIcon icon={faHotel} className="mr-5" />
              <span>Oteller</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Yeni Otel Ekle</Breadcrumb.Item>
          </Breadcrumb>
          <div className="site-layout-background">
            <Form form={hotelForm} layout="vertical" onFinish={onCreate}>
              <Tabs defaultActiveKey="1">
                <TabPane tab="Genel Bilgiler" key="1">
                  <Row gutter={[16]}>
                    <Col span={24}>
                      <Form.Item 
                        name="name" 
                        label="Otel Adı"
                        rules={[
                          { required: true, message: 'Otel adı gereklidir.' },
                          { min: 2, message: 'Otel adı minimum 2 karakter olmalıdır.' },
                          { max: 100, message: 'Otel adı maximum 100 karakter olmalıdır.' }
                        ]}
                      >
                        <Input placeholder="Otel Adı" />
                      </Form.Item>  
                    </Col>

                    <Col span={24}>
                      <Form.Item 
                        name="description" 
                        label="Otel Açıklama"
                        rules={[
                          { required: true, message: 'Otel açıklaması zorunludur.' }
                        ]}
                      >
                        <Input.TextArea placeholder="Otel Açıklama" />
                      </Form.Item>  
                    </Col>

                    <Col md={12} lg={6}>
                      <Form.Item 
                        name="hotel_category_id" 
                        label="Otel Kategorisi"
                        rules={[
                          { required: true, message: 'Otel tipi zorunludur.' }
                        ]}
                      >
                        <Select>
                          { hotelCategories.map((item) => <Option value={item.id} key={`hotelCategory${item.id}`}>{item.name}</Option>) }
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col md={12} lg={6}>
                      <Form.Item 
                        name="country" 
                        label="Ülkesi"
                        rules={[
                          { required: true, message: 'Otel ülkesi zorunludur.' }
                        ]}
                      >
                        <Input placeholder="Ülkesi" />
                      </Form.Item>
                    </Col>

                    <Col md={12} lg={6}>
                      <Form.Item 
                        name="city" 
                        label="Şehri"
                        rules={[
                          { required: true, message: 'Otel şehiri zorunludur.' }
                        ]}
                      >
                        <Input placeholder="Şehri" />
                      </Form.Item>
                    </Col>

                    <Col md={12} lg={6}>
                      <Form.Item 
                        name="district" 
                        label="Semti"
                        rules={[
                          { required: true, message: 'Otel semti zorunludur.' }
                        ]}
                        >
                        <Input placeholder="Semti" />
                      </Form.Item>
                    </Col>

                    <Col span={8}>
                      <Form.Item 
                        name="lat" 
                        label="Lat"
                        rules={[
                          { required: true, message: 'Otel lokasyonu zorunludur.' }
                        ]}
                      >
                        <InputNumber min={0} placeholder="Lat" style={{ width: '100%' }} />
                      </Form.Item>
                    </Col>

                    <Col span={8}>
                      <Form.Item 
                        name="lng" 
                        label="Long"
                        rules={[
                          { required: true, message: 'Otel lokasyonu zorunludur.' }
                        ]}
                        >
                        <InputNumber min={0} placeholder="Long" style={{ width: '100%' }} />
                      </Form.Item>
                    </Col>

                    <Col span={8}>
                      <Form.Item
                        name="region_id"
                        label="Bölge"
                        rules={[
                          { required: true, message: 'Bölge seçimi zorunludur.' }
                        ]}
                        >
                        <RegionSelect
                            placeholder="Bölge"
                            style={{ width: '100%' }}
                            onChange={(value) => hotelForm.setFieldsValue({ region_id: value })}
                        />
                      </Form.Item>
                    </Col>

                    <Col>
                      <Form.Item name="is_active" label="Aktif / Pasif" valuePropName="checked" initialValue={true}>
                        <Switch checkedChildren="Aktif" defaultChecked unCheckedChildren="Pasif" />
                      </Form.Item>
                    </Col>
                  </Row>
                </TabPane>

                <TabPane tab="Odalar" key="2">
                  <Row className="mb-20">
                    <Col span={24}>
                      <Button type="default" htmlType="button" block onClick={() => setModalAddRoom(! modalAddRoom)}>
                        <FontAwesomeIcon icon={faPlus} className="mr-5" />
                        Oda Ekle
                      </Button>
                    </Col>
                  </Row>

                  <Table dataSource={rooms} columns={roomColumns} rowKey={(record) => `${Math.random()}${record.id}`} pagination={false}></Table>
                </TabPane>
              </Tabs>

              <Button type="primary" htmlType="submit" className="mt-25" block>
                <FontAwesomeIcon icon={faSave} className="mr-5" />
                Oluştur
              </Button>
            </Form>
          </div>
        </Content>
      </Wrapper>

      <AddRoomModalForm
        form={newRoomForm}
        title="Yeni Oda Ekle"
        isVisible={modalAddRoom}
        onFinish={addNewRoomHandle}
        onCancel={() => setModalAddRoom(false)}
      />
    </>
  );
};

export default HotelCreate;