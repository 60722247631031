import axios from '../axios';

const DomainService = {
    async getByHotel(hotelId) {
        return await axios.get(`/v1/site-manager/domains/${hotelId}`);
    },
    async create(formData) {
        return await axios.post(`/v1/site-manager/domains`,formData);
    },
    async delete(id) {
        return await axios.delete(`/v1/site-manager/domains/${id}`);
    },
};

export default DomainService;