import axios from '../axios';

const FileService = {
  async uploadTempFile(formData) {
    return await axios.post(`/v1/upload-temp-file`, formData ,{
       headers: {
         'Content-Type': 'multipart/form-data'
       }
    });
  }
}

export default FileService;