import axios from '../axios';

const CurrentDeficitTransactionService = {
    async get(page, per_page) {
        return await axios.get(`/v1/current-deficit-transactions?page=${page}&per_page=${per_page}`);
    },
    async getByCurrentDeficit(currentDeficitId, page, per_page, params) {
        return await axios.get(`/v1/current-deficit-transactions/${currentDeficitId}?page=${page}&per_page=${per_page}`,{
            params
        });
    },
    async create(formData) {
        return await axios.post('/v1/current-deficit-transactions', formData);
    },
}


export default CurrentDeficitTransactionService;