import React, {useEffect, useState} from 'react';
import {
    Breadcrumb,
    Button, Checkbox,
    Col,
    DatePicker,
    Form,
    Input, InputNumber,
    Layout,
    notification,
    Row,
    Select, Space, Switch,
    Table,
    Tag,
    Tooltip
} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faFileExcel, faHouse, faSearch} from "@fortawesome/free-solid-svg-icons";
import Wrapper from "@components/Wrapper";
import {ReservationService} from "@services";
import moment from "moment/moment";
import {useTranslation} from "react-i18next";

const {Content} = Layout;
const {RangePicker} = DatePicker;
const {Option} = Select;
const {TextArea} = Input;

const AccountingReservations = () => {
    const { t } = useTranslation()
    const [reservationSearchForm] = Form.useForm();
    const [isLoading, setIsLoading] = React.useState(true);
    const [isExporting, setIsExporting] = React.useState(false);
    const [data, setData] = React.useState([]);
    const [page, setPage] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(10);
    const [searchParams, setSearchParams] = useState({
        hotel_name: "",
        uuid: "",
        check_in: "",
        check_out: "",
        guest_name: "",
        reservation_date: "",
        status: "",
        is_show_cancel: false,
        currency: "",
    });
    const getAccountingReservations = async (page = 1, pageSize = 10) => {
        try {
            let {data: response} = await ReservationService.getAccounting(page, pageSize, searchParams);
            setData(response);
        }catch (e){
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }

    const onExport = async (type) => {
        setIsExporting(true);
        try {
            let response = await ReservationService.getAccountingExport(page, pageSize, searchParams, type);
            const blob = new Blob([response.data], { type: response.headers['content-type'] });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'muhasebe_rezervasyonlari.xlsx';
            a.click();
            window.URL.revokeObjectURL(url);
            setIsExporting(false);
        }catch (e){
            if (e.response) {
                setIsExporting(false);
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }
    const columns = [
        {
            key: "reservation_number",
            title: <span style={{color:"#1890ff",fontSize:"12px",textAlign:"center"}}>
                {t('general.reservation_no')}
            </span>,
            dataIndex: 'reservation_number',
            render: (text, record) => {
                if(record.status === 6){
                    return <Button
                        type="primary"
                        style={{ "fontSize": "12px", backgroundColor: "#6b7280", borderColor: "#6b7280" }}
                        block='true'
                        href={`/reservation-card/${record.uuid}`}
                        target="_blank"
                    >
                        {record.uuid}
                    </Button>
                }
                return <Button
                    type="primary"
                    style={{ "fontSize": "12px" }}
                    block='true'
                    href={`/reservation-card/${record.uuid}`}
                    target="_blank"
                >
                    {record.uuid}
                </Button>
            }
        },
        {
            key: 'reservation_provider',
            title: <span style={{ 'fontSize': '12px',textAlign: "center",color: "#1890ff", }}>
                {t('general.reservation_creator')}
            </span>,
            dataIndex: 'reservation_provider',
            render: (text, record) => {
                if(record?.users){
                    return <Tooltip title={record.users.role_name}><h1 style={{ 'fontSize': '12px',textAlign:"center"}}>{record.users.first_name} {record.users.last_name}</h1></Tooltip>
                }
                return <Tooltip title="Rezervasyonu paymyres üzerinden müşteri kendisi oluşturmuştur."><h1 style={{ 'fontSize': '12px',textAlign:"center"}}>Widget</h1></Tooltip>
            }
        },
        {
            key: 'reservation_date',
            title: <span style={{ 'fontSize': '12px',textAlign: "center",color: "#1890ff", }}>
                {t('general.reservation_date')}
            </span>,
            dataIndex: 'reservation_date',
            render: (text) =><h1 style={{ "fontSize": "12px" }}>{text}</h1>
        },
        {
            key: 'hotel_name',
            title: <span style={{ 'fontSize': '12px',textAlign: "center",color: "#1890ff", }}>
                {t('general.hotel')}
            </span>,
            dataIndex: 'hotel_name',
            render: (text) =><h1 style={{ "fontSize": "12px" }}>{text}</h1>
        },
        {
            key: 'room_name',
            title: <span style={{ 'fontSize': '12px',textAlign: "center",color: "#1890ff", }}>
                {t('general.room')}
            </span>,
            dataIndex: 'room_name',
            render: (text) =><h1 style={{ "fontSize": "12px" }}>{text}</h1>
        },
        {
            key: 'concept',
            title: <span style={{ 'fontSize': '12px',textAlign: "center",color: "#1890ff", }}>
                {t('general.concept')}
            </span>,
            dataIndex: 'concept',
            render: (text) =><h1 style={{ "fontSize": "12px" }}>{text}</h1>
        },
        {
            key: 'full_name',
            title: <span style={{ "fontSize": "12px",textAlign: "center",color: "#1890ff", }}>
                {t('general.full_name')}
            </span>,
            dataIndex: 'full_name',
            render: (text, record) => <h1 style={{ "fontSize": "12px" }}>{text}</h1>
        },
        {
            key: 'check_in',
            title: <span style={{ "fontSize": "12px",textAlign: "center",color: "#1890ff", }}>
                {t('general.check_in')}
            </span>,
            dataIndex: 'check_in',
            render: (text, record) => <h1 style={{ "fontSize": "12px" }}>{moment(text).format('DD.MM.YYYY')}</h1>
        },
        {
            key: 'check_out',
            title: <span style={{ "fontSize": "12px",textAlign: "center",color: "#1890ff", }}>
                {t('general.check_out')}
            </span>,
            dataIndex: 'check_out',
            render: (text, record) => <h1 style={{ "fontSize": "12px" }}>{moment(text).format('DD.MM.YYYY')}</h1>
        },
        {
            key: 'total_fee',
            title: <span style={{ "fontSize": "12px",textAlign: "center",color: "#1890ff", }}>
                {t('general.reservation_amount')}
            </span>,
            dataIndex: 'total_fee',
            render: (text, record) => <h1 style={{ "fontSize": "12px" }}>{text} {record?.currency_id?.symbol}</h1>
        },
        {
            key: 'sold_price',
            title: <span style={{ "fontSize": "12px",textAlign: "center",color: "#1890ff", }}>
                {t('general.cost_amount')}
            </span>,
            dataIndex: 'cost',
            render: (text, record) => <h1 style={{ "fontSize": "12px" }}>{text ? text + " " + record?.currency_id?.symbol: "Tanımlanmamış"}</h1>
        },
        {
            key: 'discount_price',
            title: <span style={{ "fontSize": "12px",textAlign: "center",color: "#1890ff", }}>{t('general.discount')}</span>,
            dataIndex: 'discount',
            render: (text, record) => <h1 style={{ "fontSize": "12px" }}>{text} {record?.currency_id?.symbol}</h1>
        },
        {
            key: 'sale_price',
            title: <span style={{ "fontSize": "12px",textAlign: "center",color: "#1890ff", }}>
                {t('general.sale_price')}
            </span>,
            dataIndex: 'sale_price',
            render: (text, record) => <h1 style={{ "fontSize": "12px" }}>{text} {record?.currency_id?.symbol}</h1>
        },
        {
            key: 'user_bonus',
            title: <span style={{ "fontSize": "12px",textAlign: "center",color: "#1890ff", }}>
                {t('general.agent_bonus')}
            </span>,
            dataIndex: 'user_bonus',
            render: (text,record) => <InputNumber defaultValue={text} onChange={(value) => record.user_bonus = value}/>
        },
        {
            key: 'ftn',
            title: <span style={{ "fontSize": "12px",textAlign: "center",color: "#1890ff", }}>
                {t('general.ftn')}
            </span>,
            dataIndex: 'ftn',
            render: (text,record) => <Input defaultValue={text} onChange={(value) => record.ftn = value.target.value}/>
        },
        {
            key: 'hotel_tax_amount',
            title: <span style={{ "fontSize": "12px",textAlign: "center",color: "#1890ff", }}>
                {t('general.hotel_tax_amount')}
            </span>,
            dataIndex: 'hotel_tax_amount',
            render: (text,record) => <InputNumber defaultValue={text} onChange={(value) => record.hotel_tax_amount = value}/>
        },
        {
            key: 'hotel_cost_amount',
            title: <span style={{ "fontSize": "12px",textAlign: "center",color: "#1890ff", }}>
                {t('general.hotel_cost_amount')}
            </span>,
            dataIndex: 'hotel_cost_amount',
            render: (text,record) => <h1 style={{ "fontSize": "12px" }}>{record?.hotel_cost_amount ?? "0"} {record?.currency_id?.symbol}</h1>
        },
        {
            key: 'profit_loss',
            title: <span style={{ "fontSize": "12px",textAlign: "center",color: "#1890ff", }}>
                {t('general.profit_loss')}
            </span>,
            dataIndex: 'profit_loss',
            render: (text,record) => {
                if(record?.cost){
                    return <h1 style={{ "fontSize": "12px" }}>{(record?.total_fee - record?.cost).toFixed(2)} {record?.currency_id?.symbol}</h1>
                }
                return <h1 style={{ "fontSize": "12px" }}>{t('general.not_calculated')}</h1>
            }
        },
        {
            key: 'accounting_note',
            title: <span style={{ "fontSize": "12px",textAlign: "center",color: "#1890ff", }}>
                {t('general.accounting_note')}
            </span>,
            dataIndex: 'accounting_note',
            render: (text,record) => <TextArea defaultValue={text} onChange={(value) => record.accounting_note = value.target.value}/>
        },
        {
            key: 'accommodation_status',
            title: <span style={{ "fontSize": "12px",textAlign: "center",color: "#1890ff", }}>
                {t('general.stay_status')}
            </span>,
            dataIndex: 'accommodation_status',
            render: (text,record) => <Switch defaultChecked={text} onChange={(value) => record.accommodation_status = value}/>
        },
        {
            key: 'agreement_reached',
            title: <span style={{ "fontSize": "12px",textAlign: "center",color: "#1890ff", }}>
                {t('general.agreement_reached')}
            </span>,
            dataIndex: 'agreement_reached',
            render: (text,record) => <Switch defaultChecked={text} onChange={(value) => record.agreement_reached = value}/>
        },
        {
            key: 'actions',
            fixed: 'right',
            title: <span style={{ "fontSize": "12px",textAlign: "center",color: "#1890ff", }}>
                {t('general.actions')}
            </span>,
            dataIndex: 'actions',
            render: (text, record) =>
                <Button type="primary" style={{ "fontSize": "12px" }} onClick={() => onChangeReservation(record.uuid)}>
                    <FontAwesomeIcon icon={faCheck}/>
                </Button>
        }
    ];
    const onChangeReservation = async (uuid) => {
        let values = data.data.find(item => item.uuid === uuid)
        try {
            let {data: response} = await ReservationService.updateAccounting(uuid,{
                user_bonus: values.user_bonus,
                ftn: values.ftn,
                hotel_tax_amount: values.hotel_tax_amount,
                accounting_note: values.accounting_note,
                accommodation_status: values.accommodation_status,
                agreement_reached: values.agreement_reached
            });
            if(response.success){
                getAccountingReservations(page, pageSize).finally(() => setIsLoading(false));
                notification.success({
                    message: response.message
                });
            }
        }catch (e){
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }
    useEffect(() => {
        getAccountingReservations(page, pageSize).finally(() => setIsLoading(false));
    },[page, pageSize])
    return (
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-20">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse} />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {t('general.upcoming_reservations_deferred_payment')}
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background">
                    <Form form={reservationSearchForm} onFinish={() => getAccountingReservations().finally(() => setIsLoading(false))} layout="vertical">
                        <Row gutter={[16,0]}>
                            <Col span={4}>
                                <Form.Item label={t('general.reservation_number')}>
                                    <Input placeholder="Rezervasyon numarasını giriniz." onChange={(e) => setSearchParams(prevState => {
                                        return {
                                            ...prevState,
                                            uuid: e.target.value
                                        }
                                    })}/>
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item label={t('general.hotel_name')}>
                                    <Input placeholder="Otel adını giriniz." onChange={(e) => setSearchParams(prevState => {
                                        return {
                                            ...prevState,
                                            hotel_name: e.target.value
                                        }
                                    })}/>
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item label={t('general.guest_name')}>
                                    <Input placeholder="Ziyaretçi adını giriniz." onChange={(e) => setSearchParams(prevState => {
                                        return {
                                            ...prevState,
                                            guest_name: e.target.value
                                        }
                                    })}/>
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item label={t('general.reservation_date')}>
                                    <RangePicker
                                        style={{width:"100%"}}
                                        format="DD.MM.YYYY"
                                        placeholder={[t("general.start_date"), t("general.end_date")]}
                                        onChange={(e) => setSearchParams(prevState => {
                                            return {
                                                ...prevState,
                                                reservation_date: e ? [
                                                    moment(e[0]).format("YYYY-MM-DD"),
                                                    moment(e[1]).format("YYYY-MM-DD")
                                                ]: []
                                            }
                                        })}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item label={t('general.check_dates')}>
                                    <RangePicker
                                        style={{width:"100%"}}
                                        format="DD.MM.YYYY"
                                        placeholder={["Giriş tarihi", "Çıkış tarihi"]}
                                        onChange={(e) => setSearchParams(prevState => {
                                            return {
                                                ...prevState,
                                                check_in: e ? moment(e[0]).format("YYYY-MM-DD"): "",
                                                check_out: e ? moment(e[1]).format("YYYY-MM-DD"): ""
                                            }
                                        })}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item label={t('general.status')}>
                                    <Select allowClear mode="multiple" placeholder="Rezervasyon durumu seçiniz." onChange={(e) => setSearchParams(prevState => {
                                        return {
                                            ...prevState,
                                            status: e ? e : ""
                                        }
                                    })}>
                                        <Option key={1} value={1}>
                                            {t('general.payment_pending')}
                                        </Option>
                                        <Option key={2} value={2}>
                                            {t('general.confirm_pending')}
                                        </Option>
                                        <Option key={3} value={3}>
                                            {t('general.confirmed')}
                                        </Option>
                                        <Option key={4} value={4}>
                                            {t('general.confirmation_failed')}
                                        </Option>
                                        <Option key={5} value={5}>
                                            {t('general.confirmation_will_send')}
                                        </Option>
                                        <Option key={6} value={6}>
                                            {t('general.reservation_cancelled')}
                                        </Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item label={t('general.currency')}>
                                    <Select placeholder="Para birimi seçiniz." onChange={(e) => setSearchParams(prevState => {
                                        return {
                                            ...prevState,
                                            currency: e ? e : null
                                        }
                                    })}>
                                        <Option key={1} value={242}>(₺) TRY</Option>
                                        <Option key={2} value={90}>(€) EUR</Option>
                                        <Option key={2} value={251}>($) USD</Option>
                                        <Option key={2} value={249}>(£) GBP</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item
                                    name="is_show_cancel"
                                    valuePropName="checked"
                                >
                                    <Checkbox
                                        onChange={(e) => setSearchParams(prevState => {
                                            return {
                                                ...prevState,
                                                is_show_cancel: e.target.checked ? 1 : 0
                                            }
                                        })}
                                    >
                                        {t('general.show_cancelled_reservations')}
                                    </Checkbox>
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item
                                    name="show_only_negative_balance"
                                    valuePropName="checked"
                                >
                                    <Checkbox
                                        onChange={(e) => setSearchParams(prevState => {
                                            return {
                                                ...prevState,
                                                show_only_negative_balance: e.target.checked ? 1 : 0
                                            }
                                        })}
                                    >{t('general.show_negative_profit_loss')}</Checkbox>
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item
                                    name="show_call_center_sales"
                                    valuePropName="checked"
                                >
                                    <Checkbox
                                        onChange={(e) => setSearchParams(prevState => {
                                            return {
                                                ...prevState,
                                                show_call_center_sales: e.target.checked ? 1 : 0
                                            }
                                        })}
                                    >{t('general.filter_call_center_sales')}</Checkbox>
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item
                                    name="show_agency_sales"
                                    valuePropName="checked"
                                >
                                    <Checkbox
                                        onChange={(e) => setSearchParams(prevState => {
                                            return {
                                                ...prevState,
                                                show_agency_sales: e.target.checked ? 1 : 0
                                            }
                                        })}
                                    >{t('general.filter_agency_sales')}</Checkbox>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item>
                                    <Button style={{width:"100%"}} type="primary" htmlType="submit" onClick={() => setIsLoading(true)}>
                                        <FontAwesomeIcon icon={faSearch}/>
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    <Table
                        columns={columns}
                        scroll={{ x: 2500, y: "100%" }}
                        dataSource={data.data}
                        rowKey={record => record.uuid + Math.random()}
                        loading={isLoading}
                        bordered
                        size="small"
                        title={() => (<Row>
                            <Col span={24}>
                                <Button type="primary" title="Excel Olarak Dışa Aktar" loading={isExporting} onClick={() => onExport('excel')}>
                                    <FontAwesomeIcon icon={faFileExcel} style={{marginRight: "10px"}}/>
                                    Excel
                                </Button>
                            </Col>
                        </Row>)}
                        footer = {() => <Space direction="vertical">
                            <h1 style={{ fontSize: "16px", fontWeight: "600" }}>Bu Sayfa</h1>
                            {data?.total_current?.map((item) => (
                                <Space direction="horizontal" style={{marginRight:"14px"}}>
                                    <Space>
                                        <span style={{fontWeight: "bold"}}>{t('general.total_amount')}:</span>
                                        <span>{item?.total_fee} {item?.currency}</span>
                                    </Space>
                                    <Space>
                                        <span style={{fontWeight: "bold"}}>{t('general.total_cost')}:</span>
                                        <span>{item?.total_cost} {item?.currency}</span>
                                    </Space>
                                    <Space>
                                        <span style={{fontWeight: "bold"}}>{t('general.total_profit')}:</span>
                                        <span>{item?.profit} {item?.currency}</span>
                                    </Space>
                                </Space>
                            ))}
                            <h1 style={{ fontSize: "16px", fontWeight: "600" }}>Tüm Veriler</h1>
                            {data?.total_all?.map((item) => (
                                <Space direction="horizontal" style={{marginRight:"14px"}}>
                                    <Space>
                                        <span style={{fontWeight: "bold"}}>{t('general.total_amount')}:</span>
                                        <span>{item?.total_fee} {item?.currency}</span>
                                    </Space>
                                    <Space>
                                        <span style={{fontWeight: "bold"}}>{t('general.total_cost')}:</span>
                                        <span>{item?.total_cost} {item?.currency}</span>
                                    </Space>
                                    <Space>
                                        <span style={{fontWeight: "bold"}}>{t('general.total_profit')}:</span>
                                        <span>{item?.profit} {item?.currency}</span>
                                    </Space>
                                </Space>
                            ))}
                        </Space>}
                        pagination={{
                            total: data.meta?.total,
                            defaultPageSize: data.meta?.per_page,
                            pageSizeOptions: [10, 20, 50, 100, 500, 1000],
                            onChange: (page, pageSize) => {
                                setIsLoading(true)
                                setPage(page);
                                setPageSize(pageSize);
                            }
                        }}
                    />
                </div>
            </Content>
        </Wrapper>
    );
};

export default AccountingReservations;