import React, {useEffect, useState} from 'react';
import {Breadcrumb, Button, Form, Layout, notification, Space, Table} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHouse} from "@fortawesome/free-solid-svg-icons";
import Wrapper from "@/components/Wrapper";
import {ExternalHotelService} from "@/services";
import {useParams} from "react-router-dom";
import ExternalRoomDefinitionFormModal from "@/views/external/rooms/components/ExternalRoomDefinitionFormModal";
import ExternalHotelRoomAppendModal from "@/views/external/rooms/components/ExternalHotelRoomAppendModal";

const {Content} = Layout;

const ExternalHotelRoomList = () => {
    const [roomDefinitionCreateForm] = Form.useForm()
    const [hotelRoomAppendForm] = Form.useForm()
    const [isExternalRoomDefinitionCreateModalVisible, setIsExternalRoomDefinitionCreateModalVisible] = useState(false)
    const [isExternalHotelRoomAppendModalVisible, setIsExternalHotelRoomAppendModalVisible] = useState(false)
    const params = useParams();
    const [hotelRooms, setHotelRooms] = useState({
        data: [],
        meta: {},
        loading: true,
    })
    const [hotelRoomDefinitions, setHotelRoomDefinitions] = useState({
        data: [],
        meta: {},
        loading: true,
    })
    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            width: 100,
            key: 'id',
        },
        {
            title: "Oda Adı",
            dataIndex: 'name',
            key: 'name',
        }
    ];
    const getHotelRooms = async (hotelId, page = 1) => {
        try {
            let {data: response} = await ExternalHotelService.getRooms(hotelId, page)
            setHotelRooms({
                data: response.data,
                meta: response.meta,
                loading: false
            })
        }catch (e){
            if (e.response){
                notification.error({
                    message: "Error",
                    description: e.response.data.message
                })
            }
        }
    }
    const onCreateHotelRoomDefinition = async (values) => {
        try {
            let {data: response} = await ExternalHotelService.createRoomDefinition(params.hotelId, values)
            if (response.success){
                notification.success({
                    message: "Success",
                    description: response.message
                })
                getHotelRoomDefinitions(params.hotelId)
            }
        }catch (e){
            if (e.response){
                notification.error({
                    message: "Error",
                    description: e.response.data.message
                })
            }
        }
    }
    const onAppendHotelRoom = async (values) => {
        try {
            let {data: response} = await ExternalHotelService.appendRoom(params.hotelId, values)
            if (response.success){
                notification.success({
                    message: "Success",
                    description: response.message
                })
                getHotelRooms(params.hotelId)
            }
        }catch (e){
            if (e.response){
                notification.error({
                    message: "Error",
                    description: e.response.data.message
                })
            }
        }
    }
    const getHotelRoomDefinitions = async (hotelId, page = 1) => {
        try {
            let {data: response} = await ExternalHotelService.getRoomDefinitions(hotelId, page)
            setHotelRoomDefinitions({
                data: response.data,
                meta: response.meta,
                loading: false
            })
        }catch (e){
            if (e.response){
                notification.error({
                    message: "Error",
                    description: e.response.data.message
                })
            }
        }
    }
    useEffect(() => {
        getHotelRooms(params.hotelId)
        getHotelRoomDefinitions(params.hotelId)
        // eslint-disable-next-line
    },[])
    return (
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-20">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse} />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        Otel Odaları
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background">
                    <Space direction="horizontal" style={{
                        marginBottom: 16
                    }}>
                        <Button type="primary" htmlType="button" onClick={() => setIsExternalHotelRoomAppendModalVisible(true)}>
                            Otel'e Oda Ata
                        </Button>
                        <Button htmlType="button" onClick={() => setIsExternalRoomDefinitionCreateModalVisible(true)}>
                            Oda Oluştur
                        </Button>
                    </Space>
                    <Table
                        columns={columns}
                        dataSource={hotelRooms.data}
                        rowKey="id"
                        loading={hotelRooms.loading}
                        /*pagination={{

                        }}*/
                    />
                </div>
                <ExternalRoomDefinitionFormModal
                    title="Oda Tanımla"
                    isVisible={isExternalRoomDefinitionCreateModalVisible}
                    form={roomDefinitionCreateForm}
                    onCancel={() => {
                        setIsExternalRoomDefinitionCreateModalVisible(false)
                        hotelRoomAppendForm.resetFields();
                    }}
                    onFinish={onCreateHotelRoomDefinition}
                />
                <ExternalHotelRoomAppendModal
                    title="Otel'e Oda Ata"
                    isVisible={isExternalHotelRoomAppendModalVisible}
                    form={hotelRoomAppendForm}
                    onCancel={() => {
                        setIsExternalHotelRoomAppendModalVisible(false)
                        hotelRoomAppendForm.resetFields();
                    }}
                    onFinish={onAppendHotelRoom}
                    roomDefinitions={hotelRoomDefinitions.data}
                />
            </Content>
        </Wrapper>
    );
};

export default ExternalHotelRoomList;