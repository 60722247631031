import axios from 'axios';
import { Authentication } from '@services';

const instance = axios.create();

instance.defaults.baseURL = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_API_URL : process.env.REACT_APP_DEV_API_URL;
instance.defaults.headers.common['Accept'] = 'application/json';

instance.interceptors.request.use((config) => {
  config.headers.common['Authorization'] = `Bearer ${Authentication.getToken()}`;

  return config;
}, (err) => {
  return Promise.reject(err);
});

instance.interceptors.response.use((config) => {
  return config;
}, (err) => {

  if (err.response.status === 401) {
    Authentication.revokeToken();
    window.location = '/login';
  }

  return Promise.reject(err);
});

export default instance;