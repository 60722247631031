import axios from '../axios';

const ChildAgeService = {
  async get() {
    return await axios.get(`/v1/child-ages`);
  },

  async create(formData) {
    return await axios.post(`/v1/child-ages`, formData);
  },

  async update(childAgeId, formData) {
    return await axios.post(`/v1/child-ages/${childAgeId}`, formData);
  },

  async delete(childAgeId) {
    return await axios.delete(`/v1/child-ages/${childAgeId}`);
  }
};

export default ChildAgeService;