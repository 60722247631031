import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { SafeBankDefinitionService } from '@services';

const { Option } = Select;

const SafeBankSelect = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [safeBank, setSafeBank] = useState({
        data: [],
        meta: {}
    });

    useEffect(() => {
        getSafeBank();
    }, []);

    const getSafeBank = async (page = 1, per_page = 10) => {
        try {
            let { data: response } = await SafeBankDefinitionService.get(page, per_page);
            if (props.filter) {
                response.data = response.data.filter((safeBank) => {
                    let isValid = true;
                    props.filter.forEach((filter) => {
                        if (filter.field.length === 1) {
                            if (safeBank[filter.field[0]] !== filter.value) {
                                isValid = false;
                            }
                        } else if (filter.field.length === 2) {
                            if (safeBank[filter.field[0]][filter.field[1]] !== filter.value) {
                                isValid = false;
                            }
                        }
                    });
                    return isValid;
                });
            }
            setSafeBank({
                data: [...safeBank.data, ...response.data],
                meta: response.meta
            });
            setIsLoading(false);
        } catch (e) {
            console.log(e);
        }
    };

    const onScroll = (e) => {
        const { target } = e;

        if (! isLoading && safeBank.meta.last_page > safeBank.meta.current_page && (target.scrollTop + target.offsetHeight) >= (target.scrollHeight * 0.8)) {
            setIsLoading(true);
            getSafeBank(safeBank.meta.current_page + 1);
        }
    };

    return (
        <Select
            value={props.value}
            dropdownRender={menu => <div onScrollCapture={onScroll}>{menu}</div>}
            onChange={props.onChange}
            loading={isLoading}
            style={props.style}
            placeholder="Kasa & Banka seçiniz"
            onSelect={(value) => props.setSelectedSafeBank(safeBank.data.find((safeBank) => safeBank.id === value))}
        >
            {safeBank.data.map((safeBank, index) => <Option key={index} value={safeBank.id}>{safeBank.bank_name} | {safeBank.title}</Option>)}
        </Select>
    );
};

export default SafeBankSelect;