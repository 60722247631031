import axios from '../axios';

const AgencyWebCategoryService = {
    async getByHotel(hotelId) {
        return await axios.get(`/v1/site-manager/categories/${hotelId}`);
    },
    async create(hotelId,formData) {
        return await axios.post(`/v1/site-manager/categories/${hotelId}`,formData);
    },
    async update(categoryId, formData) {
        return await axios.post(`/v1/site-manager/categories/category/${categoryId}`, formData);
    },
    async delete(categoryId) {
        return await axios.delete(`/v1/site-manager/categories/category/${categoryId}`);
    },
    async findById(categoryId) {
        return await axios.get(`/v1/site-manager/categories/category/${categoryId}`);
    },
};

export default AgencyWebCategoryService;