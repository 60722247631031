import React, { useState, useEffect } from 'react';
import {Modal, Form, Row, Select, Col, DatePicker, notification} from 'antd';
import moment from "moment";
import { WidgetsService } from '@services'


const { RangePicker } = DatePicker;
const { Option } = Select;

const LinkWidgetModalForm = (props) => {
    const [widgets,setWidgets] = useState([]);
    const [contractWidgets,setContractWidgets] = useState([]);
    const onOK = () => {
        props.form.submit();
    }
    const getWidgets = async (page=1) => {
        try {
            let { data: response } = await WidgetsService.get(page);
            setWidgets(response.data);
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }
    useEffect(() => {
        getWidgets();
    }, []);

    return (
        <Modal visible={props.isVisible} title={props.title} closable={false} onOk={onOK} onCancel={props.onCancel}>
            <Form form={props.form} layout="vertical" onFinish={(values) => props.onFinish(values, props.data?.id)} fields={props.data?.fields}>
                <Row>
                    <Col span={24}>
                        <Form.Item name="widget_id" label="Widget Adı">
                            <Select mode='multiple'>
                                {widgets.map((widget, index) => <Option key={index} value={widget.id}>{widget.widget_name}</Option>)}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                {props.data.contractWidgets.filter(value => value.contract_id === props.data.selectedContract).map((contractWidget, index) => (
                    <div key={index}>{contractWidget.widget_id}</div>
                ))}
            </Form>
        </Modal>
    )
};

export default LinkWidgetModalForm;