import React from "react";
import {Select, notification} from "antd";
import {CountriesService} from "@services";
const {Option}=Select;

function CountrySelect (props){

 const [countries,setCountries] = React.useState([]);
 const [isLoading,setIsLoading] = React.useState(true);

    React.useEffect(() => {
        const getCountries = async () => {
            try {
                let {data :{data:response}} = await CountriesService.get();
                setCountries(response);
                setIsLoading(false);
            } catch (e) {
                if (e.response) {
                    notification.error({
                        message: e.response.data.message
                    });
                }
            }
        };
        getCountries();
    }, []);
    return (
     <Select {...props} loading={isLoading}>
       {countries?.map((country,i)=>(
         <Option  value={country.num_code} key={`${country.num_code}`}>{country.nationality}</Option>
       ))}
     </Select>
    )
}

export default CountrySelect;