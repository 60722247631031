import React, {useEffect} from 'react';
import {Breadcrumb, Layout, notification, Space, Table, Tag} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHouse} from "@fortawesome/free-solid-svg-icons";
import Wrapper from "@/components/Wrapper";
import {TransactionService} from "@/services";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

const {Content} = Layout;

const TransferTransactions = () => {
    const { t } = useTranslation()
    const [transactions, setTransactions] = React.useState({
        data: [],
        meta: {},
        isLoading: true
    })
    const columns = [
        {
            title: `${t("general.reservation_no")}`,
            dataIndex: 'reservation_uuid',
            key: 'reservation_uuid',
            render: (text) => (
                <Link to={`/reservation-card/${text}`}>{text}</Link>
            )
        },
        {
            title: `${t("general.bank")}`,
            dataIndex: 'bank_name',
            key: 'bank_name',
        },
        {
            title: `${t("general.currency")}`,
            dataIndex: 'currency',
            key: 'currency',
        },
        {
            title: `${t("general.amount")}`,
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: `${t("general.process_date")}`,
            dataIndex: 'created_at',
            key: 'created_at',
        }
    ]
    const getTransactions = async (page = 1, perPage = 10) => {
        setTransactions({
            ...transactions,
            isLoading: true
        })
        try {
            const {data: response} = await TransactionService.getTransfer(page, perPage);
            setTransactions({
                data: response.data,
                meta: response.meta,
                total_amount: response.total_amount,
                isLoading: false
            })
        }catch (e){
            if(e.response){
                notification.error({
                    message: e.response.data.message,
                })
            }
        }
    }
    useEffect(() => {
        getTransactions()
    },[])
    return (
        <Wrapper title="Sanal Pos İşlemleri">
            <Content className="m-25">
                <Breadcrumb className="mb-20">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse} />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                       {t("general.transfer_transactions")}
                    </Breadcrumb.Item>
                </Breadcrumb>

                <div className="site-layout-background">
                    <Table
                        loading={transactions.isLoading}
                        columns={columns}
                        dataSource={transactions.data}
                        pagination={{
                            pageSize: transactions.meta.per_page,
                            total: transactions.meta.total,
                            current: transactions.meta.current_page,
                            onChange: (page, pageSize) => {
                                getTransactions(page, pageSize)
                            }
                        }}
                        footer={() => (
                            <Space hidden={transactions?.total_amount?.length === 0}>
                                <b>{t("general.total")}:</b>
                                {transactions?.total_amount?.map((item,index) => (
                                    <Tag index={index}>{item?.amount} {item?.symbol}</Tag>
                                ))}
                            </Space>
                        )}
                    />
                </div>
            </Content>
        </Wrapper>
    );
};

export default TransferTransactions;