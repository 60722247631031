import axios from '../axios';

const ExternalOperatorService = {
    async get(page, per_page) {
        return await axios.get(`/v1/external/operators?page=${page}&per_page=${per_page}`);
    },
    async find(id) {
        return await axios.get(`/v1/external/operators/${id}`);
    },
    async installmentCreate(formData, id) {
        return await axios.post(`/v1/external/operators/${id}`, formData);
    }
};

export default ExternalOperatorService;