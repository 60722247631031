import axios from '../axios';

const CountryService = {
    async get(){
        return await axios.get(`/v1/definitions/countries`);
    },
    async create(formData) {
        return await axios.post(`/v1/definitions/countries`, formData);
    },
    async delete(id) {
        return await axios.delete(`/v1/definitions/countries/${id}`);
    },
};

export default CountryService;