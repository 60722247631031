import axios from '../axios';

const PageService = {
    async getByHotel(hotelId) {
        return await axios.get(`/v1/site-manager/pages/${hotelId}`);
    },
    async create(hotelId,formData) {
        return await axios.post(`/v1/site-manager/pages/${hotelId}`,formData);
    },
    async setParent(pageId,formData){
        return await axios.post(`/v1/site-manager/pages/set-parent/${pageId}`,formData);
    },
    async update(pageId, formData) {
        return await axios.post(`/v1/site-manager/pages/page/${pageId}`, formData);
    },
    async delete(pageId) {
        return await axios.delete(`/v1/site-manager/pages/page/${pageId}`);
    },
    async findById(pageId) {
        return await axios.get(`/v1/site-manager/pages/page/${pageId}`);
    },
};

export default PageService;