import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, Row, Col, Select, notification } from 'antd'
import { HotelService, ContractLanguageService } from '@services';
import ConceptSelect from '@components/utils/ConceptSelect';
import ConditionSelect from "@components/utils/ConditionSelect";
import PeriodSelect from "@components/utils/PeriodSelect";
import { useParams } from "react-router-dom";

const { Option } = Select;

const ContractModalForm = (props) => {
  const [contractLanguage, setContractLanguage] = useState([]);

  const params = useParams();
  const [hotelRooms, setHotelRooms] = useState([]);

  const [fields, setFields] = useState([
    {
      name: 'price',
      value: 0
    }
  ]);
  useEffect(() => {
    /*if (props.data && props.data.fields) {
      setFields([
        ...fields,
        ...props.data.fields
      ]);
    }*/
    getHotelRooms();
  }, [props.data])
  useEffect(() => {
    getContractLanguages(params.hotelId);
  }, [])
  const getHotelRooms = async () => {
    try {
      let response = await HotelService.getRooms(params.hotelId);
      setHotelRooms(response.data.data);
    } catch (e) {
      if (e.response) {
        notification.error({
          message: e.response.data.message
        });
      }
    }
  };
  const getContractLanguages = async (hotelId) => {
    try {
      let { data: response } = await ContractLanguageService.get(hotelId);
      setContractLanguage(response.data);
    } catch (e) {
      if (e.response) {
        notification.error({
          message: e.response.data.message
        });
      }
    }
  };

  const onOK = () => {
    props.form.submit();
  }

  return (
    <Modal visible={props.isVisible} title={props.title} closable={false} onOk={onOK} onCancel={props.onCancel}>
      <Form form={props.form} layout="vertical" onFinish={(values) => props.onFinish(values, props.data?.id)} fields={fields} initialValues={{ contract_group_id:params.contractGroupId }}>
        <Row gutter={[16]}>
          <Col span={12}>
            <Form.Item name="title" label="Kontrat Adı">
              <Input placeholder="Kontrat Adı" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="contract_language_id" label="Kontrat Dili">
              <Select>
                {contractLanguage.map((item) => <Option value={item.id} key={`language${item.id}`}>{item.language_name}</Option>)}
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name="hotel_room_id" label="Oda Adı">
              <Select>
                {hotelRooms.map((item) => <Option value={item.id} key={`hotelRoom${item.id}`}>{item.name}</Option>)}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="concepts" label="Konsept Adı">
              <ConceptSelect mode="multiple" hotelId={params.hotelId}/>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="condition_group_id" label="Kondüsyon Adı">
              <ConditionSelect />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="period_id" label="Periyot Adı">
              <PeriodSelect />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="contract_group_id" hidden={true}>
              <Input placeholder="Kontrat Adı" value={params.contractGroupId}/>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
};

export default ContractModalForm;