import React from 'react';
import {Col, Form, Input, Modal, Row, Select} from "antd";
import CurrencySelect from "@components/utils/CurrencySelect";
import {useTranslation} from "react-i18next";

const {Option} = Select;

const CurrentDeficitModalForm = (props) => {
    const { t } = useTranslation()
    return (
        <Modal visible={props.isVisible} title={props.title} closable={false} onOk={() => props.form.submit()} onCancel={props.onCancel}>
            <Form form={props.form} layout="vertical" onFinish={(values) => props.onFinish(values, props?.data?.id)}>
                <Row gutter={[16,8]}>
                    <Col span={12}>
                        <Form.Item
                            name="cari_id"
                            label={t('general.cari_type')}
                            rules={[{
                                required: true,
                                message: 'Lütfen Cari Tipi Seçiniz'
                            }]}
                        >
                            <Select
                                placeholder="Lütfen Cari Tipi Seçiniz"
                            >
                                {props?.caris?.map((item) => (
                                    <Option value={item.id} key={item.id}>
                                        {item.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="currency"
                            label={t('general.currency')}
                            rules={[{
                                required: true,
                                message: 'Lütfen Para Birimi Seçiniz'
                            }]}
                        >
                            <CurrencySelect
                                placeholder="Lütfen Para Birimi Seçiniz"
                                mode={props.type === 'edit' ? 'single' : 'multiple'}
                                maxTagCount="responsive"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="company_title"
                            label={t('general.sign_title')}
                            rules={[{
                                required: true,
                                message: 'Lütfen Tabela Ünvanı Giriniz'
                            }]}
                        >
                            <Input placeholder="Tabela Ünvanı" />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            name="bill_title"
                            label={t('general.invoice_title')}
                            rules={[{
                                required: true,
                                message: 'Lütfen Fatura Başlığı Giriniz'
                            }]}
                        >
                            <Input placeholder={t('general.invoice_title')} />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            name="address"
                            label={t('general.address')}
                            rules={[{
                                required: true,
                                message: 'Lütfen Adres Giriniz'
                            }]}
                        >
                            <Input placeholder={t('general.address')} />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            name="tax_administration"
                            label={t('general.tax_administration')}
                            rules={[{
                                required: true,
                                message: 'Lütfen Vergi Dairesi Giriniz'
                            }]}
                        >
                            <Input placeholder={t('general.tax_administration')} />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            name="tax_number"
                            label={t('general.tax_number')}
                            rules={[{
                                required: true,
                                message: 'Lütfen Vergi Numarası Giriniz'
                            }]}
                        >
                            <Input placeholder={t('general.tax_number')} />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            name="authorized_person"
                            label={t('general.authorized_person')}
                        >
                            <Input placeholder={t('general.authorized_person')} />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            name="authorized_phone"
                            label={t('general.authorized_phone')}
                        >
                            <Input placeholder={t('general.authorized_phone')} />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            name="authorized_email"
                            label={t('general.authorized_email')}
                        >
                            <Input placeholder={t('general.authorized_email')} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default CurrentDeficitModalForm;