import axios from '../axios';

const ContractService = {
    async get() {
        return await axios.get(`/v1/contract-widgets`);
    },
    async getByContractId(contractId) {
        //return await axios.get(`/v1/contracts/${hotelId}/languages/${languageId}`);
    }
};

export default ContractService;