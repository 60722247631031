import React, {useEffect, useState} from 'react';
import {Breadcrumb, Button, Col, Form, Layout, notification, Row, Space, Table} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faHouse, faImage} from "@fortawesome/free-solid-svg-icons";
import Wrapper from "@/components/Wrapper";
import {useTranslation} from "react-i18next";
import { LaraBeachSettingsService, LaraBeachVehicleService } from "@/services";
import VehicleModalForm from "@/components/lara-beach-excursions/modals/VehicleModalForm";
import VehicleFileModalForm from "@/components/lara-beach-excursions/modals/VehicleFileModalForm";

const {Content} = Layout;

const VehiclesPage = () => {
    const { t } = useTranslation()
    const [supportedLanguages, setSupportedLanguages] = useState([])
    const [createForm] = Form.useForm()
    const [editForm] = Form.useForm()
    const [imagesForm] = Form.useForm()
    const [vehicles, setVehicles] = useState({
        data: [],
        meta: {},
        loading: true
    })
    const [selectedVehicle, setSelectedVehicle] = useState({})
    const [isVehicleCreateModalVisible, setIsVehicleCreateModalVisible] = useState(false)
    const [isVehicleEditModalVisible, setIsVehicleEditModalVisible] = useState(false)
    const [isVehicleImagesModalVisible, setIsVehicleImagesModalVisible] = useState(false)
    const columns = [
        {
            title: t("general.id"),
            dataIndex: "id",
            key: "id"
        },
        {
            title: t("general.name"),
            dataIndex: "name",
            key: "name"
        },
        {
            title: t("general.actions"),
            key: "actions",
            render: (text, record) => (
                <Space direction="horizontal">
                    <Button type="primary" htmlType="button" onClick={() => onEdit(record.id)}>
                        <FontAwesomeIcon icon={faEdit}/>
                    </Button>
                    <Button type="primary" htmlType="button" onClick={() => onImageEdit(record.id)}>
                        <FontAwesomeIcon icon={faImage}/>
                    </Button>
                </Space>
            )
        }
    ]
    const onCreate = async (values) => {
        try {
            let {data: response} = await LaraBeachVehicleService.create(values)
            if (response.success){
                notification.success({
                    message: response.message
                })
                setIsVehicleCreateModalVisible(false)
                createForm.resetFields()
                await getVehicles()
            }
        }catch (e){
            if (e.response){
                notification.error({
                    message: e.response.data.message
                })
            }
        }
    }
    const onUpdate = async (values, id) => {
        try {
            let {data: response} = await LaraBeachVehicleService.update(id, values)
            if (response.success){
                notification.success({
                    message: response.message
                })
                setIsVehicleEditModalVisible(false)
                editForm.resetFields()
                await getVehicles()
            }
        }catch (e){
            if (e.response){
                notification.error({
                    message: e.response.data.message
                })
            }
        }
    }
    const onImageEdit = async (id) => {
        try {
            let {data: response} = await LaraBeachVehicleService.find(id)
            imagesForm.setFieldsValue({
                files: response.data.files.map((file) => ({
                    uid: "U-"+ file.id,
                    id: file.id,
                    name: "image-"+ file.id,
                    status: 'uploaded',
                    url: file.url
                }))
            })
            setSelectedVehicle(response.data)
            setIsVehicleImagesModalVisible(true)
        }catch (e){
            if (e.response){
                notification.error({
                    message: e.response.data.message
                })
            }
        }
    }
    const onImageUpdate = async (values, id) => {
        let formData = new FormData()
        values.files.map((file) => {
            if (file.status === 'uploaded'){
                return formData.append('prevFiles[]', file.id)
            }
            return formData.append('files[]', file.originFileObj)
        })
        try {
            let {data: response} = await LaraBeachVehicleService.updateFiles(id, formData)
            if (response.success){
                notification.success({
                    message: response.message
                })
                setIsVehicleImagesModalVisible(false)
                imagesForm.resetFields()
            }
        }catch (e){
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                })
            }
        }
    }
    const onEdit = async (id) => {
        try {
            let {data: response} = await LaraBeachVehicleService.find(id)
            response?.data?.translations?.map((translation) => {
                editForm.setFields([
                    {
                        name: ['vehicles', 'translations', translation.language, 'name'],
                        value: translation.name
                    },
                ]);
            })
            editForm.setFields([
                {name: ['vehicles', 'min_capacity'], value: response.data.min_capacity},
                {name: ['vehicles', 'max_capacity'], value: response.data.max_capacity},
                {name: ['vehicles', 'baggage_capacity'], value: response.data.baggage_capacity},
            ])
            setIsVehicleEditModalVisible(true)
            setSelectedVehicle(response.data)
        }catch (e){
            if (e.response){
                notification.error({
                    message: e.response.data.message
                })
            }
        }
    }
    const getSupportedLanguages = async () => {
        try {
            let {data: response} = await LaraBeachSettingsService.show('supported_languages')
            setSupportedLanguages(response.data.value.split(',') || [])
        }catch (e){
            if (e.response){
                notification.error({
                    message: e.response.data.message
                })
            }
        }
    }
    const getVehicles = async (page = 1, per_page = 10) => {
        setVehicles(prev => ({
            ...prev,
            loading: true
        }))
        try {
            let {data: response} = await LaraBeachVehicleService.get(page, per_page)
            setVehicles({
                data: response.data,
                meta: response.meta,
                loading: false
            })
        }catch (e){
            if (e.response){
                notification.error({
                    message: e.response.data.message
                })
            }
            setVehicles(prev => ({
                ...prev,
                loading: false
            }))
        }
    }
    useEffect(() => {
        getSupportedLanguages()
        getVehicles()
    },[])
    return (
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-20">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse} />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {t("general.vehicles")}
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background">
                    <Row gutter={[16,16]}>
                        <Col span={24}>
                            <Button type="primary" htmlType="button" onClick={() => setIsVehicleCreateModalVisible(true)}>
                                {t("general.create")}
                            </Button>
                        </Col>
                    </Row>
                    <Table
                        columns={columns}
                        dataSource={vehicles.data}
                        rowKey="id"
                        pagination={{
                            current: vehicles.meta.current_page,
                            pageSize: vehicles.meta.per_page,
                            total: vehicles.meta.total,
                            showSizeChanger: true,
                            onChange: (page, pageSize) => {
                                getVehicles(page, pageSize)
                            },
                        }}
                        loading={vehicles.loading}
                    />
                </div>
            </Content>
            <VehicleModalForm
                title={t("general.create")}
                isVisible={isVehicleCreateModalVisible}
                onCancel={() => {
                    setIsVehicleCreateModalVisible(false)
                    createForm.resetFields()
                }}
                form={createForm}
                supportedLanguages={supportedLanguages}
                onFinish={onCreate}
            />
            <VehicleModalForm
                title={t("general.edit")}
                isVisible={isVehicleEditModalVisible}
                onCancel={() => {
                    setIsVehicleEditModalVisible(false)
                    editForm.resetFields()
                }}
                form={editForm}
                supportedLanguages={supportedLanguages}
                onFinish={onUpdate}
                data={selectedVehicle}
            />
            <VehicleFileModalForm
                title={t("general.images")}
                isVisible={isVehicleImagesModalVisible}
                onCancel={() => {
                    setIsVehicleImagesModalVisible(false)
                    imagesForm.resetFields()
                }}
                onFinish={onImageUpdate}
                form={imagesForm}
                data={selectedVehicle}
            />
        </Wrapper>
    );
};

export default VehiclesPage;