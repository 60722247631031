import axios from '../axios';

const SafeBankCariTransactionService = {
    async get(page, per_page) {
        return await axios.get(`/v1/safe-bank-cari-transactions?page=${page}&per_page=${per_page}`);
    },
    async getBySafeBank(safeBankId, page, per_page, params) {
        return await axios.get(`/v1/safe-bank-cari-transactions/${safeBankId}?page=${page}&per_page=${per_page}`,{
            params
        });
    },
    async create(formData) {
        return await axios.post('/v1/safe-bank-cari-transactions',formData);
    },
};

export default SafeBankCariTransactionService;