import {Layout, Result} from "antd";
import React, { useEffect } from "react";
import {Routes, Route, Link} from "react-router-dom";
import useRouteHistory from "@hooks/useRouteHistory";
import { useDispatch } from "react-redux";
import { setLanguages } from "@features/appSlice";
import LanguageService from "@services/languages/Language.service";
import PrivateRoute from "@components/PrivateRoute";
import Dashboard from "@views/dashboards/Dashboard";
import RoomDefinitionList from "@views/rooms/RoomDefinitionList";
import Login from "@views/login/Login";
import HotelList from "@views/hotels/HotelList";
import HotelCreate from "@views/hotels/HotelCreate";
import HotelEdit from "@views/hotels/HotelEdit";
import RoomEdit from "@views/rooms/RoomEdit";
import Concept from "@views/concepts/Concept";
import Conditions from "@views/conditions/Conditions";
import ConditionGroup from "@views/conditions/ConditionGroup";
import PeriodDate from "@views/periods/PeriodDate";
import Period from "@views/periods/Period";
import Widgets from "@views/widgets/Widgets";
import ChildAges from "@views/child-ages/ChildAges";
import ContractGroup from "@views/contracts/ContractGroup";
import ContractDetails from "@views/contracts/ContractDetails";
import ContractDays from "@views/contracts/ContractDays";
import Photos from "@views/photos/Photos";
import Reservations from "@views/reservations/Reservations";
import HotelRoomMatch from "@views/room-match/HotelRoomMatch";
import HotelSettings from "@views/hotels/HotelSettings";
import Users from "@views/users/UserList";
import ReservationCreate from "@views/reservations/ReservationCreate";
import SiteList from "@views/site-management/SiteList";
import SiteSettings from "@views/site-management/SiteSettings";
import ReservationCard from "@views/reservations/ReservationCard";
import ProviderReservationCard from "@views/reservations/ProviderReservationCard";
import PageSettings from "@views/site-management/PageSettings";
import CategorySettings from "@views/site-management/CategorySettings";
import ContractGroups from "@views/contracts/ContractGroups";
import Contract from "@views/contracts/Contract";
import HeaderSettings from "@views/site-management/HeaderSettings";
import FooterSettings from "@views/site-management/FooterSettings";
import SafeBank from "@views/safe-bank/SafeBank";
import NightDiscounts from "@views/discounts/NightDiscounts";
import Discounts from "@views/discounts/Discounts";
import HotelEventList from "@views/events/HotelEventList";
import Voucher from "@views/vouchers/Voucher";
import LoginExit from "@views/login-exit/LoginExit";
import Icons from "@views/icons/Icons";
import Confirme from "@views/confirme/Confirme";
import CurrentDeficit from "@views/current-deficit/CurrentDeficit";
import Forecast from "@views/forecast/Forecast";
import MobileDiscounts from "@views/discounts/MobileDiscounts";
import EarlyReservationDiscounts from "@views/discounts/EarlyReservationDiscounts";
import ActionDiscounts from "@views/discounts/ActionDiscounts";
import ReservationForm from "@views/reservations/ReservationForm";
import HotelStats from "@views/hotels/HotelStats";
import TempReservations from "@views/reservations/TempReservations";
import DeferredReservations from "@views/reservations/DeferredReservations";
import AccountingReservations from "@views/reservations/AccountingReservations";
import VirtualPosTransactions from "@views/virtual-pos/VirtualPosTransactions";
import SystemLog from "@views/system-logs/SystemLog";
import TransferTransactions from "@views/transactions/TransferTransactions";
import SafeBankTransactions from "@views/safe-bank/SafeBankTransactions";
import SafeBankTransactionsDetail from "@views/safe-bank/SafeBankTransactionsDetail";
import CurrentDeficitTransactions from "@views/current-deficit/CurrentDeficitTransactions";
import CurrentDeficitTransactionDetails from "@views/current-deficit/CurrentDeficitTransactionDetails";
import CmHotelList from "@views/channel-management/CMHotelList";
import CmRoomList from "@views/channel-management/CMRoomList";
import CmConceptList from "@views/channel-management/CMConceptList";
import CMPeriodList from "@views/channel-management/CMPeriodList";
import CMPeriodDateList from "@views/channel-management/CMPeriodDateList";
import CMConditionGroupList from "@views/channel-management/CMConditionGroupList";
import CMConditionList from "@views/channel-management/CMConditionList";
import CMChildAgeList from "@views/channel-management/CMChildAgeList";
import CMContractHotelList from "@views/channel-management/CMContractHotelList";
import CMContractPrices from "@views/channel-management/CMContractPrices";
import CMContractGroupList from "@views/channel-management/CMContractGroupList";
import CMContractList from "@views/channel-management/CMContractList";
import PackageReservations from "@views/package-reservations/PackageReservations";
import CmReservations from "@views/channel-management/CMReservations";
import Countries from "@views/countries/Countries";
import Cities from "@views/cities/Cities";
import Regions from "@views/regions/Regions";
import MainRegion from "@views/main-regions/MainRegion";
import Airports from "@views/airports/Airports";
import Flights from "@views/flights/Flights";
import ExternalHotelRoomList from "@views/external/rooms/ExternalHotelRoomList";
import ExternalHotelConditionGroupList from "@views/external/conditions/ExternalHotelConditionGroupList";
import ExternalHotelConditionList from "@views/external/conditions/ExternalHotelConditionList";
import ExternalHotelConceptList from "@views/external/concepts/ExternalHotelConceptList";
import ExternalHotelDetails from "@views/external/hotels/ExternalHotelDetails";
import ExternalHotelCreate from "@views/external/hotels/ExternalHotelCreate";
import ExternalPropertyDefinition from "@views/external/definitions/ExternalPropertyDefinition";
import ExternalDescriptionDefinition from "@views/external/definitions/ExternalDescriptionDefinition";
import ExternalPopularFacilityDefinition from "@views/external/definitions/ExternalPopularFacilityDefinition";
import BankTransactions from "@views/reports/BankTransactions";
import ExternalCardsDefinition from "@views/external/definitions/ExternalCardsDefinition";
import AdditionalServices from "@views/additional-services/AdditionalServices";
import SaleReports from "@views/reports/SaleReports";
import UserCallList from "@views/user-calls/UserCallList";
import UserCallReport from "@views/user-calls/UserCallReport";
import ReservationProvidersList from "@views/reservation-providers/ReservationProvidersList";
import ProviderReservations from "@views/reservations/ProviderReservations";
import ProviderReservationCreate from "@views/reservations/ProviderReservationCreate";
import PoolHotelList from "@views/external/hotels/PoolHotelList";
import PoolBankList from "@views/external/banks/PoolBankList";
import ContractCommissions from "@views/contracts/ContractCommissions";
import Notifications from "@views/notifications/Notifications";
import ProgressPayment from "@views/progress-payment/ProgressPayment";
import PaymentTable from "@views/payment-table/PaymentTable";
import PoolOperatorList from "@views/external/operators/PoolOperatorList";
import ExternalContractHotels from "@views/external/contracts/ContractHotelList";
import ExternalContracts from "@views/external/contracts/ExternalContracts";
import ExternalContractGroups from "@views/external/contracts/ExternalContractGroups";
import ExternalContractDetails from "@views/external/contracts/ExternalContractDetails";
import ExternalHotelPeriodDateList from "@/views/external/periods/ExternalHotelPeriodDateList";
import ExternalHotelPeriodList from "@/views/external/periods/ExternalHotelPeriodList";
import ExternalHotelChildAgeList from "@/views/external/child-ages/ExternalHotelChildAgeList";
import ExternalForecast from "@/views/external/forecast/Forecast";
import ExternalCustomerList from "@/views/external/customers/ExternalCustomerList";
import ExternalOfferList from "@/views/external/offers/ExternalOfferList";
import WidgetActivities from "@/views/widgets/WidgetActivities";
import Targets from "@/views/targets/Targets";
import TargetUserProgress from "@/views/targets/TargetUserProgress";
import ResetPassword from "@/views/reset-password/ResetPassword";
import ForgotPassword from "@/views/forgot-password/ForgotPassword";
import ProfitReport from "@/views/reports/ProfitReport";
import ReservationForecast from "@/views/reservations/ReservationForecast";
import PointsPage from "@/views/lara-beach-excursions/points/PointsPage";
import VehiclesPage from "@/views/lara-beach-excursions/vehicles/VehiclesPage";
import TransfersPage from "@/views/lara-beach-excursions/transfers/TransfersPage";
import ToursPage from "@/views/lara-beach-excursions/tours/ToursPage";
import TourFormPage from "@/views/lara-beach-excursions/tours/TourFormPage";
import TourDetailPage from "@/views/lara-beach-excursions/tours/TourDetailPage";
import ActivitiesPage from "@/views/lara-beach-excursions/activities/ActivitiesPage";
import ActivityFormPage from "@/views/lara-beach-excursions/activities/ActivityFormPage";
import ActivityDetailPage from "@/views/lara-beach-excursions/activities/ActivityDetailPage";
import ShoppingPage from "@/views/lara-beach-excursions/shopping/ShoppingPage";
import ShoppingFormPage from "@/views/lara-beach-excursions/shopping/ShoppingFormPage";
import ShoppingDetailPage from "@/views/lara-beach-excursions/shopping/ShoppingDetailPage";
import ReservationsPage from "@/views/lara-beach-excursions/reservations/ReservationsPage";
import SubPointsPage from "@/views/lara-beach-excursions/points/SubPointsPage";
import HotelListPage from "@/views/new-version/hotels/HotelListPage";
import HotelCreatePage from "@/views/new-version/hotels/HotelCreatePage";
import HotelUpdatePage from "@/views/new-version/hotels/HotelUpdatePage";
import ContractList from "@/views/new-version/contracts/ContractList";
import ContractEdit from "@/views/new-version/contracts/ContractEdit";

const App = () => {
  const dispatch = useDispatch();
  useRouteHistory();

  useEffect(() => {
    const getLanguages = async () => {
      try {
        let response = await LanguageService.get();
        dispatch(setLanguages(response.data.data));
      } catch (e) {
        console.log(e.response);
      }
    };
    getLanguages();
  }, []);


  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Routes>
          <Route
              path="/v2/contracts"
              element={
                  <PrivateRoute permissions={['v2.pages.view']}>
                      <ContractList />
                  </PrivateRoute>
              }
          />

          <Route
              path="/v2/contracts/:id"
              element={
                  <PrivateRoute permissions={['v2.pages.view']}>
                      <ContractEdit />
                  </PrivateRoute>
              }
          />

          <Route
              path="/v2/hotels"
              element={
                  <PrivateRoute permissions={['v2.pages.view']}>
                      <HotelListPage/>
                  </PrivateRoute>
              }
          />

          <Route
              path="/v2/hotels/create"
              element={
                  <PrivateRoute permissions={['v2.pages.view']}>
                      <HotelCreatePage/>
                  </PrivateRoute>
              }
          />

          <Route
              path="/v2/hotels/:id"
              element={
                  <PrivateRoute permissions={['v2.pages.view']}>
                      <HotelUpdatePage/>
                  </PrivateRoute>
              }
          />


          <Route
            path="lara-beach-excursions/points"
            element={
              <PrivateRoute permissions={['lara-beach-excursions.view']}>
                  <PointsPage />
              </PrivateRoute>
            }
          />
          <Route
            path="lara-beach-excursions/points/:id"
            element={
              <PrivateRoute permissions={['lara-beach-excursions.view']}>
                  <SubPointsPage />
              </PrivateRoute>
            }
          />
          <Route
            path="lara-beach-excursions/transfers"
            element={
              <PrivateRoute permissions={['lara-beach-excursions.view']}>
                  <TransfersPage />
              </PrivateRoute>
            }
          />
          <Route
            path="lara-beach-excursions/tours"
            element={
              <PrivateRoute permissions={['lara-beach-excursions.view']}>
                  <ToursPage />
              </PrivateRoute>
            }
          />
          <Route
            path="lara-beach-excursions/tours/:id"
            element={
              <PrivateRoute permissions={['lara-beach-excursions.view']}>
                  <TourDetailPage />
              </PrivateRoute>
            }
          />
          <Route
              path="lara-beach-excursions/tours/create"
              element={
                  <PrivateRoute permissions={['lara-beach-excursions.view']}>
                      <TourFormPage />
                  </PrivateRoute>
              }
          />
          <Route
              path="lara-beach-excursions/shopping"
              element={
                  <PrivateRoute permissions={['lara-beach-excursions.view']}>
                      <ShoppingPage />
                  </PrivateRoute>
              }
          />
          <Route
              path="lara-beach-excursions/shopping/:id"
              element={
                  <PrivateRoute permissions={['lara-beach-excursions.view']}>
                      <ShoppingDetailPage />
                  </PrivateRoute>
              }
          />
          <Route
              path="lara-beach-excursions/shopping/create"
              element={
                  <PrivateRoute permissions={['lara-beach-excursions.view']}>
                      <ShoppingFormPage />
                  </PrivateRoute>
              }
          />
          <Route
              path="lara-beach-excursions/activities"
              element={
                  <PrivateRoute permissions={['lara-beach-excursions.view']}>
                      <ActivitiesPage />
                  </PrivateRoute>
              }
          />
          <Route
              path="lara-beach-excursions/activities/:id"
              element={
                  <PrivateRoute permissions={['lara-beach-excursions.view']}>
                      <ActivityDetailPage />
                  </PrivateRoute>
              }
          />
          <Route
              path="lara-beach-excursions/activities/create"
              element={
                  <PrivateRoute permissions={['lara-beach-excursions.view']}>
                      <ActivityFormPage />
                  </PrivateRoute>
              }
          />
          <Route
              path="lara-beach-excursions/reservations"
              element={
                  <PrivateRoute permissions={['lara-beach-excursions.view']}>
                      <ReservationsPage />
                  </PrivateRoute>
              }
          />

          <Route
            path="lara-beach-excursions/vehicles"
            element={
              <PrivateRoute permissions={['lara-beach-excursions.view']}>
                  <VehiclesPage />
              </PrivateRoute>
            }
          />

          <Route
            path="reports/bank-transactions"
            element={
              <PrivateRoute>
                  <BankTransactions/>
              </PrivateRoute>
            }
          />

          <Route
            path="/notifications"
            element={
              <PrivateRoute>
                  <Notifications/>
              </PrivateRoute>
            }
          />
          <Route
            path="/payment-table"
            element={
              <PrivateRoute>
                  <PaymentTable/>
              </PrivateRoute>
            }
          />

          <Route
            path="/progress-payment"
            element={
              <PrivateRoute>
                  <ProgressPayment/>
              </PrivateRoute>
            }
          />



          <Route
            path="/reports/widget-activities"
            element={
              <PrivateRoute>
                  <WidgetActivities/>
              </PrivateRoute>
            }
          />

          <Route
              path="external-management/hotels/:hotelId/rooms"
                element={
                    <PrivateRoute permissions={[]}>
                        <ExternalHotelRoomList/>
                    </PrivateRoute>
                }
          />
          <Route
              path="external-management/pool-management/forecast"
                element={
                    <PrivateRoute permissions={[]}>
                        <ExternalForecast/>
                    </PrivateRoute>
                }
          />

          <Route
              path="external-management/hotels/:hotelId/condition-groups"
                element={
                    <PrivateRoute permissions={[]}>
                        <ExternalHotelConditionGroupList/>
                    </PrivateRoute>
                }
          />

          <Route
              path="external-management/customers"
                element={
                    <PrivateRoute permissions={['crm.customers.view']}>
                        <ExternalCustomerList/>
                    </PrivateRoute>
                }
          />
          <Route
              path="external-management/offers"
                element={
                    <PrivateRoute permissions={['crm.offers.view']}>
                        <ExternalOfferList/>
                    </PrivateRoute>
                }
          />

          <Route
              path="external-management/hotels/:hotelId/condition-groups/:conditionGroupId/conditions"
                element={
                    <PrivateRoute permissions={[]}>
                        <ExternalHotelConditionList/>
                    </PrivateRoute>
                }
          />

          <Route
              path="external-management/hotels/:hotelId/child-ages"
                element={
                    <PrivateRoute permissions={[]}>
                        <ExternalHotelChildAgeList/>
                    </PrivateRoute>
                }
          />

          <Route
              path="external-management/hotels/:hotelId/periods"
                element={
                    <PrivateRoute permissions={[]}>
                        <ExternalHotelPeriodList/>
                    </PrivateRoute>
                }
          />

          <Route
              path="external-management/hotels/:hotelId/periods/:periodId/dates"
                element={
                    <PrivateRoute permissions={[]}>
                        <ExternalHotelPeriodDateList/>
                    </PrivateRoute>
                }
          />

          <Route
              path="external-management/hotels/:hotelId/concepts"
                element={
                    <PrivateRoute permissions={[]}>
                        <ExternalHotelConceptList/>
                    </PrivateRoute>
                }
          />

          <Route
              path={`/contract-hotels/:hotelId/contract-groups/:contractGroupId/commissions`}
                element={
                    <PrivateRoute permissions={[]}>
                        <ContractCommissions/>
                    </PrivateRoute>
                }
          />

          <Route
              path="external-management/pool-management"
                element={
                    <PrivateRoute permissions={[]}>
                        <PoolHotelList/>
                    </PrivateRoute>
                }
          />

          <Route
              path="external-management/pool-management/operators"
                element={
                    <PrivateRoute permissions={[]}>
                        <PoolOperatorList/>
                    </PrivateRoute>
                }
          />

          <Route
              path="external-management/pool-management/banks"
                element={
                    <PrivateRoute permissions={[]}>
                        <PoolBankList/>
                    </PrivateRoute>
                }
          />

          <Route
              path="external-management/contract-hotels"
                element={
                    <PrivateRoute permissions={[]}>
                        <ExternalContractHotels/>
                    </PrivateRoute>
                }
          />

          <Route
              path="reports/sale-report"
                element={
                    <PrivateRoute permissions={['reports.sale_report.view']}>
                        <SaleReports/>
                    </PrivateRoute>
                }
          />

          <Route
              path="reports/profit-report"
                element={
                    <PrivateRoute permissions={['reports.profit_report.view']}>
                        <ProfitReport />
                    </PrivateRoute>
                }
          />

          <Route
              path="operation/reservation-forecast"
                element={
                    <PrivateRoute permissions={['operations.reservation-forecast.view']}>
                        <ReservationForecast />
                    </PrivateRoute>
                }
          />

          <Route
              path="agent-calls"
                element={
                    <PrivateRoute permissions={['agent.call.view']}>
                        <UserCallList/>
                    </PrivateRoute>
                }
          />
          <Route
              path="providers"
                element={
                    <PrivateRoute permissions={['reservation_providers.view']}>
                        <ReservationProvidersList/>
                    </PrivateRoute>
                }
          />

          <Route
              path="agent-calls/reports"
                element={
                    <PrivateRoute permissions={['agent.call.view']}>
                        <UserCallReport/>
                    </PrivateRoute>
                }
          />

          <Route
              path="provider-reservations"
                element={
                    <PrivateRoute permissions={['provider_reservations.view']}>
                        <ProviderReservations/>
                    </PrivateRoute>
                }
          />
          <Route
              path="provider-reservations/create"
                element={
                    <PrivateRoute permissions={['provider_reservations.create']}>
                        <ProviderReservationCreate/>
                    </PrivateRoute>
                }
          />

          <Route
              path="additional-services"
                element={
                    <PrivateRoute permissions={[]}>
                        <AdditionalServices/>
                    </PrivateRoute>
                }
          />

          <Route
              path="external-management/hotels/:id/list"
                element={
                    <PrivateRoute permissions={[]}>
                        <ExternalHotelDetails/>
                    </PrivateRoute>
                }
          />

          <Route
              path="external-management/hotels/create"
                element={
                    <PrivateRoute permissions={[]}>
                        <ExternalHotelCreate/>
                    </PrivateRoute>
                }
          />
          <Route
              path="/external-management/definitions/hotel-cards"
                element={
                    <PrivateRoute permissions={[]}>
                        <ExternalCardsDefinition/>
                    </PrivateRoute>
                }
          />
          <Route
              path="/external-management/definitions/properties"
                element={
                    <PrivateRoute permissions={[]}>
                        <ExternalPropertyDefinition/>
                    </PrivateRoute>
                }
          />
          <Route
              path="/external-management/definitions/descriptions"
                element={
                    <PrivateRoute permissions={[]}>
                        <ExternalDescriptionDefinition/>
                    </PrivateRoute>
                }
          />
          <Route
              path="/external-management/definitions/popular-facilities"
                element={
                    <PrivateRoute permissions={[]}>
                        <ExternalPopularFacilityDefinition/>
                    </PrivateRoute>
                }
          />
          <Route
              path="/external-management/definitions/countries"
              element={
                  <PrivateRoute permissions={[]}>
                      <Countries />
                  </PrivateRoute>
              }
          />
          <Route
              path="/external-management/definitions/main-regions"
              element={
                  <PrivateRoute permissions={[]}>
                      <MainRegion />
                  </PrivateRoute>
              }
          />
          <Route
              path="/external-management/definitions/cities"
              element={
                  <PrivateRoute permissions={[]}>
                      <Cities />
                  </PrivateRoute>
              }
          />
          <Route
              path="/external-management/definitions/regions"
                element={
                    <PrivateRoute permissions={[]}>
                        <Regions />
                    </PrivateRoute>
                }
          />
        <Route
            path="/package-reservations"
            element={
                <PrivateRoute permissions={['package_reservations.view']}>
                    <PackageReservations />
                </PrivateRoute>
            }
        />

        <Route
          path="/"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />

        <Route
          path="/events"
          element={
            <PrivateRoute permissions={['hotel.events.view']}>
              <HotelEventList />
            </PrivateRoute>
          }
        />

        <Route
          path="/discounts"
          element={
            <PrivateRoute permissions={['hotel.discounts.view']}>
              <Discounts />
            </PrivateRoute>
          }
        />

        <Route
          path="/night-discounts"
          element={
            <PrivateRoute permissions={['hotel.night-discounts.view']}>
              <NightDiscounts />
            </PrivateRoute>
          }
        />
        <Route
          path="/mobile-discounts"
          element={
            <PrivateRoute permissions={['hotel.mobile-discounts.view']}>
              <MobileDiscounts />
            </PrivateRoute>
          }
        />
        <Route
          path="/early-reservation-discounts"
          element={
            <PrivateRoute permissions={['hotel.early-reservation-discounts.view']}>
              <EarlyReservationDiscounts />
            </PrivateRoute>
          }
        />
        <Route
          path="/system-logs"
          element={
            <PrivateRoute permissions={['system.log.folders.view','system.log.file.view']}>
              <SystemLog />
            </PrivateRoute>
          }
        />
        <Route
          path="/action-discounts"
          element={
            <PrivateRoute permissions={['hotel.action-discounts.view']}>
              <ActionDiscounts />
            </PrivateRoute>
          }
        />

        <Route
          path="/hotels/:hotelId/room-definitions"
          element={
            <PrivateRoute>
              <RoomDefinitionList />
            </PrivateRoute>
          }
        />

        <Route
          path="/hotels/:hotelId/stats"
          element={
            <PrivateRoute>
              <HotelStats />
            </PrivateRoute>
          }
        />

        <Route
          path="/forecast"
          element={
            <PrivateRoute permissions={['hotel.forecast.view']}>
              <Forecast />
            </PrivateRoute>
          }
        />

        <Route
          path="/virtual-pos-transactions"
          element={
            <PrivateRoute permissions={['accounting.virtual-pos-transactions.view']}>
              <VirtualPosTransactions />
            </PrivateRoute>
          }
        />

        <Route
          path="/transfer-transactions"
          element={
            <PrivateRoute permissions={[]}>
              <TransferTransactions />
            </PrivateRoute>
          }
        />

          <Route
              path="/channel-management/hotels"
                element={
                    <PrivateRoute permissions={[]}>
                        <CmHotelList />
                    </PrivateRoute>
                }
          />

          {/*<Route
              path="/channel-management/forecast"
                element={
                    <PrivateRoute permissions={[]}>
                        <CMForecast />
                    </PrivateRoute>
                }
          />*/}

          <Route
              path="/countries"
                element={
                    <PrivateRoute permissions={[]}>
                        <Countries />
                    </PrivateRoute>
                }
          />
          <Route
              path="/cities"
                element={
                    <PrivateRoute permissions={[]}>
                        <Cities />
                    </PrivateRoute>
                }
          />
          <Route
              path="/regions"
                element={
                    <PrivateRoute permissions={[]}>
                        <Regions />
                    </PrivateRoute>
                }
          />
          <Route
              path="/airports"
                element={
                    <PrivateRoute permissions={[]}>
                        <Airports />
                    </PrivateRoute>
                }
          />
          <Route
              path="/flights"
                element={
                    <PrivateRoute permissions={[]}>
                        <Flights />
                    </PrivateRoute>
                }
          />

          <Route
              path="/channel-management/reservations"
                element={
                    <PrivateRoute permissions={[]}>
                        <CmReservations />
                    </PrivateRoute>
                }
          />

          <Route
              path="/channel-management/hotels/:hotelId/periods"
                element={
                    <PrivateRoute permissions={[]}>
                        <CMPeriodList />
                    </PrivateRoute>
                }
          />

          <Route
              path="/channel-management/contract-hotels"
                element={
                    <PrivateRoute permissions={[]}>
                        <CMContractHotelList />
                    </PrivateRoute>
                }
          />

          <Route
              path="/channel-management/contract-hotels/:hotelId/contract-groups"
                element={
                    <PrivateRoute permissions={[]}>
                        <CMContractGroupList />
                    </PrivateRoute>
                }
          />

          <Route
              path="/channel-management/contract-hotels/:hotelId/contract-groups/:contractGroupId/contracts"
                element={
                    <PrivateRoute permissions={[]}>
                        <CMContractList />
                    </PrivateRoute>
                }
          />

          <Route
              path="/channel-management/contract-hotels/:hotelId/contract-groups/:contractGroupId/contracts/:contractId/prices"
                element={
                    <PrivateRoute permissions={[]}>
                        <CMContractPrices />
                    </PrivateRoute>
                }
          />

          <Route
              path="/channel-management/hotels/:hotelId/periods/:periodId/dates"
                element={
                    <PrivateRoute permissions={[]}>
                        <CMPeriodDateList />
                    </PrivateRoute>
                }
          />

          <Route
              path="/channel-management/hotels/:hotelId/rooms"
                element={
                    <PrivateRoute permissions={[]}>
                        <CmRoomList />
                    </PrivateRoute>
                }
          />

          <Route
              path="/channel-management/hotels/:hotelId/child-ages"
                element={
                    <PrivateRoute permissions={[]}>
                        <CMChildAgeList />
                    </PrivateRoute>
                }
          />
          <Route
              path="/channel-management/hotels/:hotelId/condition-groups"
                element={
                    <PrivateRoute permissions={[]}>
                        <CMConditionGroupList />
                    </PrivateRoute>
                }
          />

          <Route
              path="/channel-management/hotels/:hotelId/concepts"
                element={
                    <PrivateRoute permissions={[]}>
                        <CmConceptList />
                    </PrivateRoute>
                }
          />

          <Route
              path="/channel-management/hotels/:hotelId/condition-groups/:conditionGroupId/conditions"
                element={
                    <PrivateRoute permissions={[]}>
                        <CMConditionList />
                    </PrivateRoute>
                }
          />



        <Route
          path="/icons"
          element={
            <PrivateRoute>
              <Icons />
            </PrivateRoute>
          }
        />

        <Route
          path="/hotel-room/match/:hotelId"
          element={
            <PrivateRoute>
              <HotelRoomMatch />
            </PrivateRoute>
          }
        />
        <Route
          path="/hotel-settings/:hotelId"
          element={
            <PrivateRoute>
              <HotelSettings />
            </PrivateRoute>
          }
        />
        <Route
          path="/hotels"
          element={
            <PrivateRoute permissions={['hotel.view']}>
              <HotelList />
            </PrivateRoute>
          }
        />

        <Route
          path="/hotels/create"
          element={
            <PrivateRoute>
              <HotelCreate />
            </PrivateRoute>
          }
        />

        <Route
          path="/hotels/:hotelId"
          element={
            <PrivateRoute>
              <HotelEdit />
            </PrivateRoute>
          }
        />
        <Route
          path="/hotels/:hotelId/rooms/:roomId"
          element={
            <PrivateRoute>
              <RoomEdit />
            </PrivateRoute>
          }
        />

        <Route
          path="hotels/:hotelId/concepts"
          element={
            <PrivateRoute>
              <Concept />
            </PrivateRoute>
          }
        />

        <Route
          path="/condition-groups"
          element={
            <PrivateRoute permissions={['hotel.condition-groups.view']}>
              <ConditionGroup />
            </PrivateRoute>
          }
        />

        <Route
          path="/condition-groups/:conditionGroupId"
          element={
            <PrivateRoute>
              <Conditions />
            </PrivateRoute>
          }
        />

        <Route
          path="/child-ages"
          element={
            <PrivateRoute permissions={['hotel.child-ages.view']}>
              <ChildAges />
            </PrivateRoute>
          }
        />

        <Route
          path="/periods"
          element={
            <PrivateRoute permissions={['hotel.periods.view']}>
              <Period />
            </PrivateRoute>
          }
        />

        <Route
          path="/periods/:periodId/dates"
          element={
            <PrivateRoute>
              <PeriodDate />
            </PrivateRoute>
          }
        />

        <Route
          path="/contract-hotels"
          element={
            <PrivateRoute permissions={['contract.group.view']}>
              <ContractGroup />
            </PrivateRoute>
          }
        />
        <Route
          path="/contract-hotels/:hotelId/contract-groups"
          element={
            <PrivateRoute>
              <ContractGroups />
            </PrivateRoute>
          }
        />
        <Route
          path="external-management/contract-hotels/:hotelId/contract-groups"
          element={
            <PrivateRoute>
              <ExternalContractGroups />
            </PrivateRoute>
          }
        />
        <Route
          path="external-management/contract-hotels/:hotelId/contract-groups/:contractGroupId/contracts"
          element={
            <PrivateRoute>
              <ExternalContracts />
            </PrivateRoute>
          }
        />

          <Route
              path="external-management/contract-hotels/:hotelId/contract-groups/:contractGroupId/contracts/:contractId/details"
              element={
                  <PrivateRoute>
                      <ExternalContractDetails />
                  </PrivateRoute>
              }
          />
        <Route
          path="/contract-hotels/:hotelId/contract-groups/:contractGroupId/contracts"
          element={
            <PrivateRoute>
              <Contract />
            </PrivateRoute>
          }
        />
        <Route
          path="/contract-hotels/:hotelId/contract-groups/:contractGroupId/contracts/:contractId/details"
          element={
            <PrivateRoute>
              <ContractDetails />
            </PrivateRoute>
          }
        />

        <Route
          path="/contract-hotels/:hotelId/contract-groups/:contractGroupId/contracts/:contractId/details/days"
          element={
            <PrivateRoute>
              <ContractDays />
            </PrivateRoute>
          }
        />

        <Route
          path="/photos"
          element={
            <PrivateRoute permissions={['hotel.image.view']}>
              <Photos />
            </PrivateRoute>
          }
        />

        <Route
          path="/widgets"
          element={
            <PrivateRoute permissions={['widgets.view']}>
              <Widgets />
            </PrivateRoute>
          }
        />

        <Route
          path="/reservations"
          element={
            <PrivateRoute permissions={['reservations.view']}>
              <Reservations />
            </PrivateRoute>
          }
        />

        <Route
          path="/temp-reservations"
          element={
            <PrivateRoute>
              <TempReservations />
            </PrivateRoute>
          }
        />

        <Route
          path="/deferred-reservations"
          element={
            <PrivateRoute permissions={['deferred.reservation.view']}>
              <DeferredReservations />
            </PrivateRoute>
          }
        />
        <Route
          path="/accounting-reservations"
          element={
            <PrivateRoute permissions={['accounting.accounting-reservations.view']}>
              <AccountingReservations />
            </PrivateRoute>
          }
        />

        <Route
          path="/new-reservation"
          element={
            <PrivateRoute permissions={['reservation.create']}>
              <ReservationCreate />
            </PrivateRoute>
          }
        />

        <Route
          path="/users"
          element={
            <PrivateRoute permissions={['users.view']}>
              <Users />
            </PrivateRoute>
          }
        />

        <Route
          path="/targets"
          element={
            <PrivateRoute permissions={['targets.view']}>
              <Targets />
            </PrivateRoute>
          }
        />

        <Route
          path="/targets/:targetId"
          element={
            <PrivateRoute permissions={['target_progress.view']}>
              <TargetUserProgress />
            </PrivateRoute>
          }
        />

        <Route
          path="/site-management"
          element={
            <PrivateRoute permissions={['site-management.view']}>
              <SiteList />
            </PrivateRoute>
          }
        />

        <Route
          path="/site-management/:hotelId"
          element={
            <PrivateRoute>
              <SiteSettings />
            </PrivateRoute>
          }
        />

        <Route
          path="/site-management/:hotelId/header/:headerId"
          element={
            <PrivateRoute>
              <HeaderSettings />
            </PrivateRoute>
          }
        />

        <Route
          path="/site-management/:hotelId/footer/:footerId"
          element={
            <PrivateRoute>
              <FooterSettings />
            </PrivateRoute>
          }
        />

        <Route
          path="/site-management/:hotelId/page/:pageId"
          element={
            <PrivateRoute>
              <PageSettings />
            </PrivateRoute>
          }
        />

        <Route
          path="/site-management/:hotelId/category/:categoryId"
          element={
            <PrivateRoute>
              <CategorySettings />
            </PrivateRoute>
          }
        />

        <Route
          path="/reservation-card/:reservationId"
          element={
            <PrivateRoute>
              <ReservationCard />
            </PrivateRoute>
          }
        />
          <Route
              path="/provider-reservation-card/:reservationId"
              element={
                  <PrivateRoute>
                      <ProviderReservationCard />
                  </PrivateRoute>
              }
          />
        <Route
          path="/safe-bank"
          element={
            <PrivateRoute permissions={['definitions.safe-bank.view']}>
              <SafeBank />
            </PrivateRoute>
          }
        />
        <Route
          path="/safe-bank-transactions"
          element={
            <PrivateRoute permissions={['safe_bank_cari_transactions.view']}>
              <SafeBankTransactions />
            </PrivateRoute>
          }
        />

        <Route
          path="/safe-bank-transactions/:safeBankDefinitionId"
          element={
            <PrivateRoute permissions={['safe_bank_cari_transactions.view']}>
              <SafeBankTransactionsDetail />
            </PrivateRoute>
          }
        />

        <Route
          path="/reservation-card/:reservationId/voucher"
          element={
            <PrivateRoute>
              <Voucher />
            </PrivateRoute>
          }
        />
        <Route
          path="/reservation-card/:reservationId/reservation-form"
          element={
            <PrivateRoute>
              <ReservationForm />
            </PrivateRoute>
          }
        />
        <Route path="/login" element={<Login />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route
          path="/login-exit"
          element={
            <PrivateRoute permissions={['operation.login-exit.view']}>
              <LoginExit />
            </PrivateRoute>
          }
        />
        <Route
          path="/confirme"
          element={
            <PrivateRoute permissions={['operation.confirm.view']}>
              <Confirme />
            </PrivateRoute>
          }
        />
        <Route
          path="/current-deficit"
          element={
            <PrivateRoute permissions={['accounting.current-deficit.view']}>
              <CurrentDeficit />
            </PrivateRoute>
          }
        />
        <Route
          path="/current-deficit-transactions"
          element={
            <PrivateRoute permissions={[]}>
              <CurrentDeficitTransactions />
            </PrivateRoute>
          }
        />
        <Route
          path="/current-deficit-transactions/:currentDeficitId/detail"
          element={
            <PrivateRoute permissions={[]}>
              <CurrentDeficitTransactionDetails />
            </PrivateRoute>
          }
        />

          <Route path="*" element={<div style={{
              width: '100%',
              height: '100vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
          }}>
              <Result
                  status="404"
                  title="404"
                  subTitle="Üzgünüz, ziyaret ettiğiniz sayfa mevcut değil."
                  extra={<Link to="/">Geri Dön</Link>}
              />
          </div>} />
      </Routes>
    </Layout>
  );
};

export default App;
