import React from 'react';
import {Button, Col, Form, InputNumber, Modal, Row, Select, Space} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";

const {Option} = Select;

const HotelPaymentOptionsModalForm = (props) => {
    return (
        <Modal visible={props.isVisible} title={props.title} closable={false} onOk={() => props.form.submit()} onCancel={props.onCancel}>
            <Form form={props.form} layout="vertical" onFinish={props.onFinish}>
                <Row gutter={[16,16]}>
                    <Col span={24}>
                        <Form.List
                            name={['installments']}
                        >
                            {(fields, { add, remove }, { errors }) => (
                                <>
                                    {fields.map((field, index) => (
                                        <Form.Item
                                            label={index === 0 ? 'Alt Limit' : ''}
                                            required={false}
                                            key={field.key}
                                        >
                                            <Space direction="horizontal">
                                                <Form.Item
                                                    name={[field.name, 'limit']}
                                                    validateTrigger={['onChange', 'onBlur']}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Lütfen ücret alt limiti giriniz.",
                                                        }
                                                    ]}
                                                    noStyle
                                                >
                                                    <InputNumber
                                                        placeholder="Ücret alt limiti"
                                                        min={0}
                                                        style={{
                                                            width:"100%"
                                                        }}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    name={[field.name, 'min_installment']}
                                                    validateTrigger={['onChange', 'onBlur']}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Lütfen minimum taksit sayısı giriniz.",
                                                        }
                                                    ]}
                                                    noStyle
                                                >
                                                    <InputNumber
                                                        min={1}
                                                        style={{
                                                            width:"100%"
                                                        }}
                                                        placeholder="Minimum taksit sayısı"
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    name={[field.name, 'max_installment']}
                                                    validateTrigger={['onChange', 'onBlur']}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Lütfen maksimum taksit sayısı giriniz.",
                                                        },
                                                        ({ getFieldValue }) => ({
                                                            validator(_, value) {
                                                                if (!value || getFieldValue(['installments', index, 'min_installment']) <= value) {
                                                                    return Promise.resolve();
                                                                }
                                                                return Promise.reject(new Error('Maksimum taksit sayısı minimum taksit sayısından küçük olamaz.'));
                                                            }
                                                        })
                                                    ]}
                                                    noStyle
                                                >
                                                    <InputNumber
                                                        min={1}
                                                        style={{
                                                            width:"100%"
                                                        }}
                                                        placeholder="Maksimum taksit sayısı"
                                                    />
                                                </Form.Item>
                                                <Button
                                                    icon={<FontAwesomeIcon icon={faMinus}/>}
                                                    type="dashed"
                                                    onClick={() => remove(field.name)}
                                                />
                                            </Space>
                                        </Form.Item>
                                    ))}
                                    <Form.Item>
                                        <Button
                                            type="dashed"
                                            onClick={() => add()}
                                            style={{
                                                width: '100%',
                                            }}
                                            icon={<FontAwesomeIcon icon={faPlus}/>}
                                        >
                                            Taksit Seçeneği Ekle
                                        </Button>
                                        <Form.ErrorList errors={errors} />
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="day_limit"
                            label="Gün Limiti"
                            tooltip="Havale/EFT ile ödeme işlemi için gün limiti. Rezervasyon tarihi ile giriş tarihi arasındaki gün sayısı."
                        >
                            <InputNumber
                                min={1}
                                style={{width:"100%"}}
                                placeholder="Gün Limiti / Varsayılan: 5"
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default HotelPaymentOptionsModalForm;
