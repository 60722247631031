import React from 'react';
import {Col, DatePicker, Form, Modal, Row} from "antd";
import {useTranslation} from "react-i18next";

const {RangePicker} = DatePicker;

const EditPeriodModalForm = (props) => {
    const { t } = useTranslation()
    return (
        <Modal visible={props.isVisible} title={props.title} closable={false} onCancel={props.onCancel} onOk={() => props.form.submit()}>
            <Form form={props.form} layout="vertical" onFinish={(values) => props.onFinish(values, props.data?.id)}>
                <Row>
                    <Col span={24}>
                        <Form.Item name="period" label={t("general.period")}>
                            <RangePicker
                                format="DD.MM.YYYY"
                                style={{width: "100%"}}
                                placeholder={[t("general.start_date"), t("general.end_date")]}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default EditPeriodModalForm;