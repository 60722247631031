import {Form, Modal, Select} from "antd";
import {useEffect, useState} from "react";

const {Option} = Select

function PageGroupModalForm(props){
    useEffect(() => {
        props.form.setFieldsValue({
            parent_id: props?.initialValues?.parent_id
        })
    },[props.initialValues])
    return(
        <Modal title={props.title} visible={props.isVisible} onOk={() => props.form.submit()} onCancel={props.onCancel}>
            <Form form={props.form} layout="vertical" onFinish={props.onFinish}>
                <Form.Item label="Sayfa Grubu" name="parent_id">
                    <Select placeholder="Grup tanımlamak için sayfa seçiniz!">
                        <Option value={null}>Seçim yok</Option>
                        {props?.pages.map((page,i)=>(
                            <Option key={i} value={page.id}>{page.name}</Option>
                        ))}
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    )
}
export default PageGroupModalForm;