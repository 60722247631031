import React from 'react';
import {Col, Form, Input, Modal, Row, Select} from "antd";

const { Option } = Select;

const CmContractGroupCreateModalForm = (props) => {
    return (
        <Modal visible={props.isVisible} title={props.title} closable={false} onOk={() => props.form.submit()} onCancel={props.onCancel}>
            <Form form={props.form} layout="vertical" onFinish={(values) => props.onFinish(values, props.data?.id)} initialValues={{ is_active: true }} fields={props.data?.fields}>
                <Row gutter={[16,0]}>
                    <Col span={12}>
                        <Form.Item
                            name="title"
                            label="Kontrat Grup Adı"
                            rules={[{ required: true, message: 'Lütfen kontrat grup adını giriniz.' }]}
                        >
                            <Input placeholder="Kontrat grup adını giriniz"/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="channel"
                            label="Kanal"
                        >
                            <Select placeholder="Kanal seçimi yapınız">
                                <Option key={1} value={1}>İşlem Yapılacak</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="operator"
                            label="Operatör"
                        >
                            <Select placeholder="Operatör seçimi yapınız">
                                <Option key={1} value={1}>İşlem Yapılacak</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default CmContractGroupCreateModalForm;