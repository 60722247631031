import React from 'react';
import {Col, Form, Input, Modal, Row, Tabs} from 'antd';

const CategoryModalForm = (props) => {
    return (
        <Modal visible={props.isVisible} title={props.title} closable={false} onCancel={props.onCancel} onOk={() => props.form.submit()}>
            <Form form={props.form} layout="vertical" onFinish={(values) => props.onFinish(values, props.data?.id)}>
                <Tabs>
                    {
                        props?.languages.map((lang)=>(
                            <Tabs.TabPane
                                key={lang.code}
                                tab={lang.native_name}
                            >
                                <Row gutter={[16,16]}>
                                    <Col span={24}>
                                        <Form.Item
                                            name={['categories', lang.code, 'name']}
                                            label="Kategori Adı"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Lütfen kategori adı giriniz.',
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Kategori adı giriniz"/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            name={['categories', lang.code, 'href']}
                                            label="Bağlantı Linki"
                                            rules={[{
                                                pattern:"^[A-Za-z0-9]+$",
                                                message:"Bağlantı özel karakter içeremez!"
                                            },{
                                                required:true,
                                                message:"Bağlantı girilmedi!"
                                            }]}
                                        >
                                            <Input addonBefore="category/" placeholder="Bağlantı adresi giriniz."/>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Tabs.TabPane>
                        ))
                    }
                </Tabs>
            </Form>
        </Modal>
    );
};

export default CategoryModalForm;