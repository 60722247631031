import axios from "../axios";

const HotelPaymentOptionService = {
    async create(formData,hotelId) {
        return await axios.post(`/v1/hotels/${hotelId}/payment-options`,formData);
    },
    async show(hotelId){
        return await axios.get(`/v1/hotels/${hotelId}/payment-options`);
    }
};

export default HotelPaymentOptionService;