import React from "react";
import {Col, Collapse, Form, Input, Modal, Row, Select} from "antd";

const {Option} = Select;
const {Panel} = Collapse;

function ContractPosMatchModalForm(props){
    return(
        <Modal visible={props.isVisible} title={props.title} closable={false} onOk={() => props.form.submit()} onCancel={() => props.onCancel()}>
            <Form form={props.form} layout="vertical" onFinish={(values) => props.onFinish(values, props?.selectedContract?.id)} fields={props.data?.fields}>
                <Collapse defaultActiveKey={['0']}>
                    {props.selectedContract?.concepts?.map((concept,concept_index) => (
                        <Panel header={concept?.concept?.active_language?.name} key={concept_index}>
                            <Row gutter={[16,16]}>
                                <Form.Item
                                    name={['concepts', concept_index, 'concept_id']}
                                    hidden
                                >
                                    <Input/>
                                </Form.Item>
                                <Col span={24}>
                                    <Form.Item
                                        name={['concepts', concept_index, 'payment_types']}
                                        rules={[{
                                            required: true,
                                            message: "Lütfen ödeme yöntemi seçimi yapınız."
                                        }]}
                                        label="Ödeme Yöntemleri">
                                        <Select
                                            mode="multiple"
                                            placeholder="Ödeme yönetimi seçimi yapınız."
                                        >
                                            {props.paymentTypes?.filter(value => value.is_system_payment)?.map((pos)=>(
                                                <Option key={pos.id} value={pos.id}>{pos.name}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Panel>
                    ))}
                </Collapse>
            </Form>
        </Modal>
    )
}
export default ContractPosMatchModalForm;