import React, { useEffect } from 'react';
import { Modal, Form, Input, Tabs } from 'antd';
import { useSelector } from 'react-redux';

const { TabPane } = Tabs;

const RoomDefinitionModalForm = (props) => {
  return (
    <Modal visible={props.isVisible} title={props.title} closable={false} onOk={() => props.form.submit()} onCancel={props.onCancel}>
      <Form form={props.form} layout="vertical" onFinish={(values) => props.onFinish(values, props.data?.id)} fields={props.data?.fields}>
        <Tabs>
          {console.log(props)}
          {props.languages?.map((language, index) => (
            <TabPane tab={language.web_language.name} key={index} forceRender>
              <Form.Item
                  name={["room_definitions", language.web_language["iso_639-1"], 'name']}
                  label="Oda Adı"
                  rules={[{ required: true, message: 'Lütfen oda adı giriniz!' }]}
              >
                <Input placeholder="Oda Adı" />
              </Form.Item>
            </TabPane>
          ))}
        </Tabs>
      </Form>
    </Modal>
  );
};

export default RoomDefinitionModalForm;