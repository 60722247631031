import React, { useEffect } from 'react';
import {Modal, Form, Input, Tabs, Select, Row, Col, DatePicker} from 'antd';

const { TabPane } = Tabs;
const {Option} = Select;
const current = new Date();
const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
const UserEditModalForm = (props) => {
    return (
        <Modal visible={props.isVisible} title={props.title} closable={false} onOk={() => props.form.submit()} onCancel={props.onCancel}>
            <Form form={props.form} layout="vertical" onFinish={(values) => props.onFinish(values, props.data?.id)} fields={props.data?.fields}>
               <Row gutter={[16]}>
                <Col span={24}>
                <Form.Item rules={[
                       {
                           required: true,
                           message: 'Bu kısım zorunludur!',
                       },
                   ]} name="hotel" label="Otel">
                       <Select placeholder="Otel Seçiniz" mode="multiple">
                           {
                            props?.hotels.map((hotel,index)=>(
                                <Option key={index} value={hotel.id} >{hotel.name}</Option>
                            ))
                           }
                       </Select>
                   </Form.Item>
                </Col>
              </Row>
            </Form>
        </Modal>
    );
};

export default UserEditModalForm;