import axios from '../axios';

const AirportService = {
    async get(){
        return await axios.get(`/v1/definitions/airports`);
    },
    async create(formData) {
        return await axios.post(`/v1/definitions/airports`, formData);
    },
    async delete(id) {
        return await axios.delete(`/v1/definitions/airports/${id}`);
    },
};

export default AirportService;