import axios from '../axios';

const MainRegionService = {
    async get(id){
        return await axios.get(`/v1/definitions/main-regions?country_id=${id}`);
    },
    async getPaginate(page) {
        return await axios.get(`/v1/definitions/main-regions?page=${page}`);
    },
    async create(formData) {
        return await axios.post(`/v1/definitions/main-regions`, formData);
    },
    async delete(id) {
        return await axios.delete(`/v1/definitions/main-regions/${id}`);
    },
};

export default MainRegionService;