import React, { useState, useEffect, useMemo } from 'react';
import {Modal, Form, Input, Row, Col, Select, notification} from 'antd';
import {useParams} from "react-router-dom";
import ExternalCardService from "@services/external/ExternalCard.service";
import ExternalBgService from "../../../services/external/ExternalBg.service";

const { Option } = Select;
const BgMatchModalForm = (props) => {


    const params = useParams();

    
    useEffect(() => {
        if(props.selectedHotel>0){
            getBgMatch();
        }
    }, [props.selectedHotel]);

    const getBgMatch = async () => {

        try {
            let {data: response} = await ExternalBgService.get(props.selectedHotel);
            console.log(response)
            props.form.setFieldsValue({
                bg_id:response.data.bg_id
            })
        }
        catch (e){
            if(e.response){
                notification.error({
                    message: 'Hata',
                    description: e.response.data.message
                })
            }
        }

    }



    return (
        <Modal visible={props.isVisible} title={props.title} closable={false} onOk={() => props.form.submit()} onCancel={props.onCancel}>
            <Form form={props.form} layout="vertical" onFinish={(values) => props.onFinish(values, props.data?.name)}>
                <Row gutter={[16]}>
                    <Col span={24}>
                        <Form.Item name="bg_id" label="BG Id">
                        <Input placeholder="BG Id" />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal >
    )
};

export default BgMatchModalForm;
