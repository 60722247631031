import React, { useState, useEffect } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {Layout, Breadcrumb, Button, Form, notification, Col, Row, Skeleton, InputNumber,} from 'antd';
import {useParams} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faHouse, faPerson, faPeopleGroup, faSave, faClipboardCheck} from '@fortawesome/free-solid-svg-icons';
import { HotelService,HotelRoomService } from '@services';
import Wrapper from '@components/Wrapper';

const { Content } = Layout;

const RoomDefinitionList = () => {
    const params = useParams();
    const [roomForm] = Form.useForm();
    const [rooms,setRooms] = useState()
    const [roomDescription,setRoomDescription] = useState()
    const [loading, setLoading] = useState(true);
    const getRooms = async (hotelId) => {
        try {
            let { data: response } = await HotelService.getRooms(params.hotelId);
            let temp = [];
            response.data.map((item) => {
                if (item.id == params.roomId){
                    temp.push({
                        id: item.id,
                        name: item.room_definition.name,
                        room_definition_id: item.room_definition.id,
                        m2: item.m2,
                        min_people: item.min_people,
                        max_people: item.max_people,
                        quota: item.quota,
                        description:item.description
                    });
                }
            });

            setRoomDescription(temp[0].description)
            setRooms(temp[0]);
            setLoading(false);
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    };
    const handleUpdate = async (values) => {
        values = {...values,description:roomDescription}
        try {
            let { data: { data: response } } = await HotelRoomService.update(params.hotelId,params.roomId,values);
            setLoading(true);
            getRooms(params.hotelId)
            notification.success({
                message: 'Oda Güncellendi.'
            });
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    };
    useEffect(()=>{
        getRooms()
    },[])
    return (
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-20">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse} />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        Oda Düzenle
                    </Breadcrumb.Item>
                </Breadcrumb>

                <div className="site-layout-background">
                    {loading && <Skeleton />}
                    {!loading &&
                    <Form form={roomForm} onFinish={handleUpdate} layout={"vertical"} initialValues={{
                        min_people:rooms.min_people,
                        max_people:rooms.max_people,
                        m2:rooms.m2,
                        quota:rooms.quota,
                    }}>
                        <Row gutter={[16]}>
                            <Col span={24}>
                                <Form.Item name="min_people" label="Minimum Kişi Sayısı">
                                    <InputNumber placeholder="Kişi sayısı" min={"0"} addonBefore={<FontAwesomeIcon icon={faPerson}/>}/>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name="max_people" label="Maksimum Kişi Sayısı">
                                    <InputNumber placeholder="Kişi sayısı" min={"0"} addonBefore={<FontAwesomeIcon icon={faPeopleGroup}/>}/>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name="m2" label="Oda Boyutu (m²)">
                                    <InputNumber placeholder="m2" min={"0"} addonBefore={'M²'}/>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name="quota" label="Oda Kotası">
                                    <InputNumber placeholder="Kota sayısı" min={"0"} addonBefore={<FontAwesomeIcon icon={faClipboardCheck}/>}/>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name="description" label="Oda Özellikleri">
                                    <CKEditor
                                        editor={ ClassicEditor }
                                        data={roomDescription}
                                        onChange={ ( event, editor ) => {
                                            const data = editor.getData();
                                            setRoomDescription(data);
                                        } }
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                            <Button type="primary" htmlType="submit" block>
                                <FontAwesomeIcon icon={faSave} className="mr-5" />
                                Kaydet
                            </Button>
                            </Col>
                        </Row>
                    </Form>
                    }
                </div>
            </Content>
        </Wrapper>
    );
};

export default RoomDefinitionList;