import React, { useEffect, useState } from 'react';
import {notification, Select} from 'antd';
import { PeriodService } from '@services';

const { Option } = Select;

const PeriodSelect = ({ value = {},  onChange }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [periods, setPeriods] = useState({
        data: [],
        meta: {}
    });

    useEffect(() => {
        getPeriods();
    }, []);

    const getPeriods = async (page = 1) => {
        try {
            let { data: response } = await PeriodService.get(page);
            setPeriods({
                data: [...periods.data, ...response.data],
                meta: response.meta
            });
            setIsLoading(false);
        } catch (e) {
            console.log(e);
        }
    };

    const onScroll = (e) => {
        const { target } = e;

        if (! isLoading && periods.meta.last_page > periods.meta.current_page && (target.scrollTop + target.offsetHeight) >= (target.scrollHeight * 0.8)) {
            setIsLoading(true);
            getPeriods(periods.meta.current_page + 1);
        }
    };

    const handleChange = (value) => {
        if (onChange) {
            onChange(value);
        }
    };

    return (
        <Select value={value} dropdownRender={menu => <div onScrollCapture={onScroll}>{menu}</div>} onChange={handleChange} loading={isLoading}>
            {periods.data.map((period, index) => <Option key={index} value={period.id}>{period.title}</Option>)}
        </Select>
    );
};

export default PeriodSelect;