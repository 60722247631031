import React from 'react';
import { Modal, Form, Input, Row, Col } from 'antd';

const { TextArea } = Input;


const ConditionModalForm = (props) => {
  return (
    <Modal visible={props.isVisible} title={props.title} okText="Confirme Et" cancelText="İptal" closable={false} onOk={() => props.form.submit()} onCancel={props.onCancel}>
      <Form form={props.form} layout="vertical" onFinish={(values) => props.onFinish(values, props.data?.uuid)}>
        <Row gutter={[16,16]}>
          <Col span={24}>
            <Form.Item name="note" label="Confirme Notu">
              <TextArea placeholder="Confirme notunuzu giriniz." />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
};

export default ConditionModalForm;