import axios from '../axios';

const HotelSectionService = {
    async get(pageId) {
        return await axios.get(`/v1/site-manager/hotel-sections/${pageId}`);
    },
    async create(pageId,formData) {
        return await axios.post(`/v1/site-manager/hotel-sections/${pageId}`,formData);
    },
    async changeOrder(formData){
        return await axios.post(`/v1/site-manager/hotel-sections/order`,formData);
    }
};

export default HotelSectionService;