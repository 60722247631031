import React from "react";
import {Button} from "antd";
import {Authentication} from '@services'
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

const LogoutButton = () => {
    const navigate = useNavigate();
    const { t } = useTranslation()
    return (
        <Button type="primary" onClick={() => {Authentication.revokeToken();navigate('/login');}}>
            {t('general.logout')}
        </Button>
    );
};

export default LogoutButton;