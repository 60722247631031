import React, {useCallback, useEffect, useMemo} from 'react';
import {
    Alert,
    Breadcrumb,
    Button,
    Divider,
    Form,
    InputNumber,
    Layout,
    notification, Popover,
    Select,
    Space,
    Table,
    Tooltip
} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowDown, faCalendarDay,
    faCheck, faDoorOpen,
    faEdit,
    faExclamation,
    faHouse,
    faMinus, faPercent,
    faPlus, faRefresh, faXmark
} from "@fortawesome/free-solid-svg-icons";
import Wrapper from "@/components/Wrapper";
import {useParams} from "react-router-dom";
import {ContractService} from "@services";
import moment from "moment/moment";
import ImportPeriodModalForm from "@/views/new-version/contracts/components/ImportPeriodModalForm";
import EditPeriodModalForm from "@/views/new-version/contracts/components/EditPeriodModalForm";
import debounce from "lodash/debounce";

const {Content} = Layout;
const {Option} = Select;

const HOTEL_ID = 30;


const fixedQuotaRow = {
    id: "QUOTAS",
    adult: null,
    ages: [],
    child: null,
    prices: {}
}
const fixedPerPersonRow = {
    id: "PER-PERSON",
    adult: null,
    ages: [],
    child: null,
    prices: {}
}

const ContractEdit = () => {
    const [periodEditForm] = Form.useForm();
    const [isContractProcessing, setIsContractProcessing] = React.useState(false);
    const [contract, setContract] = React.useState({});
    const [isEditPeriodModalVisible, setIsEditPeriodModalVisible] = React.useState(false);
    const [prices, setPrices] = React.useState([]);
    const [hotel, setHotel] = React.useState({});
    const params = useParams();
    const [periods, setPeriods] = React.useState([]);
    const [selectedPeriod, setSelectedPeriod] = React.useState({});
    const [isLoading, setIsLoading] = React.useState(true);
    const changeStatusPeriod = (id) => {
        setPeriods(prev => {
            return prev.map((period) => {
                if (period.id === id) {
                    period.is_daily = !period.is_daily;
                }
                return period;
            })
        })
    }
    const onEditPeriod = (period) => {
        setSelectedPeriod(period);
        periodEditForm.setFieldsValue({
            period: [moment(period.start), moment(period.end)]
        })
        setIsEditPeriodModalVisible(true);
    }
    const onUpdatePeriod = (values, id) => {
        setPeriods(prev => {
            return prev.map((period) => {
                if (period.id === id) {
                    period.start = values.period[0].format('YYYY-MM-DD');
                    period.end = values.period[1].format('YYYY-MM-DD');
                }
                return period;
            })
        })
        setIsEditPeriodModalVisible(false);
    }
    const getDaysInRange = useMemo(() => (start, end) => {
        let startDate = moment(start);
        let endDate = moment(end);
        let dates = [];

        while (startDate <= endDate) {
            dates.push(startDate.format('YYYY-MM-DD'));
            startDate = startDate.add(1, 'days');
        }

        return dates;
    }, []);
    const addRow = () => {
        let nextId = prices.length + 1;
        setPrices((prevState) => {
            return [
                ...prevState,
                {
                    id: nextId,
                    adult: contract?.room?.min_adult,
                    child: contract?.room?.min_child,
                    ages: Array.from({length: contract?.room?.min_child}, (_, index) => "CHILD"),
                    prices: {}
                }
            ]
        })
    }
    const removeRow = (id) => {
        setPrices((prevState) => {
            return [
                ...prevState.filter((item) => item.id !== id)
            ]
        })
    }
    const isRemoveButtonDisabled = () => {
        let minRow = 1;
        if (contract?.calculating_policy === "multiply"){
            minRow = 2;
        }
        return prices.length === minRow
    }
    const priceCode = (adult, child, ages) => {
        return adult + child + ages.sort().join('')
    }
    const onPriceChange = useCallback((record, date, period, price, type, target = "adultPrice") => {
        let rowId = record.id;
        let start_date = period.start;
        let end_date = period.end;

        setPrices((prevState) => {
            const itemIndex = prevState.findIndex((item) => item.id === rowId);
            if (itemIndex === -1) return prevState;
            const updatedPrices = (type === "date" && date)
                ? {
                    ...prevState[itemIndex].prices,
                    [date]: {
                        ...prevState[itemIndex]?.prices?.[date],
                        [target]: price,
                    }
                }
                : (type === "period")
                    ? getDaysInRange(start_date, end_date).reduce((acc, date) => {
                        acc[date] = {
                            ...prevState[itemIndex]?.prices?.[date],
                            [target]: price,
                        };
                        return acc;
                    }, { ...prevState[itemIndex].prices })
                    : prevState[itemIndex].prices;
            const updatedItem = {
                ...prevState[itemIndex],
                prices: updatedPrices,
            };
            const updatedState = [...prevState];
            updatedState[itemIndex] = updatedItem;
            return updatedState;
        });
    }, [setPrices]);
    const onMinStayChange = (record, date, period, min_stay, type) => {
        let rowId = record.id;
        let start_date = period.start;
        let end_date = period.end;
        if (type === "date" && date){
            setPrices((prevState) => {
                return [
                    ...prevState.map((item) => {
                        if (item.id === rowId) {
                            return {
                                ...item,
                                min_stay: {
                                    ...item.min_stay,
                                    [date]: min_stay
                                }
                            }
                        }
                        return item
                    })
                ]
            });
        }
        if (type === "period"){
            setPrices((prevState) => {
                return [
                    ...prevState.map((item) => {
                        if (item.id === rowId) {
                            return {
                                ...item,
                                min_stay: {
                                    ...item.min_stay,
                                    ...getDaysInRange(start_date, end_date).reduce((acc, date) => {
                                        acc[date] = min_stay;
                                        return acc;
                                    }, {})
                                }
                            }
                        }
                        return item
                    })
                ]
            });
        }
    }
    const onQuotaChange = (record, date, period, quota, type) => {
        let rowId = record.id;
        let start_date = period.start;
        let end_date = period.end;
        if (type === "date" && date){
            setPrices((prevState) => {
                return [
                    ...prevState.map((item) => {
                        if (item.id === rowId) {
                            return {
                                ...item,
                                quotas: {
                                    ...item.quotas,
                                    [date]: quota
                                }
                            }
                        }
                        return item
                    })
                ]
            });
        }
        if (type === "period"){
            setPrices((prevState) => {
                return [
                    ...prevState.map((item) => {
                        if (item.id === rowId) {
                            return {
                                ...item,
                                quotas: {
                                    ...item.quotas,
                                    ...getDaysInRange(start_date, end_date).reduce((acc, date) => {
                                        acc[date] = quota;
                                        return acc;
                                    }, {})
                                }
                            }
                        }
                        return item
                    })
                ]
            });
        }
    }
    const onChildAgeChange = (value, record, index) => {
        setPrices((prevState) => {
            return [
                ...prevState.map((item) => {
                    const ages = item.ages.map((age, ageIndex) => {
                        if (ageIndex === index) {
                            return value
                        }
                        return age
                    })
                    if (item.id === record.id) {
                        return {
                            ...item,
                            ages: ages,
                        }
                    }
                    return item
                })
            ]
        })
    }
    const onChildChange = (value, record) => {
        if (value < contract?.room?.min_child || value > contract?.room?.max_child) {
            return;
        }
        setPrices((prevState) => {
            return [
                ...prevState.map((item) => {
                    if (item.id === record.id) {
                        return {
                            ...item,
                            child: value,
                            ages: Array.from({length: value}, (_, index) => "CHILD"),
                        }
                    }
                    return item
                })
            ]
        })
    }
    const onAdultChange = (value, record) => {
        if (value < contract?.room?.min_adult || value > contract?.room?.max_adult) {
            return;
        }
        setPrices((prevState) => {
            return [
                ...prevState.map((item) => {
                    if (item.id === record.id) {
                        return {
                            ...item,
                            adult: value,
                        }
                    }
                    return item
                })
            ]
        })
    }
    const sharedOnCell = (record, index) => {
        if ((index === 0 && record.id === "QUOTAS") || (index === 1 && record.id === "PER-PERSON")) {
            return { colSpan: 0 };
        }

        return {};
    };
    const columns = useMemo(() => [
        {
            title: <Tooltip title="Satır Ekle">
                <Button
                    type="dashed"
                    onClick={addRow}
                    icon={<FontAwesomeIcon icon={faPlus} style={{
                        fontSize: '10px',
                        marginLeft: '1px',
                    }}/>}
                    style={{
                        width: '14px',
                        height: '14px',
                        padding: 0,
                        borderRadius: '50%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        margin: '0 auto'
                    }}
                />
            </Tooltip>,
            dataIndex: 'key',
            key: 'key',
            width: 50,
            onCell: (_, index) => ({
                colSpan: (index) > 0 ? 1 : 5,
            }),
            render: (value, record, index) => {
                if (record.id === "PER-PERSON"){
                    return <p style={{ margin: 0, textAlign: "center", fontWeight: 500 }}>
                        Kişi Başı
                    </p>
                }
                if (record.id === "QUOTAS"){
                    return <p style={{ margin: 0, textAlign: "center", fontWeight: 500 }}>
                        Kontenjan
                    </p>
                }
                return (
                    <>
                        <Button disabled={!!isRemoveButtonDisabled()} type="danger" onClick={() => removeRow(record.id)} style={{
                            width: '14px',
                            height: '14px',
                            padding: 0,
                            borderRadius: '50%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            margin: '0 auto'
                        }}>
                            <FontAwesomeIcon icon={faMinus}  style={{
                                fontSize: '10px',
                            }}/>
                        </Button>
                    </>
                )
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: 40,
            onCell: sharedOnCell,
            render: (value, record, index) => {
                if (record.id === "PER-PERSON"){
                    return null;
                }
                return (
                    <div style={{ display: 'flex', justifyContent: 'center'}}>
                        {prices.filter((item) => priceCode(item.adult, item.child, item.ages) === priceCode(record.adult, record.child, record.ages)).length > 1 ? (
                            <FontAwesomeIcon icon={faExclamation} style={{ color: 'red' }} />
                        ):(
                            <FontAwesomeIcon icon={faCheck} style={{ color: 'green' }} />
                        )}
                    </div>
                )
            }
        },
        {
            title: 'Adult',
            dataIndex: 'adult',
            key: 'adult',
            width: 80,
            onCell: sharedOnCell,
            className: (record) => record.adult > 2 ? 'background-red-500' : 'background-red-500',
            render: (adult, record, index) => {
                if (record.id === "PER-PERSON"){
                    return null;
                }
                return (
                    <InputNumber
                        style={{width: '100%'}}
                        value={adult}
                        onChange={(value) => onAdultChange(value, record)}
                    />
                )
            }
        },
        {
            title: 'Child',
            dataIndex: 'child',
            key: 'child',
            width: 80,
            onCell: sharedOnCell,
            render: (child, record, index) => {
                if (record.id === "PER-PERSON"){
                    return null;
                }
                return (
                    <InputNumber
                        value={child}
                        style={{width: '100%'}}
                        onChange={(value) => onChildChange(value, record)}
                    />
                )
            }
        },
        {
            title: 'Ages',
            dataIndex: 'ages',
            key: 'ages',
            onCell: sharedOnCell,
            render: (ages, record, index) => {
                if (record.id === "PER-PERSON"){
                    return null;
                }
                return (
                    <Space direction="horizontal">
                        {ages?.map((item, index) => (
                            <Select key={index} value={item} onChange={(value) => onChildAgeChange(value, record, index)}>
                                <Option value="INFANT">
                                    {hotel?.infant_policy?.[0]} - {hotel?.infant_policy?.[1]}
                                </Option>
                                <Option value="CHILD">
                                    {hotel?.child_policy?.[0]} - {hotel?.child_policy?.[1]}
                                </Option>
                            </Select>
                        ))}
                    </Space>
                )
            }
        },
        ...periods.map((period) => {
            let isSingle = period.is_daily === true;
            return {
                title: <Space direction="horizontal">
                    <Tooltip title={isSingle ? "Periyodu Gizle": "Periyodu Göster"}>
                        <Button type="link" style={{ padding: 0 }} onClick={() => changeStatusPeriod(period.id)}>
                            {moment(period.start).format('DD.MM.YYYY')} <br/> {moment(period.end).format('DD.MM.YYYY')}
                        </Button>
                    </Tooltip>
                    <Tooltip title="Periyodu Düzenle">
                        <Button onClick={() => onEditPeriod(period)} shape="circle" size="small" type="dashed" icon={<FontAwesomeIcon icon={faEdit} width={10} height={10} className="ant-btn-link" />} />
                    </Tooltip>
                </Space>,
                className: "text-left",
                width: "auto",
                render: (record) => {
                    if (record.id === "PER-PERSON"){
                        return "Bu PERPERSON";
                    }
                    if (record.id === "QUOTAS"){
                        return (
                            <Space direction="horizontal">
                                <InputNumber
                                    addonBefore={<Tooltip title="Kontenjan">
                                        <FontAwesomeIcon icon={faDoorOpen} style={{ opacity: "0.5", fontSize: "12px" }}/>
                                    </Tooltip>}
                                    value={prices?.find((price) => price.id === record.id)?.quotas?.[period.start] || 0}
                                    min={0}
                                    style={{ width: "100%" }}
                                    onChange={(value) => onQuotaChange(record, null, period, value, "period")}
                                />
                                <InputNumber
                                    addonBefore={<Tooltip title="Min. Konaklama">
                                        <FontAwesomeIcon icon={faCalendarDay} style={{ opacity: "0.5", fontSize: "12px" }}/>
                                    </Tooltip>}
                                    value={prices?.find((price) => price.id === record.id)?.min_stay?.[period.start] || 0}
                                    min={0}
                                    style={{ width: "100%" }}
                                    onChange={(value) => onMinStayChange(record, null, period, value, "period")}
                                />
                            </Space>
                        )
                    }
                    return <PriceInputs onPriceChange={onPriceChange} prices={prices} record={record} period={period} />
                },
                children: isSingle ? getDaysInRange(period.start, period.end)?.map((periodDay) => ({
                    title: moment(periodDay).format('DD.MM.YYYY'),
                    dataIndex: "prices",
                    key: periodDay,
                    width: 280,
                    render: (value, record) => {
                        if (record.id === "PER-PERSON"){
                            return "Bu PERPERSON";
                        }
                        if (record.id === "ACTIONS"){
                            return <FixedPriceManager getDaysInRange={getDaysInRange} date={periodDay} setPrices={setPrices} period={period} type="date"/>;
                        }
                        if (record.id === "QUOTAS"){
                            return <Space direction="horizontal">
                                <InputNumber
                                    addonBefore={<Tooltip title="Kontenjan">
                                        <FontAwesomeIcon icon={faDoorOpen} style={{ opacity: "0.5", fontSize: "12px" }}/>
                                    </Tooltip>}
                                    value={prices?.find((price) => price.id === record.id)?.quotas?.[periodDay] || 0}
                                    min={0}
                                    style={{ width: "100%" }}
                                    onChange={(value) => onQuotaChange(record, periodDay, period, value, "date")}
                                />
                                <InputNumber
                                    addonBefore={<Tooltip title="Min. Konaklama">
                                        <FontAwesomeIcon icon={faCalendarDay} style={{ opacity: "0.5", fontSize: "12px" }}/>
                                    </Tooltip>}
                                    value={prices?.find((price) => price.id === record.id)?.min_stay?.[periodDay] || 0}
                                    min={0}
                                    style={{ width: "100%" }}
                                    onChange={(value) => onMinStayChange(record, periodDay, period, value, "date")}
                                />
                            </Space>
                        }
                        return <PriceInputsDate onPriceChange={onPriceChange} prices={prices} record={record} periodDay={periodDay} period={period} />
                    },
                })): []
            }
        })
    ], [periods, prices]);
    const getContract = async (hotelId, contractId) => {
        setIsLoading(true)
        try {
            let {data: response} = await ContractService.demoShow(hotelId, contractId)
            let prices = response.data.conditions;
            setPeriods(response.data.periods)
            setPrices(response.data.conditions)
            setHotel(response.data.hotel)
            setContract(response.data.contract)
            if(Object.keys(response.data.quotas)?.length > 0){
                fixedQuotaRow.quotas = response.data.quotas
            }
            if(response?.data?.contract?.calculating_policy === "fixed"){
                prices = [
                    ...prices
                ]
            }else{
                prices = [
                    fixedPerPersonRow,
                    ...prices
                ]
            }
            prices = [
                fixedQuotaRow,
                ...prices
            ]
            setPrices(prices)
            setIsLoading(false)
        }catch (e) {
            if (e.response){
                notification.error({
                    message: e.response.data.message
                })
            }
            setIsLoading(false)
        }
    }

    const onSaveContract = async () => {
        let formData = {
            prices: prices,
            periods: periods,
        }
        setIsLoading(true);
        try {
            let {data: response} = await ContractService.demoSave(HOTEL_ID, params.id, formData)
            if (response.success){
                notification.success({
                    message: response.message,
                })
            }
            setIsLoading(false)
        }catch (e){
            if (e.response){
                notification.error({
                    message: e.response.data.message,
                    duration: 5
                })
            }
            setIsLoading(false)
        }
    }
    const isPeriodsValid = () => {
        return periods?.length > 0;
    }
    const isPricesValid = () => {
        return prices?.some(item => Object.keys(item.prices || {}).length > 0);
    }
    useEffect(() => {
        getContract(HOTEL_ID, params.id)
    },[])
    return (
        <Wrapper title="Sanal Pos İşlemleri">
            <Content className="m-25">
                <Breadcrumb className="mb-20">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse} />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        Demo
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background">
                    <Table
                        columns={columns}
                        dataSource={prices}
                        pagination={false}
                        loading={isLoading}
                        bordered
                        size="small"
                        title={() => <TableHeader
                            periods={periods}
                            prices={prices}
                            contract={contract}
                            isPricesValid={isPricesValid}
                            isPeriodsValid={isPeriodsValid}
                            contractId={params.id}
                            getContract={() => getContract(HOTEL_ID, params.id)}
                        />}
                        footer={() => <TableFooter onSaveContract={onSaveContract} isLoading={isLoading} isPricesValid={isPricesValid} isPeriodsValid={isPeriodsValid}/>}
                        rowKey="id"
                        scroll={{ x: 'max-content' }}
                    />
                </div>
            </Content>
            <EditPeriodModalForm
                isVisible={isEditPeriodModalVisible}
                onCancel={() => setIsEditPeriodModalVisible(false)}
                title="Periyot Düzenle"
                form={periodEditForm}
                data={selectedPeriod}
                onFinish={(values, id) => onUpdatePeriod(values, id)}
            />
        </Wrapper>
    );
};
const TableFooter = ({ onSaveContract = () => {}, isLoading = false, isPeriodsValid, isPricesValid }) => {
    return (
        <Space style={{
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px 0"
        }}>
            <span></span>
            <span></span>
            <Button type="primary" htmlType="button" onClick={onSaveContract} loading={isLoading} disabled={!isPeriodsValid() || !isPricesValid()}>
                Kontratı Kaydet
            </Button>
        </Space>
    )
}

const TableHeader = ({ contractId, getContract, contract, isPeriodsValid, isPricesValid }) => {
    const onPeriodImport = async (values) => {
        try {
            let {data: response} = await ContractService.demoImportPeriod(HOTEL_ID, contractId, values)
            if (response.success){
                notification.success({
                    message: response.message
                })
                getContract()
            }
        }catch (e){
            if (e.response){
                notification.error({
                    message: e.response.data.message
                })
            }
        }
    }
    return (
        <Space direction="horizontal" style={{ width: "100%", "justifyContent": "space-between" }}>
            <div>
                <span style={{ fontSize: "18px", fontWeight: 500 }}>{contract?.title}</span> <br/>
                <span style={{ fontSize: "14px", fontWeight: 400 }}>{contract?.room?.name && `(${contract?.room?.name})`}</span>
            </div>
            <div>
                <Space direction="vertical" align="center">
                    <Popover content={
                        isPeriodsValid() ? (
                            <h4>Periyot tanımlamalarınız tamamlanmış durumda.</h4>
                        ): (
                            <h4>Periyot tanımı yapıldıktan sonra, periyot tarihlerini kontratınıza eklemeniz gerekmektedir.</h4>
                        )
                    }>
                        <Button shape="circle" htmlType="button" type="dashed" icon={
                            isPeriodsValid() > 0 ? <FontAwesomeIcon icon={faCheck} style={{ color: "green" }} />: <FontAwesomeIcon icon={faXmark} style={{ color: "red" }} />
                        } />
                    </Popover>
                    <h4>Periyot</h4>
                </Space>
                <Divider type="vertical" />
                <Space direction="vertical" align="center">
                    <Popover content={
                        <>
                            {isPricesValid() ? (
                                <h4>Fiyat tanımlamalarınız tamamlanmış durumda.</h4>
                            ): (
                                <h4>Fiyat tanımlamalarınız eksik. Lütfen fiyat tanımlamalarınızı yapınız.</h4>
                            )}

                            <Alert message={<>0 <strong>(sıfır)</strong> olarak tanımlanan fiyatlar <strong>STOP</strong> olarak işlenecektir.</>} type="warning" />
                        </>
                    }>
                        <Button shape="circle" htmlType="button" type="dashed" icon={
                            isPricesValid() > 0 ? <FontAwesomeIcon icon={faCheck} style={{ color: "green" }} />: <FontAwesomeIcon icon={faXmark} style={{ color: "red" }} />
                        } />
                    </Popover>
                    <h4>Fiyat</h4>
                </Space>
            </div>
            <ImportPeriodModalForm
                title="Periyot Aktar"
                onFinish={(values) => onPeriodImport(values)}
            />
        </Space>
    )
}

const PriceInputsDate = React.memo(({ onPriceChange, prices, record, periodDay, period }) => {
    const debouncedOnPriceChange = useMemo(() => debounce(onPriceChange, 300), [onPriceChange]);
    return (
        <div style={{ width: "100%", display: "flex", gap: "0.6rem"}}>
            <div style={{ position: "relative" }} className="input-head-label" data-label="Yetişkin Fiyatı">
                <InputNumber
                    value={prices?.find((price) => price.id === record.id)?.prices?.[periodDay]?.['adultPrice'] || 0}
                    min={0}
                    onChange={(value) => debouncedOnPriceChange(record, periodDay, period, value, "date", "adultPrice")}
                    style={{ fontSize: "12px" }}
                />
            </div>
            {record.ages && record.ages.length > 0 && record.ages.map((age, index) => {
                return (
                    <div key={index} style={{ position: "relative" }} className="input-head-label" data-label={`${index + 1}. Çocuk Fiyatı`}>
                        <InputNumber
                            key={index}
                            value={prices?.find((price) => price.id === record.id)?.prices?.[periodDay]?.[`childPrice${index}`] || 0}
                            min={0}
                            style={{ width: "100%", maxWidth: "100px", fontSize: "12px" }}
                            onChange={(value) => debouncedOnPriceChange(record, periodDay, period, value, "date", `childPrice${index}`)}
                        />
                    </div>
                )
            })}
        </div>
    )
})

const PriceInputs = React.memo(({ onPriceChange, prices, record, period }) => {
    const debouncedOnPriceChange = useMemo(() => debounce(onPriceChange, 300), [onPriceChange]);
    return (
        <div style={{ width: "100%", display: "flex", gap: "0.6rem"}}>
            <div style={{ position: "relative" }} className="input-head-label" data-label="Yetişkin Fiyatı">
                <InputNumber
                    value={prices?.find((price) => price.id === record.id)?.prices?.[period.start]?.['adultPrice'] || 0}
                    min={0}
                    onChange={(value) => debouncedOnPriceChange(record, null, period, value, "period", "adultPrice")}
                    style={{ fontSize: "12px" }}
                />
            </div>
            {record.ages && record.ages.length > 0 && record.ages.map((age, index) => {
                return (
                    <div key={index} style={{ position: "relative" }} className="input-head-label" data-label={`${index + 1}. Çocuk Fiyatı`}>
                        <InputNumber
                            key={index}
                            value={prices?.find((price) => price.id === record.id)?.prices?.[period.start]?.[`childPrice${index}`] || 0}
                            min={0}
                            style={{ width: "100%", maxWidth: "100px", fontSize: "12px" }}
                            onChange={(value) => debouncedOnPriceChange(record, null, period, value, "period", `childPrice${index}`)}
                        />
                    </div>
                )
            })}
        </div>
    )
})

const FixedPriceManager = ({ type, period, date, setPrices, getDaysInRange }) => {
    const [price, setPrice] = React.useState(0)
    const [changeType, setChangeType] = React.useState("discount")
    const [changeWith, setChangeWith] = React.useState("percentage")
    const resetAllStates = () => {
        setPrice(0)
        setChangeType("discount")
        setChangeWith("percentage")
    }
    const getPrice = (price, value, changeWith, changeType) => {
        let calculatedPrice;
        switch (changeType) {
            case "discount":
                calculatedPrice = changeWith === "percentage" ? price - (price * value / 100): price - value;
                break;
            case "increase":
                calculatedPrice = changeWith === "percentage" ? price + (price * value / 100): price + value;
                break;
            default:
                calculatedPrice = price;
                break;
        }
        if (calculatedPrice < 0){
            calculatedPrice = 0;
        }
        return calculatedPrice;
    }
    const onChangeColumnDiscount = (price) => {
        if (type === "date"){
            setPrices((prevState) => {
                return [
                    ...prevState.map((item) => {
                        return {
                            ...item,
                            prices: {
                                ...item.prices,
                                [date]: getPrice(item.prices[date], price, changeWith, changeType)
                            }
                        }
                    })
                ]
            })
        }
        if (type === "period"){
            setPrices((prevState) => {
                return [
                    ...prevState.map((item) => {
                        return {
                            ...item,
                            prices: {
                                ...item.prices,
                                ...getDaysInRange(period.start, period.end).reduce((acc, date) => {
                                    acc[date] = getPrice(item.prices[date], price, changeWith, changeType)
                                    return acc;
                                }, {})
                            }
                        }
                    })
                ]
            })
        }
    }
    const onChangeColumnPrice = (price) => {
        if (type === "date"){
            setPrices((prevState) => {
                return [
                    ...prevState.map((item) => {
                        return {
                            ...item,
                            prices: {
                                ...item.prices,
                                [date]: price
                            }
                        }
                    })
                ]
            })
        }
        if (type === "period"){
            setPrices((prevState) => {
                return [
                    ...prevState.map((item) => {
                        return {
                            ...item,
                            prices: {
                                ...item.prices,
                                ...getDaysInRange(period.start, period.end).reduce((acc, date) => {
                                    acc[date] = price;
                                    return acc;
                                }, {})
                            }
                        }
                    })
                ]
            })
        }
    }
    return (
        <Space direction="horizontal" style={{ width: "100%", justifyContent: "center" }}>
            <Popover title={type === "date" ? "Tarih'e Fiyat Uygula": "Periyod'a Fiyat Uygula"} onVisibleChange={(e) => {
                if (!e){
                    resetAllStates()
                }
            }} content={<div>
                <InputNumber onKeyDown={(e) => {if (e.key === "Enter"){onChangeColumnPrice(price)}}} min={0} style={{ width: "100%" }} value={price} onChange={(value) => setPrice(value)} placeholder="Fiyat" />
                <Divider style={{ margin: "8px 0" }} />
                <Space style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button htmlType="button" type="dashed" size="small" icon={<FontAwesomeIcon icon={faRefresh} />} onClick={() => onChangeColumnPrice(0)}/>
                    <Button htmlType="button" type="primary" size="small" icon={<FontAwesomeIcon icon={faCheck} />} onClick={() => onChangeColumnPrice(price)}/>
                </Space>
            </div>} trigger="click">
                <Button htmlType="button" shape="circle" size="small" type="dashed" icon={<FontAwesomeIcon icon={faArrowDown} width={10} height={10} className="ant-btn-link" />} />
            </Popover>
            <Divider type="vertical" />
            <Popover title={type === "date" ? "Tarih'e Fiyat Değişikliği Yap": "Periyod'a Fiyat Değişikliği Yap"} onVisibleChange={(e) => {
                if (!e){
                    resetAllStates()
                }
            }} content={<div>
                <InputNumber min={0} style={{ width: "100%" }} value={price} onChange={(value) => setPrice(value)} placeholder="Fiyat" addonBefore={
                    <Select value={changeWith} onChange={(value) => setChangeWith(value)}>
                        <Option value="percentage">Yüzde</Option>
                        <Option value="value">Birim</Option>
                    </Select>
                } addonAfter={
                    <Select value={changeType} onChange={(value) => setChangeType(value)}>
                        <Option value="discount">İndirim</Option>
                        <Option value="increase">Artış</Option>
                    </Select>
                }/>
                <Divider style={{ margin: "8px 0" }} />
                <Space style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button htmlType="button" type="primary" size="small" icon={<FontAwesomeIcon icon={faCheck} />} onClick={() => onChangeColumnDiscount(price, type, changeWith)}/>
                </Space>
            </div>} trigger="click">
                <Button htmlType="button" shape="circle" size="small" type="dashed" icon={<FontAwesomeIcon icon={faPercent} width={10} height={10} className="ant-btn-link" />} />
            </Popover>
        </Space>
    )
}

export default ContractEdit;