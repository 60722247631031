import axios from '../axios';

const ContractLanguageService = {
  async get() {
    return await axios.get('/v1/contract-languages');
  },
  async delete(contractLanguageId) {
    return await axios.delete(`/v1/contractLanguage/${contractLanguageId}`);
  },
  async update(contractLanguageId) {
    return await axios.delete(`/v1/contractLanguage/${contractLanguageId}`);
  }

};

export default ContractLanguageService;