import axios from '../axios';

const ContractGroupService = {

  async findById(contractId) {
    return await axios.get(`/v1/contracts/${contractId}`);
  },

  async get(page = 1) {
    return await axios.get(`/v1/contracts?page=${page}`);
  },

  async create(formData) {
    return await axios.post(`/v1/contracts`, formData);
  },

  async update(contractId, formData) {
    return await axios.post(`/v1/contracts/${contractId}`, formData);
  },

  async delete(contractId) {
    return await axios.delete(`/v1/contracts/${contractId}`);
  }
};

export default ContractGroupService;