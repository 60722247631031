import React, {useEffect, useState} from 'react';
import {Breadcrumb, Button, Form, Layout, notification, Space, Table} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHouse} from "@fortawesome/free-solid-svg-icons";
import Wrapper from "@/components/Wrapper";
import {ExternalHotelService} from "@/services";
import {useParams} from "react-router-dom";
import ExternalPeriodDateModalForm from "@/views/external/periods/components/PeriodDateModalForm";

const {Content} = Layout;

const ExternalHotelPeriodDateList = () => {
    const [periodDateCreateForm] = Form.useForm()
    const [isExternalPeriodDateCreateModalVisible, setIsExternalPeriodDateCreateModalVisible] = useState(false)
    const params = useParams();
    const [hotelPeriodDates, setHotelPeriodDates] = useState({
        data: [],
        meta: {},
        loading: true,
    })
    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            width: 100,
            key: 'id',
        },
        {
            title: "Başlangıç Tarihi",
            dataIndex: 'start_date',
            key: 'start_date',
        },
        {
            title: "Bitiş Tarihi",
            dataIndex: 'end_date',
            key: 'end_date',
        }
    ];
    const getHotelPeriodDates = async (hotelId, periodId, page = 1) => {
        try {
            let {data: response} = await ExternalHotelService.getPeriodDates(hotelId, periodId, page)
            setHotelPeriodDates({
                data: response.data,
                meta: response.meta,
                loading: false
            })
        }catch (e){
            if (e.response){
                notification.error({
                    message: "Error",
                    description: e.response.data.message
                })
            }
        }
    }
    const onCreateHotelPeriodDate = async (values) => {
        try {
            let {data: response} = await ExternalHotelService.createPeriodDate(params.hotelId, params.periodId, {
                start_date: values.date_range[0].format("YYYY-MM-DD"),
                end_date: values.date_range[1].format("YYYY-MM-DD")
            })
            if (response.success){
                notification.success({
                    message: "Success",
                    description: response.message
                })
                getHotelPeriodDates(params.hotelId, params.periodId)
            }
        }catch (e){
            if (e.response){
                notification.error({
                    message: "Error",
                    description: e.response.data.message
                })
            }
        }
    }
    useEffect(() => {
        getHotelPeriodDates(params.hotelId, params.periodId)
        // eslint-disable-next-line
    },[])
    return (
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-20">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse} />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        Periyot Tarihleri
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background">
                    <Space direction="horizontal" style={{
                        marginBottom: 16
                    }}>
                        <Button type="primary" htmlType="button" onClick={() => setIsExternalPeriodDateCreateModalVisible(true)}>
                            Periyot Tarihi Ekle
                        </Button>
                    </Space>
                    <Table
                        columns={columns}
                        dataSource={hotelPeriodDates.data}
                        rowKey="id"
                        loading={hotelPeriodDates.loading}
                        /*pagination={{

                        }}*/
                    />
                </div>
                <ExternalPeriodDateModalForm
                    title="Periyot Tarihi Tanımla"
                    isVisible={isExternalPeriodDateCreateModalVisible}
                    form={periodDateCreateForm}
                    onCancel={() => {
                        setIsExternalPeriodDateCreateModalVisible(false)
                        periodDateCreateForm.resetFields();
                    }}
                    onFinish={onCreateHotelPeriodDate}
                />
            </Content>
        </Wrapper>
    );
};

export default ExternalHotelPeriodDateList;