import React, {useEffect, useState} from 'react';
import {Breadcrumb, Col, DatePicker, Layout, notification, Row, Table} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHouse} from "@fortawesome/free-solid-svg-icons";
import Wrapper from "@components/Wrapper";
import {CurrentDeficitTransactionService} from "@services";
import {useParams} from "react-router-dom";
import moment from "moment";
import {useTranslation} from "react-i18next";

const { Content } = Layout;

const CurrentDeficitTransactionDetail = () => {
    const { t } = useTranslation()
    const [currentDate, setCurrentDate] = useState(moment())
    const columns = [
        {
            title: `${t('general.transaction_id')}`,
            dataIndex: 'id',
            key: 'id',
            render: (text) => {
                if(text) return text
                return <span style={{
                    whiteSpace: 'nowrap'
                }}>Başlangıç Bakiyesi</span>
            },
        },
        {
            title: `${t('general.cari')}`,
            dataIndex: 'cari_detail',
            key: 'cari',
            render: (text) => text && text?.cari?.name + ' | ' + text?.company_title + ' - ' + text?.bill_title
        },
        {
            title: `${t('general.process_type')}`,
            dataIndex: 'process_type',
            key: 'process_type',
            render: (text) => {
                let type = `${t('general.debt')}`
                if(text === 2) type = `${t('general.receive')}`
                if(text === undefined) type = ''
                return type
            }
        },
        {
            title: `${t('general.amount')}`,
            dataIndex: 'amount',
            key: 'amount',
            render: (text,record) => text && text + ' ' + record?.currency?.symbol
        },
        {
            title: `${t('general.debt')}`,
            dataIndex: 'amount',
            key: 'debt',
            render: (text,record) => {
                if(record.process_type === 1) return text + ' ' + record?.currency?.symbol
                if(record.process_type === undefined) return ''
                return 0 + ' ' + record?.currency?.symbol
            }
        },
        {
            title: `${t('general.receive')}`,
            dataIndex: 'amount',
            key: 'credit',
            render: (text,record) => {
                if(record.process_type === 2) return text + ' ' + record?.currency?.symbol
                if(record.process_type === undefined) return ''
                return 0 + ' ' + record?.currency?.symbol
            }
        },
        {
            title: `${t('general.balance')}`,
            dataIndex: 'balance',
            key: 'balance',
            render: (text,record) => {
                record.id === 0 ? text = record.balance : text = record.balance + " " + record?.currency?.symbol
                if(text){
                    return text
                }
            }
        },
        {
            title: `${t('general.process_user')}`,
            dataIndex: 'user',
            key: 'user',
            render: (text) => text?.full_name || 'Sistem'
        },
        {
            title: `${t('general.process_date')}`,
            dataIndex: 'created_at',
            key: 'created_at',

        },
        {
            title: `${t('general.description')}`,
            dataIndex: 'description',
            key: 'description',
        }
    ]
    const params = useParams()
    const [loading, setLoading] = useState(false)
    const [currentDeficitTransactions, setCurrentDeficitTransactions] = useState({
        data: [],
        meta: {},
        safe_bank: undefined
    })
    const getCurrentDeficitTransactions = async (page = 1, per_page = 10, filters) => {
        filters = {
            ...filters,
            created_at: [
                filters?.year ?
                    moment(filters.year).startOf('year').format('YYYY-MM-DD'):
                    moment(currentDate).startOf('year').format('YYYY-MM-DD'),
                filters?.year ?
                    moment(filters.year).endOf('year').format('YYYY-MM-DD'):
                    moment(currentDate).endOf('year').format('YYYY-MM-DD')
            ]
        }
        setLoading(true)
        try {
            let {data: response} = await CurrentDeficitTransactionService.getByCurrentDeficit(params.currentDeficitId,page, per_page, {
                ...filters
            });
            if(response.previous_balance && page === 1){
                response.data = [
                    {
                        id: 0,
                        balance: response.previous_balance || undefined,
                    },
                    ...response.data
                ]
            }
            setCurrentDeficitTransactions({
                data: response.data,
                meta: response.meta,
                current_deficit: response.current_deficit
            })
            setLoading(false)
        }catch (e){
            if(e.response){
                notification.error({
                    message: e.response.data.message
                })
            }
        }
    }
    useEffect(() => {
        getCurrentDeficitTransactions().then(() => setLoading(false))
    },[])
    return (
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-20">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse} />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href="/current-deficit-transactions">
                        {t('general.cari_transactions')}
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {currentDeficitTransactions?.current_deficit}
                    </Breadcrumb.Item>
                </Breadcrumb>

                <div className="site-layout-background">
                    <Row style={{
                        marginBottom: 20
                    }}>
                        <Col span={4}>
                            <DatePicker
                                picker="year"
                                placeholder="Yıl Seçiniz"
                                style={{width: '100%'}}
                                value={currentDate}
                                onChange={(date) => {
                                    setCurrentDate(date)
                                    getCurrentDeficitTransactions(1, 10, {
                                        year: date
                                    })
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Table
                                loading={loading}
                                columns={columns}
                                dataSource={currentDeficitTransactions.data}
                                rowKey={record => record.id}
                                pagination={{
                                    pageSize: currentDeficitTransactions.meta.per_page,
                                    total: currentDeficitTransactions.meta.total,
                                    current: currentDeficitTransactions.meta.current_page,
                                    showSizeChanger: true,
                                }}
                                onChange={(pagination, filters, sorter) => getCurrentDeficitTransactions(pagination.current, pagination.pageSize, filters)}
                            />
                        </Col>
                    </Row>
                </div>
            </Content>
        </Wrapper>
    );
};

export default CurrentDeficitTransactionDetail;