import React, { useState, useEffect, useMemo } from 'react';
import {Modal, Form, Input, Row, Col, Select, notification} from 'antd';
import {useParams} from "react-router-dom";
import WebLanguageService from "@services/web-languages/WebLanguage.service";

const { Option } = Select;
const HotelLanguageModalForm = (props) => {
    const [isWebLanguageModalVisible, setIsWebLanguageModalVisible] = useState(false);

    const [webLanguage, setWebLanguage] = useState([]);
    
    const params = useParams();

    
    useEffect(() => {
        getWebLanguage();
        if(props.selectedHotel){
            getByHotelId();
        }
    }, [props.selectedHotel]);
    const getByHotelId = async () => {
        try {
            let {data:{data:response}} = await WebLanguageService.getHotelLanguage(props.selectedHotel);
      
            let initialValues = [];
            response.map((item) => {
                initialValues.push(item.web_language_id)
            })
            props.form.setFieldsValue({
                name:initialValues
            })

        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.message
                });
            }
      
            console.log(e);
        }
      };
      
    const getWebLanguage = async () => {
        try {
            let response = await WebLanguageService.get();
            setWebLanguage(response.data.data);

        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    };


    return (
        <Modal visible={props.isVisible} title={props.title} closable={false} onOk={() => props.form.submit()} onCancel={props.onCancel}>
            <Form form={props.form} layout="vertical" onFinish={(values) => props.onFinish(values, props.data?.name)}>
                <Row gutter={[16]}>
                    <Col span={24}>
                        <Form.Item name="name" label="Diller">
                        <Select mode="multiple">
                        {webLanguage.map((item) => <Option  value={item.id} key={`${item.id}`}>{item.name}</Option>)}
                        </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal >
    )
};

export default HotelLanguageModalForm;
