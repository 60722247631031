import React from 'react';
import {Col, Form, Input, Modal, Row} from "antd";
import TextArea from "antd/es/input/TextArea";

const CmHotelCreateModalForm = (props) => {
    return (
        <Modal visible={props.isVisible} title={props.title} closable={false} onOk={() => props.form.submit()} onCancel={props.onCancel}>
            <Form form={props.form} layout="vertical" onFinish={(values) => props.onFinish(values, props.data?.id)} initialValues={{ is_active: true }} fields={props.data?.fields}>
                <Row gutter={[16,0]}>
                    <Col span={12}>
                        <Form.Item
                            name="name"
                            label="Otel Adı"
                            rules={[{ required: true, message: 'Lütfen otel adını giriniz.' }]}
                        >
                            <Input placeholder="Otel adını giriniz"/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="phone_number"
                            label="Telefon Numarası"
                            rules={[{ required: true, message: 'Lütfen telefon numarası giriniz.' }]}
                        >
                            <Input placeholder="Telefon numarası giriniz"/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="address"
                            label="Adres"
                            rules={[{ required: true, message: 'Lütfen adres giriniz.' }]}
                        >
                            <TextArea
                                placeholder="Adres giriniz"
                                autoSize={{ minRows: 3, maxRows: 5 }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="description"
                            label="Açıklama"
                            rules={[{ required: true, message: 'Lütfen açıklama giriniz.' }]}
                        >
                            <TextArea
                                placeholder="Açıklama giriniz"
                                autoSize={{ minRows: 3, maxRows: 5 }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="zip_code"
                            label="Posta Kodu"
                        >
                            <Input
                                placeholder="Posta kodu giriniz"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="lat"
                            label="Enlem"
                        >
                            <Input
                                placeholder="Enlem giriniz"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="lng"
                            label="Boylam"
                        >
                            <Input
                                placeholder="Boylam giriniz"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="email"
                            label="E-posta"
                            rules={[{ required: true, message: 'Lütfen e-posta giriniz.' }]}
                        >
                            <Input
                                placeholder="E-posta giriniz"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="country"
                            label="Ülke"
                        >
                            <Input
                                placeholder="Ülke giriniz"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="city"
                            label="Şehir"
                        >
                            <Input
                                placeholder="Şehir giriniz"
                            />
                        </Form.Item>
                    </Col>

                </Row>
            </Form>
        </Modal>
    );
};

export default CmHotelCreateModalForm;