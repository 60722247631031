import React, { useEffect, useState } from 'react';
import Wrapper from '@components/Wrapper';
import { Layout, Breadcrumb, Button, Table, notification, Form, Space, Popconfirm } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faHouse, faTrash, faPlus, faHotel, faEnvelope} from '@fortawesome/free-solid-svg-icons';
import { UserService,RoleService,UserHotelService,HotelService,UserEmailAssignmentService } from '@services';
import UserCreateModalForm from '@components/users/UserCreateModalForm';
import UserEditModalForm from '@components/users/UserEditModalForm';
import UserEmailAssignmentModalForm from "@components/users/UserEmailAssignmentModalForm";
import {useTranslation} from "react-i18next";


const { Content } = Layout;

const Concept = () => {
    const { t } = useTranslation()
    const [users, setUsers] = useState({
        data: [],
        meta: {},
        loading: true,
    });
    const [selectedUsers, setSelectedUsers] = useState();
    const [isEmailAssignmentLoading, setIsEmailAssignmentLoading] = useState(true);
    const [hotels, setHotels] = useState([]);
    const [agencies, setAgencies] = useState([]);
    const [userEmailAssignments, setUserEmailAssignments] = useState([]);
    const [roles, setRoles] = useState([]);
    const columns = [
        {
            title: '#',
            render: (text, record, index) => index + 1
        },
        {
            title: `${t("general.agency")}`,
            key: 'agency',
            dataIndex: 'agency',
            render : (text) => text.name
        },
        {
            title: `${t("general.role")}`,
            key: 'role',
            dataIndex: 'role',
            render : (text) => text.name
        },
        {
            title: `${t("general.full_name")}`,
            key: 'fullName' ,
            render : (text,record)=>(
                record.first_name + " " + record.last_name
            )
        },
        {
            title: `${t("general.email")}`,
            dataIndex: 'email',
        },
        {
            title: `${t("general.actions")}`,
            key: 'actions',
            render: (text, record) => (
                <Space align="middle">
                    <Popconfirm title={t("general.are_you_sure_delete")} onConfirm={()=>onDelete(record.id)} okText={t("general.delete")} cancelText={t("general.cancel")}>
                        <Button type="danger">
                            <FontAwesomeIcon icon={faTrash} />
                        </Button>
                    </Popconfirm>
                    <Button type="primary" onClick={()=> getUserHotels(record.id)} >
                        <FontAwesomeIcon icon={faHotel} />
                    </Button>
                    <Button type="primary" onClick={()=> getUserEmailAssignments(record.id)} >
                        <FontAwesomeIcon icon={faEnvelope} />
                    </Button>
                </Space>
            )
        }
    ];

    /* create modal form states */
    const [createModalForm] = Form.useForm();
    const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);

    /* email assignment modal form states */
    const [emailAssignmentForm] = Form.useForm();
    const [isEmailAssignmentModalVisible, setIsEmailAssignmentModalVisible] = useState(false);

    /* edit modal form states */
    const [editModalForm] = Form.useForm();
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);

    useEffect(() => {
        getUsers();
        getRoles();
        getHotels();
    }, []);

    const getUsers = async (page = 1) => {
        try {
            let { data: users } = await UserService.getUsers(page);
            setUsers({
                data: users.data,
                meta: users.meta,
                loading: false,
            });
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    };

    const getHotels = async ( ) => {
        try {
            let { data: { data: hotels } } = await HotelService.get();
            setHotels(hotels);
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    };

    const getUserHotels = async (id) => {
        try {
            let { data: data } = await UserHotelService.getByUser(id);
            let hotels = [];
            data?.map(x => {
                hotels.push(x.hotel_id);
            });
            editModalForm.setFieldsValue({
                hotel: hotels
            });
            setSelectedUsers(id);

            setIsEditModalVisible(true);
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    };

    const getRoles = async ( ) => {
        try {
            let { data: { data: roles } } = await RoleService.getRoles();
            console.log(roles);
            setRoles(roles);
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    };

    const onCreate = async (values) => {
        /*values= {
            user: {
                first_name: values.first_name,
                last_name: values.last_name,
                role_id: values.role_id,
                agency_id: values.agency_id,
                email: values.email,
                password: values.password
            },
            company: {
                company_name:values.company_name,
                nameplate_title:values.nameplate_title,
                tax_number:values.tax_number,
                tax_administration:values.tax_administration,
                authorized_person:values.authorized_person,
                authorized_contact:values.authorized_contact,
                long:values.long,
                lat:values.lat,
                membership_start_date:moment(values.membership_start_date).format('YYYY-MM-DD HH:mm:ss'),
                membership_end_date:moment(values.membership_end_date).format('YYYY-MM-DD HH:mm:ss')
            }
        }*/

        values = {
            "first_name": values.first_name,
            "last_name": values.last_name,
            "role_id": values.role_id,
            "agency_id": values.agency_id,
            "email": values.email,
            "password": values.password
        }

        try {
            await UserService.create(values);
            setUsers({...users, loading: true})
            setIsCreateModalVisible(false);
            getUsers();
            createModalForm.resetFields();
            notification.success({
                message: "Kullanıcı eklendi!"
            });
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e
                });
            }
        }
    };

    const onEdit = async (values)=>{
        values = {
            "hotels": values.hotel,
            "user_id": selectedUsers
        }
        try {
            let { data: response } = await UserHotelService.create(values);
            if(response.success){
                notification.success({
                    message: response.message
                });
                setIsEditModalVisible(false);
            }
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }

        
    }
    const onDelete = async (userId) => {
        try {
            let { data: response } = await UserService.delete(userId);

            notification.success({
                message: response.message
            });
            setUsers({...users, loading: true})
            getUsers();
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    };
    const getUserEmailAssignments = async (userId) => {
        try {
            let { data: {data: response} } = await UserEmailAssignmentService.getByUser(userId);
            setSelectedUsers(userId)
            setUserEmailAssignments(response);
            setIsEmailAssignmentLoading(false);
            setIsEmailAssignmentModalVisible(true);
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    };
    const onUserEmailAssign = async (values,userId) => {
        setIsEmailAssignmentLoading(true);
        try {
            let { data: response } = await UserEmailAssignmentService.create(values,userId);
            if(response.success){
                notification.success({
                    message: response.message
                })
                getUserEmailAssignments(userId);
            }
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    };
    const onChangeActive = async (id,userId) => {
        setIsEmailAssignmentLoading(true);
        try {
            let { data: response } = await UserEmailAssignmentService.onChangeActive(id,userId);
            if(response.success){
                notification.success({
                    message: response.message
                })
                getUserEmailAssignments(userId);
            }
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    };




    return (
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-20">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse} />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {t("general.users")}
                    </Breadcrumb.Item>
                </Breadcrumb>

                <div className="site-layout-background">
                    <Button type="primary" className="mb-10" onClick={() => setIsCreateModalVisible(true)}>
                        <FontAwesomeIcon icon={faPlus} className="mr-10" />
                        {t("general.create_new")}
                    </Button>

                    <Table
                        loading={users.loading}
                        columns={columns}
                        dataSource={users.data}
                        rowKey={record => record.id}
                        bordered
                        pagination={{
                            total: users.meta?.total,
                            defaultPageSize: users.meta?.per_page,
                            onChange: (page, pageSize) => {
                                setUsers({ ...users, loading: true })
                                getUsers(page)
                            }
                        }}
                    />
                </div>
            </Content>

            <UserCreateModalForm
                title={t("general.create_new")}
                isVisible={isCreateModalVisible}
                form={createModalForm}
                onCancel={() => setIsCreateModalVisible(false)}
                onFinish={onCreate}
                agencies={agencies}
                roles={roles}
            />
            <UserEditModalForm
                title={t("general.hotel_assignments")}
                isVisible={isEditModalVisible}
                form={editModalForm}
                onCancel={() => {setIsEditModalVisible(false);editModalForm.resetFields();}}
                onFinish={onEdit}
                hotels={hotels}
            />
            <UserEmailAssignmentModalForm
                title={t("general.email_assignments")}
                form={emailAssignmentForm}
                isVisible={isEmailAssignmentModalVisible}
                onFinish={onUserEmailAssign}
                onCancel={() => {setIsEmailAssignmentModalVisible(false);emailAssignmentForm.resetFields();}}
                onChangeActive = {onChangeActive}
                hotels={hotels}
                loading={isEmailAssignmentLoading}
                data={userEmailAssignments}
                selectedUser={selectedUsers}
            />
        </Wrapper>
    );
};

export default Concept;