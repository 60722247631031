import React, {useEffect, useState} from 'react';
import {Col, Form, Input, Modal, notification, Row, Select, Tabs} from "antd";
import {WebLanguageService} from "@services";

const {TabPane} = Tabs;
const {Option} = Select;

const HotelVoucherModal = (props) => {
    const languages = Form.useWatch('languages', props.form);
    const [webLanguage, setWebLanguage] = useState([]);
    const getWebLanguage = async () => {
        try {
            let response = await WebLanguageService.get();
            setWebLanguage(response.data.data);

        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    };

    useEffect(() => {
        getWebLanguage()
    }, []);
    return (
        <Modal visible={props.isVisible} title={props.title} closable={false} onOk={() => props.form.submit()} onCancel={props.onCancel} okText="Kaydet" cancelText="İptal">
            <Form form={props.form} layout="vertical" onFinish={props.onFinish}>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="languages"
                            label="Diller"
                            rules={[{required: true, message: 'Lütfen dil seçiniz!'}]}
                        >
                            <Select
                                mode="multiple"
                                style={{ width: "100%" }}
                            >
                                {webLanguage.map((item) => <Option  value={item['iso_639-1']} key={`${item['iso_639-1']}`}>{item.name}</Option>)}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Tabs>
                    {languages?.map((item) =>
                        <TabPane tab={webLanguage.find(x => x['iso_639-1'] === item).name} key={item}>
                            <Form.Item
                                name={['voucher', webLanguage.find(x => x['iso_639-1'] === item)['iso_639-1'], 'title']}
                                label="E-Posta Başlığı"
                                rules={[{required: true, message: 'Lütfen başlık giriniz!'}]}
                            >
                                <Input placeholder="Başlık"/>
                            </Form.Item>
                            <Form.Item
                                name={['voucher', webLanguage.find(x => x['iso_639-1'] === item)['iso_639-1'], 'content']}
                                label="E-Posta İçerik"
                                rules={[{required: true, message: 'Lütfen içerik giriniz!'}]}
                            >
                                <Input placeholder="Başlık"/>
                            </Form.Item>
                        </TabPane>
                    )}
                </Tabs>
            </Form>
        </Modal>
    );
};

export default HotelVoucherModal;