import {
    Button,
    Col,
    Form,
    InputNumber,
    Modal,
    Row,
    Tabs,
    Input,
    Select,
    Space,
    notification,
    Table,
    Popconfirm
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faEdit, faPlusCircle, faTrash} from "@fortawesome/free-solid-svg-icons";
import { IconService, HotelRoomAttributeService, HotelRoomService, WebLanguageService, HotelRoomBedService } from "@services";
import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import RoomAttributeEditModal from "../hotels/RoomAttributeEditModal";

function RoomDetailsModalForm(props) {
    const params = useParams()
    const [roomAttributeEditForm] = Form.useForm();
    const { visible, onCancel, title, initialValues, room, getRoomById } = props;
    const [icons, setIcons] = useState({
        data: [],
        loading: true
    });
    const [webLanguages, setWebLanguages] = useState({
        data: [],
        loading: true
    });
    const [selectedAttribute, setSelectedAttribute] = useState(null);
    const [roomAttributeEditModalVisible, setRoomAttributeEditModalVisible] = useState(false);
    const roomBedColumns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            render: (text, record, index) => index + 1
        },
        {
            title: 'Yatak Tipi',
            dataIndex: 'name',
            key: 'name',
            render: (text, record, index) => text
        },
        {
            title: 'Yatak Sayısı',
            dataIndex: 'count',
            key: 'count',
            render: (text, record, index) => text
        },
        {
            title: 'İşlemler',
            key: 'actions',
            render: (text, record, index) => {
                return (
                    <Space direction="horizontal" align="center">
                        <Popconfirm title="Silmek istediğinize emin misiniz?" cancelText="İptal" onCancel="Sil" onConfirm={() => onBedDelete(record.id)}>
                            <Button type="danger">
                                <FontAwesomeIcon icon={faTrash} />
                            </Button>
                        </Popconfirm>
                    </Space>
                )
            }
        }
    ]
    const roomAttributeColumns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            render: (text, record, index) => index + 1
        },
        {
            title: 'İkon',
            dataIndex: 'icon',
            key: 'icon',
            render: (text, record, index) => {
                return (
                    <Space direction="horizontal" align="center">
                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox={text.source.split('&&')[1]} height="1.2em" width="1.2em" xmlns="http://www.w3.org/2000/svg">
                            <path d={text.source.split('&&')[0]}></path>
                        </svg>
                        <div>{text.name}</div>
                    </Space>
                )
            }
        },
        {
            title: 'Özellik',
            dataIndex: 'name',
            key: 'name',
            render: (text, record, index) => text
        },
        {
            title: 'İşlemler',
            key: 'actions',
            render: (text, record, index) => {
                return (
                    <Space direction="horizontal" align="center">
                        <Button type="primary" onClick={() => getAttribute(record.id)}>
                            <FontAwesomeIcon icon={faEdit} />
                        </Button>
                        <Popconfirm title="Silmek istediğinize emin misiniz?" cancelText="İptal" onCancel="Sil" onConfirm={() => onAttributeDelete(record.id)}>
                            <Button type="danger">
                                <FontAwesomeIcon icon={faTrash} />
                            </Button>
                        </Popconfirm>
                    </Space>
                )
            }
        }
    ]
    const [roomDetailsForm] = Form.useForm();
    const [roomAttributeForm] = Form.useForm();
    const [roomBedsForm] = Form.useForm();
    const { TabPane } = Tabs;
    const { TextArea } = Input;
    const { Option } = Select;
    const onGeneralFinish = async (values) => {
        try {
            let { data: response } = await HotelRoomService.update(params.hotelId, room?.id, values);
            if (response.success) {
                notification.success({
                    message: 'Oda Güncellendi.'
                });
            }
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    };
    /*
    * Attributes
    * */
    const getAttribute = async (id) => {
        try {
            let { data: {data: response} } = await HotelRoomAttributeService.getById(params.hotelId, room?.id, id);
            response.names.map((item) => {
                roomAttributeEditForm.setFields([
                    {
                        name:['attributes',item.language,'name'],
                        value: item.name
                    },
                    {
                        name:'icon_id',
                        value: item.icon.id
                    }
                ])
            })
            setSelectedAttribute(response.id)
            setRoomAttributeEditModalVisible(true)
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
            console.log(e);
        }
    }
    const onAttributeFinish = async (values) => {
        try {
            let { data: response } = await HotelRoomAttributeService.create(params.hotelId, room?.id, values);
            if (response.success) {
                getRoomById(room?.id)
                notification.success({
                    message: response.message
                });
            }
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
            console.log(e);
        }
    }
    const onAttributeUpdateFinish = async (values) => {
        try {
            let { data: response } = await HotelRoomAttributeService.update(params.hotelId, room?.id, selectedAttribute, values);
            if (response.success) {
                notification.success({
                    message: response.message
                })
                setRoomAttributeEditModalVisible(false)
                roomAttributeEditForm.resetFields()
            }
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
            console.log(e);
        }
    }
    const onAttributeDelete = async (id) => {
        try {
            let { data: response } = await HotelRoomAttributeService.delete(params.hotelId, room?.id, id);
            if (response.success) {
                getRoomById(room?.id)
                notification.success({
                    message: response.message
                });
            }
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
            console.log(e);
        }
    }
    /*
    * Beds
    * */
    const onBedsFinish = async (values) => {
        try {
            let { data: response } = await HotelRoomBedService.create(params.hotelId, room?.id, values);
            if (response.success) {
                getRoomById(room?.id)
                notification.success({
                    message: response.message
                });
            }
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
            console.log(e);
        }
    }
    const onBedDelete = async (id) => {
        try {
            let { data: response } = await HotelRoomBedService.delete(params.hotelId, room?.id, id);
            if (response.success) {
                getRoomById(room?.id)
                notification.success({
                    message: response.message
                });
            }
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
            console.log(e);
        }
    }
    const getWebLanguages = async () => {
        try {
            let { data: { data: response } } = await WebLanguageService.getHotelLanguage(params.hotelId);
            setWebLanguages(prevState => {
                return {
                    ...prevState,
                    data: response,
                    loading: false
                }
            });
        } catch (e) {
            console.log(e);
        }
    };
    useEffect(() => {
        const getIcons = async () => {
            try {
                let { data: { data: response } } = await IconService.get();
                setIcons(prevState => {
                    return {
                        ...prevState,
                        data: response,
                        loading: false
                    }
                })
            } catch (e) {
                console.log(e);
            }
        };
        getWebLanguages()
        getIcons()
    }, [])
    return (
        <Modal visible={visible} title={title} onCancel={() => {onCancel();roomDetailsForm.resetFields();roomAttributeForm.resetFields();roomBedsForm.resetFields()}} footer={false} width="1024px">
            <Tabs>
                <TabPane key="1" tab="Genel Bilgiler">
                    <Form form={roomDetailsForm} layout="vertical" onFinish={onGeneralFinish} fields={initialValues?.general}>
                        <Tabs>
                            {webLanguages.data?.map((language, i) => (
                                <TabPane key={i} tab={language?.web_language.name} forceRender={true}>
                                    <Row gutter={[16, 8]}>
                                        <Col span={12}>
                                            <Form.Item name="min_people" label="Minimum Kişi Sayısı">
                                                <InputNumber placeholder="Kişi sayısı" style={{ width: "100%" }} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item name="max_people" label="Maksimum Kişi Sayısı">
                                                <InputNumber placeholder="Kişi sayısı" style={{ width: "100%" }} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item name="m2" label="Oda Boyutu (m²)">
                                                <InputNumber placeholder="Kişi sayısı" style={{ width: "100%" }} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item name={['rooms',language?.web_language['iso_639-1'],'description']} label="Açıklama">
                                                <TextArea placeholder="Oda Açıklaması" style={{ width: "100%" }} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item>
                                                <Button type="primary" htmlType="submit" style={{ width: "100%" }}>Genel Özellikleri Kaydet</Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </TabPane>
                            ))}
                        </Tabs>
                    </Form>
                </TabPane>
                <TabPane key="2" tab="Ek Özellikler">
                    <Form form={roomAttributeForm} layout="vertical" onFinish={onAttributeFinish}>
                        <Tabs>
                            {webLanguages.data?.map((language, i) => (
                                <TabPane key={i} tab={language?.web_language.name} forceRender={true}>
                                    <Row gutter={[16, 8]}>
                                        <Col span={12}>
                                            <Form.Item
                                                name="icon_id"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'İkon seçimi zorunludur',
                                                    },
                                                ]}
                                                label="İkon"
                                                tooltip={<div>İkon listesi için <Link to="/icons" target="_blank" rel="noopener noreferrer">tıklayın</Link></div>}
                                            >
                                                <Select
                                                    showSearch
                                                    filterOption={(input, option) =>
                                                        (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
                                                    }
                                                    loading={icons.loading}
                                                    disabled={icons.loading}
                                                    placeholder="İkon seçimi yapınız!"
                                                >
                                                    {icons.data?.map((icon, i) => {
                                                        return (
                                                            <Option key={i} value={icon.id}>
                                                                {icon.name}
                                                            </Option>
                                                        )
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                name={['attributes', language?.web_language['iso_639-1'], 'name']}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Özellik girilmedi',
                                                    },
                                                ]}
                                                label="Özellik"
                                            >
                                                <Input placeholder="Özellik" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item>
                                                <Button type="primary" htmlType="submit" style={{ width: "100%" }}>Oda Özelliklerini Kaydet</Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </TabPane>
                            ))}
                        </Tabs>
                        <Table
                            dataSource={room?.room_attributes}
                            columns={roomAttributeColumns}
                            rowKey="id"
                            pagination={{
                                pageSize: 5,
                                position: ['bottomRight']
                            }}
                        />
                    </Form>
                </TabPane>
                <TabPane key="3" tab="Yatak Özellikleri">
                    <Form form={roomBedsForm} layout="vertical" onFinish={onBedsFinish}>
                        <Tabs>
                            {webLanguages.data?.map((language, i) => (
                                <TabPane key={i} tab={language?.web_language.name} forceRender={true}>
                                    <Row gutter={[16, 8]}>
                                        <Col span={12}>
                                            <Form.Item name={['beds', language?.web_language['iso_639-1'], 'name']} label="Yatak Tipi">
                                                <Input placeholder="Yatak tipi" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item name="count" label="Yatak Sayısı">
                                                <InputNumber placeholder="Yatak sayısı" style={{ width: "100%" }} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </TabPane>
                            ))}
                        </Tabs>
                        <Row>
                            <Col span={24}>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" style={{ width: "100%" }}>Yatak Özelliklerini Kaydet</Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    <Table
                        dataSource={room?.room_beds}
                        columns={roomBedColumns}
                        rowKey="id"
                        pagination={{
                            pageSize: 5,
                            position: ['bottomRight']
                        }}
                    />
                </TabPane>
            </Tabs>
            <RoomAttributeEditModal
                title="test"
                form={roomAttributeEditForm}
                isVisible={roomAttributeEditModalVisible}
                onCancel={() => {setRoomAttributeEditModalVisible(false);roomAttributeEditForm.resetFields()}}
                onFinish={onAttributeUpdateFinish}
                languages={webLanguages.data}
                icons={icons}
            />
        </Modal>
    )
}
export default RoomDetailsModalForm