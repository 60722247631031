import React, {useEffect} from 'react';
import {Col, Form, Modal, notification, Row, Select} from "antd";
import {CurrentDeficitService} from "@services";
import {useTranslation} from "react-i18next";

const {Option} = Select;

const SafeBankAppendCariModalForm = (props) => {
    const { t } = useTranslation();
    return (
        <Modal visible={props.isVisible} title={props.title} closable={false} onOk={() => props.form.submit()} onCancel={props.onCancel} okText="Kaydet" cancelText="İptal">
            <Form form={props.form} layout="vertical" onFinish={(values) => props.onFinish(values, props?.selectedSafeBank?.id)} fields={[{name: "cari", value: props?.selectedSafeBank?.cari_detail_id || undefined}]}>
                <Row>
                    <Col span={24}>
                        <Form.Item
                            name="cari"
                            label={t('general.cari')}
                            rules={[{required: true, message: 'Cari alanı boş bırakılamaz!'}]}
                        >
                            <Select loading={props.isCariLoading} dropdownRender={menu => <div onScrollCapture={props.onScroll}>{menu}</div>} placeholder="Cari Seçiniz">
                                {props?.currentDeficit?.data?.map((item,index) => (
                                    <Option value={item.id} key={index}>{item?.cari?.role} ({item?.company_title} - {item?.authorized_person})</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default SafeBankAppendCariModalForm;