import React from 'react';
import {Form, Input, Modal} from "antd";
import {useTranslation} from "react-i18next";

const AdditionalServiceModalForm = (props) => {
    const { t } = useTranslation()
    return (
        <Modal visible={props.isVisible} title={props.title} closable={false} onOk={() => props.form.submit()} onCancel={props.onCancel}>
            <Form form={props.form} layout="vertical" onFinish={(values) => props.onFinish(values, props.data?.id)}>
                <Form.Item name="name" label={t('general.service_name')} rules={[{required: true, message: 'Lütfen ek hizmet adını giriniz.'}]}>
                    <Input
                        placeholder={t('general.service_name')}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default AdditionalServiceModalForm;