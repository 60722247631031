import axios from '../axios';

const ContractDateDiscountsService = {
    async getAllByAgency(params) {
        return await axios.get(`/v1/contract-date-discounts`, { params });
    },
    async create(formData) {
        return await axios.post(`/v1/contract-date-discounts`, formData);
    },
    async delete(contractDateDiscountId) {
        return await axios.delete(`/v1/contract-date-discounts/${contractDateDiscountId}`);
    },
    async getById(id){
        return await axios.get(`/v1/contract-date-discounts/${id}`);
    },
    async update(id, formData){
        return await axios.post(`/v1/contract-date-discounts/${id}`, formData);
    }
};

export default ContractDateDiscountsService;