import React, {useEffect} from 'react';
import {Breadcrumb, Layout, notification, Table, Tag} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHouse} from "@fortawesome/free-solid-svg-icons";
import Wrapper from "@/components/Wrapper";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {TargetService} from "@services";

const {Content} = Layout;

const TargetUserProgress = () => {
    const params= useParams()
    const { t } = useTranslation()
    const [data, setData] = React.useState({
        data: [],
        meta: {},
        loading: true
    })
    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: t('general.name'),
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: t('general.status'),
            dataIndex: 'progress',
            key: 'progress',
            render: (text) => text?.completed ? <Tag color="green">{t('general.completed')}</Tag> : <Tag color="red">{t('general.in_progress')}</Tag>
        },
        {
            title: t('general.progress'),
            dataIndex: 'progress',
            key: 'progress',
            render: (text) => text?.progress
        }
    ];
    const getTargetUserProgress = async (page = 1, per_page = 10) => {
        try {
            let {data: response} = await TargetService.getTargetUserProgress(params.targetId, page, per_page)
            setData({
                data: response.data,
                meta: response.meta,
                loading: false
            })
        }catch (e){
            if (e.response){
                notification.error({
                    message: t('general.error'),
                    description: e.response.data.error,
                })
            }
            setData({
                data: [],
                meta: {},
                loading: false
            })
        }
    }
    useEffect(() => {
        getTargetUserProgress()
    },[])
    return (
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-20">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse} />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {t('general.target_progress')}
                    </Breadcrumb.Item>
                </Breadcrumb>

                <div className="site-layout-background">
                    <Table
                        columns={columns}
                        dataSource={data.data?.users}
                        rowKey={record => record.id}
                        loading={data.loading}
                        pagination={false}
                    />
                </div>
            </Content>
        </Wrapper>
    );
};

export default TargetUserProgress;