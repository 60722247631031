import axios from '../axios';

const ConceptService = {
  async findById(hotelId, id) {
    return await axios.get(`/v1/hotels/${hotelId}/concepts/${id}`);
  },

  async get(hotelId) {
    return await axios.get(`/v1/hotels/${hotelId}/concepts`);
  },

  async create(hotelId, formData) {
    return await axios.post(`/v1/hotels/${hotelId}/concepts`, formData);
  },

  async update(hotelId, conceptId, formData) {
    return await axios.post(`/v1/hotels/${hotelId}/concepts/${conceptId}`, formData);
  },

  async delete(hotelId, conceptId) {
    return await axios.delete(`/v1/hotels/${hotelId}/concepts/${conceptId}`);
  }
};

export default ConceptService;