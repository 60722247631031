import axios from '../../axios';

const PointService = {
    async get(page = 1, per_page = 10) {
        return await axios.get(`/lara-beach/points?page=${page}&per_page=${per_page}`);
    },
    async getParents(page = 1, per_page = 10) {
        return await axios.get(`/lara-beach/points?page=${page}&per_page=${per_page}&parent=true`);
    },
    async getSubPoints(id, page = 1, per_page = 10) {
        return await axios.get(`/lara-beach/points/${id}/sub-points?page=${page}&per_page=${per_page}`);
    },
    async find(id){
        return await axios.get(`/lara-beach/points/${id}`);
    },
    async update(id, formData){
        return await axios.post(`/lara-beach/points/${id}`, formData);
    },
    async create(formData){
        return await axios.post('/lara-beach/points', formData);
    },
    async createSubPoint(id, formData){
        return await axios.post(`/lara-beach/points/${id}/sub-points`, formData);
    }
};

export default PointService;