import axios from '../axios';

const PeriodDateService = {
  async get(periodId) {
    return await axios.get(`/v1/periods/${periodId}/dates`);
  },

  async create(periodId, formData) {
    return await axios.post(`/v1/periods/${periodId}/dates`, formData);
  },

  async update(periodId, periodDateId, formData) {
    return await axios.post(`/v1/periods/${periodId}/dates/${periodDateId}`, formData);
  },

  async delete(periodId, periodDateId) {
    return await axios.delete(`/v1/periods/${periodId}/dates/${periodDateId}`);
  }
};

export default PeriodDateService;