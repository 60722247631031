import axios from '../axios';

const DashboardService = {
    async get() {
        return await axios.get(`/v1/dashboard`);
    },
    async getTargets() {
        return await axios.get(`/v1/dashboard/targets`);
    },
    async getPaidPendingReservations() {
        return await axios.get(`/v1/dashboard/paid-pending-reservations`);
    }
}

export default DashboardService;