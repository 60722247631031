import axios from '../axios';

const SectionService = {
    async get() {
        return await axios.get(`/v1/site-manager/sections`);
    },
    async getById(hotelPageId) {
        return await axios.get(`/v1/site-manager/sections/${hotelPageId}`);
    },
};

export default SectionService;