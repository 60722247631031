import React from 'react';
import {Breadcrumb, Button, Col, Form, Input, InputNumber, Image, Layout, notification, Row, Select, Tabs} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHouse} from "@fortawesome/free-solid-svg-icons";
import Wrapper from "@components/Wrapper";
import {ExternalHotelService, ExternalPropertyService,ExternalPopularFacilityService, ExternalDescriptionService} from "@services";
const {Content} = Layout;
const {Option} = Select;
const {TextArea} = Input;

const ExternalHotelCreate = () => {
    const [form] = Form.useForm();
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [properties, setProperties] = React.useState({
        data: [],
        loading: true,
    });
    const [descriptions, setDescriptions] = React.useState({
        data: [],
        loading: true,
    });
    const [popularFacilities, setPopularFacilities] = React.useState({
        data: [],
        loading: true,
    });

    const getAllProperties = async () => {
        try {
            let {data: response} = await ExternalPropertyService.get();
            setProperties({
                data: response.data,
                loading: false,
            })
        }catch (e){
            if(e.response){
                notification.error({
                    message: 'Hata',
                    description: e.response.data.message
                })
            }
        }
    }

    const getAllPopularFacilities = async () => {
        try {
            let {data: response} = await ExternalPopularFacilityService.get();
            setPopularFacilities({
                data: response.data,
                loading: false,
            })
        }catch (e){
            if(e.response){
                notification.error({
                    message: 'Hata',
                    description: e.response.data.message
                })
            }
        }
    }

    const getAllDescriptions = async () => {
        try {
            let {data: response} = await ExternalDescriptionService.get();
            setDescriptions({
                data: response.data,
                loading: false,
            })
        }catch (e){
            if(e.response){
                notification.error({
                    message: 'Hata',
                    description: e.response.data.message
                })
            }
        }
    }

    const onSave = async (values) => {
        setIsSubmitting(true);
        try {
            let {data: response} = await ExternalHotelService.create(values);
            notification.success({
                message: 'Başarılı',
                description: response.message
            })
            setIsSubmitting(false);
        }catch (e){
            if (e.response){
                notification.error({
                    message: 'Hata',
                    description: e.response.data.message
                })
            }
            setIsSubmitting(false);
        }
    }

    React.useEffect(() => {
        getAllProperties();
        getAllDescriptions();
        getAllPopularFacilities();
    },[])
    return (
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-20">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse} />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href="/external-management/hotels">
                        Dış Kanal Yönetimi (Oteller)
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        Otel Oluştur
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background">
                    <Form layout="vertical" form={form} onFinish={(values) => onSave(values)}>
                        <Tabs>
                            <Tabs.TabPane tab="Genel Bilgiler" key="general">
                                <Row gutter={[16,16]}>
                                    <Col span={8}>
                                        <Form.Item
                                            name="name"
                                            label="Otel Adı"
                                            rules={[{
                                                required: true,
                                                message: 'Otel adı zorunludur'
                                            }]}
                                        >
                                            <Input placeholder="Otel Adı" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            name="description"
                                            label="Otel Açıklaması"
                                            rules={[{
                                                required: true,
                                                message: 'Otel açıklaması zorunludur'
                                            }]}
                                        >
                                            <TextArea
                                                rows={6}
                                                placeholder="Otel açıklaması" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            name={['details', 'ages_policy']}
                                            label="Yaş Politikası"
                                            rules={[{
                                                required: true,
                                                message: 'Yaş politikası zorunludur'
                                            }]}
                                        >
                                            <Input placeholder="Yaş politikası" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            name={['details', 'checkin_policy']}
                                            label="Giriş Politikası"
                                            rules={[{
                                                required: true,
                                                message: 'Giriş politikası zorunludur'
                                            }]}
                                        >
                                            <Input placeholder="Giriş politikası" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            name={['details', 'checkout_policy']}
                                            label="Çıkış Politikası"
                                            rules={[{
                                                required: true,
                                                message: 'Çıkış politikası zorunludur'
                                            }]}
                                        >
                                            <Input placeholder="Çıkış politikası" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            name={['details', 'quality']}
                                            label="Otel Kalitesi"
                                            rules={[{
                                                required: true,
                                                message: 'Otel kalitesi zorunludur'
                                            }]}
                                        >
                                            <InputNumber style={{
                                                width: '100%'
                                            }} placeholder="Otel kalitesi" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="Özellikler" key="features">
                                <Row gutter={[16,16]}>
                                    <Col span={24}>
                                        <Form.Item
                                            name={['properties']}
                                            label="Otel Özellikleri"
                                            rules={[{
                                                required: true,
                                                message: 'Otel özellikleri zorunludur'
                                            }]}
                                        >
                                            <Select
                                                mode="multiple"
                                                placeholder="Otel özellikleri"
                                            >
                                                {properties.data.map((item) => (
                                                    <Option key={item.id} value={item.id}>{item.name}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            name={['descriptions']}
                                            label="Otel Hatırlatmaları"
                                            rules={[{
                                                required: true,
                                                message: 'Otel hatırlatmaları zorunludur'
                                            }]}
                                        >
                                            <Select
                                                mode="multiple"
                                                placeholder="Otel hatırlatmaları"
                                            >
                                                {descriptions.data.map((item) => (
                                                    <Option key={item.id} value={item.id}>{item.content}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            name={['popular_facilities']}
                                            label="Otel Popüler Özellikleri"
                                            rules={[{
                                                required: true,
                                                message: 'Otel popüler özellikleri zorunludur'
                                            }]}
                                        >
                                            <Select
                                                mode="multiple"
                                                placeholder="Otel popüler özellikleri"
                                            >
                                                {popularFacilities.data.map((item) => (
                                                    <Option key={item.id} value={item.id}>{item.facility}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Tabs.TabPane>
                        </Tabs>
                        <Button type="primary" loading={isSubmitting} htmlType="submit" style={{
                            width: '100%',
                            marginTop: '10px'
                        }}>Bilgileri Kaydet</Button>
                    </Form>
                </div>
            </Content>
        </Wrapper>
    );
};

export default ExternalHotelCreate;