import axios from '../axios';

const VirtualPosTransactionService = {
    async get(page = 1, pageSize = 10, filters) {
        return await axios.get(`/v1/virtual-pos-transactions?page=${page}&pageSize=${pageSize}`, {params: filters});
    },
    async export(filters = {}) {
        axios.defaults.responseType = 'blob';
        let data = await axios.get(`/v1/virtual-pos-transactions/export?${new URLSearchParams(filters).toString()}`);
        axios.defaults.responseType = 'json';
        return data;
    },
};

export default VirtualPosTransactionService;