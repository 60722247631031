import React, {useEffect, useState} from 'react';
import {Breadcrumb, Layout, List, notification, Tooltip} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBell, faHouse} from "@fortawesome/free-solid-svg-icons";
import Wrapper from "@components/Wrapper";
import {useTranslation} from "react-i18next";
import { NotificationService } from '@services';
import {Link} from "react-router-dom";

const {Content} = Layout;

const Notifications = () => {
    const { t } = useTranslation();
    const [notifications, setNotifications] = useState({
        data: [],
        loading: false,
        meta: {}
    });
    const getNotifications = async (page = 1, per_page = 10) => {
        setNotifications({
            ...notifications,
            loading: true
        })
        try {
            let {data: response} = await NotificationService.get(page, per_page);
            setNotifications({
                data: response.data,
                loading: false,
                meta: response.meta
            });
        }catch (e){
            if (e.response){
                notification.error({
                    message: 'Error',
                    description: e.response.data.message
                });
            }
            setNotifications({
                ...notifications,
                loading: false
            });
        }
    }
    useEffect(() => {
        getNotifications()
    },[])
    return (
        <Wrapper title="Hotels">
            <Content className="m-25">
                <Breadcrumb className="mb-20">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse} />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {t("general.notifications")}
                    </Breadcrumb.Item>
                </Breadcrumb>

                <div className="site-layout-background">
                    <List
                        pagination={{
                            total: notifications.meta?.total || 0,
                            current: notifications.meta?.current_page || 1,
                            pageSize: notifications.meta?.per_page || 10,
                            showSizeChanger: false,
                            onChange: (page, per_page) => getNotifications(page, per_page)
                        }}
                        dataSource={notifications.data}
                        renderItem={(item, index) => (
                            <List.Item>
                                <List.Item.Meta
                                    avatar={<FontAwesomeIcon icon={faBell} />}
                                    title={<Link to={item.content?.url}>{item.content?.title}</Link>}
                                    description={<div>
                                        {item.content?.body}<br/>
                                        {item?.user} -
                                        <Tooltip title={item?.created_at}>
                                             &nbsp;{item?.created_at_human}
                                        </Tooltip>
                                    </div>}
                                />
                            </List.Item>
                        )}
                    />
                </div>
            </Content>
        </Wrapper>
    );
};

export default Notifications;