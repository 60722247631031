import React from 'react';
import {Breadcrumb, Button, Col, Form, Layout, notification, Popconfirm, Row, Space, Table} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faHouse, faTrash} from "@fortawesome/free-solid-svg-icons";
import Wrapper from "@components/Wrapper";
import {useNavigate, useSearchParams} from "react-router-dom";
import {ExternalDescriptionService} from "@services";
import DescriptionModal from "@components/external/description/DescriptionModal";

const {Content} = Layout;

const ExternalDescriptionDefinition = () => {
    const [editForm] = Form.useForm();
    const [createForm] = Form.useForm();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [selectedDescription, setSelectedDescription] = React.useState({});
    const [editModalVisible, setEditModalVisible] = React.useState(false);
    const [createModalVisible, setCreateModalVisible] = React.useState(false);
    const page = searchParams.get('page') || 1;
    const per_page = searchParams.get('per_page') || 10;
    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            width: 100
        },
        {
            title: 'Açıklama',
            dataIndex: 'content',
            key: 'content',
        },
        {
            title: 'İşlemler',
            key: 'action',
            render: (text, record) => (
                <Space>
                    <Button type="primary" disabled={record?.is_pool} htmlType="button" onClick={() => {
                        setSelectedDescription(record);
                        setEditModalVisible(true);
                    }}>
                        <FontAwesomeIcon icon={faEdit}/>
                    </Button>
                    <Popconfirm disabled={record?.is_pool} title="Silmek istediğinize emin misiniz?" okText="Sil" cancelText="İptal" onConfirm={() => onDelete(record.id)}>
                        <Button disabled={record?.is_pool} type="danger" htmlType="button">
                            <FontAwesomeIcon icon={faTrash}/>
                        </Button>
                    </Popconfirm>
                </Space>
            )
        }
    ];

    const onEdit = async (values, id) => {
        try {
            let {data: response} = await ExternalDescriptionService.update(values,id);
            if(response.success){
                setEditModalVisible(false);
                getDescriptions(page, per_page);
                notification.success({
                    message: 'Başarılı',
                    description: response.message
                });
            }
        }catch (e) {
            if (e.response) {
                notification.error({
                    message: 'Hata',
                    description: e.response.data.message
                });
            }
        }
    }

    const onCreate = async (values) => {
        try {
            let {data: response} = await ExternalDescriptionService.create(values);
            if(response.success){
                setCreateModalVisible(false);
                getDescriptions(page, per_page);
                createForm.resetFields();
                notification.success({
                    message: 'Başarılı',
                    description: response.message
                });
            }
        }catch (e) {
            if (e.response) {
                notification.error({
                    message: 'Hata',
                    description: e.response.data.message
                });
            }
        }
    }

    const onDelete = async (id) => {
        try {
            let {data: response} = await ExternalDescriptionService.delete(id);
            if(response.success){
                getDescriptions(page, per_page);
                notification.success({
                    message: 'Başarılı',
                    description: response.message
                });
            }
        }catch (e) {
            if (e.response) {
                notification.error({
                    message: 'Hata',
                    description: e.response.data.message
                });
            }
        }
    }

    const [descriptions, setDescriptions] = React.useState({
        data: [],
        meta: {},
        loading: true
    });

    const getDescriptions = async (page, per_page) => {
        try{
            let {data: response} = await ExternalDescriptionService.getPaginate(page, per_page);
            setDescriptions({
                data: response.data,
                meta: response.meta,
                loading: false
            });
        }catch (e){
            if(e.response){
                notification.error({
                    message: 'Hata',
                    description: e.response.data.message
                });
            }
        }
    }

    React.useEffect(() => {
        getDescriptions(page, per_page)
    }, [page, per_page]);

    return (
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-20">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse} />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        Dış Kanal Yönetimi (Tanımlamalar)
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background">
                    <Row gutter={[16,16]} style={{
                        marginBottom: 16
                    }}>
                        <Col span={12}>
                            <Button
                                type="primary"
                                htmlType="button"
                                onClick={() => setCreateModalVisible(true)}
                            >Yeni Açıklama Ekle</Button>
                        </Col>
                    </Row>
                    <Table
                        columns={columns}
                        dataSource={descriptions.data}
                        loading={descriptions.loading}
                        pagination={{
                            total: descriptions?.meta?.total,
                            current: descriptions?.meta?.current_page,
                            pageSize: descriptions?.meta?.per_page,
                            onChange: (page, pageSize) => navigate(`/external-management/definitions/descriptions?page=${page}&per_page=${pageSize}`)
                        }}
                        rowKey={record => record.id}
                    />
                </div>
            </Content>
            <DescriptionModal
                title="Açıklama Düzenle"
                form={editForm}
                isVisible={editModalVisible}
                onCancel={() => setEditModalVisible(false)}
                data={selectedDescription}
                onFinish={(values, id) => onEdit(values, id)}
            />
            <DescriptionModal
                title="Açıklama Ekle"
                form={createForm}
                isVisible={createModalVisible}
                onCancel={() => setCreateModalVisible(false)}
                onFinish={(values) => onCreate(values)}
            />
        </Wrapper>
    );
};

export default ExternalDescriptionDefinition;