import axios from '../axios';

const ExternalHotelService = {
    async get(page = 1, per_page = 10, filters) {
        return await axios.get(`/v1/external/hotels/list?page=${page}&per_page=${per_page}`, {
            params: {
                ...filters
            }
        });
    },
    async getOnlyPool(page = 1, per_page = 10, filters) {
        return await axios.get(`/v1/external/hotels/list?page=${page}&per_page=${per_page}&onlyPool=true`,{
            params: {
                ...filters
            }
        });
    },
    async show(id) {
        return await axios.get(`/v1/external/hotels/${id}`);
    },
    async getOperatorPriceMultiplexes(id) {
        return await axios.get(`/v1/external/hotels/${id}/operator-price-multiplexes`);
    },
    async storeOperatorPriceMultiplexes(hotelId, formData) {
        return await axios.post(`/v1/external/hotels/${hotelId}/operator-price-multiplexes`, formData);
    },
    async uploadLogo(hotelId, formData) {
        return await axios.post(`/v1/external/hotels/${hotelId}/logo`, formData);
    },
    async update(values, id) {
        return await axios.post(`/v1/external/hotels/${id}`, values);
    },
    async create(values) {
        return await axios.post(`/v1/external/hotels/create`, values);
    },
    async changeActive(id, isActive) {
        return await axios.post(`/v1/external/hotels/${id}/change-active`, {
            isActive: isActive
        });
    },
    async changeFeatured(id) {
        return await axios.post(`/v1/external/hotels/${id}/change-featured`);
    },
    async getPresetHotels() {
        return await axios.get(`/v1/external/hotels/preset-hotels`);
    },
    async import(formData) {
        return await axios.post(`/v1/external/hotels/preset-hotels/import`, formData);
    },
    async setBanner(hotelId, bannerId) {
        return await axios.post(`/v1/external/hotels/${hotelId}/set-banner`, {
            bannerId: bannerId
        });
    },
    async uploadImage(hotelId, formData) {
        return await axios.post(`/v1/external/hotels/${hotelId}/upload-image`, formData);
    },
    async removeImage(hotelId, imageId) {
        return await axios.delete(`/v1/external/hotels/${hotelId}/remove-image/${imageId}`);
    },
    async getRooms(hotelId, page) {
        return await axios.get(`/v1/external/hotels/${hotelId}/rooms?page=${page}`);
    },
    async getRoomDefinitions(hotelId, page) {
        return await axios.get(`/v1/external/hotels/${hotelId}/room-definitions?page=${page}`);
    },
    async getConcepts(hotelId, page) {
        return await axios.get(`/v1/external/hotels/${hotelId}/concepts?page=${page}`);
    },
    async getConditionGroups(hotelId, page) {
        return await axios.get(`/v1/external/hotels/${hotelId}/condition-groups?page=${page}`);
    },
    async getPeriods(hotelId, page) {
        return await axios.get(`/v1/external/hotels/${hotelId}/periods?page=${page}`);
    },
    async getChildAges(hotelId, page) {
        return await axios.get(`/v1/external/hotels/${hotelId}/child-ages?page=${page}`);
    },
    async getConditions(hotelId, conditionGroupId, page) {
        return await axios.get(`/v1/external/hotels/${hotelId}/condition-groups/${conditionGroupId}/conditions?page=${page}`);
    },
    async getPeriodDates(hotelId, periodId, page) {
        return await axios.get(`/v1/external/hotels/${hotelId}/periods/${periodId}/dates?page=${page}`);
    },
    async createRoomDefinition(hotelId, values) {
        return await axios.post(`/v1/external/hotels/${hotelId}/room-definitions`, values);
    },
    async createPeriod(hotelId, values) {
        return await axios.post(`/v1/external/hotels/${hotelId}/periods`, values);
    },
    async createPeriodDate(hotelId, periodDateId, values) {
        return await axios.post(`/v1/external/hotels/${hotelId}/periods/${periodDateId}/dates`, values);
    },
    async createChildAge(hotelId, values) {
        return await axios.post(`/v1/external/hotels/${hotelId}/child-ages`, values);
    },
    async createConditionGroup(hotelId, values) {
        return await axios.post(`/v1/external/hotels/${hotelId}/condition-groups`, values);
    },
    async createCondition(hotelId, conditionGroupId, values) {
        return await axios.post(`/v1/external/hotels/${hotelId}/condition-groups/${conditionGroupId}/conditions`, values);
    },
    async createConcept(hotelId, values) {
        return await axios.post(`/v1/external/hotels/${hotelId}/concepts`, values);
    },
    async appendRoom(hotelId, values) {
        return await axios.post(`/v1/external/hotels/${hotelId}/rooms`, values);
    }
};

export default ExternalHotelService;