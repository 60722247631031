import axios from '../axios';

const AgencyWebHotelDetailService = {
    async getByHotel(hotelId) {
        return await axios.get(`/v1/site-manager/details/${hotelId}`);
    },
    async create(hotelId,formData) {
        return await axios.post(`/v1/site-manager/details/${hotelId}`,formData);
    },
};

export default AgencyWebHotelDetailService;