import React from "react";
import {Select, notification} from "antd";
import {MainRegionService} from "../../services";
const {Option}=Select;

function RegionDefinitionSelect (props){
    const [mainRegion,setMainRegion] = React.useState([]);
    const [isLoading,setIsLoading] = React.useState(true);
    React.useEffect(() => {
        const getMainRegion = async () => {
            try {
                let {data :{data:response}} = await MainRegionService.getPaginate();
                setMainRegion(response);
                setIsLoading(false);
            } catch (e) {
                if (e.response) {
                    notification.error({
                        message: e.response.data.message
                    });
                }
            }
        };
        getMainRegion();
    }, []);
    return (
        <Select {...props} loading={isLoading}>
            {mainRegion?.map((options,i)=>(
                <Option value={options.id} key={options.id}>{options.name}</Option>
            ))}
        </Select>
    )
}

export default RegionDefinitionSelect;