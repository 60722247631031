import axios from '../axios';

const AgencyWebImageService = {
    async getByHotel(hotelId) {
        return await axios.get(`/v1/site-manager/images/${hotelId}`);
    },
    async delete(id) {
        return await axios.delete(`/v1/site-manager/images/${id}`);
    },
};

export default AgencyWebImageService;