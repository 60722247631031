import React, {useEffect} from 'react';
import {Breadcrumb, Card, Col, Layout, notification, Row} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHouse} from "@fortawesome/free-solid-svg-icons";
import Wrapper from "@components/Wrapper";
import {SaleReportService} from "@services";

const {Content} = Layout;
const SaleReports = () => {
    const [currentReport, setCurrentReport] = React.useState({})
    const getReport = async () => {
        try {
            let {data: response} = await SaleReportService.get()
            setCurrentReport(response.data)
        }catch (e){
            if(e.response){
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }

    useEffect(() => {
        getReport()
    },[])

    return (
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-20">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse} />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>Satış Raporu</Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background">
                    <Row gutter={[16,16]}>
                        <Col span={12}>
                            <Card
                                title="Rational Rezervasyonu"
                            >
                                <div style={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    border: "1px solid #e8e8e8",
                                    padding: "10px",
                                    marginBottom: "40px"
                                }}>
                                    <h3><b>{currentReport?.today?.date}</b></h3>
                                    <p><b>{currentReport?.today?.count?.rational}</b> Toplam Rezervasyon</p>
                                    {currentReport?.today?.total?.map((item,key) => (
                                        <div key={key}>
                                            <p>Toplam Tutar: <h3><b>{item?.rational?.totalFee} {item?.currency?.symbol}</b></h3></p>
                                            <p>Maliyet: <h3><b>{item?.rational?.cost} {item?.currency?.symbol}</b></h3></p>
                                            <p>Hak Ediş: <h3><b>{item?.rational?.total} {item?.currency?.symbol}</b></h3></p>
                                            {key !== currentReport?.today?.total?.length - 1 && (
                                                <hr/>
                                            )}
                                        </div>
                                    ))}
                                </div>
                                <div style={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    border: "1px solid #e8e8e8",
                                    padding: "10px",
                                    marginBottom: "40px"
                                }}>
                                    <h3><b>{currentReport?.thisMonth?.date}</b></h3>
                                    <p><b>{currentReport?.thisMonth?.count?.rational}</b> Toplam Rezervasyon</p>
                                    {currentReport?.thisMonth?.total?.map((item,key) => (
                                        <div key={key}>
                                            <p>Toplam Tutar: <h3><b>{item?.rational?.totalFee} {item?.currency?.symbol}</b></h3></p>
                                            <p>Maliyet: <h3><b>{item?.rational?.cost} {item?.currency?.symbol}</b></h3></p>
                                            <p>Hak Ediş: <h3><b>{item?.rational?.total} {item?.currency?.symbol}</b></h3></p>
                                            {key !== currentReport?.thisMonth?.total?.length - 1 && (
                                                <hr/>
                                            )}
                                        </div>
                                    ))}
                                </div>
                                <div style={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    border: "1px solid #e8e8e8",
                                    padding: "10px",
                                    marginBottom: "40px"
                                }}>
                                    <h3><b>{currentReport?.allTime?.date}</b></h3>
                                    <p><b>{currentReport?.allTime?.count?.rational}</b> Toplam Rezervasyon</p>
                                    {currentReport?.allTime?.total?.map((item,key) => (
                                        <div key={key}>
                                            <p>Toplam Tutar: <h3><b>{item?.rational?.totalFee} {item?.currency?.symbol}</b></h3></p>
                                            <p>Maliyet: <h3><b>{item?.rational?.cost} {item?.currency?.symbol}</b></h3></p>
                                            <p>Hak Ediş: <h3><b>{item?.rational?.total} {item?.currency?.symbol}</b></h3></p>
                                            {key !== currentReport?.allTime?.total?.length - 1 && (
                                                <hr/>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </Card>
                        </Col>
                        <Col span={12}>
                            <Card
                                title="Call Center Rezervasyonu"
                            >
                                <div style={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    border: "1px solid #e8e8e8",
                                    padding: "10px",
                                    marginBottom: "40px"
                                }}>
                                    <h3><b>{currentReport?.today?.date}</b></h3>
                                    <p><b>{currentReport?.today?.count?.call_center}</b> Toplam Rezervasyon</p>
                                    {currentReport?.today?.total?.map((item,key) => (
                                        <div key={key}>
                                            <p>Toplam Tutar: <h3><b>{item?.call_center?.totalFee} {item?.currency?.symbol}</b></h3></p>
                                            <p>Maliyet: <h3><b>{item?.call_center?.cost} {item?.currency?.symbol}</b></h3></p>
                                            <p>Hak Ediş: <h3><b>{item?.call_center?.total} {item?.currency?.symbol}</b></h3></p>
                                            {key !== currentReport?.today?.total?.length - 1 && (
                                                <hr/>
                                            )}
                                        </div>
                                    ))}
                                </div>
                                <div style={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    border: "1px solid #e8e8e8",
                                    padding: "10px",
                                    marginBottom: "40px"
                                }}>
                                    <h3><b>{currentReport?.thisMonth?.date}</b></h3>
                                    <p><b>{currentReport?.thisMonth?.count?.call_center}</b> Toplam Rezervasyon</p>
                                    {currentReport?.thisMonth?.total?.map((item,key) => (
                                        <div key={key}>
                                            <p>Toplam Tutar: <h3><b>{item?.call_center?.totalFee} {item?.currency?.symbol}</b></h3></p>
                                            <p>Maliyet: <h3><b>{item?.call_center?.cost} {item?.currency?.symbol}</b></h3></p>
                                            <p>Hak Ediş: <h3><b>{item?.call_center?.total} {item?.currency?.symbol}</b></h3></p>
                                            {key !== currentReport?.thisMonth?.total?.length - 1 && (
                                                <hr/>
                                            )}
                                        </div>
                                    ))}
                                </div>
                                <div style={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    border: "1px solid #e8e8e8",
                                    padding: "10px",
                                    marginBottom: "40px"
                                }}>
                                    <h3><b>{currentReport?.allTime?.date}</b></h3>
                                    <p><b>{currentReport?.allTime?.count?.call_center}</b> Toplam Rezervasyon</p>
                                    {currentReport?.allTime?.total?.map((item,key) => (
                                        <div key={key}>
                                            <p>Toplam Tutar: <h3><b>{item?.call_center?.totalFee} {item?.currency?.symbol}</b></h3></p>
                                            <p>Maliyet: <h3><b>{item?.call_center?.cost} {item?.currency?.symbol}</b></h3></p>
                                            <p>Hak Ediş: <h3><b>{item?.call_center?.total} {item?.currency?.symbol}</b></h3></p>
                                            {key !== currentReport?.allTime?.total?.length - 1 && (
                                                <hr/>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Content>
        </Wrapper>
    );
};

export default SaleReports;