import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { CurrencyService } from '@services';

const { Option } = Select;

const CurrencySelect = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [currencies, setCurrencies] = useState({
        data: [],
        meta: {}
    });

    useEffect(() => {
        getCurrencies();
    }, []);

    const getCurrencies = async (page = 1, per_page = 10) => {
        try {
            let { data: response } = await CurrencyService.get();
            setCurrencies({
                data: response.data,
            });
            setIsLoading(false);
        } catch (e) {
            console.log(e);
        }
    };

   /* const onScroll = (e) => {
        const { target } = e;

        if (! isLoading && safeBank.meta.last_page > safeBank.meta.current_page && (target.scrollTop + target.offsetHeight) >= (target.scrollHeight * 0.8)) {
            setIsLoading(true);
            getSafeBank(safeBank.meta.current_page + 1);
        }
    };*/

    return (
        <Select
            /*dropdownRender={menu => <div onScrollCapture={onScroll}>{menu}</div>}*/
            loading={isLoading}
            {...props}
        >
            {currencies.data.map((currency, index) => <Option key={index} value={currency.id}>{currency.currency} | {currency.symbol}</Option>)}
        </Select>
    );
};

export default CurrencySelect;