import axios from '../axios';

const UserHotelService = {
    async get() {
        return await axios.get(`/v1/userHotels`);
    },
    async getByUser(userId) {
        return await axios.get(`/v1/userHotels/${userId}`);
    },

    async create(data) {
        return await axios.post(`/v1/userHotels`,data);
    },

}

export default UserHotelService;