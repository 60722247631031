import axios from '../axios';

const ExternalHotelCardService = {
    async get() {
        return await axios.get(`/v1/external/hotel-cards`);
    },
    async getPaginate(page, per_page) {
        return await axios.get(`/v1/external/hotel-cards?page=${page}&per_page=${per_page}`);
    },
    async update(values, id) {
        return await axios.post(`/v1/external/hotel-cards/${id}`, values);
    },
    async delete(id) {
        return await axios.delete(`/v1/external/hotel-cards/${id}`);
    },
    async create(values) {
        return await axios.post(`/v1/external/hotel-cards`, values);
    },
    async show(id) {
        return await axios.get(`/v1/external/hotel-cards/${id}/hotel`);
    },
    async createHotelCard(id,values) {
        return await axios.post(`/v1/external/hotel-cards/${id}/hotel`, values);
    }
};

export default ExternalHotelCardService;