import {Button, Col, Form, Modal, Row, Select, Switch, Table} from "antd";
import {useTranslation} from "react-i18next";

const {Option} = Select;

const UserEmailAssignmentModalForm = (props) => {
    const { t } = useTranslation()
    const columns = [
        {
            title: `${t("general.active")}`,
            dataIndex: 'is_active',
            key: 'is_active',
            width: "10%",
            render: (text, record) => (
                <Switch checked={text} onChange={() => props.onChangeActive(record.id,record.user.id)}/>
            )
        },
        {
            title: `${t("general.hotel")}`,
            dataIndex: 'hotel',
            key: 'hotel',
            render: (text) => text.name
        },
        {
            title: `${t("general.mail_type")}`,
            dataIndex: 'email_type',
            key: 'email_type',
            render: (text) => text.name
        },
    ];
    return (
        <Modal visible={props.isVisible} title={props.title} closable={false} onOk={props.onCancel} onCancel={props.onCancel} width={1000} cancelText={null}>
            <Form layout="vertical" form={props.form} onFinish={(values) => props.onFinish(values,props.selectedUser)}>
                <Row gutter={[16,4]}>
                    <Col span={12}>
                        <Form.Item
                            name="hotel_id"
                            label={t("general.hotel")}
                            rules={[{
                                required: true,
                                message: "Lütfen otel seçiniz."
                            }]}
                        >
                            <Select
                                mode="multiple"
                                placeholder="Lütfen otel seçiniz."
                                showSearch
                                filterOption={(input, option) =>
                                    option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {props.hotels?.map((hotel,i) => (
                                    <Option key={i} value={hotel.id}>{hotel.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="email_type_id"
                            label={t("general.email_type")}
                            rules={[{
                                required: true,
                                message: "Lütfen e-posta tipi seçiniz."
                            }]}
                        >
                            <Select
                                mode="multiple"
                                placeholder="Lütfen e-posta tipi seçiniz."
                                showSearch
                                filterOption={(input, option) =>
                                    option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                <Option key={1} value={1}>{t("general.new_reservation")}</Option>
                                <Option key={2} value={2}>{t("general.room_quota_ending")}</Option>
                                <Option key={3} value={3}>{t("general.cancelled_confirmed_reservation")}</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item>
                            <Button style={{width:"100%"}} type="primary" htmlType="submit">
                                {t("general.save")}
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
                <Table
                    columns={columns}
                    dataSource={props.data}
                    rowKey={record => record.id}
                    loading={props.loading}
                />
            </Form>
        </Modal>
    );
};

export default UserEmailAssignmentModalForm;