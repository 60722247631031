import axios from '../axios';

const HotelFooterService = {
    async getByHotel(hotelId) {
        return await axios.get(`/v1/site-manager/hotel-footers/${hotelId}`);
    },
    async create(footerId,formData) {
        return await axios.post(`/v1/site-manager/hotel-footer-details/${footerId}`,formData);
    },
    async changeFooter(hotelId,formData) {
        return await axios.post(`/v1/site-manager/hotel-footers/${hotelId}`,formData);
    },
};

export default HotelFooterService;