import React, { useEffect, useState } from 'react';
import {Select} from 'antd';
import {ExternalHotelService} from "@services";

const { Option } = Select;

const ExternalPeriodSelect = ({ value = null,  onChange, hotelId, placeholder = "" }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [hotelRooms, setHotelRooms] = useState({
        data: [],
        meta: {}
    });

    useEffect(() => {
        if (!hotelId) return null;
        getHotelRooms(hotelId);
        // eslint-disable-next-line
    }, []);



    const getHotelRooms = async (hotelId, page = 1) => {
        try {
            let { data: response } = await ExternalHotelService.getRooms(hotelId, page);
            setHotelRooms({
                data: [...hotelRooms.data, ...response.data],
                meta: response.meta
            });
            setIsLoading(false);
        } catch (e) {
            console.log(e);
        }
    };

    const onScroll = (e) => {
        const { target } = e;

        if (! isLoading && hotelRooms.meta.last_page > hotelRooms.meta.current_page && (target.scrollTop + target.offsetHeight) >= (target.scrollHeight * 0.8)) {
            setIsLoading(true);
            getHotelRooms(hotelId, hotelRooms.meta.current_page + 1);
        }
    };

    const handleChange = (value) => {
        if (onChange) {
            onChange(value);
        }
    };

    return (
        <Select placeholder={placeholder} value={value} dropdownRender={menu => <div onScrollCapture={onScroll}>{menu}</div>} onChange={handleChange} loading={isLoading}>
            {hotelRooms.data.map((hotelRoom, index) => <Option key={index} value={hotelRoom.id}>{hotelRoom.name}</Option>)}
        </Select>
    );
};

export default ExternalPeriodSelect;