import React from "react";
import {Button, Col, Form, Input, InputNumber, Modal, Row, Select} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";

const {Option} = Select;

function ContractAdditionalServiceModalForm(props){
    const { t } = useTranslation()
    return(
        <Modal visible={props.isVisible} title={props.title} closable={false} onOk={() => props.form.submit()} onCancel={() => props.onCancel()}>
            <Form form={props.form} layout="vertical" onFinish={(values) => props.onFinish(values, props?.selectedContractGroup?.data?.id)} fields={props.data?.fields}>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.List
                            name={['additional_services']}
                        >
                            {(fields, { add, remove }, { errors }) => (
                                <>
                                    {fields.map((field, index) => (
                                        <Form.Item
                                            label={index === 0 ? `${t("general.service_name")}` : ''}
                                            required={false}
                                            key={field.key}
                                        >
                                            <Row gutter={[16,16]}>
                                                <Col span={11}>
                                                    <Form.Item
                                                        name={[field.name, 'id']}
                                                        validateTrigger={['onChange', 'onBlur']}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Lütfen hizmet seçimi yapınız!",
                                                            },
                                                        ]}
                                                        noStyle
                                                    >
                                                        <Select
                                                            placeholder={t("general.service_name")}
                                                        >
                                                            {props?.additionalServices?.map((item) => (
                                                                <Option key={item.id} value={item.id}>{item.name}</Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={11}>
                                                    <Form.Item
                                                        name={[field.name, 'price']}
                                                        validateTrigger={['onChange', 'onBlur']}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Lütfen hizmet fiyatını giriniz!",
                                                            },
                                                        ]}
                                                        noStyle
                                                    >
                                                        <InputNumber
                                                            placeholder={t("general.service_price")}
                                                            style={{width:"100%"}}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={2}>
                                                    <Button
                                                        icon={<FontAwesomeIcon icon={faMinus}/>}
                                                        type="dashed"
                                                        onClick={() => remove(field.name)}
                                                    />
                                                </Col>
                                            </Row>
                                        </Form.Item>
                                    ))}
                                    <Form.Item>
                                        <Button
                                            type="dashed"
                                            onClick={() => add()}
                                            style={{
                                                width: '100%',
                                            }}
                                            icon={<FontAwesomeIcon icon={faPlus}/>}
                                        >
                                            {t("general.add_service")}
                                        </Button>
                                        <Form.ErrorList errors={errors} />
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}
export default ContractAdditionalServiceModalForm;