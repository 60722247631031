import axios from '../axios';

const UserService = {
    async getUsers(page = 1) {
        return await axios.get(`/v1/users?page=${page}`);
    },
    async create(formData) {
        return await axios.post(`/v1/users`,formData)},
    async delete(userId) {
        return await axios.delete(`/v1/users/${userId}`);
    },
    async readNotification(notificationId) {
        return await axios.post(`/v1/users/notifications/${notificationId}/read`);
    },
    async changeLanguage(language) {
        return await axios.get(`/v1/users/change-language?language=${language}`);
    }
}

export default UserService;