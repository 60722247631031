import React from 'react';
import {Alert, Col, Divider, Form, Input, Row, Tabs} from "antd";
import {useTranslation} from "react-i18next";
import AllLanguageSelect from "@/components/utils/AllLanguageSelect";
import TextArea from "antd/es/input/TextArea";

const { TabPane } = Tabs;

const HotelCreateGeneralStep = ({ form }) => {
    const hotelLanguages = Form.useWatch('hotel_languages', form);
    const { t } = useTranslation();
    return (
        <>
            <Row gutter={[16,2]}>
                <Col span={6}>
                    <Form.Item
                        label="Otel Adı"
                        name="hotel_name"
                        rules={[{ required: true, message: `${t("general.hotel_name")} ${t("general.cannot_be_empty")}` }]}
                    >
                        <Input
                            placeholder={t("general.hotel_name")}
                            style={{ width: "100%" }}
                        />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        label="Otel Dil Seçimi"
                        name="hotel_languages"
                        rules={[{ required: true, message: `${t("general.hotel_languages")} ${t("general.cannot_be_empty")}` }]}
                    >
                        <AllLanguageSelect
                            onChange={(values) => form.setFieldsValue({ hotel_languages: values })}
                            placeholder={t("general.hotel_languages")}
                            style={{ width: "100%" }}
                            value={hotelLanguages}
                            multiple
                        />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        label="Lokasyon"
                        name="hotel_location"
                        rules={[{ required: true, message: `${t("general.hotel_location")} ${t("general.cannot_be_empty")}` }]}
                    >
                        <Input
                            placeholder={t("general.hotel_location")}
                            style={{ width: "100%" }}
                        />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        label="Google Maps Bağlantısı"
                        name="hotel_google_maps"
                        rules={[{ required: true, message: `${t("general.hotel_google_map_link")} ${t("general.cannot_be_empty")}` }]}
                    >
                        <Input
                            placeholder={t("general.hotel_google_map_link")}
                            style={{ width: "100%" }}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Divider />
            {hotelLanguages?.length > 0 ? (
                <Tabs tabPosition="left">
                    {hotelLanguages.map((language) => (
                        <TabPane tab={language.label} key={language.value} forceRender={true}>
                            <Row gutter={[16,2]}>
                                <Col span={24}>
                                    <Form.Item
                                        label="Otel Açıklaması"
                                        name={["translations", language.value, "hotel_description"]}
                                        rules={[{ required: true, message: `${t("general.hotel_description")} ${t("general.cannot_be_empty")}` }]}
                                    >
                                        <TextArea
                                            placeholder={t("general.hotel_description")}
                                            style={{ width: "100%" }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </TabPane>
                    ))}
                </Tabs>
            ) : (
                <Alert
                    message={t("general.warning")}
                    description={t("general.choose_at_least_one_language")}
                    type="warning"
                    showIcon
                />
            )}
        </>
    )
};

export default HotelCreateGeneralStep;