import axios from '../axios';

const SaleReportService = {
    async get() {
        return await axios.get(`/v1/reports/sale`);
    },
    async download(customDate = {}) {
        return await axios.get(`/v1/reports/sale/download`,{
            responseType: 'blob',
            params: customDate
        });
    },
    async sendMail(customDate = {}) {
        return await axios.post(`/v1/reports/sale/send-email`,customDate);
    }
};

export default SaleReportService;