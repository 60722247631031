import React from 'react';
import {Col, Form, Input, Modal, Row, Select, Tabs} from "antd";
import {Link} from "react-router-dom";

const {TabPane} = Tabs;
const {Option} = Select;
const RoomAttributeEditModal = (props) => {
    return (
        <Modal visible={props.isVisible} title={props.title} closable={false} onOk={() => props.form.submit()} onCancel={props.onCancel}>
            <Form form={props.form} layout="vertical" onFinish={props.onFinish}>
                <Tabs>
                    {props.languages.map((language, i) => (
                    <TabPane key={i} tab={language?.web_language.name} forceRender={true}>
                        <Row gutter={[16,8]}>
                            <Col span={24}>
                                <Form.Item
                                    name="icon_id"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'İkon seçimi zorunludur',
                                        },
                                    ]}
                                    label="İkon"
                                    tooltip={<div>İkon listesi için <Link to="/icons" target="_blank" rel="noopener noreferrer">tıklayın</Link></div>}
                                >
                                    <Select
                                        showSearch
                                        filterOption={(input, option) =>
                                            (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        loading={props.icons.loading}
                                        disabled={props.icons.loading}
                                        placeholder="İkon seçimi yapınız!"
                                    >
                                        {props.icons.data?.map((icon, i) => {
                                            return (
                                                <Option key={i} value={icon.id}>
                                                    {icon.name}
                                                </Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name={['attributes', language?.web_language['iso_639-1'], 'name']}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Özellik girilmedi',
                                        },
                                    ]}
                                    label="Özellik"
                                >
                                    <Input placeholder="Özellik" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </TabPane>
                ))}
            </Tabs>
            </Form>
        </Modal>
    );
};

export default RoomAttributeEditModal;
