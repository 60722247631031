import axios from '../axios';

const ContractQuotaService = {
  async getByConceptId($contractId, $contractConceptId) {
    return await axios.get(`/v1/contract-quotas/${$contractId}/${$contractConceptId}`);
  },
  async create(contractId, contractConceptId, formData) {
    return await axios.post(`/v1/contract-quotas/${contractId}/${contractConceptId}`, formData);
  },
  async createByContractId(contractId, formData) {
    return await axios.post(`/v1/contract-quotas/${contractId}/dates`, formData);
  },
  async stopSale(contractId, formData) {
    return await axios.post(`/v1/contract-quotas/${contractId}/dates/stop-sale`, formData);
  },
  async openSale(contractId, formData) {
    return await axios.post(`/v1/contract-quotas/${contractId}/dates/open-sale`, formData);
  },
  async getByDate(params) {
    return await axios.get(`/v1/contract-quotas/${params.start_date}/${params.end_date}`,{
        params: {
          contracts: params.contracts,
        }
    });
  },
};

export default ContractQuotaService;