import axios from '../axios';

const ProfitReportService = {
    async get(values) {
        return await axios.get(`/v1/reports/profit`, {
            params: values
        });
    },
};

export default ProfitReportService;