import React, { useEffect, useState } from 'react';
import { notification, Select } from 'antd';
import { WebLanguageService } from '@services';

const { Option } = Select;

const AllLanguageSelect = ({ placeholder = "", multiple = false, onChange = () => {}, value }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [allLanguages, setAllLanguages] = useState([]);

    useEffect(() => {
        getAllLanguages();
    }, []);

    const getAllLanguages = async () => {
        setIsLoading(true);
        try {
            let { data: response } = await WebLanguageService.get();
            setAllLanguages(response?.data.map((language) => (
                {
                    value: language['iso_639-1'],
                    label: language.name
                }
            )))
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
        }
    };

    return (
        <Select
            onChange={(value) => onChange(value.map((value) => (
                allLanguages.find((language) => language.value === value)
            )))}
            loading={isLoading}
            mode={multiple ? "multiple" : ""}
            placeholder={placeholder}
            showSearch
            filterOption={(input, option) =>
                (option?.children ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
            }
            value={value}
            virtual
        >
            {allLanguages.map((language, index) =>
                <Option key={index} value={language.value}>{language.label}</Option>
            )}
        </Select>
    );
};

export default AllLanguageSelect;