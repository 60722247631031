import React, {useEffect, useState} from 'react';
import {Breadcrumb, Button, Col, Form, Layout, notification, Row, Space, Table} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHouse, faTrash} from "@fortawesome/free-solid-svg-icons";
import Wrapper from "@components/Wrapper";
import ContractCommissionModalForm from "@components/contracts/ContractCommissionModalForm";
import {useTranslation} from "react-i18next";
import {ContractService, ContractCommissionService} from "@services";
import {useParams} from "react-router-dom";
import moment from "moment";

const { Content } = Layout;

const ContractCommissions = () => {
    const params = useParams()
    const [form] = Form.useForm();
    const { t } = useTranslation()
    const [contracts, setContracts] = useState([]);
    const [isModalVisible, setIsModalVisible] = React.useState(false);
    const [data, setData] = React.useState({
        data: [],
        loading: false,
        meta: {}
    });
    const columns = [
        {
            title: "Kontrat Adı",
            dataIndex: "contract_name",
            key: "contract_name"
        },
        {
            title: "Komisyon Oranı",
            dataIndex: "commission",
            key: "commission",
            render: (text, record) => {
                return record.commission_type === 1 ? `${text}%` : `${text}`
            }
        },
        {
            title: "Başlangıç Tarihi",
            dataIndex: "start_date",
            key: "start_date",
            render: (text) => text ? moment(text).format("DD.MM.YYYY"): ""
        },
        {
            title: "Bitiş Tarihi",
            dataIndex: "end_date",
            key: "end_date",
            render: (text) => text ? moment(text).format("DD.MM.YYYY"): ""
        },
        {
            title: "İşlemler",
            key: "actions",
            render: (text, record) => (
                <Space>
                    <Button htmlType="button" type="danger" disabled={true}>
                        <FontAwesomeIcon icon={faTrash} />
                    </Button>
                </Space>
            )
        }
    ];
    const onCreate = async (values) => {
        values = {
            ...values,
            contract_group_id: params.contractGroupId,
            commissions: values?.commissions?.map((commission) => {
                return {
                    ...commission,
                    start_date: commission.date_range[0].format("YYYY-MM-DD"),
                    end_date: commission.date_range[1].format("YYYY-MM-DD"),
                }
            })
        }
        try {
            let { data: response } = await ContractCommissionService.create(values);
            if (response.success) {
                getCommissions()
                setIsModalVisible(false)
                notification.success({
                    message: response.message
                });
            }
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.message
                });
            }
            console.log(e);
        }
    }
    const getCommissions = async (page, per_page) => {
        try {
            let { data: commissions } = await ContractCommissionService.getByContractGroupId(page, per_page, params.contractGroupId);
            setData({
                data: commissions.data,
                loading: false,
                meta: commissions.meta
            });
        }catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }
    useEffect(() => {
        const getContracts = async () => {
            try {
                let { data: contracts } = await ContractService.getByGroupId(params.contractGroupId);
                setContracts(contracts);
            } catch (e) {
                if (e.response) {
                    notification.error({
                        message: e.response.data.message
                    });
                }
            }
        }
        getContracts()
        getCommissions()
        // eslint-disable-next-line
    },[])
    return (
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-20">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse} />
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background">
                    <Row gutter={[16,16]}>
                        <Col span={24}>
                            <Space direction="horizontal">
                                <Button
                                    htmlType="button"
                                    type="primary"
                                    onClick={() => setIsModalVisible(true)}
                                >
                                    {t("general.add")}
                                </Button>
                                <Button
                                    htmlType="button"
                                    type="danger"
                                    disabled={true}
                                    onClick={() => setIsModalVisible(true)}
                                >
                                    {t("general.clear_all")}
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                    <Table
                        columns={columns}
                        dataSource={data.data}
                        rowKey={record => record.id}
                        loading={data.loading}
                        pagination={{
                            total: data.meta?.total,
                            defaultPageSize: data.meta?.per_page,
                            onChange: (page, pageSize) => {
                                getCommissions(page, pageSize)
                            }
                        }}
                    />
                </div>
            </Content>
            <ContractCommissionModalForm
                isVisible={isModalVisible}
                onCancel={() => {
                    form.resetFields()
                    setIsModalVisible(false)
                }}
                title={t('general.contract_commissions')}
                form={form}
                contracts={contracts}
                onFinish={onCreate}
            />
        </Wrapper>
    );
};

export default ContractCommissions;