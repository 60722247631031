import React, { useEffect } from 'react';
import { Button, Form, Input, notification } from 'antd';
import useRouteHistory from '@hooks/useRouteHistory';
import {useNavigate, useSearchParams} from 'react-router-dom';

import { Authentication } from '@services';
import './reset-password.less';
import {useTranslation} from "react-i18next";

const ResetPassword = () => {
  const { t } = useTranslation()
  const [error, setError] = React.useState(null);
  const [loginForm] = Form.useForm();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { getLatestRoute } = useRouteHistory();

  useEffect(() => {
    if (!searchParams.has('token') || !searchParams.has('email')) {
      setError('Şifre sıfırlama bağlantısı geçersizdir, lütfen bağlantıyı kontrol edin.');
    }
    if (Authentication.hasToken()) {
      navigate('/');
    }
  }, []);

  const onFinishResetPassword = async (values) => {
    values = {
        ...values,
        token: searchParams.get('token'),
        email: searchParams.get('email')
    }
    try {
      let {data: response} = await Authentication.resetPassword(values);
      if (response.success){
        notification.success({
          message: response.message
        });
        setTimeout(() => {
            navigate('/login');
        }, 2000);
      }
    } catch (e) {
      if (e.response) {
        notification.error({
          message: e.response.data.message
        });
      }
    }
  };

  return (
    <div className="reset-password-wrapper">
      <div className="reset-password-form">
        <h1>{t('general.reset_password')}</h1>
        {error ? <p className="error">{t(error)}</p>: (
            <Form form={loginForm} layout="vertical" onFinish={onFinishResetPassword}>
              <Form.Item
                  label={t('general.password')}
                  name="password"
                  rules={[
                    {required: true, message: 'Şifre alanı zorunludur.'},
                    {min: 6, message: 'Şifreniz minimum 6 karakter olmalıdır.' }
                  ]}
              >
                <Input
                    type="password"
                    placeholder="********"
                />
              </Form.Item>

              <Form.Item
                  label={t('general.password_confirmation')}
                  name="password_confirmation"
                  rules={[
                    {required: true, message: 'Şifre doğrulama alanı zorunludur.'},
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject('Şifreler eşleşmiyor.');
                      },
                    }),
                  ]}
              >
                <Input
                    type="password"
                    placeholder="********"
                />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  {t('general.reset_password')}
                </Button>
              </Form.Item>
            </Form>
        )}
      </div>
    </div>
  )
};

export default ResetPassword;