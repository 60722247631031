import React from 'react';
import {Modal, Form, Input, Row, Col} from 'antd';
import {useTranslation} from "react-i18next";

const ContractGroupModalForm = (props) => {
    const { t } = useTranslation()
    return (
        <Modal visible={props.isVisible} title={props.title} closable={false} onOk={() => props.form.submit()} onCancel={props.onCancel}>
            <Form form={props.form} layout="vertical" onFinish={props.onFinish}>
                <Row gutter={[16,8]}>
                    <Col span={24}>
                        <Form.Item
                            name="name"
                            label={t("general.contract_group_name")}
                            rules={[{
                                required: true,
                                message: 'Kontrat Grup Adı Girmelisiniz'
                            }]}
                        >
                            <Input placeholder="Kontrat Adı" />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
};

export default ContractGroupModalForm;