import React, { useEffect, useState } from 'react';
import {
  Layout,
  Breadcrumb,
  Button,
  notification,
  Form,
  Table,
  Space,
  Popconfirm,
  Switch, Tag, Tooltip
} from 'antd';
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faEdit, faHouse, faPlus, faTrash, faShapes, faCreditCard} from '@fortawesome/free-solid-svg-icons';
import { ContractService, ContractWidgetService, PaymentMethodsService,ContractConceptPaymentOptionService } from '@services';
import Wrapper from '@components/Wrapper';
import ContractModalForm from '@components/contracts/ContractModalForm';
import LinkWidgetModalForm from '@components/widgets/LinkWidgetModalForm';
import ContractPosMatchModalForm from "@components/contracts/ContractPosMatchModalForm";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
const { Content } = Layout;

const Contract = () => {
  const { t } = useTranslation()
  const params = useParams();
  const permissions = useSelector((state) => state.user.user.role.permissions);

  const [selectedContract, setSelectedContract] = useState();
  const [linkWidgetModalVisible, setLinkWidgetModalVisible] = useState(false);
  const [contracts, setContracts] = useState([]);
  const [paymentTypes, setPaymentTypes] = useState([]);
  const [contractWidgets, setContractWidgets] = useState([]);
  const [isPosMatchModalVisible, setIsPosMatchModalVisible] = useState(false)

  const [additionalServices, setAdditionalServices] = useState({
    data: [],
    loading:true
  })
  const [isLoadingTable, setIsLoadingTable] = useState(true);

  const [createForm] = Form.useForm();
  const [editForm] = Form.useForm();
  const [linkForm] = Form.useForm();
  const [paymentTypeForm] = Form.useForm();
  const [additionalServiceForm] = Form.useForm();
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const columns = [
    {
      title: '#',
      render: (text, record, index) => index + 1
    },
    {
      title: `${t("general.room_name")}`,
      dataIndex: 'title',
      render: (text, record) =>
          <Link to={`${record.id}/details`}>
            {record.hotel_room.room_definition.active_language.name}
            &nbsp;({record?.title})
          </Link>
    },
    {
      title: `${t("general.concepts")}`,
      dataIndex: 'concepts',
      render:(text) => {
        return text.map((concept, index) => {
          return <Tag key={index} color="green">{concept?.concept?.active_language?.name}</Tag>
        })
      }
    },
    {
      title: `${t("general.widgets")}`,
      dataIndex: 'widgets',
      render: (text, record) => record.contract_widget.map((widget, index) => {
        return(
            <span key={index}>
                  "{widget.widget.widget_name}" ,
                </span>
        )
      }),
    },
    {
      title: `${t("general.active")}/${t("general.passive")}`,
      dataIndex: 'status',
      render: (text, record)=>{
        if (record.status!==1)
        {
          return (
              <Form.Item name="status" valuePropName="checked">
                <Switch checkedChildren={t("general.active")} defaultChecked unCheckedChildren={t("general.passive")} id={record.id} onChange={e=>statusChange(record.id,false)}/>
              </Form.Item>
          )
        }
        else {
          return (
              <Form.Item name="status" valuePropName="checked">
                <Switch checkedChildren="Aktif" unCheckedChildren="Pasif"  id={record.id} onChange={e=>statusChange(record.id,true)}/>
              </Form.Item>
          )
        }
      }
    },
    {
      title: `${t("general.actions")}`,
      render: (text, record) => (
          <Space align="middle">
            <Tooltip title={!permissions.includes('contract.edit') ? "Bu işlem için yetkiniz bulunmuyor.": "Düzenle"}>
              <Button disabled={!permissions.includes('contract.edit')} type="primary" onClick={() => onEditContract(record.id)}>
                <FontAwesomeIcon icon={faEdit} />
              </Button>
            </Tooltip>
            <Tooltip title={!permissions.includes('contract.delete') ? "Bu işlem için yetkiniz bulunmuyor.": "Sil"}>
              <Popconfirm disabled={!permissions.includes('contract.delete')} title="Silmek istediğine emin misin?" okText="Sil" cancelText="İptal" onConfirm={()=>onDelete(record.id)}>
                <Button type="danger" disabled={!permissions.includes('contract.delete')}>
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              </Popconfirm>
            </Tooltip>
            <Button type="primary" onClick={() => onShowWidgetLink(record.id,record.contract_widget)}>
              <FontAwesomeIcon icon={faShapes} />
            </Button>
            <Button type="primary" onClick={() => getContractPaymentTypes(record.id)}>
              <FontAwesomeIcon icon={faCreditCard} />
            </Button>
          </Space>
      )
    }
  ];

  useEffect(() => {
    getContractsByGroup(params.contractGroupId);
    getContractWidgets();
    getPaymentTypes();
  }, [params.contractGroupId]);

  const getContractPaymentTypes = async (contractId) => {
    try {
      let { data: {data: response} } = await ContractService.getById(params.contractGroupId, contractId);
      response?.concepts?.map((concept,i) => {
        paymentTypeForm.setFields([
          {
            name: ['concepts',i,'payment_types'],
            value: concept.payment_options.map((paymentOption) => paymentOption.payment_method_id)
          }, {
            name:['concepts', i, 'concept_id'],
            value: concept.id
          }
        ])
      })
        setSelectedContract(response);
        setIsPosMatchModalVisible(true);
    }catch (e){
      console.log(e)
    }
  }



  const getPaymentTypes = async () => {
    try {
      let { data: {data:response} } = await PaymentMethodsService.get();
      setPaymentTypes(response);
    } catch (e) {
      if (e.response) {
        notification.error({
          message: e.response.data.message
        });
      }
    }
  }


  const onCreatePosMatch = async (values,contractId) => {
    try {
      let { data: response } = await ContractConceptPaymentOptionService.create(values,contractId);
      if(response.success){
        notification.success({
          message: response.message
        });
        setIsPosMatchModalVisible(false);
      }
    } catch (e) {
      if (e.response) {
        notification.error({
          message: e.response.data.message
        });
      }
    }
  }



  const getContractsByGroup = async () => {
    try {
      let { data: {data:response} } = await ContractService.getByGroupId(params.contractGroupId);
      setContracts(response)
      setIsLoadingTable(false);
    } catch (e) {
      if (e.response) {
        notification.error({
          message: e.response.data.message
        });
      }
    }
  };
  const onDelete = async (contractId) => {
    try {
      let { data:response } = await ContractService.delete(contractId);
      if (response.success) {
        notification.success({
          message: response.message
        });
        setIsLoadingTable(true);
        getContractsByGroup(params.contactGroupId);
      }
    } catch (e) {
      if (e.response) {
        notification.error({
          message: e.response.message
        });
      }
    }
  };
  const onEditContract = async (contractId) => {
    try {
      let { data: {data: response} } = await ContractService.getById(params.contractGroupId, contractId);
       editForm.setFieldsValue({
         title: response.title,
         contract_language_id: response.contract_language_id,
         hotel_room_id: response.hotel_room_id,
         condition_group_id: response.condition_group_id,
         period_id: response.period_id,
         concepts: response?.concepts?.map(concept => concept?.concept_id),
       });
        setSelectedContract(response);
        setIsEditModalVisible(true);
    } catch (e) {
      if (e.response) {
        notification.error({
          message: e.response.message
        });
      }
    }
  };
  const getContractWidgets = async () => {
    try {
      let { data: response } = await ContractWidgetService.get();
      setContractWidgets(response.data);
      setIsLoadingTable(false);
    } catch (e) {
      if (e.response) {
        notification.error({
          message: e.response.data.message
        });
      }
    }
  }

  const onCreate = async (values) => {
    try {
      let { data: response } = await ContractService.create(params.hotelId, {
        hotel_id: params.hotelId,
        ...values
      });
      if (response.success) {
        setIsCreateModalVisible(false)
        getContractsByGroup(params.contactGroupId);
        createForm.resetFields();
        notification.success({
          message: response.message
        });
      }
    } catch (e) {
      if (e.response) {
        notification.error({
          message: e.response.data.message
        });
      }
    }
  }

  const onEdit = async (values,id) => {
    try {
      let { data: response } = await ContractService.edit(params.hotelId, id, {
        hotel_id: params.hotelId,
        ...values
      });
      if (response.success) {
        setIsEditModalVisible(false)
        getContractsByGroup(params.contactGroupId);
        editForm.resetFields();
        notification.success({
          message: response.message
        });
      }
    } catch (e) {
      if (e.response) {
        notification.error({
          message: e.response.data.message
        });
      }
    }
  }



  const onShowWidgetLink = (id) => {
    setSelectedContract(id);
    setLinkWidgetModalVisible(true);
  }
  const linkWidget = async (values) => {
    const widgetIds = []
    values.widget_id.map((widget) => {
      widgetIds.push({ widget_id: widget, contract_id: selectedContract })
    })
    try {
      let { data: response } = await ContractService.linkWidgetToContract(params.hotelId, selectedContract, widgetIds);

      if (response.success) {
        setIsLoadingTable(true);
        getContractsByGroup(params.contactGroupId);
        setLinkWidgetModalVisible(false);
        notification.success({
          message: response.message
        });
      }
    } catch (e) {
      if (e.response) {
        notification.error({
          message: e.response.data.message
        });
      }

      console.log(e);
    }
  }
  const statusChange = async (id,status) =>
  {
    try {
      let { data: response } = await ContractService.setStatus(id,status);

      if (response.success) {
        setIsLoadingTable(true);
        getContractsByGroup(params.contactGroupId);
        setLinkWidgetModalVisible(false);
        notification.success({
          message: response.message
        });
      }
    } catch (e) {
      if (e.response) {
        notification.error({
          message: e.response.data.message
        });
      }
      console.log(e);
    }
  }

  return (
      <Wrapper>
        <Content className="m-25">
          <Breadcrumb className="mb-20">
            <Breadcrumb.Item href="/">
              <FontAwesomeIcon icon={faHouse} />
            </Breadcrumb.Item>
            <Breadcrumb.Item href="/contract-hotels">
              Kontrat Otelleri
            </Breadcrumb.Item>
            <Breadcrumb.Item href={`/contract-hotels/${params.hotelId}/contract-groups`}>
              Kontrat Grupları
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              Odalar
            </Breadcrumb.Item>
          </Breadcrumb>
          <div className="site-layout-background">
            <Tooltip title={!permissions.includes('contract.create') ? "Bu işlem için yetkiniz bulunmuyor.": "Yeni Oluştur"}>
              <Button disabled={!permissions.includes('contract.create')} type="primary" className="mb-10" onClick={() => setIsCreateModalVisible(true)}>
                <FontAwesomeIcon icon={faPlus} className="mr-10" />
                Yeni Oda Tanımla
              </Button>
            </Tooltip>

            <Table
                loading={isLoadingTable}
                dataSource={contracts}
                rowKey="id"
                columns={columns}
                bordered
            >
            </Table>

          </div>
        </Content>

        <ContractModalForm
            title="Kontrat Oluştur"
            form={createForm}
            isVisible={isCreateModalVisible}
            onFinish={onCreate}
            onCancel={() => setIsCreateModalVisible(false)}
            contractId={params.contractId}
        />

        <ContractModalForm
            title="Kontrat Düzenle"
            form={editForm}
            isVisible={isEditModalVisible}
            onFinish={onEdit}
            onCancel={() => setIsEditModalVisible(false)}
            contractId={params.contractId}
            data={selectedContract}
        />

        <LinkWidgetModalForm
            form={linkForm}
            isVisible={linkWidgetModalVisible}
            title="Kontrat Widget Ekle"
            onFinish={linkWidget}
            data={{selectedContract,contractWidgets}}
            onCancel={() => {
              setLinkWidgetModalVisible(false);
              linkForm.resetFields();
            }}
        />

        <ContractPosMatchModalForm
            isVisible={isPosMatchModalVisible}
            title="Sanal Pos Eşleştirme"
            form={paymentTypeForm}
            onFinish={onCreatePosMatch}
            onCancel={() => {setIsPosMatchModalVisible(false);paymentTypeForm.resetFields();}}
            paymentTypes={paymentTypes}
            selectedContract={selectedContract}
        />

      </Wrapper>
  );
};

export default Contract;