import React, {useEffect} from 'react';
import {Breadcrumb, Button, Col, Form, Layout, notification, Row, Table, Tag} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHouse} from "@fortawesome/free-solid-svg-icons";
import Wrapper from "@components/Wrapper";
import {Link, useParams} from "react-router-dom";
import { CMContractService } from '@services';
import CmContractCreateModalForm from "@components/channel-management/CMContractCreateModalForm";

const {Content} = Layout;

const CMContractList = () => {
    const columns = [
        {
            title: 'Kontrat ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Kontrat Adı',
            dataIndex: 'title',
            key: 'title',
            render: (text,record) => <Link to={`${record.id}/prices`}>{text}</Link>
        },
        {
            title: 'Oda',
            dataIndex: 'room',
            key: 'room',
            render: (text) => text ? text.name : null
        },
        {
            title: 'Konsept',
            dataIndex: 'room',
            key: 'concepts',
            render: (text) => text ? text?.concepts?.map((concept) => concept.concept.name).join(", ") : null
        },
        {
            title: 'Periyot',
            dataIndex: 'period',
            key: 'period',
            render: (text) => text ? text.title : null
        },
        {
            title: 'Kondüsyon',
            dataIndex: 'condition',
            key: 'condition',
            render: (text) => text ? text.title : null
        },
        {
            title: 'Aktif mi?',
            dataIndex: 'is_active',
            key: 'is_active',
            render: (text) => text ? <Tag color="green">Evet</Tag> : <Tag color="red">Hayır</Tag>
        }
    ];
    const [isLoading, setIsLoading] = React.useState(true);
    const [isModalVisible, setIsModalVisible] = React.useState(false);
    const [contracts, setContracts] = React.useState({
        data: [],
        meta: {},
        hotel: {},
        periods: [],
        rooms: [],
        conditions: []
    });
    const [form] = Form.useForm();
    const params = useParams()
    const getContracts = async (contractGroupId, hotelId, page) => {
        setIsLoading(true)
        try{
            let {data: response} = await CMContractService.get(contractGroupId, hotelId, page);
            setContracts({
                data: response.data,
                meta: response.meta,
                hotel: response.hotel,
                periods: response.periods,
                rooms: response.rooms,
                conditions: response.conditions
            });
            setIsLoading(false);
        }catch (e){
            if(e.response){
                notification.error({
                    message: e.response.data.message,
                });
            }
        }
    }

    const onCreate = async (values) => {
        values = {...values, hotel_id: params.hotelId, is_active: values.is_active ? 1 : 0}
        try {
            let response = await CMContractService.create(params.contractGroupId, values);
            setContracts({
                ...contracts,
                data: [...contracts.data, response.data.data]
            });
            setIsModalVisible(false);
            form.resetFields();
            notification.success({
                message: response.data.message,
            });
        }catch (e){
            if(e.response){
                notification.error({
                    message: e.response.data.message,
                });
            }
        }
    }

    useEffect(() => {
        getContracts(params.contractGroupId, params.hotelId);
    },[params.hotelId, params.contractGroupId])
    return (
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-20">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse}/>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href="/channel-management/hotels">
                        Kanal Yönetimi - Tesisler
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href={`/channel-management/contract-hotels/${params.hotelId}/contract-groups`}>
                        Kanal Yönetimi - Kontrat Grupları
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {contracts?.hotel?.name ?? '...'} - Kontrat Listesi
                    </Breadcrumb.Item>
                </Breadcrumb>

                <div className="site-layout-background">
                    <Row gutter={[16,16]}>
                        <Col span={24}>
                            <Button type="primary" className="mb-10" onClick={() => setIsModalVisible(true)}>Yeni Kontrat Ekle</Button>
                        </Col>
                        <Col span={24}>
                            <Table
                                columns={columns}
                                rowKey={record => record.id}
                                dataSource={contracts.data}
                                loading={isLoading}
                                pagination={{
                                    pageSize: contracts?.meta?.per_page,
                                    total: contracts?.meta?.total,
                                    current: contracts?.meta?.current_page,
                                }}
                                onChange={(pagination) => getContracts(params.contractGroupId, params.hotelId, pagination.current)}
                            />
                        </Col>
                    </Row>
                </div>
            </Content>
            <CmContractCreateModalForm
                isVisible={isModalVisible}
                onFinish={onCreate}
                onCancel={() => setIsModalVisible(false)}
                form={form}
                title="Yeni Kontrat Oluştur"
                hotelId={params.hotelId}
                periods={contracts?.periods ?? []}
                rooms={contracts?.rooms ?? []}
                conditions={contracts?.conditions ?? []}
            />
        </Wrapper>
    );
};

export default CMContractList;