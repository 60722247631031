import axios from '../axios';

const AgencyWebDocumentService = {
    async getByHotel(hotelId) {
        return await axios.get(`/v1/site-manager/documents/${hotelId}`);
    },
    async delete(id) {
        return await axios.delete(`/v1/site-manager/documents/${id}`);
    },
};

export default AgencyWebDocumentService;