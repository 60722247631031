import axios from '../axios';

const CityService = {
    async get(main_region_id) {
        return await axios.get(`/v1/definitions/cities?main_region_id=${main_region_id}`);
    },
    async getPaginate(page) {
        return await axios.get(`/v1/definitions/cities?page=${page}`);
    },
    async create(formData) {
        return await axios.post(`/v1/definitions/cities`, formData);
    },
    async delete(id) {
        return await axios.delete(`/v1/definitions/cities/${id}`);
    },
};

export default CityService;