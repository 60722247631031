import React from 'react';
import {Modal, Form, Row, Col} from 'antd';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const HotelAgencyInfoModalForm = (props) => {
    return (
        <Modal visible={props.isVisible} title={props.title} closable={false} onOk={() => props.form.submit()} onCancel={props.onCancel} width={1000} okText="Kaydet" cancelText="İptal">
            <Form form={props.form} layout="vertical" onFinish={(values) => props.onFinish(values)}>
                <Row gutter={[16,4]}>
                    <Col span={24}>
                        <Form.Item
                            name="info"
                            label="Bilgilendirme Mesajı"
                            valuePropName='data'
                            getValueFromEvent={(event, editor) => {
                                return editor.getData();
                            }}
                        >
                            <CKEditor
                                editor={ ClassicEditor }
                                config={{
                                    toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote'],
                                    alignment: {
                                        options: [ 'left', 'right' ]
                                    },
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
};

export default HotelAgencyInfoModalForm;
