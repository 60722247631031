import React, {useEffect, useState} from "react";
import {Breadcrumb, Typography, Table, notification, Tag} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHouse} from "@fortawesome/free-solid-svg-icons";
import { HotelService} from '@services'
import { Layout } from 'antd';
import Wrapper from "@components/Wrapper";
import { Link } from "react-router-dom";

const { Content } = Layout;
const { Title } = Typography;


function SiteList(){
    const [isLoadingTable,setIsLoadingTable] = useState(true)
    const [hotels,setHotels] = useState({})
    const columns = [
        {
            title: '#',
            key: 'id',
            render: (text, record, index) => index + 1
        },
        {
            title: 'Otel Adı',
            dataIndex: 'name',
            key: 'name',
            render:(text,record) =>(
                <Link to={`${record.id}`}>{text}</Link>
            )
        },
        {
            title: 'Durum',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => (
                <Tag color={record.is_active ? 'green' : 'red'}>{record.is_active ? 'Aktif' : 'Pasif'}</Tag>
            )
        },
    ]
    const getHotels = async (page = 1) => {
        try {
            let { data: response } = await HotelService.get(page);
            setHotels(response);
            setIsLoadingTable(false);
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    };
    useEffect(()=>{
        getHotels()
    },[])
    return (
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-20">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse} />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        Site Yönetimi
                    </Breadcrumb.Item>
                </Breadcrumb>

                <div className="site-layout-background">
                    <Title level={4}>Hangi Otelinizi Özelleştirmek İstiyorsunuz?</Title>
                    <Table
                        columns={columns}
                        dataSource={hotels?.data}
                        rowKey="id"
                        loading={isLoadingTable}
                        bordered
                    />
                </div>
            </Content>
        </Wrapper>
    );
}
export default SiteList