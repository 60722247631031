import React, {useEffect} from 'react';
import {Breadcrumb, Button, Col, Form, Layout, notification, Row, Space, Table, Tooltip} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBaby, faBabyCarriage,
    faCalendar,
    faChildren,
    faClipboardList,
    faDoorOpen,
    faHouse,
    faLaptopCode,
    faStar
} from "@fortawesome/free-solid-svg-icons";
import Wrapper from "@components/Wrapper";
import CmHotelCreateModalForm from "@components/channel-management/CMHotelCreateModalForm";
import {CMHotelService, CMOperatorService, CMHotelMatchService} from "@services";
import CMHotelOperatorMatchModalForm from "@components/channel-management/CMHotelOperatorMatchModalForm";

const {Content} = Layout;

const CmHotelList = () => {
    const columns = [
        {
            title: 'Otel ID',
            dataIndex: 'id',
            key: 'id',
            width: 200,
        },
        {
            title: 'Otel Adı',
            dataIndex: 'name',
            key: 'name',
            width: "50%",
            render: (text) => text
        },
        {
            title: 'İşlemler',
            key: 'action',
            render: (text, record) => (
                <Space>
                    <Tooltip title="Oda Tanımla">
                        <Button type="primary" href={`hotels/${record.id}/rooms`}>
                            <FontAwesomeIcon icon={faDoorOpen}/>
                        </Button>
                    </Tooltip>
                    <Tooltip title="Konsept Tanımla">
                        <Button type="primary" href={`hotels/${record.id}/concepts`}>
                            <FontAwesomeIcon icon={faStar}/>
                        </Button>
                    </Tooltip>
                    <Tooltip title="Periyot Tanımla">
                        <Button type="primary" href={`hotels/${record.id}/periods`}>
                            <FontAwesomeIcon icon={faCalendar}/>
                        </Button>
                    </Tooltip>
                    <Tooltip title="Kondüsyon Tanımla">
                        <Button type="primary" href={`hotels/${record.id}/condition-groups`}>
                            <FontAwesomeIcon icon={faChildren}/>
                        </Button>
                    </Tooltip>
                    <Tooltip title="Çocuk Yaşı Tanımla">
                        <Button type="primary" href={`hotels/${record.id}/child-ages`}>
                            <FontAwesomeIcon icon={faBabyCarriage}/>
                        </Button>
                    </Tooltip>
                    {/*<Tooltip title="Politika Tanımla">
                        <Button type="primary" href={`hotels/${record.id}/policies`}>
                            <FontAwesomeIcon icon={faClipboardList}/>
                        </Button>
                    </Tooltip>
                    <Tooltip title="Operator Eşleştirmesi">
                        <Button type="primary" onClick={() => {
                            setSelectedHotel(record);
                            setIsOperatorModalVisible(true)
                        }}>
                            <FontAwesomeIcon icon={faLaptopCode}/>
                        </Button>
                    </Tooltip>*/}
                </Space>
            )
        }
    ];
    const [isModalVisible, setIsModalVisible] = React.useState(false);
    const [isOperatorModalVisible, setIsOperatorModalVisible] = React.useState(false);
    const [operators, setOperators] = React.useState([]);
    const [selectedHotel, setSelectedHotel] = React.useState({});
    const [isLoading, setIsLoading] = React.useState(true);
    const [hotels, setHotels] = React.useState({
        data: [],
        meta: {}
    });
    const [form] = Form.useForm();
    const [operatorMatchForm] = Form.useForm();

    const getHotels = async (page) => {
        setIsLoading(true)
        try{
            let {data: response} = await CMHotelService.get(page);
            setHotels({
                data: response.data,
                meta: response.meta
            });
            setIsLoading(false);
        }catch (e){
            if(e.response){
                notification.error({
                    message: e.response.data.message,
                });
            }
        }
    }

    const onCreate = async (values) => {
        try{
            let response = await CMHotelService.create(values);
            setHotels({
                ...hotels,
                data: [
                    ...hotels.data,
                    response.data.data
                ]
            });
            setIsModalVisible(false);
            form.resetFields();
            notification.success({
                message: response.data.message,
            });
        }catch (e){
            if(e.response){
                notification.error({
                    message: e.response.data.message,
                });
            }
        }
    }
    const onOperatorMatch = async (id, values) => {
        try{
            let response = await CMHotelMatchService.create(id, values);
            setIsOperatorModalVisible(false);
            form.resetFields();
            notification.success({
                message: response.data.message,
            });
        }catch (e){
            if(e.response){
                notification.error({
                    message: e.response.data.message,
                });
            }
        }
    }

    useEffect(() => {
        const getOperators = async () => {
            try{
                let {data: response} = await CMOperatorService.get();
                setOperators(response.data);
            }catch (e){
                if(e.response){
                    notification.error({
                        message: e.response.data.message,
                    });
                }
            }
        }
        /*getOperators();*/
        getHotels();
    },[])

    return (
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-20">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse}/>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        Kanal Yönetimi - Tesis Listesi
                    </Breadcrumb.Item>
                </Breadcrumb>

                <div className="site-layout-background">
                    <Row gutter={[16,16]}>
                        <Col span={24}>
                            <Button type="primary" className="mb-10" onClick={() => setIsModalVisible(true)}>Yeni Otel Ekle</Button>
                        </Col>
                        <Col span={24}>
                            <Table
                                columns={columns}
                                rowKey={record => record.id}
                                dataSource={hotels.data}
                                loading={isLoading}
                                pagination={{
                                    pageSize: hotels?.meta?.per_page,
                                    total: hotels?.meta?.total,
                                    current: hotels?.meta?.current_page,
                                }}
                                onChange={(pagination) => getHotels(pagination.current)}
                            />
                        </Col>
                    </Row>
                </div>
            </Content>
            <CmHotelCreateModalForm
                isVisible={isModalVisible}
                title="Yeni Otel Ekle"
                form={form}
                onCancel={() =>{
                    setIsModalVisible(false);
                    form.resetFields();
                }}
                onFinish={onCreate}

            />
            <CMHotelOperatorMatchModalForm
                isVisible={isOperatorModalVisible}
                title="Operator Eşleştir"
                form={operatorMatchForm}
                onCancel={() =>{
                    setIsOperatorModalVisible(false);
                    operatorMatchForm.resetFields();
                }}
                onFinish={onOperatorMatch}
                operators={operators}
                hotel={selectedHotel}
            />
        </Wrapper>
    );
};

export default CmHotelList;