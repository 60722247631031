import React from 'react';
import {Col, Form, Input, Modal, Row, Select, Switch} from "antd";

const { Option } = Select;

const CmContractCreateModalForm = (props) => {
    return (
        <Modal visible={props.isVisible} title={props.title} closable={false} onOk={() => props.form.submit()} onCancel={props.onCancel}>
            <Form form={props.form} layout="vertical" onFinish={(values) => props.onFinish(values, props.data?.id)} initialValues={{ is_active: true }} fields={props.data?.fields}>
                <Row gutter={[16,0]}>
                    <Col span={12}>
                        <Form.Item
                            name="title"
                            label="Kontrat Adı"
                            rules={[{ required: true, message: 'Lütfen kontrat adını giriniz.' }]}
                        >
                            <Input placeholder="Kontrat adını giriniz"/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="room_id"
                            label="Oda"
                            rules={[{ required: true, message: 'Lütfen oda seçiniz.' }]}
                        >
                            <Select placeholder="Oda seçiniz">
                                {props?.rooms?.map((room) => (
                                    <Option key={room.id} value={room.id}>{room.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="period_id"
                            label="Periyot"
                            rules={[{ required: true, message: 'Lütfen periyot seçiniz.' }]}
                        >
                            <Select placeholder="Periyot seçiniz">
                                {props?.periods?.map((period) => (
                                    <Option key={period.id} value={period.id}>{period.title}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="condition_id"
                            label="Kondüsyon"
                            rules={[{ required: true, message: 'Lütfen kondüsyon seçiniz.' }]}
                        >
                            <Select placeholder="Kondüsyon seçiniz">
                                {props?.conditions?.map((condition) => (
                                    <Option key={condition.id} value={condition.id}>{condition.title}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="is_active"
                            label="Aktif mi?"
                            defaultValue={true}
                            valuePropName="checked"
                        >
                            <Switch checkedChildren="Aktif" unCheckedChildren="Pasif"/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default CmContractCreateModalForm;