import axios from '../axios';

const HotelCategorySectionService = {
    async get(categoryId) {
        return await axios.get(`/v1/site-manager/hotel-category-sections/${categoryId}`);
    },
    async create(categoryId,formData) {
        return await axios.post(`/v1/site-manager/hotel-category-sections/${categoryId}`,formData);
    },
    async changeOrder(formData){
        return await axios.post(`/v1/site-manager/hotel-category-sections/order`,formData);
    }
};

export default HotelCategorySectionService;