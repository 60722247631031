import axios from '../axios';

const BankTransactionService = {
    async get(page, per_page, filters) {
        return await axios.get(`/v1/reports/bank-transactions`,{
            params: {
                page: page,
                per_page: per_page,
                filters: filters
            }
        });
    },
    async exportExcel(filters) {
        axios.defaults.responseType = 'blob';
        let data = await axios.post(`/v1/reports/bank-transactions/export/excel`, {
            filters: filters
        });
        axios.defaults.responseType = 'json';
        return data;
    },
};

export default BankTransactionService;