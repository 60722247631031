import axios from '../axios';

const ExternalContractGroupService = {
    async get(hotelId, page = 1) {
        return await axios.get(`/v1/external/hotels/${hotelId}/contract-groups?page=${page}`);
    },
    async create(hotelId, formData) {
        return await axios.post(`/v1/external/hotels/${hotelId}/contract-groups`, formData);
    },
};

export default ExternalContractGroupService;