import React, { useEffect } from 'react';
import {Modal, Form, Input, Tabs, Select, Row, Col, DatePicker} from 'antd';
import {useTranslation} from "react-i18next";

const { TabPane } = Tabs;
const {Option} = Select;
const current = new Date();
const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
const UserCreateModalForm = (props) => {
    const { t } = useTranslation()
    return (
        <Modal visible={props.isVisible} title={props.title} closable={false} onOk={() => props.form.submit()} onCancel={props.onCancel}>
            <Form form={props.form} layout="vertical" onFinish={(values) => props.onFinish(values, props.data?.id)} fields={props.data?.fields} initialValues={{
                role_id:'2'
            }}>
               <Tabs defaultActiveKey="1">
                <TabPane tab={t("general.user")} key="1" >
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item name="first_name" label={t("general.name")}>
                                <Input autoComplete="off" placeholder={t("general.name")} />
                            </Form.Item>
                        </Col>
                            <Col span={12}>
                            <Form.Item name="last_name" label={t("general.surname")}>
                                <Input autoComplete="off" placeholder={t("general.surname")} />
                            </Form.Item>
                        </Col>
                            <Col span={12}>
                            <Form.Item name="email" label={t("general.email")}>
                                <Input autoComplete="off" placeholder={t("general.password")}/>
                            </Form.Item>
                        </Col>
                            <Col span={12}>
                            <Form.Item name="password" label={t("general.password")}>
                                <Input autoComplete="off" type='password' placeholder={t("general.password")}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="role_id" label="Rol">
                                <Select style={{pointerEvents:"none",opacity:"0.7"}} placeholder={t("general.choose")} >
                                    <Option key={2} value={'2'}>{t("general.agency_admin")}</Option>

                                    {/* {props?.roles.map((role,index)=>(

                                    <Option key={index} value={role.id}>{role.name}</Option>
                                ))}
                                */}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    </TabPane>
                    <TabPane tab={t("general.agency")} key="2">
                        <Row gutter={16}>
                            <Col span={12}>
                            <Form.Item name="company_name" label={t("general.agency_name")}>
                                <Input placeholder="Şirket Adı" />
                            </Form.Item>
                        </Col>
                            <Col span={12}>
                            <Form.Item name="nameplate_title" label={t("general.sign_title")}>
                                <Input placeholder="Tabela Ünvanı" />
                            </Form.Item>
                        </Col>
                            <Col span={12}>
                            <Form.Item name="tax_number" label={t("general.tax_number")}>
                                <Input placeholder="Vergi Numarası" />
                            </Form.Item>
                        </Col>
                            <Col span={12}>
                            <Form.Item name="tax_administration" label={t("general.tax_administration")}>
                                <Input placeholder="Vergi Dairesi" />
                            </Form.Item>
                        </Col>
                            <Col span={12}>
                            <Form.Item name="authorized_person" label={t("general.authorized_person")}>
                                <Input placeholder="Yetkili Kişi" />
                            </Form.Item>
                        </Col>
                            <Col span={12}>
                            <Form.Item name="authorized_contact" label={t("general.authorized_phone")}>
                                <Input placeholder="Yetkili İletişim" />
                            </Form.Item>
                        </Col>
                            <Col span={12}>
                            <Form.Item name="long" label={t("general.longitude")}>
                                <Input placeholder="Long" />
                            </Form.Item>
                        </Col>
                            <Col span={12}>
                            <Form.Item name="lat" label={t("general.latitude")}>
                                <Input placeholder="Lat" />
                            </Form.Item>
                        </Col>
                            <Col span={12}>
                            <Form.Item name="membership_start_date" label={t("general.start_date")}>
                                <DatePicker selected={date}  />
                            </Form.Item>
                        </Col>
                            <Col span={12}>
                            <Form.Item name="membership_end_date" label={t("general.end_date")}>
                                <DatePicker selected={date}  />
                            </Form.Item>
                        </Col>
                            </Row>
                    </TabPane>
               </Tabs>
            </Form>
        </Modal>
    );
};

export default UserCreateModalForm;