import axios from '../axios';

const SafeBankDefinitionService = {
    async get(page, pageSize) {
        return await axios.get(`/v1/safe-bank?page=${page}&per_page=${pageSize}`);
    },
    async getByPayment(paymentId) {
        return await axios.get(`/v1/safe-bank/payment-method/${paymentId}`);
    },
    async getByReservationId(reservationId, paymentId) {
        return await axios.get(`/v1/safe-bank/payment-method/${paymentId}/reservation/${reservationId}`);
    },
    async getByHotelId(hotelId, paymentId) {
        return await axios.get(`/v1/safe-bank/payment-method/${paymentId}/hotel/${hotelId}`);
    },
    async delete(safeBankId) {
        return await axios.delete(`/v1/safe-bank/${safeBankId}`);
    },
    async create(formData) {
        return await axios.post('/v1/safe-bank',formData);
    },

};

export default SafeBankDefinitionService;