import React from 'react';
import {Col, Form, Input, InputNumber, Modal, Row} from "antd";

const CmChildAgeCreateModalForm = (props) => {
    return (
        <Modal visible={props.isVisible} title={props.title} closable={false} onOk={() => props.form.submit()} onCancel={props.onCancel}>
            <Form form={props.form} layout="vertical" onFinish={(values) => props.onFinish(values, props.data?.id)} initialValues={{ is_active: true }} fields={props.data?.fields}>
                <Row gutter={[16,0]}>
                    <Col span={24}>
                        <Form.Item
                            name="name"
                            label="Çocuk Yaşı Adı"
                            rules={[{ required: true, message: 'Lütfen çocuk yaş adını giriniz.' }]}
                        >
                            <Input placeholder="Çocuk yaş adını giriniz"/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="minimum_age"
                            label="Minimum Yaş"
                            rules={[{ required: true, message: 'Lütfen minimum yaşı giriniz.' }]}
                        >
                            <InputNumber style={{width:"100%"}} placeholder="Minimum yaşı giriniz"/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="maximum_age"
                            label="Maksimum Yaş"
                            rules={[{ required: true, message: 'Lütfen maksimum yaşı giriniz.' }]}
                        >
                            <InputNumber style={{width:"100%"}} placeholder="Maksimum yaşı giriniz"/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default CmChildAgeCreateModalForm;