import axios from '../axios';

const CMPriceService = {
    async create(hotelId, contractGroupId, contractId, formData) {
        return await axios.post(`/v1/channel-management/contracts/prices/`, {
            hotel_id: hotelId,
            contract_group_id: contractGroupId,
            contract_id: contractId,
            ...formData
        });
    },
    async get(contractId, contractGroupId, hotelId) {
        return await axios.get(`/v1/channel-management/contracts/prices`, {
            params: {
                hotel_id: hotelId,
                contract_id: contractId,
                contract_group_id: contractGroupId,
            }
        });
    },
    async getByDate(params) {
        return await axios.get(`/v1/channel-management/price-changes/list`,{
            params: {
                start_date: params.start_date,
                end_date: params.end_date,
                contracts: params.contracts,
                hotel_id: params.hotel_id,
                contract_group_id: params.contract_group_id,
            }
        });
    },
};

export default CMPriceService;