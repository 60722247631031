import React, { useState, useEffect, useMemo } from 'react';
import { Modal, Form, Input, Row, Col, Select, notification } from 'antd';
import { useParams } from "react-router-dom";
import WebLanguageService from "@services/web-languages/WebLanguage.service";
import ContractGroupLanguageService from '../../services/contract_group_languages/ContractGroupLanguage.service';
import {useTranslation} from "react-i18next";

const { Option } = Select;
const ContractGroupLanguageModalForm = (props) => {
    const { t } = useTranslation()
    const [hotelLanguage, setHotelLanguage] = useState([]);
    const [selectedContractLanguages, setContractSelectedLanguages] = useState([]);

    const params = useParams();


    useEffect(() => {
        getHotelLanguage();
    }, [props.data]);

    const getHotelLanguage = async () => {
        try {
            let response = await WebLanguageService.getHotelLanguage(props.hotelId);
            setHotelLanguage(response.data.data);
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    };

    const getSelectedLanguages = async () => {
        let value = props.contract_group_id;
        try {
            let { data: { data: response } } = await ContractGroupLanguageService.show(value);
            props.form.setFieldsValue({
                hotel_language_id: response.map((item) => item.hotel_language_id)
            })
            setContractSelectedLanguages(response.data.data);
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    };
    useEffect(() => {
        if (props.contract_group_id) {
            getSelectedLanguages();
        }
    }, [props.contract_group_id]);


    return (
        <Modal visible={props.isVisible} title={props.title} closable={false} onOk={() => props.form.submit()} onCancel={props.onCancel}>
            <Form form={props.form} layout="vertical" onFinish={(values) => props.onFinish(values, props.data?.name)}>
                <Row gutter={[16]}>
                    <Col span={24}>
                        <Form.Item name="hotel_language_id" label={t("general.languages")}>
                            <Select mode="multiple">
                                {hotelLanguage.map((item) => <Option value={item.id} key={`${item.id}`}>{item.web_language?.name}</Option>)}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal >
    )
};

export default ContractGroupLanguageModalForm;
