import axios from '../axios';

const PaymentTableService = {
    async get(start_date, end_date, hotel) {
        return await axios.get(`/v1/payment-periods`, {
            params: {
                start_date: start_date,
                end_date: end_date,
                hotel: hotel
            }
        });
    },
}
export default PaymentTableService