import React, { useEffect, useState } from "react";
import {
  Breadcrumb,
  Form,
  Input,
  Button,
  Layout,
  Row,
  Col,
  Select,
  Tabs,
  Table,
  Space,
  Switch,
  notification,
  InputNumber,
  Tooltip, Spin
} from "antd";
import {faHotel, faHouse, faImages, faPlus, faSave, faTrash,faEdit} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Wrapper from "@components/Wrapper";
import { HotelCategoryService } from "@services";
import { HotelService, RoomDefinitionService,RoomImageService,HotelRoomService} from "@services";
import { useNavigate, useParams} from "react-router-dom";
import AddRoomModalForm from '@components/hotels/AddRoomModalForm';
import UploadRoomImageModalForm from '@components/rooms/UploadRoomImageModalForm';
import RoomDetailsModalForm from "../../components/rooms/RoomDetailsModalForm";

const { Content } = Layout;
const { TabPane } = Tabs;
const { Option } = Select;

const HotelEdit = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { hotelId } = useParams();

  const [hotelForm] = Form.useForm();
  const [newRoomForm] = Form.useForm();
  const [uploadImageForm] = Form.useForm();

  const [selectedRoom,setSelectedRoom] = useState()
  const [rooms, setRooms] = useState([]);
  const [images,setImages]=useState();
  const [deletedRooms, setDeletedRooms] = useState([]);
  const [hotelCategories, setHotelCategories] = useState([]);

  const [modalAddRoom, setModalAddRoom] = useState(false);
  const [isRoomsLoading, setIsRoomsLoading] = useState(true);
  const [isHotelLoading, setIsHotelLoading] = useState(true);
  const [modalUploadRoomImage,setModalUploadRoomImage] = useState(false);

  const [roomDetails,setRoomDetails] = useState({
    modalVisible:false,
    title:"Oda Detayları",
    initialValues:{
      general:[],
    }
  });

  useEffect(() => {
    getHotelCategories();
    getHotelDetail(hotelId);
    getRooms(hotelId);
    getImages();
  }, []);
  const getImages = async () => {
    try {
      let { data: response } = await RoomImageService.get(params.hotelId);
      setImages(response);
    } catch (e) {
      if (e.response) {
        notification.error({
          message: e.response.data.message
        });
      }
    }
  }
  /**
   * Otel kategorilerini getirir.
   */
  const getHotelCategories = async () => {
    try {
      let response = await HotelCategoryService.get();
      setHotelCategories(response.data.data);
    } catch (e) {
      if (e.response) {
        notification.error({
          message: e.response.data.message
        });
      }
    }
  };

  /**
   * Otelin detay bilgilerini getirir.
   * 
   * @param {integer} hotelId 
   */
  const getHotelDetail = async (hotelId) => {
    try {
      let { data: { data: response } } = await HotelService.findById(hotelId);
      hotelForm.setFieldsValue({
        name: response.name,
        description: response.description,
        hotel_category_id: response.category.id,
        country: response.country,
        city: response.city,
        district: response.district,
        lat: response.lat,
        lng: response.lng,
        is_active: response.is_active,
        ruspo_id: response.ruspo_id
      });
      setIsHotelLoading(false);
    } catch (e) {
      if (e.response) {
        notification.error({
          message: e.response.data.message
        });
      }
    }
  };


  const getRoomById = async (roomId) => {
    setRoomDetails({
      modalVisible:false,
      title:"Oda Detayları",
      initialValues:{
        general:[],
      }
    })
    try {
      let { data: { data: response } } = await HotelRoomService.getByRoomId(params.hotelId,roomId);
      response?.names.map((item) => {
        setRoomDetails(prevState => {
          return {
            ...prevState,
            initialValues: {
              ...prevState.initialValues,
              general: [
                ...prevState.initialValues.general,
                {name:['rooms',item.language,'description'], value:item.description},
              ]
            }
          }
        })
      })
      setRoomDetails(prevState => {
        return {
          ...prevState,
          modalVisible:true,
          room:response,
          initialValues:{
            ...prevState.initialValues,
            general:[
                ...prevState.initialValues.general,
                {name:"max_people", value:response.max_people},
                {name:"min_people", value:response.min_people},
                {name:"m2", value:response.m2},
            ],
          },
        }
      })
    } catch (e) {
      if (e.response) {
        notification.error({
          message: e.response.data.message
        });
      }
    }
  };

  /**
   * Otelin odalarını getirir.
   * 
   * @param {integer} hotelId 
   */
  const getRooms = async (hotelId) => {
    try {
      let { data: response } = await HotelService.getRooms(hotelId);
      let temp = [];
      response.data.map((item) => {
        temp.push({
          id: item.id,
          name: item.room_definition.name,
          room_definition_id: item.room_definition.id,
          m2: item.m2,
          min_people: item.min_people,
          max_people: item.max_people
        });
      });

      setRooms(temp);
      setIsRoomsLoading(false);
    } catch (e) {
      if (e.response) {
        notification.error({
          message: e.response.data.message
        });
      }
    }
  };

  /**
   * Otelin yeni bilgilerini kayıt eder.
   * 
   * @param {object} values 
   */
  const onUpdate = async (values) => {
    try {
      let { data: response } = await HotelService.update(hotelId, {

        ...values,
        rooms: {
          ...rooms
        },
        deleted_rooms: {
          ...deletedRooms
        }
      });
      notification.success({
        message: response.message
      });
      console.log(...rooms);


      navigate('/hotels');
    } catch (e) {
      if (e.response) {
        notification.error({
          message: e.response.data.message
        });
      }
    }
  };

  /**
   * Otel odalarını kayıt eder.
   * 
   * @param {object} values 
   */
  const addNewRoomHandle = async (values) => {
    try {
      let { data: { data: roomDefinition } } = await RoomDefinitionService.findById(params.hotelId,values.room_definition_id);
      setRooms([
        ...rooms,
        {
          name: roomDefinition.name,
          room_definition_id: values.room_definition_id,
          m2: values.m2,
          description: values.description,
          min_people: values.min_people,
          max_people: values.max_people
        }
      ]);

      setModalAddRoom(false);
      newRoomForm.resetFields();
      notification.success({
        message: 'Oda başarıyla kaydedildi.'
      });
    } catch (e) {
      if (e.response) {
        notification.error({
          message: e.response.data.message
        });
      }
    }
  };

  /**
   * Otel oda kaydını siler
   * 
   * @param {integer} roomIndex 
   * @param {object} record 
   */
  const onDeleteRoom = (roomIndex, record) => {
    let selectedRoom = rooms.filter((item, index) => index === roomIndex)[0];

    if (selectedRoom.id) {
      setDeletedRooms([
        ...deletedRooms,
        selectedRoom.id
      ]);
    }

    setRooms([
      ...rooms.filter((item, index) => index !== roomIndex)
    ]);
  };

  const roomColumns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      render: (text, record, index) => index + 1
    },
    {
      title: 'Oda Adı',
      dataIndex: 'name',
      key: 'name',
      render:(text,record)=> record.name
    },
    {
      title: 'Metrekare (m2)',
      dataIndex: 'm2',
      key: 'm2'
    },
    {
      title: 'Min. Kişi Sayısı',
      dataIndex: 'min_people',
      key: 'min_people'
    },
    {
      title: 'Max. Kişi Sayısı',
      dataIndex: 'max_people',
      key: 'max_people'
    },
    {
      title: 'İşlemler',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record, index) => (
        <Space align="middle">
          <Tooltip placement="top" title="Oda Bilgilerini Düzenle">
            <Button type="primary" onClick={() => getRoomById(record.id)}>
              <FontAwesomeIcon icon={faEdit} />
            </Button>
          </Tooltip>
          <Tooltip placement="top" title="Odaya Resim Ekle">
            <Button type="primary" onClick={()=>{setModalUploadRoomImage(true);setSelectedRoom(record.id)}}>
              <FontAwesomeIcon icon={faImages} />
            </Button>
          </Tooltip>
          <Tooltip placement="top" title="Otel Odasını Sil">
            <Button type="primary" htmlType="button" onClick={(e) => {
              onDeleteRoom(index, record);
            }} danger>
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </Tooltip>
        </Space>
      )
    }
  ];

  return (
    <>
      <Wrapper>
        <Content className="m-25">
          <Breadcrumb className="mb-20">
            <Breadcrumb.Item href="/">
              <FontAwesomeIcon icon={faHouse} />
            </Breadcrumb.Item>
            <Breadcrumb.Item href="/hotels">
              <FontAwesomeIcon icon={faHotel} className="mr-5" />
              <span>Oteller</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Yeni Otel Ekle</Breadcrumb.Item>
          </Breadcrumb>

          <div className="site-layout-background">
            <Form form={hotelForm} layout="vertical" onFinish={onUpdate}>
              <Tabs defaultActiveKey="1">
                <TabPane tab="Genel Bilgiler" key="1">
                  {isHotelLoading ? (
                      <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: 400,
                        width: '100%'
                      }}><Spin/></div>
                  ):
                    <Row gutter={[16]}>
                    <Col span={24}>
                      <Form.Item
                        name="name"
                        label="Otel Adı"
                        rules={[
                          { required: true, message: 'Otel adı gereklidir.' },
                          { min: 2, message: 'Otel adı minimum 2 karakter olmalıdır.' },
                          { max: 100, message: 'Otel adı maximum 100 karakter olmalıdır.' }
                        ]}
                      >
                        <Input placeholder="Otel Adı"/>
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item
                        name="description"
                        label="Otel Açıklama"
                        rules={[
                          { required: true, message: 'Otel açıklaması zorunludur.' }
                        ]}
                      >
                        <Input.TextArea placeholder="Otel Açıklama" />
                      </Form.Item>
                    </Col>

                    <Col md={12} lg={6}>
                      <Form.Item
                        name="hotel_category_id"
                        label="Otel Kategorisi"
                        rules={[
                          { required: true, message: 'Otel tipi zorunludur.' }
                        ]}
                      >
                        <Select>
                          {hotelCategories.map((item) => <Option value={item.id} key={`hotelCategory${item.id}`}>{item.name}</Option>)}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col md={12} lg={6}>
                      <Form.Item
                        name="country"
                        label="Ülkesi"
                        rules={[
                          { required: true, message: 'Otel ülkesi zorunludur.' }
                        ]}
                      >
                        <Input placeholder="Ülkesi" />
                      </Form.Item>
                    </Col>

                    <Col md={12} lg={6}>
                      <Form.Item
                        name="city"
                        label="Şehri"
                        rules={[
                          { required: true, message: 'Otel şehiri zorunludur.' }
                        ]}
                      >
                        <Input placeholder="Şehri" />
                      </Form.Item>
                    </Col>

                    <Col md={12} lg={6}>
                      <Form.Item
                        name="district"
                        label="Semti"
                        rules={[
                          { required: true, message: 'Otel semti zorunludur.' }
                        ]}
                      >
                        <Input placeholder="Semti" />
                      </Form.Item>
                    </Col>

                    <Col span={8}>
                      <Form.Item
                        name="lat"
                        label="Lat"
                        rules={[
                          { required: true, message: 'Otel lokasyonu zorunludur.' }
                        ]}
                      >
                        <InputNumber min={0} placeholder="Lat" style={{ width: '100%' }} />
                      </Form.Item>
                    </Col>

                    <Col span={8}>
                      <Form.Item
                        name="lng"
                        label="Long"
                        rules={[
                          { required: true, message: 'Otel lokasyonu zorunludur.' }
                        ]}
                      >
                        <InputNumber min={0} placeholder="Long" style={{ width: '100%' }} />
                      </Form.Item>
                    </Col>

                    <Col span={8}>
                      <Form.Item
                        name="ruspo_id"
                        label="Ruspo ID"
                      >
                        <Input placeholder="Ruspo id" style={{ width: '100%' }} />
                      </Form.Item>
                    </Col>

                    <Col>
                      <Form.Item name="is_active" label="Aktif / Pasif" valuePropName="checked" initialValue={true}>
                        <Switch checkedChildren="Aktif" defaultChecked unCheckedChildren="Pasif" />
                      </Form.Item>
                    </Col>
                  </Row>
                  }
                </TabPane>

                <TabPane tab="Odalar" key="2">
                  <Row className="mb-20">
                    <Col span={24}>
                      <Button type="default" htmlType="button" block onClick={() => setModalAddRoom(!modalAddRoom)}>
                        <FontAwesomeIcon icon={faPlus} className="mr-5" />
                        Oda Ekle
                      </Button>
                    </Col>
                  </Row>

                  <Table
                      dataSource={rooms}
                      loading={isRoomsLoading}
                      columns={roomColumns}
                      rowKey={(record) => `${Math.random()}${record.id}`}
                      pagination={false}
                  />
                </TabPane>
              </Tabs>

              <Button type="primary" htmlType="submit" className="mt-25" block>
                <FontAwesomeIcon icon={faSave} className="mr-5" />
                Kaydet
              </Button>
            </Form>
          </div>
        </Content>
      </Wrapper>

      <AddRoomModalForm
        form={newRoomForm}
        title="Yeni Oda Ekle"
        isVisible={modalAddRoom}
        onFinish={addNewRoomHandle}
        onCancel={() => setModalAddRoom(false)}
      />
      <UploadRoomImageModalForm
        form={uploadImageForm}
        title="Oda Fotoğrafı Yükle"
        images={images}
        getImages={getImages}
        isVisible={modalUploadRoomImage}
        selectedRoom={selectedRoom}
        onCancel={() => setModalUploadRoomImage(false)}
      />
      <RoomDetailsModalForm
        visible={roomDetails.modalVisible}
        title={roomDetails.title}
        room={roomDetails.room}
        getRoomById={getRoomById}
        initialValues={roomDetails.initialValues}
        onCancel={() => setRoomDetails({ ...roomDetails, modalVisible: false })}
      />
    </>
  );
};

export default HotelEdit;