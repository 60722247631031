import axios from '../axios';

const ContractService = {
  async get(hotelId, languageId) {
    return await axios.get(`/v1/contracts/${hotelId}`);
  },
  async setStatus(contractId,status) {
    return await axios.post(`/v1/contracts/${contractId}/setStatus/${status}`);
  },
  async getByGroupId(groupId) {
    return await axios.get(`/v1/contract-groups/${groupId}/contracts-all`);
  },
  async getById(groupId,contractId) {
    return await axios.get(`/v1/contract-groups/${groupId}/contracts/${contractId}`);
  },
  async hideContractGroup(id) {
    return await axios.get(`/v1/contract-groups/${id}/hide`);
  },
  async getContractConceptsById(contractId) {
    return await axios.get(`/v1/contracts/${contractId}/contract-concepts`);
  },
  async delete(contractId) {
    return await axios.delete(`/v1/contracts/${contractId}`);
  },
  async create(hotelId, formData) {
    return await axios.post(`/v1/contracts/${hotelId}`, formData);
  },
  async edit(hotelId, id, formData) {
    return await axios.post(`/v1/contracts/${hotelId}/contracts/${id}`, formData);
  },
  async linkWidgetToContract(hotelId, contractId, formData) {
    return await axios.post(`/v1/contracts/${hotelId}/widget/${contractId}`, formData);
  },
  async demoCreate(hotelId, formData) {
    return await axios.post(`/v2/dashboard/hotels/${hotelId}/contracts`, formData);
  },
  async demoGet(hotelId) {
    return await axios.get(`/v2/dashboard/hotels/${hotelId}/contracts`);
  },
  async demoShow(hotelId, contractId) {
    return await axios.get(`/v2/dashboard/hotels/${hotelId}/contracts/${contractId}`);
  },
  async demoSave(hotelId, contractId, formData) {
    return await axios.post(`/v2/dashboard/hotels/${hotelId}/contracts/${contractId}`, formData);
  },
  async demoImportPeriod(hotelId, contractId, formData) {
    return await axios.post(`/v2/dashboard/hotels/${hotelId}/contracts/${contractId}/import-period`, formData);
  }
};

export default ContractService;