import React from 'react';
import {Col, Form, Input, Modal, Row} from "antd";

const PhotoGroupModalForm = (props) => {
    return (
        <Modal visible={props.isVisible} title={props.title} closable={false} onOk={() => props.form.submit()} onCancel={props.onCancel}>
            <Form form={props.form} layout="vertical" onFinish={props.onFinish}>
                <Row>
                    <Col span={24}>
                        <Form.Item
                            name="title"
                            label="Albüm Adı"
                            rules={[{ required: true, message: 'Lütfen albüm adını giriniz!' }]}
                        >
                            <Input
                                placeholder="Albüm Adı"
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default PhotoGroupModalForm;