import React, { useEffect, useState } from 'react';
import {Layout, Breadcrumb, Button, Table, notification, Space, Tag, Row, Col, Input, Form, Tooltip, DatePicker, Select, Popconfirm, Checkbox} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faHouse, faEdit, faTrash, faSearch, faBan, faList, faLock, faLockOpen} from '@fortawesome/free-solid-svg-icons';
import Wrapper from '@components/Wrapper';
import { ProviderReservationService, ReservationService } from '@services';
import moment from "moment";
import {useSelector} from "react-redux";

const { Content } = Layout;
const { Option } = Select;
const { RangePicker } = DatePicker;

const ProviderReservations = () => {
    const permissions = useSelector((state) => state.user.user.role.permissions);
    const user = useSelector((state) => state.user.user);
    const [isLoadingTable, setIsLoadingTable] = useState(true);
    const [sendMailOnCancel, setSendMailOnCancel] = useState(false);
    const [reservations, setReservations] = useState({
        data: [],
        isLoading: true,
        meta: {},
    });
    const [searchParams, setSearchParams] = useState({
        hotel_name: "",
        agent_name: "",
        uuid: "",
        check_in: "",
        check_out: "",
        guest_name: "",
        reservation_date: "",
        status: "",
        currencies: [242,251,90],
        only_checkin: false,
        only_checkout: false,
    });
    const [reservationSearchForm] = Form.useForm();
    const columns = [
        {
            key: "reservation_number",
            title: <span style={{color:"#1890ff",fontSize:"12px",textAlign:"center"}}>Rezervasyon No.</span>,
            onCell: (record) => {
                if(record?.payments?.find((item) => item?.safe_bank?.payment_method_id === 5)){
                    return {className: "background-red-500 border-right-none"}
                }
            },
            dataIndex: 'reservation_number',
            render: (text, record) => {
                let reservationNumber = record.uuid
                let hasDeferredPayment = false
                let totalPayment = 0
                record?.payments?.length > 0 && record?.payments?.map((payment) => {
                    if(payment?.safe_bank?.payment_method_id === 4 && payment?.approved === 1){
                        let amount = 0;
                        payment?.balance_completions?.map((item) => {
                            amount = amount + parseInt(item.amount)
                        })
                        if (amount !== payment?.amount){
                            hasDeferredPayment = true
                        }
                    }
                    if(payment.approved !== 1){
                        return null
                    }
                    totalPayment = totalPayment + payment.amount
                })
                if(record.status === 6){
                    return <Button
                        type="primary"
                        style={{ "fontSize": "12px", backgroundColor: "#6b7280", borderColor: "#6b7280" }}
                        block='true'
                        href={`/provider-reservation-card/${record.uuid}`}
                        target="_blank"
                    >
                        <u style={{color:"white",marginRight:"1px"}}>{reservationNumber}</u>
                    </Button>
                }
                if(hasDeferredPayment){
                    return <Button
                        type="primary"
                        style={{ "fontSize": "12px", backgroundColor: "#be185d", borderColor: "#be185d" }}
                        block='true'
                        href={`/provider-reservation-card/${record.uuid}`}
                        target="_blank"
                    >
                        <u style={{color:"white",marginRight:"1px"}}>{reservationNumber}</u>
                    </Button>
                }
                if (totalPayment === 0) {
                    return <Button
                        type="primary"
                        style={{ "fontSize": "12px", backgroundColor: "#ef4444", borderColor: "#ef4444" }}
                        block='true'
                        href={`/provider-reservation-card/${record.uuid}`}
                        target="_blank"
                    >
                        <u style={{color:"white",marginRight:"1px"}}>{reservationNumber}</u>
                    </Button>
                }
                if (totalPayment > 0 && totalPayment < record.total_fee) {
                    return <Button
                        type="primary"
                        style={{ "fontSize": "12px" }}
                        block='true'
                        href={`/provider-reservation-card/${record.uuid}`}
                        target="_blank"
                    >
                        <u style={{color:"white",marginRight:"1px"}}>{reservationNumber}</u>
                    </Button>
                }
                if (totalPayment >= record.total_fee) {
                    return <Button
                        type="primary"
                        style={{ "fontSize": "12px", backgroundColor: "#22c55e", borderColor: "#22c55e" }}
                        block='true'
                        href={`/provider-reservation-card/${record.uuid}`}
                        target="_blank"
                    >
                        <u style={{color:"white",marginRight:"1px"}}>{reservationNumber}</u>
                    </Button>
                }
            }
        },
        {
            key: 'reservation_provider',
            title: <span style={{ 'fontSize': '12px',textAlign: "center",color: "#1890ff", }}>Rezervasyonu Oluşturan</span>,
            onCell: (record) => {
                if(record?.payments?.find((item) => item?.safe_bank?.payment_method_id === 5)){
                    return {className: "background-red-500 border-right-none"}
                }
            },
            dataIndex: 'reservation_provider',
            render: (text, record) => {
                if(record?.users){
                    return <Tooltip title={record.users.role.name}><h1 style={{ 'fontSize': '12px',textAlign:"center"}}>{record.users.first_name} {record.users.last_name}</h1></Tooltip>
                }
                return <Tooltip title="Rezervasyonu paymyres üzerinden müşteri kendisi oluşturmuştur."><h1 style={{ 'fontSize': '12px',textAlign:"center"}}>Widget</h1></Tooltip>
            }
        },
        {
            key: 'hotel_name',
            title: <span style={{ 'fontSize': '12px',textAlign: "center",color: "#1890ff", }}>Otel</span>,
            onCell: (record) => {
                if(record?.payments?.find((item) => item?.safe_bank?.payment_method_id === 5)){
                    return {className: "background-red-500 border-right-none"}
                }
            },
            dataIndex: 'hotels',
            render: (text) =><h1 style={{ "fontSize": "12px" }}>{text.name}</h1>
        },
        {
            key: 'room_name',
            title: <span style={{ 'fontSize': '12px',textAlign: "center",color: "#1890ff", }}>Oda Adı</span>,
            onCell: (record) => {
                if(record?.payments?.find((item) => item?.safe_bank?.payment_method_id === 5)){
                    return {className: "background-red-500 border-right-none"}
                }
            },
            dataIndex: 'room_detail',
            render: (text) =><h1 style={{ "fontSize": "12px" }}>{text?.active_language?.name}</h1>
        },
        {
            key: 'full_name',
            title: <span style={{ "fontSize": "12px",textAlign: "center",color: "#1890ff", }}>Ad Soyad</span>,
            onCell: (record) => {
                if(record?.payments?.find((item) => item?.safe_bank?.payment_method_id === 5)){
                    return {className: "background-red-500 border-right-none"}
                }
            },
            dataIndex: 'full_name',
            render: (text, record) => <h1 style={{ "fontSize": "12px" }}>{text}</h1>
        },
        {
            key: 'phone_number',
            title: <span style={{ "fontSize": "12px",textAlign: "center",color: "#1890ff", }}>İletişim Numarası</span>,
            onCell: (record) => {
                if(record?.payments?.find((item) => item?.safe_bank?.payment_method_id === 5)){
                    return {className: "background-red-500 border-right-none"}
                }
            },
            dataIndex: 'phone_number',
            render: (text, record) => <h1 style={{ "fontSize": "12px" }}>{text}</h1>
        },
        {
            key: 'email',
            title: <span style={{ "fontSize": "12px",textAlign: "center",color: "#1890ff", }}>E-Posta Adresi</span>,
            onCell: (record) => {
                if(record?.payments?.find((item) => item?.safe_bank?.payment_method_id === 5)){
                    return {className: "background-red-500 border-right-none"}
                }
            },
            dataIndex: 'email',
            render: (text) => <h1 style={{ "fontSize": "12px",textAlign:text ? "left":"center" }}>{text ? text: "-"}</h1>
        },
        {
            key: 'check_in',
            title: <span style={{ "fontSize": "12px",textAlign: "center",color: "#1890ff", }}>Giriş Tarihi</span>,
            onCell: (record) => {
                if(record?.payments?.find((item) => item?.safe_bank?.payment_method_id === 5)){
                    return {className: "background-red-500 border-right-none"}
                }
            },
            dataIndex: 'check_in',
            render: (text, record) => <h1 style={{ "fontSize": "12px" }}>{text}</h1>
        },
        {
            key: 'check_out',
            title: <span style={{ "fontSize": "12px",textAlign: "center",color: "#1890ff", }}>Çıkış Tarihi</span>,
            onCell: (record) => {
                if(record?.payments?.find((item) => item?.safe_bank?.payment_method_id === 5)){
                    return {className: "background-red-500 border-right-none"}
                }
            },
            dataIndex: 'check_out',
            render: (text, record) => <h1 style={{ "fontSize": "12px" }}>{text}</h1>
        },
        {
            key: 'locked',
            title: <span style={{ "fontSize": "12px",textAlign: "center",color: "#1890ff", }}>Kilit Durumu</span>,
            dataIndex: 'locked',
            render: (text) => <div style={{
                fontSize: "12px",
                display: "flex",
                alignItems: "center",
                justifyItems: "center"
            }}>
                <div style={{
                    margin: "0 auto",
                    color: text ? "red" : "green"
                }}>
                    {text ? (<FontAwesomeIcon icon={faLock}/>) : (<FontAwesomeIcon icon={faLockOpen}/>)}
                </div>
            </div>
        },
        {
            key: 'total_fee',
            title: <span style={{ "fontSize": "12px",textAlign: "center",color: "#1890ff", }}>Rezervasyon Ücreti</span>,
            onCell: (record) => {
                if(record?.payments?.find((item) => item?.safe_bank?.payment_method_id === 5)){
                    return {className: "background-red-500 border-right-none"}
                }
            },
            dataIndex: 'total_fee',
            render: (text, record) => <h1 style={{ "fontSize": "12px" }}>{text} {record?.currency_id?.symbol}</h1>
        },
        {
            key: 'total_fee_discount',
            title: <span style={{ "fontSize": "12px",textAlign: "center",color: "#1890ff" }}>İndirimli Rez. Ücreti</span>,
            onCell: (record) => {
                if(record?.payments?.find((item) => item?.safe_bank?.payment_method_id === 5)){
                    return {className: "background-red-500 border-right-none"}
                }
            },
            dataIndex: 'total_fee',
            render: (text, record) => {
                let discounts = 0;
                record?.payments?.map((item) => {
                    if(item?.safe_bank?.payment_method_id === 6){
                        return discounts += item?.amount
                    }
                })
                return <h1 style={{ "fontSize": "12px" }}>{text - discounts} {record?.currency_id?.symbol}</h1>
            }
        },
        {
            key: 'reservation_create_date',
            title: <span style={{ "fontSize": "12px",textAlign: "center",color: "#1890ff", }}>Rezervasyon Tarihi</span>,
            onCell: (record) => {
                if(record?.payments?.find((item) => item?.safe_bank?.payment_method_id === 5)){
                    return {className: "background-red-500 border-right-none"}
                }
            },
            dataIndex: 'created_at',
            render: (text, record) => <h1 style={{ "fontSize": "12px" }}>{text}</h1>
        },
        {
            key: 'title',
            title: <span style={{ "fontSize": "12px",textAlign: "center", color: "#1890ff",}}>Durum</span>,
            onCell: (record) => {
                if(record?.payments?.find((item) => item?.safe_bank?.payment_method_id === 5)){
                    return {className: "background-red-500 border-right-none"}
                }
            },
            dataIndex: 'payments',
            render: (text, record) => {
                let status = "Confirme Bekleniyor"
                if(record.status === 0){
                    status = "Ön Rezervasyon"
                    return <Tooltip title="Rezervasyon bilgileri girildi, ödeme işlemi sağlanamdı!"><Tag color="blue" style={{whiteSpace:"normal",textAlign:"center"}}>{status}</Tag></Tooltip>
                }
                if(record.status === 2){
                    status = "Confirme Bekleniyor"
                    return <Tooltip title="Otel'e confirme işlemi için mail gönderildi, cevap bekleniyor."><Tag color="orange" style={{whiteSpace:"normal",textAlign:"center"}}>{status}</Tag></Tooltip>
                }
                if(record.status === 3){
                    status = "Confirme Edildi"
                    return <Tooltip title="Otel işlemi onayladı."><Tag color="green" style={{whiteSpace:"normal",textAlign:"center"}}>{status}</Tag></Tooltip>
                }
                if (record.status === 4) {
                    status = "Confirme Reddedildi"
                    return <Tooltip title="Otel işlemi reddetti."><Tag color="red" style={{whiteSpace:"normal",textAlign:"center"}}>{status}</Tag></Tooltip>
                }
                if (record.status === 5) {
                    status = "Confirm'e Gönderilecek"
                    return <Tooltip title="Otel'e confirme işlemi için mail gönderilmedi."><Tag color="magenta" style={{whiteSpace:"normal",textAlign:"center"}}>{status}</Tag></Tooltip>
                }
                if (record.status === 6) {
                    status = "Rezervasyon İptal Edildi"
                    return <Tooltip title="Rezervasyon iptal edildi."><Tag color="red" style={{whiteSpace:"normal",textAlign:"center"}}>{status}</Tag></Tooltip>
                }
                return <Tag color="yellow">{status}</Tag>
            }
        },
        {
            key: 'title',
            title: <span style={{ "fontSize": "12px",textAlign: "center",color: "#1890ff", }}>Ödeme</span>,
            onCell: (record) => {
                if(record?.payments?.find((item) => item?.safe_bank?.payment_method_id === 5)){
                    return {className: "background-red-500"}
                }
            },
            dataIndex: 'payments',
            render: (text, record) => {
                let totalPayment = 0
                text.length > 0 && text.map((payment) => {
                    if(payment.approved !== 1){
                        return null
                    }
                    totalPayment = totalPayment + payment.amount
                })
                if (totalPayment === 0) {
                    return (<Tag color="red">Ödenmedi</Tag>)
                }
                if (totalPayment > 0 && totalPayment < record.total_fee) {
                    return (<Tag color="cyan">Kısmi Ödeme</Tag>)
                }
                if (totalPayment >= record.total_fee) {
                    return (<Tag color="green">Ödendi</Tag>)
                }
            }
        },
        {
            key: 'actions',
            title: <span style={{ "fontSize": "12px",textAlign: "center",color: "#1890ff", }}>İşlemler</span>,
            fixed: 'right',
            width: 240,
            render: (text, record) => (
                <Space align="middle">
                    <Button type="primary" onClick={() => console.log(record.uuid)}>
                        <FontAwesomeIcon icon={faEdit} />
                    </Button>
                    {(user?.is_super_admin && !record?.cl) ? (
                        <Tooltip title="Rezervasyonu Listelemeye Aç">
                            <Popconfirm okText="Listele" cancelText="İptal" title="Rezervasyon kullanıcılar tarafından görüntülenmeye başlanacaktır. Emin misiniz?" onConfirm={() => onListReservation(record.uuid)}>
                                <Button type="primary">
                                    <FontAwesomeIcon icon={faList} />
                                </Button>
                            </Popconfirm>
                        </Tooltip>
                    ): null}
                    <Tooltip title={!permissions.includes('reservation.delete') ? "Bu işlem için yetkiniz bulunmuyor.": "Sil"}>
                        <Popconfirm disabled={!permissions.includes('reservation.delete')} title="Silmek istediğinize emin misiniz?" onConfirm={() => onDeleteReservation(record.uuid)}>
                            <Button type="danger" disabled={!permissions.includes('reservation.delete')}>
                                <FontAwesomeIcon icon={faTrash} />
                            </Button>
                        </Popconfirm>
                    </Tooltip>
                    {record.status !== 6 && (
                        <Tooltip title="Rezervasyon İptal">
                            <Popconfirm
                                title={
                                <span>
                                    Rezervasyonu iptal etmek istediğinize emin misiniz?.
                                    <br/>
                                    İptal E-postası gönderilsin mi? <Checkbox checked={sendMailOnCancel} onChange={() => setSendMailOnCancel(!sendMailOnCancel)}></Checkbox>
                                </span>
                            }
                                onConfirm={() => onCancelReservation(record.uuid)}
                                okText="Evet"
                                cancelText="Hayır"
                            >
                                <Button type="danger">
                                    <FontAwesomeIcon icon={faBan}/>
                                </Button>
                            </Popconfirm>
                        </Tooltip>
                    )}
                    <Tooltip title={!permissions.includes('reservation.lock.update') ? "Bu işlem için yetkiniz bulunmuyor.": "Değiştir"}>
                        <Popconfirm disabled={!permissions.includes('reservation.lock.update')} title="Kilit durumunu değiştirmek istediğinize emin misiniz?" onConfirm={() => onChangeLockReservation(record.uuid)}>
                            <Button type={!record.locked ? "danger": "primary"} disabled={!permissions.includes('reservation.lock.update')}>
                                <FontAwesomeIcon icon={!record?.locked ? faLock : faLockOpen} />
                            </Button>
                        </Popconfirm>
                    </Tooltip>
                </Space>
            )
        }
    ];

    useEffect(() => {
        getReservations()
    }, []);

    const onDeleteReservation = async (id) => {
        try {
            let { data: response } = await ReservationService.delete(id);
            if(response.success){
                setIsLoadingTable(true)
                getReservations()
                notification.success({
                    message: response.message
                })
            }
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }
    const onListReservation = async (id) => {
        try {
            let { data: response } = await ReservationService.listReservation(id);
            if(response.success){
                setIsLoadingTable(true)
                getReservations()
                notification.success({
                    message: response.message
                })
            }
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }
    const onCancelReservation = async (id) => {

        try {
            let { data: response } = await ReservationService.cancel(id,{
                sendMail: sendMailOnCancel
            });
            if(response.success){
                setIsLoadingTable(true)
                getReservations()
                notification.success({
                    message: response.message
                })
            }
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }
    const onChangeLockReservation = async (id) => {
        try {
            let { data: response } = await ReservationService.changeLock(id);
            if(response.success){
                setIsLoadingTable(true)
                getReservations()
                notification.success({
                    message: response.message
                })
            }
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }

    const getReservations = async (page = 1, pageSize = 10 ) => {
        try {
            let { data: response } = await ProviderReservationService.get(page, pageSize, searchParams);
            setReservations(prevState => (
                {
                    ...prevState,
                    data: response.data,
                    meta: response.meta,
                    isLoading: false
                }
            ));
            setIsLoadingTable(false);
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }
    return (
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-20">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse} />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        Rezervasyonlar
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background">
                    <Form form={reservationSearchForm} onFinish={() => getReservations()} layout="vertical">
                        <Row gutter={[16,0]}>
                            <Col span={2}>
                                <Form.Item label="Rez. Oluşturan">
                                    <Input placeholder="Çalışan adını giriniz." onChange={(e) => setSearchParams(prevState => {
                                        return {
                                            ...prevState,
                                            agent_name: e.target.value
                                        }
                                    })}/>
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item label="Rezervasyon Numarası">
                                    <Input placeholder="Rezervasyon numarasını giriniz." onChange={(e) => setSearchParams(prevState => {
                                        return {
                                            ...prevState,
                                            uuid: e.target.value
                                        }
                                    })}/>
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item label="Otel Adı">
                                    <Input placeholder="Otel adını giriniz." onChange={(e) => setSearchParams(prevState => {
                                        return {
                                            ...prevState,
                                            hotel_name: e.target.value
                                        }
                                    })}/>
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item label="Ziyaretçi Adı">
                                    <Input placeholder="Ziyaretçi adını giriniz." onChange={(e) => setSearchParams(prevState => {
                                        return {
                                            ...prevState,
                                            guest_name: e.target.value
                                        }
                                    })}/>
                                </Form.Item>
                            </Col>
                            <Col span={2}>
                                <Form.Item label="Rezervasyon Tarihi">
                                    <DatePicker style={{width:"100%"}} format="DD.MM.YYYY" placeholder="Rezervasyon tarihini giriniz." onChange={(e) => setSearchParams(prevState => {
                                        return {
                                            ...prevState,
                                            reservation_date: e ? moment(e).format("YYYY-MM-DD"): ""
                                        }
                                    })}/>
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item label="Giriş - Çıkış Tarihi">
                                    <RangePicker
                                        style={{width:"100%"}}
                                        format="DD.MM.YYYY"
                                        placeholder={["Giriş tarihi", "Çıkış tarihi"]}
                                        onChange={(e) => setSearchParams(prevState => {
                                            return {
                                                ...prevState,
                                                check_in: e ? moment(e[0]).format("YYYY-MM-DD"): "",
                                                check_out: e ? moment(e[1]).format("YYYY-MM-DD"): ""
                                            }
                                        })}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item label="Durum">
                                    <Select
                                        allowClear
                                        mode="multiple"
                                        placeholder="Rezervasyon durumu seçiniz." onChange={(e) => setSearchParams(prevState => {
                                        return {
                                            ...prevState,
                                            status: e ? e : ""
                                        }
                                    })}>
                                        <Option key={1} value={1}>Ödeme Bekleniyor</Option>
                                        <Option key={2} value={2}>Confirme Bekleniyor</Option>
                                        <Option key={3} value={3}>Confirme Edildi</Option>
                                        <Option key={4} value={4}>Confirme Reddedildi</Option>
                                        <Option key={5} value={5}>Confirm'e Gönderilecek</Option>
                                        <Option key={6} value={6}>Rezervasyon İptal Edildi</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item label="Para Birimi">
                                    <Checkbox.Group
                                        defaultValue={[242,251,90]}
                                        onChange={(e) => setSearchParams(prevState => {
                                            return {
                                                ...prevState,
                                                currencies: e
                                            }
                                        })}
                                    >
                                        <Checkbox value={251}>USD</Checkbox>
                                        <Checkbox value={242}>TRY</Checkbox>
                                        <Checkbox value={90}>EURO</Checkbox>
                                    </Checkbox.Group>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="Tarih Tipi"
                                >
                                    <Checkbox.Group
                                        onChange={(e) => setSearchParams(prevState => {
                                            return {
                                                ...prevState,
                                                only_checkin: e.includes("only_checkin"),
                                                only_checkout: e.includes("only_checkout")
                                            }
                                        })}
                                    >
                                        <Checkbox value="only_checkin">Sadece Giriş Tarihi</Checkbox>
                                        <Checkbox value="only_checkout">Sadece Çıkış Tarihi</Checkbox>
                                    </Checkbox.Group>
                                </Form.Item>
                            </Col>
                            <Col span={24} style={{display:"flex",justifyContent:"flex-end",marginBottom:"14px"}}>
                                <Space>
                                    <div className="background-red-500" style={{width:"20px",height:"20px",border:"1px solid rgba(0,0,0,0.2)"}}></div>
                                    <span className="ml-2" style={{fontSize:"12px"}}>Kapıda Ödeme</span>
                                </Space>
                            </Col>
                            <Col span={24}>
                                <Form.Item>
                                    <Button style={{width:"100%"}} type="primary" htmlType="submit" onClick={() => setIsLoadingTable(true)}>
                                        <FontAwesomeIcon icon={faSearch}/>
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    <div style={{
                        display:"flex",
                        justifyContent:"space-between",
                        marginBottom:"10px"
                    }}>
                        <Space direction="vertical">
                            <Row gutter={[0,8]}>
                                <Col span={8}>
                                    <div style={{display:"flex",columnGap:"6px",alignItems:"center"}}>
                                        <div style={{width:"20px",height:"20px",backgroundColor:"#6b7280",border:"1px solid rgba(0,0,0,0.2)"}}></div>
                                        <span className="ml-2" style={{fontSize:"12px"}}>Rezervasyon İptal</span>
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <div style={{display:"flex",columnGap:"6px",alignItems:"center"}}>
                                        <div style={{width:"20px",height:"20px",backgroundColor:"#ef4444",border:"1px solid rgba(0,0,0,0.2)"}}></div>
                                        <span className="ml-2" style={{fontSize:"12px"}}>Ödenmedi</span>
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <div style={{display:"flex",columnGap:"6px",alignItems:"center"}}>
                                        <div style={{width:"20px",height:"20px",backgroundColor:"#1890FF",border:"1px solid rgba(0,0,0,0.2)"}}></div>
                                        <span className="ml-2" style={{fontSize:"12px"}}>Kısmi Ödeme</span>
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <div style={{display:"flex",columnGap:"6px",alignItems:"center"}}>
                                        <div style={{width:"20px",height:"20px",backgroundColor:"#22c55e",border:"1px solid rgba(0,0,0,0.2)"}}></div>
                                        <span className="ml-2" style={{fontSize:"12px"}}>Tam Ödeme</span>
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <div style={{display:"flex",columnGap:"6px",alignItems:"center"}}>
                                        <div style={{width:"20px",height:"20px",backgroundColor:"#be185d",border:"1px solid rgba(0,0,0,0.2)"}}></div>
                                        <span className="ml-2" style={{fontSize:"12px"}}>Vadeli Ödeme</span>
                                    </div>
                                </Col>
                            </Row>
                        </Space>
                    </div>
                    <Table
                        columns={columns}
                        scroll={{ x: 2000, y: "100%" }}
                        dataSource={reservations.data}
                        rowKey={record => record.uuid}
                        loading={isLoadingTable}
                        bordered
                        size="small"
                        pagination={{
                            total: reservations.meta?.total,
                            defaultPageSize: reservations.meta?.per_page,
                            onChange: (page, pageSize) => {
                                setIsLoadingTable(true)
                                getReservations(page, pageSize)
                            }
                        }}
                    />
                </div>
            </Content>
        </Wrapper>
    );
};

export default ProviderReservations;