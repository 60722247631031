import Wrapper from "@components/Wrapper";
import {Breadcrumb, Layout, notification, Form, Col, Input, Row, Button} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {HotelFooterService} from '@services';
import {faHouse} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";

const {Content} = Layout;
function FooterSettings(){
    const [footerSettings] = Form.useForm()
    const params = useParams()
    const [footer,setFooter] = useState([])
    const [initialValues,setInitialValues] = useState([])
    const getHotelFooters = async () =>{
        try {
            let { data: response } = await HotelFooterService.getByHotel(params.hotelId);
            setInitialValues(JSON.parse(response[0].footer.footer_details.values))
            setFooter(response)
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }
    const onSubmit = async (values) =>{
        try {
            let { data: response } = await HotelFooterService.create(params.footerId,values);
            if(response.success){
                notification.success({
                    message:response.message
                })
            }
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }

    useEffect(()=>{
        getHotelFooters()
    },[])
    return(
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-20">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse} />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href={`/site-management`}>
                        Site Yönetimi
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href={`/site-management/${params.hotelId}`}>
                        Site Ayarları
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        Footer Ayarları
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background">
                    <Form form={footerSettings} onFinish={onSubmit}>
                        <Row gutter={[16,16]}>
                        {
                            footer[0]?.footer.fields && JSON.parse(footer[0]?.footer.fields).map((field,i)=>{
                                if(field.type === 'color'){
                                    return(
                                        <Col key={i} span={4}>
                                            <Form.Item
                                                name={field.name}
                                                noStyle={true}
                                                rules={[
                                                    {
                                                        required:true,
                                                        message:"Required"
                                                    }
                                                ]}
                                                initialValue={initialValues[field.name]}
                                                style={{ display: 'none' }}
                                            ><Input type="hidden"/></Form.Item>
                                            <div className="ant-col ant-form-item-label"><label>{field.label}</label></div>
                                            <div className="ant-col ant-form-item-control">
                                                <input type="color" defaultValue={initialValues[field.name]} style={{border:'none',width:"55px",height:"32px"}} onChange={(e) => footerSettings.setFields([
                                                    {
                                                        name:field.name,
                                                        value: e.target.value
                                                    }
                                                ])}/>
                                            </div>
                                        </Col>
                                    )
                                }
                            })
                        }
                        <Col span={24}>
                            <Form.Item>
                                <Button type="primary" htmlType="submit">Kaydet</Button>
                            </Form.Item>
                        </Col>
                        </Row>
                    </Form>
                </div>
            </Content>
        </Wrapper>
    )
}

export default FooterSettings