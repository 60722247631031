import React, {useEffect, useState} from 'react';
import {Breadcrumb, Button, Col, Layout, notification, Row, Space, Table} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHouse} from "@fortawesome/free-solid-svg-icons";
import Wrapper from "@components/Wrapper";
import {useTranslation} from "react-i18next";
import {HotelService} from "@services";
import {Link} from "react-router-dom";

const { Content } = Layout;

const HotelListPage = () => {
    const { t } = useTranslation();
    const [hotelList, setHotelList] = useState({
        data: [],
        loading: false,
        meta: {}
    });
    const [filters, setFilters] = useState({
        search: ''
    });
    const columns = [
        {
            title: t('general.id'),
            dataIndex: 'id',
            key: 'id',
            width: 100
        },
        {
            title: t('general.name'),
            dataIndex: 'name',
            key: 'name',
            width: 200,
            render: (value, record) => <Link to={`/v2/hotels/${record.id}`}>{value}</Link>
        }
    ]
    const getHotelList = async (page = 1, per_page = 10, filters = {}) => {
        setHotelList({
            ...hotelList,
            loading: true
        });
        try {
            let {data : response} = await HotelService.get(page, filters);
            setHotelList({
                data: response.data,
                loading: false,
                meta: response.meta
            });
        }catch (e){
            if (e.response){
                notification.error({
                    message: t('general.error'),
                    description: e.response.data.message
                })
            }
            setHotelList({
                ...hotelList,
                loading: false
            });
        }
    }
    useEffect(() => {
        getHotelList(1, 10, filters);
    },[])
    return (
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-20">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse} />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {t("general.hotels")}
                    </Breadcrumb.Item>
                </Breadcrumb>

                <div className="site-layout-background">
                    <Row gutter={[16,16]}>
                        <Col span={24}>
                            <Space direction="horizontal">
                                <Link to="/v2/hotels/create">
                                    <Button type="primary">
                                        {t('general.create_hotel')}
                                    </Button>
                                </Link>
                            </Space>
                        </Col>
                        <Col span={24}>
                            <Table
                                columns={columns}
                                loading={hotelList.loading}
                                dataSource={hotelList.data}
                                scroll={{ x: 768 }}
                                bordered
                                pagination={{
                                    total: hotelList?.meta?.total || 0,
                                    current: hotelList?.meta?.current_page || 1,
                                    pageSize: hotelList?.meta?.per_page || 10,
                                    showSizeChanger: true,
                                    onChange: (page, pageSize) => getHotelList(page, pageSize, filters),
                                }}
                            />
                        </Col>
                    </Row>
                </div>
            </Content>
        </Wrapper>
    );
};

export default HotelListPage;