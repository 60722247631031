import React, { useState, useEffect, useMemo } from 'react';
import {Modal, Form, Input, Row, Col, Select, notification} from 'antd';
import {useParams} from "react-router-dom";
import CountriesService from "@services/countries/Countries.service";
import {useTranslation} from "react-i18next";

const { Option } = Select;
const ContractNationalityModalForm = (props) => {
    const { t } = useTranslation()
    const [contractCountries, setContractCountries] = useState([]);
    const [selectedContractCountries, setContractSelectedCountries] = useState([]);
    const params = useParams();
    useEffect(() => {
        getCountries();
    }, [props.data]);

    const getCountries = async () => {
        try {
            let {data: response} = await CountriesService.getNationalities();
            setContractCountries(response);
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    };
    const getSelectedCountries = async () => {
        let value = props.contract_group_id;
        try {
            let {data:{data:response}} = await CountriesService.getSelectedCountry(value);
            let initialValues = [];
            response.map((item) => {
                initialValues.push(item.nationalities.id)
            })
            props.form.setFieldsValue({
                nationalities:initialValues
            })
            setContractSelectedCountries(response.data.data);
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    };
    useEffect(() => {
        if(props.contract_group_id){
            getSelectedCountries();
        }
    }, [props.contract_group_id]);

    return (
        <Modal visible={props.isVisible} title={props.title} closable={false} onOk={() => props.form.submit()} onCancel={props.onCancel}>
            <Form form={props.form} layout="vertical" onFinish={(values) => props.onFinish(values, props.data?.num_code)}>
                <Row gutter={[16]}>
                    <Col span={24}>
                        <Form.Item name="nationalities" label={t("general.nationality")}>
                        <Select mode="multiple">
                                { contractCountries.map((item) => <Option  value={item.id} key={`${item.id}`}>{item.label}</Option>)}
                        </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal >
    )
};

export default ContractNationalityModalForm;
