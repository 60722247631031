import React from 'react';
import {Col, Form, Input, InputNumber, Modal, Row} from "antd";

const CmConditionCreateModalForm = (props) => {
    return (
        <Modal visible={props.isVisible} title={props.title} closable={false} onOk={() => props.form.submit()} onCancel={props.onCancel}>
            <Form form={props.form} layout="vertical" onFinish={(values) => props.onFinish(values, props.data?.id)} initialValues={{ is_active: true }} fields={props.data?.fields}>
                <Row gutter={[16,0]}>
                    <Col span={24}>
                        <Form.Item
                            name="title"
                            label="Kondüsyon Adı"
                            rules={[{ required: true, message: 'Lütfen kondüsyon adını giriniz.' }]}
                        >
                            <Input placeholder="Kondüsyon adını giriniz"/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="adult"
                            label="Yetişkin Sayısı"
                            rules={[{ required: true, message: 'Lütfen yetişkin sayısını giriniz.' }]}
                            initialValue={2}
                        >
                            <InputNumber style={{width:"100%"}} placeholder="Yetişkin sayısını giriniz"/>
                        </Form.Item>
                    </Col>
                    {props.childAges.map((item,index) => (
                        <Col span={12} key={index}>
                            <Form.Item
                                name={['children', item.id.toString()]}
                                label={item.name}
                                rules={[{ required: true, message: 'Lütfen çocuk sayısını giriniz.' }]}
                                initialValue={0}
                            >
                                <InputNumber style={{width:"100%"}} placeholder="Çocuk sayısını giriniz"/>
                            </Form.Item>
                        </Col>
                    ))}
                </Row>
            </Form>
        </Modal>
    );
};

export default CmConditionCreateModalForm;