import Wrapper from "@components/Wrapper";
import {
    Breadcrumb,
    Layout,
    notification,
    Form,
    Col,
    Input,
    Row,
    Button,
    Collapse,
    Space,
    Radio,
    Image,
    Checkbox, Tabs, Popconfirm
} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {HotelHeaderService, AgencyWebLanguageService, IconService, AgencyWebImageService} from '@services';
import {faHouse, faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import IconSelect from "../../components/utils/IconSelect";

const {Content} = Layout;
const {Panel} = Tabs;
function HeaderSettings(){
    const [headerSettings] = Form.useForm()
    const params = useParams()
    const [header,setHeader] = useState([])
    const [initialValues,setInitialValues] = useState([])
    const [images,setImages] = useState([])
    const [icons,setIcons] = useState([])
    const [isIconsLoading,setIsIconsLoading] = useState(true)
    const [languages, setLanguages] = useState([])
    const applyToAll = (code) => {
        let values = headerSettings.getFieldsValue().values[code]
        let keys = Object.keys(values)
        if(values) {
            keys?.map(key => {
                languages?.map((lang)=>{
                    headerSettings.setFields([
                        {
                            name: ['values',lang.code,key],
                            value: values[key],
                        }
                    ])
                })
            })
            notification.success({
                message:"Tüm veriler diğer tüm dilleri uygulandı!"
            })
        }
    }
    const getImages = async () => {
        try {
            let { data: { data: response } } = await AgencyWebImageService.getByHotel(params.hotelId);
            let array = []
            response.map((res) => {
                array.push({
                    uid: res.id,
                    name: res.name,
                    status: 'done',
                    url: `https://${res.url}/${res.hotel_id}/${res.hashed_name}`,
                })
            })
            setImages(array)
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    };
    const getIcons = async () => {
        try {
            let { data: { data: response } } = await IconService.get();
            setIcons(response)
            setIsIconsLoading(false)
        } catch (e) {
            console.log(e);
        }
    };
    const getHotelHeaders = async () =>{
        try {
            let { data: response } = await HotelHeaderService.getByHotel(params.hotelId);
            setHeader(response)
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }
    const getLanguages = async () => {
        try {
            let { data: { data: response } } = await AgencyWebLanguageService.getByHotel(params.hotelId);
            setLanguages(response)
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }
    const onSubmit = async (values) =>{
        try {
            let { data: response } = await HotelHeaderService.create(params.headerId,values);
            if(response.success){
                notification.success({
                    message:response.message
                })
            }
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }
    function FieldItem(props) {
        let initial = initialValues;
        if (props?.type === "array" && initialValues[props?.lang.code]) {
            for (let i = 1; i < props?.name.length; i++) {
                let key = props?.name[i]
                initial = initial[key]
            }
        }
        switch (props.type) {
            case "text":
                return (
                    <Col span={props?.span}>
                        <Form.Item name={props?.array ? props?.name : ['values', props?.lang.code, props?.name]} rules={props?.rules} label={props?.label} initialValue={initialValues?.[props?.lang.code]?.[props?.name]}>
                            <Input type={props?.type} placeholder={props?.placeholder} />
                        </Form.Item>
                    </Col>
                )
            case "array":
                return (
                    <Col span={24}>
                        <Form.List
                            name={props?.name}
                            initialValue={initial}
                            rules={[
                                {
                                    validator: async (_, names) => {
                                        if (!names || names.length < 1) {
                                            return Promise.reject(new Error('Minimum 1 adet giriş yapılmalı!'));
                                        }
                                    },
                                },
                            ]}>
                            {(fields, { add, remove }, { errors }) => (
                                <>
                                    <Collapse style={{ display: fields.length < 1 && 'none' }}>
                                        {fields.map(({ key, name, ...restField }) => (
                                            <Panel forceRender={true} key={key} extra={<Space direction="horizontal" align="center">
                                                <FontAwesomeIcon onClick={() => remove(name)} icon={faTrash} />
                                            </Space>}>
                                                {props?.items.map((item, i) => (
                                                    <FieldItem key={i} {...item} name={[name, item.name]} lang={props?.lang} array={true} />
                                                ))}
                                            </Panel>
                                        ))}
                                    </Collapse>
                                    <Row style={{ marginTop: "12px" }}>
                                        <Col span={24}>
                                            <Form.Item>
                                                <Button htmlType="button" type="dashed" onClick={() => add()} block>
                                                    <Space align="center" direction="horizontal">
                                                        <FontAwesomeIcon icon={faPlus} />
                                                        {props?.add}
                                                    </Space>
                                                </Button>
                                                <Form.ErrorList errors={errors} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </>
                            )}
                        </Form.List>
                    </Col >
                )
            case "icon":
                return (
                    <Col span={props?.span}>
                        <Form.Item name={props?.array ? props?.name : ['values', props?.lang.code, props?.name]} rules={props?.rules} label={props?.label} initialValue={initialValues?.[props?.lang.code]?.[props?.name]}>
                            <IconSelect icons={icons} loading={isIconsLoading} data="source" />
                        </Form.Item>
                    </Col>
                )
            case "color":
                return (
                    <Col span={props?.span}>
                        <Form.Item name={props?.array ? props?.name : ['values', props?.lang.code, props?.name]} rules={props?.rules} label={props?.label} initialValue={initialValues?.[props?.lang.code]?.[props?.name] ? initialValues?.[props?.lang.code]?.[props?.name] : '#ffffff'}>
                            <Input type={props?.type} />
                        </Form.Item>
                    </Col>
                )
            case "image":
                return (
                    <Col span={props?.span}>
                        <div className="horizontal-scrollbar-1" style={{ width: "100%", overflowX: "auto", display: "flex", columnGap: "12px" }}>
                            <Form.Item name={props?.array ? props?.name : ['values', props?.lang.code, props?.name]} rules={props?.rules} label={props?.label} initialValue={initialValues?.[props?.lang.code]?.[props?.name] && initialValues?.[props?.lang.code]?.[props?.name]}>
                                <Radio.Group style={{ display: "flex", columnGap: "1rem" }}>
                                    {images?.map((image, index) => (
                                        <div key={index} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                            <Image width={100} height={100} style={{ objectFit: "cover" }} src={image.url} />
                                            <Radio value={image.url} />
                                        </div>
                                    ))}
                                </Radio.Group>
                            </Form.Item>
                        </div>
                    </Col>
                )
            case "images":
                return (
                    <Col span={props?.span}>
                        <div className="horizontal-scrollbar-1" style={{ width: "100%", overflowX: "auto", display: "flex", columnGap: "12px" }}>
                            <Form.Item name={props?.array ? props?.name : ['values', props?.lang.code, props?.name]} rules={props?.rules} label={props?.label} style={{ marginBottom: "10px" }} tooltip={"Performans açısından maksimum 3 adet seçilmesi önerilmektedir!"} initialValue={initialValues?.[props?.lang.code]?.[props?.name] && initialValues?.[props?.lang.code]?.[props?.name]}>
                                <Checkbox.Group style={{ display: "flex", columnGap: "1rem" }}>
                                    {images?.map((image, index) => (
                                        <div key={index} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                            <Image width={100} height={100} style={{ objectFit: "cover" }} src={image.url} />
                                            <Checkbox value={image.url} />
                                        </div>
                                    ))}
                                </Checkbox.Group>
                            </Form.Item>
                        </div>
                    </Col>
                )
            default:
                return false;
        }
    }
    useEffect(()=>{
        getHotelHeaders()
        getLanguages()
        getIcons()
        getImages()
    },[])
    return(
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-20">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse} />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href={`/site-management/${params.hotelId}`}>
                        Site Ayarları
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        Header Ayarları
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background">
                    <Form form={headerSettings} onFinish={onSubmit} layout="vertical">
                        <Tabs>
                            {
                                languages?.map((lang) => (
                                    <Tabs.TabPane key={lang.code} tab={lang.native_name} forceRender={true}>
                                        <Row style={{ marginBottom: "0.8rem", display: "flex", justifyContent: "flex-end" }}>
                                            <Space>
                                                <Popconfirm title="Tümünü uygulamak istediğinize emin misiniz?" okText="Uygula" cancelText="İptal" onConfirm={() => applyToAll(lang.code)}>
                                                    <Button size="small" style={{ fontSize: "12px" }}>Tümüne uygula</Button>
                                                </Popconfirm>
                                            </Space>
                                        </Row>
                                        <Row gutter={[16, 8]}>
                                            {
                                                header && JSON.parse(header?.[0]?.header?.fields).map((field, index) => (
                                                    <FieldItem {...field} key={index} lang={lang} name={field.type !== "array" ? field.name : ['values', lang.code, field.name]} />
                                                ))
                                            }
                                        </Row>
                                    </Tabs.TabPane>
                                ))
                            }
                        </Tabs>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">Kaydet</Button>
                        </Form.Item>
                    </Form>
                </div>
            </Content>
        </Wrapper>
    )
}

export default HeaderSettings