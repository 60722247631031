import React, { useEffect, useState } from "react";
import {Modal, Row, Col, Form, Select, Input, notification, Tabs} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { RoomDefinitionService, WebLanguageService } from '@services';
import RoomDefinitionModalForm from "@components/room-definitions/RoomDefinitionModalForm";
import {useParams} from "react-router-dom";

const { Option } = Select;
const { TextArea } = Input;
const { TabPane } = Tabs;

const AddRoomModalForm = (props) => {
  const params = useParams()
  const [isLoadingRooms, setIsLoadingRooms] = useState(false);
  const [roomDefinitions, setRoomDefinitions] = useState([]);
  const [webLanguages, setWebLanguages] = useState([]);

  // Yeni oda tanımlamak için
  const [newRoomDefinitionForm] = Form.useForm();
  const [newRoomDefinitionVisible, setNewRoomDefinitionVisible] = useState(false);

  useEffect(() => {
    getRoomDefinitions();
    getWebLanguages(params.hotelId)
  }, []);

  /**
   * Kayıtlı olan odaları getirir.
   */
  const getRoomDefinitions = async () => {
    try {
      let { data: response } = await RoomDefinitionService.get(params.hotelId);
      setRoomDefinitions(response.data);
      setIsLoadingRooms(false);
    } catch (e) {
      if (e.response) {
        notification.error({
          message: e.response.data.message
        });
      }
    }
  };

  const getWebLanguages = async (hotelId) => {
    try {
      let { data: response } = await WebLanguageService.getHotelLanguage(hotelId);
      setWebLanguages(response.data);
    } catch (e) {
      if (e.response) {
        notification.error({
          message: e.response.data.message
        });
      }
    }
  };

  /**
   * Otel için oda kayıt esnasında yeni oda oluşturulması için
   *
   * @param {string} selectedValue
   */
  const onChangeRoomSelectBox = (selectedValue) => {
    if (selectedValue === 'addRoom') {
      setNewRoomDefinitionVisible(true);
      setIsLoadingRooms(true);
      props.form.setFieldsValue({
        room_definition_id: null
      });
    }
  };

  /**
   * Yeni oda tanımlamak için
   *
   * @param {object} values
   */
  const onSaveNewRoomDefinition = async (values) => {
    try {

      let { data: response } = await RoomDefinitionService.create(values);
      notification.success({
        message: response.message
      });

      setNewRoomDefinitionVisible(false);
      newRoomDefinitionForm.resetFields();
      getRoomDefinitions();
    } catch (e) {
      if (e.response) {
        notification.error({
          message: e.response.data.message
        });
      }

      console.log(e);
    }
  };

  return (
      <>
        <RoomDefinitionModalForm
            form={newRoomDefinitionForm}
            isVisible={newRoomDefinitionVisible}
            title="Yeni Oda Tanımı Ekle"
            onFinish={onSaveNewRoomDefinition}
            onCancel={() => setNewRoomDefinitionVisible(false)}
        />

        <Modal visible={props.isVisible} title={props.title} closable={false} onOk={() => props.form.submit()} onCancel={props.onCancel}>
          <Form form={props.form} layout="vertical" onFinish={(values) => props.onFinish(values, props.data?.id)} fields={props.data?.fields}>
            <Tabs>
              {webLanguages?.map((language, index) => (
                <TabPane key={index} tab={language?.web_language['name']}>
                  <Row gutter={[16]}>
                    <Col span={12}>
                      <Form.Item name="room_definition_id" label="Oda Adı" rules={[{ required: true, message: 'Tanımlanan oda seçmek zorunludur.'}]}>
                        <Select onChange={onChangeRoomSelectBox} loading={isLoadingRooms} placeholder="Oda seçiniz.">
                          <Option key={`addRoomOption`} value="addRoom">
                            <FontAwesomeIcon icon={faPlus} />
                            Yeni Oda Ekle
                          </Option>
                          {roomDefinitions.map((roomDefinition) => <Option key={`room-definition-${roomDefinition.id}`} value={roomDefinition.id}>{roomDefinition.name}</Option>)}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item name="m2" label="Metrekare (m2)" rules={[{ required: true, message: 'Metrekare zorunludur.' }]}>
                        <Input key="roomM2" placeholder="m2" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item name="min_people" label="Min. Kişi Sayısı" rules={[{ required: true, message: 'Min. kişi sayısı zorunludur.' }]}>
                        <Input key="roomMinPeople" placeholder="Min. Kişi Sayısı" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item name="max_people" label="Max. Kişi Sayısı" rules={[{ required: true, message: 'Max. kişi sayısı zorunludur.' }]}>
                        <Input key="roomMaxPeople" placeholder="Max. Kişi Sayısı" />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item name={['room',language?.web_language['iso_639-1'],'description']} label="Oda Özellikleri" rules={[{ required: true, message: 'Oda Açıklaması Zorunludur.' }]}>
                        <TextArea/>
                      </Form.Item>
                    </Col>
                  </Row>
                </TabPane>
              ))}
            </Tabs>
          </Form>
        </Modal>
      </>
  );
};

export default AddRoomModalForm;