import axios from '../axios';

const ReservationDetailService = {
    async get(reservationId) {
        return await axios.get(`/v1/reservations/${reservationId}`);
    },
    async getById(reservationId) {
        return await axios.get(`/v1/reservations/${reservationId}/edit`);
    },
    async getVoucher (uuid, lang){
        axios.defaults.responseType = 'blob';
        let data = await axios.get(`/v1/reservations/${uuid}/voucher?lang=${lang}`)
        axios.defaults.responseType = 'json';
        return data;
    },
    async getReservationForm (uuid){
        axios.defaults.responseType = 'blob';
        let data = await axios.get(`/v1/reservations/${uuid}/reservation_form`)
        axios.defaults.responseType = 'json';
        return data;
    },
    async sendReservationCost (uuid,formData){
        return await axios.post(`/v1/reservations/${uuid}/reservationCostSend`,formData)
    },
    async sendReservationProforma (uuid,formData){
        return await axios.post(`/v1/reservations/${uuid}/reservationProformaSend`,formData)
    },
    async sendReservationBalance (uuid,formData){
        axios.defaults.responseType = 'blob';
        let response = await axios.post(`/v1/reservations/${uuid}/reservationBalanceSend`,formData)
        axios.defaults.responseType = 'json';
        return response;
    },
    async sendReservationVoucher (uuid,formData){
        return await axios.post(`/v1/reservations/${uuid}/reservationVoucherSend`,formData)
    },
    async sendReservationSaleAgreement (uuid,formData){
        return await axios.post(`/v1/reservations/${uuid}/reservationSaleAgreementSend`,formData)
    },
    async sendReservationFactSheet (uuid,formData){
        return await axios.post(`/v1/reservations/${uuid}/reservationFactSheetSend`,formData)
    },
    async updateReservationCost (uuid,formData){
        return await axios.post(`/v1/reservations/${uuid}/reservationCostUpdate`,formData)
    },
    async setCallCenterSale(uuid,formData){
        return await axios.post(`/v1/reservations/${uuid}/setCallCenterSale`,formData)
    },
    async createAdditionalService(uuid,formData){
        return await axios.post(`/v1/reservations/${uuid}/createAdditionalService`,formData)
    },
    async deleteAdditionalService(uuid, serviceId){
        return await axios.delete(`/v1/reservations/${uuid}/deleteAdditionalService/${serviceId}`)
    }
};

export default ReservationDetailService;