import React from "react";
import {
  Modal,
  Form,
  Row,
  Col,
  Image,
} from "antd";
import { useParams } from "react-router-dom";

const VoucherModalForm = (props) => {
  const {id,description,details,logo,city,country,district} = props.hotel;
  return (
    <Modal
      visible={props.isVisible}
      title={props.title}
      closable={false}
      onOk={() => props.form?.submit()}
      onCancel={props.onCancel}
    >
    <Row gutter={[16,16]}>
          <Col span={24}>
             <Image
              src={`${process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_API_URL : process.env.REACT_APP_DEV_API_URL}/storage/uploads/images/${id}/logo/${logo?.hashed_name}`}
              width={100}
             />
          </Col>
          <Col span={24}>
            <h4>Otel Adresi/Hotel Address/Hoteladresse/адрес отеля:</h4>
            <p>{country}/{city}/{district}</p>
          </Col>
          <Col span={24}>
            <h4>Otel İletişim Bilgisi/Hotel Contact Information/Kontaktinformationen des Hotels/Контактная информация отеля:</h4>
            <p>{details?.phone_number}</p>
          </Col>
          <Col span={24}>
            <h4>Otel Açıklaması/Hotel Description/Hotelbeschreibung/описание отеля:</h4>
            <p>{description}</p>
          </Col>
        </Row>  
    </Modal>
  );
};

export default VoucherModalForm;
