import axios from '../axios';

const ContractEarlyReservationDiscountService = {
    async getAllByAgency(params) {
        return await axios.get(`/v1/contract-early-reservation-discounts`, { params });
    },
    async getById(id) {
        return await axios.get(`/v1/contract-early-reservation-discounts/${id}`);
    },
    async create(formData) {
        return await axios.post(`/v1/contract-early-reservation-discounts`, formData);
    },
    async update(formData, id) {
        return await axios.post(`/v1/contract-early-reservation-discounts/${id}`, formData);
    },
    async delete(contractEarlyReservationDiscountId) {
        return await axios.delete(`/v1/contract-early-reservation-discounts/${contractEarlyReservationDiscountId}`);
    }
};

export default ContractEarlyReservationDiscountService;