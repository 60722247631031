import React, {useEffect, useState} from 'react';
import {Breadcrumb, Button, Col, Layout, notification, Row, Space, Switch, Table} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faHouse, faTrash} from "@fortawesome/free-solid-svg-icons";
import Wrapper from "@/components/Wrapper";
import {useTranslation} from "react-i18next";
import { LaraBeachTourService } from "@/services";
import {Link, useNavigate} from "react-router-dom";

const {Content} = Layout;

const PointsPage = () => {
    const navigate = useNavigate();
    const { t } = useTranslation()
    const redirectToCreate = () => navigate("/lara-beach-excursions/tours/create")
    const [tours, setTours] = useState({
        data: [],
        meta: {},
        loading: true
    })
    const columns = [
        {
            title: t("general.id"),
            dataIndex: "id",
            key: "id",
            render: (text) => <Link to={`/lara-beach-excursions/tours/${text}`}>{text}</Link>
        },
        {
            title: t("general.name"),
            dataIndex: "name",
            key: "name"
        },
        {
            title: t("general.price"),
            dataIndex: "price",
            key: "price",
            render: (text, record) => text + " " + record.currency?.symbol
        },
        {
            title: t("general.duration"),
            dataIndex: "duration",
            key: "duration",
            render: (text, record) => text + " " + t(`general.${record?.duration_type}`)
        },
        {
            title: t("general.is_published"),
            dataIndex: "is_published",
            key: "is_published",
            render: (text, record) => <Switch onChange={() => onPublishedChange(record.id)} checked={text} checkedChildren={t("general.yes")} unCheckedChildren={t("general.no")}/>
        },
        {
            title: t("general.actions"),
            key: "actions",
            render: (text, record) => (
                <Space size="middle">
                    <Button htmlType="button" type="danger" onClick={() => onDeleteTour(record.id)}>
                        <FontAwesomeIcon icon={faTrash} />
                    </Button>
                </Space>
            )
        }
    ]
    const onPublishedChange = async (id) => {
        try {
            let {data: response} = await LaraBeachTourService.changePublishStatus(id)
            if (response.success){
                notification.success({
                    message: response.message
                })
                setTours(prev => ({
                    ...prev,
                    data: prev.data.map(item => {
                        if (item.id === id){
                            return {
                                ...item,
                                is_published: !item.is_published
                            }
                        }
                        return item
                    })
                }))
            }
        }catch (e){
            if (e.response){
                notification.error({
                    message: e.response.data.message
                })
            }
        }
    }
    const onDeleteTour = async (id) => {
        try {
            let {data: response} = await LaraBeachTourService.delete(id)
            if (response.success){
                notification.success({
                    message: response.message
                })
                setTours(prev => ({
                    ...prev,
                    data: prev.data.filter(item => item.id !== id)
                }))
            }
        }catch (e){
            if (e.response){
                notification.error({
                    message: e.response.data.message
                })
            }
        }
    }
    const getTours = async (page = 1, per_page = 10) => {
        setTours(prev => ({
            ...prev,
            loading: true
        }))
        try {
            let {data: response} = await LaraBeachTourService.get(page, per_page)
            setTours({
                data: response.data,
                meta: response.meta,
                loading: false
            })
        }catch (e){
            if (e.response){
                notification.error({
                    message: e.response.data.message
                })
            }
            setTours(prev => ({
                ...prev,
                loading: false
            }))
        }
    }
    useEffect(() => {
        getTours()
    },[])
    return (
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-20">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse} />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {t("general.tours")}
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background">
                    <Row gutter={[16,16]}>
                        <Col span={24}>
                            <Button type="primary" htmlType="button" onClick={redirectToCreate}>
                                {t("general.create")}
                            </Button>
                        </Col>
                    </Row>
                    <Table
                        columns={columns}
                        dataSource={tours.data}
                        rowKey="id"
                        pagination={{
                            current: tours?.meta?.current_page,
                            pageSize: tours?.meta?.per_page,
                            total: tours?.meta?.total,
                            showSizeChanger: true,
                            onChange: (page, pageSize) => {
                                getTours(page, pageSize)
                            },
                        }}
                        loading={tours.loading}
                    />
                </div>
            </Content>
        </Wrapper>
    );
};

export default PointsPage;