import React, {useEffect} from 'react';
import {Form, Modal, notification, Select} from "antd";
import { CMConceptService } from '@services'
import {useParams} from "react-router-dom";
const {Option} = Select;

const CmRoomConceptAppendModalForm = (props) => {
    const params = useParams()
    const [isMounted, setIsMounted] = React.useState(false);
    const [concepts, setConcepts] = React.useState({
        data: [],
        isLoading: true,
        isError: false
    })
    const getConcepts = async (hotelId, page) => {
        try{
            let {data: response} = await CMConceptService.get(hotelId, page);
            setConcepts({
                ...concepts,
                data: response.data,
                isLoading: false,
            });
        }catch (e){
            if(e.response){
                notification.error({
                    message: e.response.data.message,
                });
            }
            setConcepts({
                ...concepts,
                isLoading: false,
                isError: true
            })
        }
    }

    useEffect(() => {
        if(!isMounted && props.isVisible){
            setIsMounted(true)
        }
    },[props.isVisible])

    useEffect(() => {
        if (isMounted){
            getConcepts(params.hotelId, "all")
        }
    },[isMounted])

    return (
        <Modal visible={props.isVisible} title={props.title} closable={false} onOk={() => props.form.submit()} onCancel={props.onCancel}>
            <Form form={props.form} layout="vertical" onFinish={(values) => props.onFinish(values, props.data)} initialValues={{ is_active: true }} fields={props.data?.fields}>
                <Form.Item
                    name="concepts"
                    label="Konseptler"
                    rules={[
                        {
                            required: true,
                            message: 'Konsept seçiniz.',
                        }
                    ]}
                >
                    <Select placeholder="Konsept seçiniz." mode="multiple">
                        {concepts.data.map((concept) => (
                            <Option key={concept.id} value={concept.id}>{concept.name}</Option>
                        ))}
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default CmRoomConceptAppendModalForm;