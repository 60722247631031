import React, {useEffect} from 'react';
import {
    Breadcrumb,
    Button,
    Col,
    DatePicker,
    Form,
    Input,
    InputNumber,
    Layout,
    notification,
    Radio,
    Row,
    Select
} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHouse, faMinus, faPlus, faSave} from "@fortawesome/free-solid-svg-icons";
import Wrapper from "@components/Wrapper";
import {ProviderReservationService, CountriesService} from "@services";
import ReservationProviderSelect from "@components/utils/ReservationProviderSelect";
import CurrencySelect from "@components/utils/CurrencySelect";
import TextArea from "antd/es/input/TextArea";

const {Content} = Layout;
const {Option} = Select;
const ProviderReservationCreate = () => {
    const [form] = Form.useForm();
    const [countries, setCountries] = React.useState([]);
    const onSubmit = async (values) => {
        values = {
            ...values,
            check_in: values.check_in.format('YYYY-MM-DD'),
            check_out: values.check_out.format('YYYY-MM-DD'),
            reservation_date: values.reservation_date.format('YYYY-MM-DD'),
            adults: values.adults.map(adult => {
                return {
                    ...adult,
                    birth_date: adult.birth_date ? adult.birth_date.format('YYYY-MM-DD'): null,
                    passport_date: adult.passport_date ? adult.passport_date.format('YYYY-MM-DD'): null
                }
            }),
            children: values?.children ? values.children.map(child => {
                return {
                    ...child,
                    birth_date: child.birth_date ? child.birth_date.format('YYYY-MM-DD'): null
                }
            }): []
        }
        try {
            let {data: response} = await ProviderReservationService.create(values);
            if (response.success){
                notification.success({
                    message: 'Başarılı',
                    description: response.message
                })
                form.resetFields();
            }
        }catch (e){
            if (e.response){
                notification.error({
                    message: 'Hata',
                    description: e.response.data.message
                })
            }
        }
    }

    const getCountries = async () => {
        try {
            let {data: {data: response}} = await CountriesService.get();
            setCountries(response);
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    };

    useEffect(() => {
        getCountries();
    }, [])
    return (
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-20">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse} />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        Provider Rezervasyonu Oluştur
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background">
                    <Form layout="vertical" form={form} onFinish={onSubmit}>
                        <Row gutter={[16,16]}>
                            <Col span={24}>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    icon={<FontAwesomeIcon icon={faSave} style={{
                                        marginRight: '5px'
                                    }}/>}
                                >
                                    Rezervasyonu Kaydet
                                </Button>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    name="provider"
                                    label="Provider"
                                    rules={[{
                                        required: true,
                                        message: "Provider seçimi yapınız!"
                                    }]}
                                >
                                    <ReservationProviderSelect
                                        placeholder="Provider seçiniz."
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    name="hotel_name"
                                    label="Otel Adı"
                                    rules={[{
                                        required: true,
                                        message: "Otel adı giriniz!"
                                    }]}
                                >
                                    <Input placeholder="Otel adını giriniz."/>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    name="room_name"
                                    label="Oda Adı"
                                    rules={[{
                                        required: true,
                                        message: "Oda adı giriniz!"
                                    }]}
                                >
                                    <Input placeholder="Oda adını giriniz."/>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    name="total_fee"
                                    label="Toplam Ücret"
                                    rules={[{
                                        required: true,
                                        message: "Toplam ücreti giriniz!"
                                    }]}
                                >
                                    <InputNumber min={0} placeholder="Toplam ücreti giriniz." style={{
                                        width: '100%'
                                    }}/>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    name="cost"
                                    label="Toplam Maliyet"
                                    rules={[{
                                        required: true,
                                        message: "Toplam maliyeti giriniz!"
                                    }]}
                                >
                                    <InputNumber min={0} placeholder="Toplam maliyeti giriniz." style={{
                                        width: '100%'
                                    }}/>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    name="currency"
                                    label="Para Birimi"
                                    rules={[{
                                        required: true,
                                        message: "Para birimini seçiniz!"
                                    }]}
                                >
                                    <CurrencySelect/>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    name="check_in"
                                    label="Giriş Tarihi"
                                    rules={[{
                                        required: true,
                                        message: "Giriş tarihini seçiniz!"
                                    }]}
                                >
                                    <DatePicker
                                        format="DD.MM.YYYY"
                                        style={{width:"100%"}}
                                        placeholder="Giriş Tarihi"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    name="check_out"
                                    label="Çıkış Tarihi"
                                    rules={[{
                                        required: true,
                                        message: "Çıkış tarihini seçiniz!"
                                    }]}
                                >
                                    <DatePicker
                                        format="DD.MM.YYYY"
                                        style={{width:"100%"}}
                                        placeholder="Giriş Tarihi"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    name="reservation_date"
                                    label="Rezervasyon Tarihi"
                                    rules={[{
                                        required: true,
                                        message: "Rezervasyon tarihini seçiniz!"
                                    }]}
                                >
                                    <DatePicker
                                        format="DD.MM.YYYY"
                                        style={{width:"100%"}}
                                        placeholder="Rezervasyon Tarihi"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name="guest_note"
                                    label="Misafir Notu"
                                >
                                    <TextArea
                                        placeholder="Misafir notu giriniz."
                                        autoSize={{ minRows: 3, maxRows: 5 }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.List
                                    name={['adults']}
                                    rules={[{
                                        validator: async (_, mails) => {
                                            if (!mails || mails.length < 1) {
                                                return Promise.reject(new Error('En az bir yetişkin girmelisiniz.!'));
                                            }
                                        }
                                    }]}
                                >
                                    {(fields, { add, remove }, { errors }) => (
                                        <>
                                            {fields.map((field, index) => (
                                                <div style={{ position: "relative" }} key={field.key}>
                                                    <Form.Item
                                                        label={<h4>{index + 1}. Yetişkin</h4>}
                                                        required={false}
                                                    >
                                                        <Row gutter={[16,0]}>
                                                            <Col span={24}>
                                                                <Form.Item
                                                                    name={[field.name, 'gender']}
                                                                    validateTrigger={['onChange', 'onBlur']}
                                                                    label="Cinsiyet"
                                                                    rules={[{
                                                                        required: index === 0,
                                                                        message: "Cinsiyet seçiniz!"
                                                                    }]}
                                                                >
                                                                    <Radio.Group>
                                                                        <Radio value="Erkek">Erkek</Radio>
                                                                        <Radio value="Kadin">Kadın</Radio>
                                                                    </Radio.Group>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={12}>
                                                                <Form.Item
                                                                    name={[field.name, 'first_name']}
                                                                    validateTrigger={['onChange', 'onBlur']}
                                                                    label="Ad"
                                                                    rules={[{
                                                                        required: index === 0,
                                                                        message: "Ad giriniz!"
                                                                    }]}
                                                                >
                                                                    <Input
                                                                        placeholder="Ad"
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={12}>
                                                                <Form.Item
                                                                    name={[field.name,'last_name']}
                                                                    label="Soyad"
                                                                    validateTrigger={['onChange', 'onBlur']}
                                                                    rules={[{
                                                                        required: index === 0,
                                                                        message: "Soyad giriniz!"
                                                                    }]}
                                                                >
                                                                    <Input
                                                                        placeholder="Soyad"
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={12}>
                                                                <Form.Item
                                                                    name={[field.name,'birth_date']}
                                                                    label="Doğum Tarihi"
                                                                    validateTrigger={['onChange', 'onBlur']}
                                                                    rules={[{
                                                                        required: index === 0,
                                                                        message: "Doğum tarihi giriniz!"
                                                                    }]}
                                                                >
                                                                    <DatePicker
                                                                        format="DD.MM.YYYY"
                                                                        style={{width:"100%"}}
                                                                        placeholder="Doğum Tarihi"
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={12}>
                                                                <Form.Item name={[field.name,'email']} label="E-Posta Adresi">
                                                                    <Input
                                                                        placeholder="E-Posta Adresi"
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={12}>
                                                                <Form.Item name={[field.name,'phone_number']} label="Telefon Numarası">
                                                                    <Input
                                                                        placeholder="Telefon Numarası"
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={12}>
                                                                <Form.Item name={[field.name,'identity']} label="Kimlik Numarası">
                                                                    <Input
                                                                        placeholder="Kimlik Numarası"
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={12}>
                                                                <Form.Item name={[field.name,'passport_date']} label="Pasaport Son Kul. Tarih">
                                                                    <DatePicker
                                                                        format="DD.MM.YYYY"
                                                                        style={{width:"100%"}}
                                                                        placeholder="Pasaport Son Kul. Tarih"
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={12}>
                                                                <Form.Item name={[field.name,'passport_country']} label="Pasaportun Verildiği Ülke">
                                                                    <Select
                                                                        showSearch
                                                                        placeholder="Uyruk"
                                                                        filterOption={(input, option) =>
                                                                            option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                                            option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                        }
                                                                    >
                                                                        {countries?.map((country, index) => (
                                                                            <Option key={country.num_code} value={country.num_code}>{country.nationality}</Option>
                                                                        ))}
                                                                    </Select>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={12}>
                                                                <Form.Item name={[field.name,'passport_number']} label="Pasaport Numarası">
                                                                    <Input
                                                                        placeholder="Pasaport Numarası"
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={12}>
                                                                <Form.Item name={[field.name,'country_of_birth']} label="Uyruk">
                                                                    <Select
                                                                        showSearch
                                                                        placeholder="Uyruk"
                                                                        filterOption={(input, option) =>
                                                                            option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                                            option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                        }
                                                                    >
                                                                        {countries?.map((country, index) => (
                                                                            <Option key={country.num_code} value={country.num_code}>{country.nationality}</Option>
                                                                        ))}
                                                                    </Select>
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </Form.Item>
                                                    <Button type="dashed" size="small" style={{
                                                        position: 'absolute',
                                                        right: '0',
                                                        top: '0',
                                                    }} onClick={() => remove(field.name)}>
                                                        <FontAwesomeIcon icon={faMinus}/>
                                                    </Button>
                                                </div>
                                            ))}
                                            <Form.Item>
                                                <Button
                                                    type="primary"
                                                    onClick={() => add()}
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                    icon={<FontAwesomeIcon icon={faPlus} style={{
                                                        marginRight: '5px'
                                                    }}/>}
                                                >
                                                    Yetişkin Ekle
                                                </Button>
                                                <Form.ErrorList errors={errors} />
                                            </Form.Item>
                                        </>
                                    )}
                                </Form.List>
                            </Col>
                            <Col span={12}>
                                <Form.List
                                    name={['children']}
                                >
                                    {(fields, { add, remove }, { errors }) => (
                                        <>
                                            {fields.map((field, index) => (
                                                <div style={{ position: "relative" }} key={field.key}>
                                                    <Form.Item
                                                        label={<h4>{index + 1}. Çocuk</h4>}
                                                        required={false}
                                                    >
                                                        <Row gutter={[16,0]}>
                                                            <Col span={24}>
                                                                <Form.Item
                                                                    name={[field.name, 'gender']}
                                                                    validateTrigger={['onChange', 'onBlur']}
                                                                    label="Cinsiyet"
                                                                >
                                                                    <Radio.Group>
                                                                        <Radio value="Erkek">Erkek</Radio>
                                                                        <Radio value="Kadin">Kadın</Radio>
                                                                    </Radio.Group>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={12}>
                                                                <Form.Item
                                                                    name={[field.name, 'first_name']}
                                                                    validateTrigger={['onChange', 'onBlur']}
                                                                    label="Ad"
                                                                >
                                                                    <Input
                                                                        placeholder="Ad"
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={12}>
                                                                <Form.Item
                                                                    name={[field.name,'last_name']}
                                                                    label="Soyad"
                                                                    validateTrigger={['onChange', 'onBlur']}
                                                                >
                                                                    <Input
                                                                        placeholder="Soyad"
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={12}>
                                                                <Form.Item
                                                                    name={[field.name,'birth_date']}
                                                                    label="Doğum Tarihi"
                                                                    validateTrigger={['onChange', 'onBlur']}
                                                                >
                                                                    <DatePicker
                                                                        format="DD.MM.YYYY"
                                                                        style={{width:"100%"}}
                                                                        placeholder="Doğum Tarihi"
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </Form.Item>
                                                    <Button type="dashed" size="small" style={{
                                                        position: 'absolute',
                                                        right: '0',
                                                        top: '0',
                                                    }} onClick={() => remove(field.name)}>
                                                        <FontAwesomeIcon icon={faMinus}/>
                                                    </Button>
                                                </div>
                                            ))}
                                            <Form.Item>
                                                <Button
                                                    type="primary"
                                                    onClick={() => add()}
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                    icon={<FontAwesomeIcon icon={faPlus} style={{
                                                        marginRight: '5px'
                                                    }}/>}
                                                >
                                                    Çocuk Ekle
                                                </Button>
                                                <Form.ErrorList errors={errors} />
                                            </Form.Item>
                                        </>
                                    )}
                                </Form.List>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Content>
        </Wrapper>
    );
};

export default ProviderReservationCreate;