import React from 'react';
import {Modal, Form, Input, Row, Col, Switch, InputNumber, DatePicker, Button} from 'antd';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";

const ContractGroupsModalForm = (props) => {
    const { t } = useTranslation()
    return (
        <Modal visible={props.isVisible} title={props.title} closable={false} onOk={() => props.form.submit()} onCancel={props.onCancel}>
            <Form form={props.form} layout="vertical" onFinish={props.onFinish}>
                <Row gutter={[16,8]}>
                    <Col span={24}>
                        <Form.Item
                            name="name"
                            label={t("general.contract_group_name")}
                            rules={[{
                                required: true,
                                message: 'Kontrat Grup Adı Girmelisiniz'
                            }]}
                        >
                            <Input placeholder="Kontrat Adı" />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="is_debt_works"
                            label={t("general.is_debt_works")}
                        >
                            <Switch checked={props.isDebtWorks} onChange={e => props.setIsDebtWorks(e)}/>
                        </Form.Item>
                    </Col>
                    {props.isDebtWorks &&
                        <>
                            <Form.List
                                name={['debt_details']}
                                rules={[{
                                    validator: async (_, debt_details) => {
                                        if (!debt_details || debt_details.length < 1) {
                                            return Promise.reject(new Error('En az bir değer giriniz!'));
                                        }
                                    }
                                }]}
                            >
                                {(fields, { add, remove }, { errors }) => (
                                    <>
                                        {fields.map((field, index) => (
                                            <Form.Item
                                                label={index === 0 ? `${t("general.debt_values")}` : ''}
                                                required={false}
                                                key={field.key}
                                            >
                                                <div style={{display:"flex", alignItems:"center", columnGap:10}}>
                                                    <Form.Item
                                                        name={[field.name, 'debt_value']}
                                                        validateTrigger={['onChange', 'onBlur']}
                                                        noStyle
                                                        rules={[{
                                                            required: true,
                                                            message: 'Borç Değeri Girmelisiniz'
                                                        }]}
                                                    >
                                                        <InputNumber
                                                            addonBefore="%"
                                                            placeholder={t("general.debt_value")}
                                                            style={{width: '100%'}}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item
                                                        name={[field.name, 'debt_start_date']}
                                                        validateTrigger={['onChange', 'onBlur']}
                                                        noStyle
                                                        rules={[{
                                                            required: true,
                                                            message: 'Başlangıç Tarihi Girmelisiniz'
                                                        }]}
                                                    >
                                                        <DatePicker
                                                            style={{width: '100%'}}
                                                            format="DD/MM/YYYY"
                                                            placeholder={t("general.start_date")}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item
                                                        name={[field.name, 'debt_end_date']}
                                                        validateTrigger={['onChange', 'onBlur']}
                                                        noStyle
                                                        rules={[{
                                                            required: true,
                                                            message: 'Bitiş Tarihi Girmelisiniz'
                                                        }]}
                                                    >
                                                        <DatePicker
                                                            style={{width: '100%'}}
                                                            format="DD/MM/YYYY"
                                                            placeholder={t("general.end_date")}
                                                        />
                                                    </Form.Item>
                                                    <Button
                                                        icon={<FontAwesomeIcon icon={faMinus}/>}
                                                        type="dashed"
                                                        onClick={() => remove(field.name)}
                                                    />
                                                </div>
                                            </Form.Item>
                                        ))}
                                        <Col span={24}>
                                            <Form.Item>
                                                <Button
                                                    type="dashed"
                                                    onClick={() => add()}
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                    icon={<FontAwesomeIcon icon={faPlus}/>}
                                                >
                                                    {t("general.add_new_debt_value")}
                                                </Button>
                                                <Form.ErrorList errors={errors} />
                                            </Form.Item>
                                        </Col>
                                    </>
                                )}
                            </Form.List>
                        </>
                    }
                </Row>
            </Form>
        </Modal>
    )
};

export default ContractGroupsModalForm;