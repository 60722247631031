import React, { useEffect, useState } from 'react';
import {
    Layout,
    Breadcrumb,
    Button,
    Table,
    notification,
    Space,
    Tag,
    Collapse,
    Skeleton,
    Row,
    Col,
    Input,
    Form, Tooltip, DatePicker, Select, Popconfirm, Checkbox, InputNumber
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faHouse, faEdit, faTrash, faSearch, faBan, faPlaneSlash} from '@fortawesome/free-solid-svg-icons';
import Wrapper from '@components/Wrapper';
import { PackageReservationService } from '@services';
import moment from "moment/moment";

const { Content } = Layout;
const { Option } = Select;
const { RangePicker } = DatePicker;

const PackageReservations = () => {
    const [isLoadingTable, setIsLoadingTable] = useState(true);
    const [reservations, setReservations] = useState({
        data: [],
        isLoading: true,
        isError: false,
        meta: {},
    });
    const [reservationSearchForm] = Form.useForm();
    const columns = [
        {
            key: "id",
            title: "Rezervasyon Id",
            dataIndex: 'id',
            render: (text) => text,
            ...getColumnSearchProps('search'),
        },
        {
            key: "room_name",
            title: "Oda Adı",
            dataIndex: 'room_name',
            ...getColumnSearchProps('search'),
        },
        {
            key: "hotel",
            title: "Otel Adı",
            dataIndex: 'hotel',
            ...getColumnSearchProps('search'),
        },
        {
            key: "adult_count",
            title: "Yetişkin Sayısı",
            dataIndex: 'adult_count',
            ...getColumnSearchProps('number'),
        },
        {
            key: "child_count",
            title: "Çocuk Sayısı",
            dataIndex: 'child_count',
            ...getColumnSearchProps('number'),
        },
        {
            key: "check_in",
            title: "Giriş Tarihi",
            dataIndex: 'check_in',
            ...getColumnSearchProps('date'),
        },
        {
            key: "check_out",
            title: "Çıkış Tarihi",
            dataIndex: 'check_out',
            ...getColumnSearchProps('date'),
        },
        {
            key: "total_fee",
            title: "Toplam Ücret",
            dataIndex: 'total_fee',
            render: (text,record) => text + " " + record.currency.symbol,
            ...getColumnSearchProps('number'),
        },
        {
            key: "created_at",
            title: "Oluşturulma Tarihi",
            dataIndex: 'created_at',
            ...getColumnSearchProps('date'),
        }
    ];

    const handleTableChange = (pagination, filters, sorter) => {
        console.log(filters)
        filters = {
            ...filters,
            created_at: filters.created_at?.[0] && moment(filters.created_at?.[0]).format('YYYY-MM-DD'),
            check_out: filters.check_out?.[0] && moment(filters.check_out?.[0]).format('YYYY-MM-DD'),
            check_in: filters.check_in?.[0] && moment(filters.check_in?.[0]).format('YYYY-MM-DD')
        }
        getPackageReservations(pagination.current, pagination.pageSize, filters);
    }

    const getPackageReservations = async (page = 1, pageSize = 10, filters) => {
        setIsLoadingTable(true)
        try {
            let { data: response } = await PackageReservationService.get(page, pageSize, filters);
            setReservations({
                data: response.data,
                isLoading: false,
            });
            setIsLoadingTable(false);
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }

    useEffect(() => {
        getPackageReservations()
    }, []);
    return (
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-20">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse} />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        Paket Rezervasyonları
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background">
                    <Table
                        columns={columns}
                        dataSource={reservations.data}
                        rowKey={record => record.id}
                        loading={isLoadingTable}
                        bordered
                        onChange={handleTableChange}
                        expandable={{
                            expandedRowRender: (record) => (
                                <Row gutter={[16,6]}>
                                    <Col span={24}>
                                        <h3 style={{textDecoration:"underline"}}>Ziyaretçiler</h3>
                                    </Col>
                                    {record.guests.map((guest) => (
                                        <Col span={6}>
                                            <p><b>Ad Soyad:</b> {guest.name} ({guest.type})</p>
                                            <p><b>Cinsiyet:</b> {guest.gender}</p>
                                            <p><b>Doğum Tarihi:</b> {guest.birth_date}</p>
                                            <p><b>Email:</b> {guest.email}</p>
                                            <p><b>Telefon:</b> {guest.phone}</p>
                                            <p><b>Pasaport Tarihi:</b> {guest.passport_date}</p>
                                            <p><b>Pasaport Numarası:</b> {guest.passport_number}</p>
                                            <p><b>Pasaport Ülkesi:</b> {guest.passport_country}</p>
                                            <p><b>Doğum Ülkesi:</b> {guest.country_of_birth}</p>
                                        </Col>
                                    ))}
                                </Row>
                            ),
                            rowExpandable: (record) => record.guests !== null,
                        }}
                    />
                </div>
            </Content>
        </Wrapper>
    );
};

const getColumnSearchProps = (type) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
        <div style={{ padding: 8 }}>
            <Space direction="vertical">
                {type === 'search' && (
                    <Input
                        placeholder="Ara..."
                        value={selectedKeys[0]}
                        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => confirm()}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                )}
                {type === 'date_range' && (
                    <RangePicker
                        value={selectedKeys[0]}
                        onChange={(e) => setSelectedKeys(e ? [e] : [])}
                        onPressEnter={() => confirm()}
                        placeholder={['Başlangıç Tarihi', 'Bitiş Tarihi']}
                        format="DD/MM/YYYY"
                    />
                )}
                {type === 'date' && (
                    <DatePicker
                        style={{ width:"100%" }}
                        value={selectedKeys[0]}
                        onChange={(e) => setSelectedKeys(e ? [e] : [])}
                        onPressEnter={() => confirm()}
                        placeholder="Tarih"
                        format="DD.MM.YYYY"
                    />
                )}
                {type === 'number' && (
                    <InputNumber
                        style={{ width:"100%"}}
                        min={0}
                        value={selectedKeys[0]}
                        onChange={(e) => setSelectedKeys(e ? [e] : [])}
                        onPressEnter={() => confirm()}
                        placeholder="Ara..."
                    />
                )}
                {type === 'number_range' && (
                    <Row gutter={16}>
                        <Col span={12}>
                            <Input
                                placeholder="Min"
                                value={selectedKeys[0]}
                                onChange={e => setSelectedKeys([e.target.value ? parseInt(e.target.value, 10) : null])}
                                onPressEnter={() => confirm()}
                                style={{ width: 90, marginBottom: 8, display: 'block' }}
                            />
                        </Col>
                        <Col span={12}>
                            <Input
                                placeholder="Max"
                                value={selectedKeys[1]}
                                onChange={e => setSelectedKeys([selectedKeys[0], e.target.value ? parseInt(e.target.value, 10) : null])}
                                onPressEnter={() => confirm()}
                                style={{ width: 90, marginBottom: 8, display: 'block' }}
                            />
                        </Col>
                    </Row>
                )}
                <Space>
                    <Button
                        type="primary"
                        onClick={() => confirm()}
                        icon={<FontAwesomeIcon icon={faSearch} />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Ara
                    </Button>
                    <Button
                        onClick={() => {
                            clearFilters();
                            confirm();
                        }}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Sıfırla
                    </Button>
                </Space>
            </Space>
        </div>
    ),
});

export default PackageReservations;