import React, {useState} from 'react';
import {
    Breadcrumb,
    Button,
    Col,
    Form,
    Input,
    Layout,
    notification,
    Row, Select,
    Space,
    Switch,
    Table, Tag, Spin,
    Tooltip, Checkbox
} from "antd";
import debounce from 'lodash/debounce';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faAddressBook, faChild,
    faCodeCompare, faDoorOpen,
    faHouse, faList, faListOl,
    faPeopleArrows,
    faRefresh,
    faSearch, faStar
} from "@fortawesome/free-solid-svg-icons";
import Wrapper from "@components/Wrapper";
import {ExternalHotelService, ExternalCardService, RegionService, ExternalOperatorService} from "@services";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import HotelCardModalForm from "../../../components/external/card/HotelCardModalForm";
import BgMatchModalForm from "../../../components/external/bgmatch/BgModalForm";
import ExternalBgService from "../../../services/external/ExternalBg.service";
import {useTranslation} from "react-i18next";
import HotelOperatorPriceMultiplexesModal
    from "@/views/external/hotels/components/HotelOperatorPriceMultiplexesModal";

const {Content} = Layout;

const PoolHotelList = () => {
    const { t } = useTranslation()
    const [cardModalForm] = Form.useForm();
    const [hotelOperatorPriceMultiplexForm] = Form.useForm();
    const [hotelSearchForm] = Form.useForm();
    const [bgForm] = Form.useForm();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const page = searchParams.get('page') || 1;
    const per_page = searchParams.get('per_page') || 10;
    const [selectedHotel, setSelectedHotel] = useState({});
    const [hotelCard , setHotelCard] = useState([]);
    const [isHotelCardModalVisible, setIsHotelCardModalVisible] = useState(false);
    const [isBgModalVisible, setIsBgModalVisible] = useState(false);
    const [operators, setOperators] = useState([]);
    const [selectedHotelOperatorPriceMultiplexesModalVisible, setSelectedHotelOperatorPriceMultiplexesModalVisible] = useState(false);

    const [hotels, setHotels] = React.useState({
        data: [],
        meta: {},
        loading: true,
        error: false
    });
    const [searchParameters, setSearchParameters] = useState({
        name: "",
        only_has_bgmatch: false,
        only_without_bgmatch: false,
        is_active: false
    });
    function DebounceSelect({ fetchOptions, debounceTimeout = 800, selectedValues, ...props }) {
        const [fetching, setFetching] = React.useState(false);
        const [options, setOptions] = React.useState([]);
        const fetchRef = React.useRef(0);
        const debounceFetcher = React.useMemo(() => {
            const loadOptions = (value) => {
                fetchRef.current += 1;
                const fetchId = fetchRef.current;
                setOptions([]);
                setFetching(true);
                fetchOptions(value).then((newOptions) => {
                    if (fetchId !== fetchRef.current) {
                        // for fetch callback order
                        return;
                    }

                    setOptions(newOptions);
                    setFetching(false);
                });
            };

            return debounce(loadOptions, debounceTimeout);
        }, [fetchOptions, debounceTimeout]);

        return (
            <Select
                labelInValue
                filterOption={false}
                onSearch={debounceFetcher}
                notFoundContent={fetching ? <Spin size="small" /> : null}
                {...props}
                options={options}
            />

        );
    }

    async function matchAllHotels() {
        try {
            let {data: response} = await ExternalBgService.matchAllHotels();
            notification.success({
                message: 'Başarılı',
                description: 'Tüm oteller BG eşleştirildi'
            })
        }
        catch (e){
            if(e.response){
                notification.error({
                    message: 'Hata',
                    description: e.response.data.message
                })
            }
        }
    }

    const getOperatorPriceMultiplexes = async (hotelId) => {
        try {
            let {data: response} = await ExternalHotelService.getOperatorPriceMultiplexes(hotelId);
            setSelectedHotel(hotelId);
            hotelOperatorPriceMultiplexForm.setFieldsValue({
                operator_id: response.data?.operator_id,
                whatsapp_number: response.data?.whatsapp_number,
                email: response.data?.email,
                phone_number: response.data?.phone_number,
            })
            setSelectedHotelOperatorPriceMultiplexesModalVisible(true);
        }catch (e){
            if (e.response) {
                notification.error({
                    message: 'Hata',
                    description: e.response.data.message
                })
            }
        }
    }

    const saveOperatorPriceMultiplexes = async (hotelId, values) => {
        try {
            let {data: response} = await ExternalHotelService.storeOperatorPriceMultiplexes(hotelId, values);
            if (response.success){
                notification.success({
                    message: 'Başarılı',
                    description: response.message
                })
                setSelectedHotelOperatorPriceMultiplexesModalVisible(false)
                setSelectedHotel(null)
            }
        }catch (e){
            if (e.response) {
                notification.error({
                    message: 'Hata',
                    description: e.response.data.message
                })
            }
        }
    }

    const getOperators = async (page = 1, per_page = 100) => {
        try {
            let {data: response} = await ExternalOperatorService.get(page, per_page);
            setOperators(response.data)
        }catch (e){
            if(e.response){
                notification.error({
                    message: 'Hata',
                    description: e.response.data.message
                })
            }
        }
    }


    async function fetcRegionList(value) {

        let {data: response} = await RegionService.search(value);
        let regions = [];
        response.data.map((region) => {
            regions.push({
                label: region.name,
                value: region.id,
            })
        })

        return regions;
    }
    const [value, setValue] = React.useState([]);

    const columns = [
        {
            title: 'Otel ID',
            dataIndex: 'real_id',
            width: 100,
            key: 'id',
        },
        {
            title: "Durum",
            dataIndex: 'external_is_active',
            key: 'external_is_active',
            render: (text, record) => (
                <Switch onChange={(e) => onActiveChange(record.real_id, e)} checked={record.external_is_active}/>
            )
        },
        {
            title: "Öne Çıkan",
            dataIndex: 'external_is_featured',
            key: 'external_is_featured',
            render: (text, record) => (
                <Switch onChange={(e) => onFeaturedChange(record.real_id, e)} checked={record.external_is_featured}/>
            )
        },
        {
            title: 'Otel Adı',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => <Link
                state={{prevPath: `/external-management/hotels?page=${page}&per_page=${per_page}`}}
                to={`/external-management/hotels/${record.real_id}/list`}>{text}</Link>,
        },
        {
            title: 'Kartlar',
            dataIndex: 'card',
            key: 'card',
            render: (text, record) => (
                record.cards.map(
                    (card, index) => (
                        <Tag key={index} color="green">{card.name}</Tag>
                    )
                )

            )
        },
        {
            title: 'Operator',
            dataIndex: 'operator',
            key: 'scrape_provider',
            render: (text, record) => record.scrape_provider
        },
        {
            title: 'Şehir',
            dataIndex: 'regions',
            key: 'regions',
            render: (text, record) => record.regions[0]?.city_name
        },
        {
            title: 'Bölgeler',
            dataIndex: 'region',
            key: 'region',
            render: (text, record) =>

                <DebounceSelect
                    key={record.real_id}
                    mode="multiple"
                    value={value[record.real_id] || []}
                    id={record.real_id}
                    placeholder="Select Region"
                    fetchOptions={fetcRegionList}
                    onChange={(newValue) => {
                        setValue((prevValues) => ({
                            ...prevValues,
                            [record.real_id]: newValue,
                        }));
                    }}
                    style={{
                        width: '100%',
                    }}
                    onLoad ={()=>{
                        record.region.map((region)=>{

                            setValue((prevValues) => ({
                                ...prevValues,
                                [record.real_id]: {
                                    label: region.name,
                                    value: region.id,
                                },
                            }));
                        })
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            hotelRegion(value);
                        }
                    }}
                />
        },
        {
            title:
                <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}>
                    <span>İşlemler</span>
                    <Button
                        style={{ alignSelf : "self-end"}}
                        htmlType={"button"}
                        onClick={() => {
                            matchAllHotels();
                        }}
                    >
                        <FontAwesomeIcon icon={faRefresh} />
                    </Button>
                </div>,
            key: 'action',
            render: (text, record) => (
                <Space align={"middle"}>
                    <Tooltip title="Otel Kart Eşleştirmesi">
                        <Button type="primary" onClick={()=>{setIsHotelCardModalVisible(true);setSelectedHotel(record.real_id); cardModalForm.resetFields() }}>
                            <FontAwesomeIcon icon={faCodeCompare} />
                        </Button>
                    </Tooltip>
                    <Tooltip title="BG Match">
                        <Button type="primary" onClick={()=>{setIsBgModalVisible(true); setSelectedHotel(record.real_id); bgForm.resetFields() }}>
                            <FontAwesomeIcon icon={faAddressBook} />
                        </Button>
                    </Tooltip>
                    <Tooltip title="Odalar">
                        <Button type="primary" href={`hotels/${record.real_id}/rooms`}>
                            <FontAwesomeIcon icon={faDoorOpen} />
                        </Button>
                    </Tooltip>
                    <Tooltip title="Konseptler">
                        <Button type="primary" href={`hotels/${record.real_id}/concepts`}>
                            <FontAwesomeIcon icon={faStar}/>
                        </Button>
                    </Tooltip>
                    <Tooltip title="Periyotlar">
                        <Button type="primary" href={`hotels/${record.real_id}/periods`}>
                            <FontAwesomeIcon icon={faList}/>
                        </Button>
                    </Tooltip>
                    <Tooltip title="Kondüsyonlar">
                        <Button type="primary" href={`hotels/${record.real_id}/condition-groups`}>
                            <FontAwesomeIcon icon={faPeopleArrows}/>
                        </Button>
                    </Tooltip>
                    <Tooltip title="Çocuk Yaşları">
                        <Button type="primary" href={`hotels/${record.real_id}/child-ages`}>
                            <FontAwesomeIcon icon={faChild}/>
                        </Button>
                    </Tooltip>
                    <Tooltip title="Operatör Fiyat Çoklama">
                        <Button type="primary" onClick={() => getOperatorPriceMultiplexes(record.real_id)}>
                            <FontAwesomeIcon icon={faListOl}/>
                        </Button>
                    </Tooltip>
                </Space>
            ),

        }
    ];


    const handleTableChange = (pagination, filters, sorter) => {
        navigate(`/external-management/pool-management?page=${pagination.current}&per_page=${pagination.pageSize}`)
        getHotels(pagination.current, pagination.pageSize, filters);
    }

    const onActiveChange = async (record, isActive) => {
        try{
            let {data: response} = await ExternalHotelService.changeActive(record, isActive);
            if (response.success) {
                notification.success({
                    message: 'Başarılı',
                    description: response.message
                })
                getHotels(page, per_page)
            }
        }catch (e) {
            if (e.response) {
                notification.error({
                    message: 'Hata',
                    description: e.response.data.message
                })
            }
        }
    }

    const onFeaturedChange = async (id) => {
        try{
            let {data: response} = await ExternalHotelService.changeFeatured(id);
            if (response.success) {
                notification.success({
                    message: 'Başarılı',
                    description: response.message
                })
                getHotels(page, per_page)
            }
        }catch (e) {
            if (e.response) {
                notification.error({
                    message: 'Hata',
                    description: e.response.data.message
                })
            }
        }
    }

    const getCards = async () => {
        try {
            let {data: response} = await ExternalCardService.get();
            setHotelCard(response.data)
        }catch (e){
            if(e.response){
                notification.error({
                    message: 'Hata',
                    description: e.response.data.message
                })
            }
        }
    }


    const getHotels = async (page, per_page, filters = {}) => {
        setHotels({
            ...hotels,
            loading: true
        })
        try {
            let {data: response} = await ExternalHotelService.getOnlyPool(page, per_page, searchParameters);
            setHotels({
                ...hotels,
                data: response.data,
                meta: response.meta,
                loading: false,
            })
            response.data.map((hotel)=>{
                let region = hotel.regions
                let regions = [];
                region.map((region)=>
                    regions.push({
                        label: region.name,
                        value: region.id,
                    })
                )
                setValue((prevValues) => ({
                    ...prevValues,
                    [hotel.real_id]: regions,
                }))
            })

        }catch (e){
            if(e.response){
                notification.error({
                    message: 'Hata',
                    description: e.response.data.message
                })
            }
            setHotels({
                ...hotels,
                loading: false
            })
        }
    }

    const onHotelCardModalFormFinish = async (values) => {
        try{
            let {data: response} = await ExternalCardService.createHotelCard(selectedHotel,values);
            if (response.success) {
                notification.success({
                    message: 'Başarılı',
                    description: response.message
                })
                getCards();
                setIsHotelCardModalVisible(false);
            }
        }catch (e) {
            if (e.response) {
                notification.error({
                    message: 'Hata',
                    description: e.response.data.message
                })
            }
        }
    }

    const onBgModalFormFinish = async (values) => {

        try{
            let {data: response} = await ExternalBgService.create(selectedHotel,values);
            if (response.success) {
                notification.success({
                    message: 'Başarılı',
                    description: response.message
                })
                setIsBgModalVisible(false);
            }
        }catch (e) {
            if (e.response) {
                notification.error({
                    message: 'Hata',
                    description: e.response.data.message
                })
            }
        }
    }


    const hotelRegion = async (values) => {
        try{
            let {data: response} = await RegionService.hotelRegion(values);
            if (response.success) {
                notification.success({
                    message: 'Başarılı',
                    description: response.message
                })

                getHotels();
            }
        }catch (e) {
            if (e.response) {
                notification.error({
                    message: 'Hata',
                    description: e.response.data.message
                })
            }
        }
    }


    React.useEffect(() => {
        getHotels();
        getCards();
        getOperators();
    },[])
    return (
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-20">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse} />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        Dış Kanal Yönetimi (Oteller)
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background">
                    <Form form={hotelSearchForm} onFinish={() => getHotels()} layout="vertical">
                        <Row gutter={[16,0]}>
                            <Col span={6}>
                                <Form.Item label={t("general.hotel_name")}>
                                    <Input placeholder={t("general.hotel_name")} onChange={(e) => setSearchParameters(prevState => {
                                        return {
                                            ...prevState,
                                            name: e.target.value
                                        }
                                    })}/>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="BG Match Olanlar">
                                    <Checkbox
                                        checked={searchParameters.only_has_bgmatch || false}
                                        onChange={(e) => {
                                            setSearchParameters(prevState => {
                                                return {
                                                    ...prevState,
                                                    only_has_bgmatch: e.target.checked,
                                                    only_without_bgmatch: e.target.checked ? false : searchParameters.only_without_bgmatch
                                                }
                                            })
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="BG Match Olmayanlar">
                                    <Checkbox
                                        checked={searchParameters.only_without_bgmatch || false}
                                        onChange={(e) => {
                                            setSearchParameters(prevState => {
                                                return {
                                                    ...prevState,
                                                    only_without_bgmatch: e.target.checked,
                                                    only_has_bgmatch: e.target.checked ? false : searchParameters.only_has_bgmatch
                                                }
                                            })
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="Aktif Olanlar">
                                    <Checkbox
                                        checked={searchParameters.is_active || false}
                                        onChange={(e) => {
                                            setSearchParameters(prevState => {
                                                return {
                                                    ...prevState,
                                                    is_active: e.target.checked,
                                                }
                                            })
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item>
                                    <Button style={{width:"100%"}} type="primary" htmlType="submit">
                                        <FontAwesomeIcon icon={faSearch}/>
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    <Table
                        columns={columns}
                        dataSource={hotels.data}
                        loading={hotels.loading}
                        rowKey={record => record.id}
                        pagination={{
                            total: hotels.meta.total,
                            current: hotels.meta.current_page,
                            pageSize: hotels.meta.per_page,
                        }}
                        onChange={handleTableChange}
                    />
                </div>

                <HotelCardModalForm
                    isVisible={isHotelCardModalVisible}
                    onCancel={() => {
                        setIsHotelCardModalVisible(false)
                    }}
                    selectedHotel={selectedHotel}
                    form={cardModalForm}
                    hotelCard={hotelCard}
                    onFinish={onHotelCardModalFormFinish}
                />

                <BgMatchModalForm
                    isVisible={isBgModalVisible}
                    onCancel={() => {
                        setIsBgModalVisible(false)
                    }}
                    selectedHotel={selectedHotel}
                    form={bgForm}
                    onFinish={onBgModalFormFinish}
                />

                <HotelOperatorPriceMultiplexesModal
                    form={hotelOperatorPriceMultiplexForm}
                    isVisible={selectedHotelOperatorPriceMultiplexesModalVisible}
                    onCancel={() => {
                        setSelectedHotelOperatorPriceMultiplexesModalVisible(false)
                        setSelectedHotel(null)
                    }}
                    onFinish={(values, hotelId) => saveOperatorPriceMultiplexes(hotelId, values)}
                    hotelId={selectedHotel}
                    operators={operators}
                />

            </Content>
        </Wrapper>
    );
};

export default PoolHotelList;