import axios from '../axios';

const WidgetsService = {
    async findById(id) {
        return await axios.get(`/v1/widgets/${id}`);
    },

    async get(page = 1) {
        return await axios.get(`/v1/widgets?page=${page}`);
    },

    async create(formData) {
        console.log(formData);
        return await axios.post(`/v1/widgets`, formData);
    },

    async update(id, formData) {
        return await axios.post(`/v1/widgets/${id}`, formData);
    },

    async delete(id) {
        return await axios.delete(`/v1/widgets/${id}`);
    },

    async activities(id, page) {
        return await axios.get(`/v1/widgets/${id}/activities?page=${page}`);
    }
};

export default WidgetsService;