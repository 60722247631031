import React from "react";
import {Modal, Row, Col, Form, Input} from "antd";
import CountryDefinitionSelect from "@components/utils/CountryDefinitionSelect";

const MainRegionModalForm = (props) => {
    return (
        <Modal visible={props.isVisible} title={props.title} closable={false} onOk={() => props.form.submit()} onCancel={props.onCancel}>
            <Form form={props.form} layout="vertical" onFinish={(values) => props.onFinish(values, props.data?.id)} fields={props.data?.fields}>
                <Row gutter={[16,16]}>
                    <Col span={12}>
                        <Form.Item name="country_id" label="Ülke" rules={[{ required: true, message: 'Ülke seçimi boş bırakılamaz.' }]}>
                            <CountryDefinitionSelect placeholder="Ülke seçiniz." />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="name" label="Ana Bölge Adı" rules={[{ required: true, message: 'Ana Bölge adı alanı boş bırakılamaz.' }]}>
                            <Input placeholder="Ana Bölge adını giriniz." />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="alternative_names" label="Alternatif Adlar">
                            <Input placeholder="Alternatif adlar giriniz." />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default MainRegionModalForm;