import axios from "../axios";

const ImageService = {
  async get(page = 1) {
    return await axios.get(`/v1/images?page=${page}`);
  },
  async createImageGroup(data) {
    return await axios.post('/v1/image-groups', data);
  }
};

export default ImageService;