import React from 'react';
import {Col, Form, Input, Modal, Row} from "antd";
import TextArea from "antd/es/input/TextArea";

const ReservationProviderModalForm = (props) => {
    return (
        <Modal visible={props.isVisible} title={props.title} closable={false} onOk={() => props.form.submit()} onCancel={props.onCancel} okText="Kaydet" cancelText="İptal">
            <Form form={props.form} layout="vertical" onFinish={(values) => props.onFinish(values)}>
                <Row gutter={[16,16]}>
                    <Col span={12}>
                        <Form.Item
                            name="name"
                            label="Sağlayıcı Adı"
                            rules={[{required: true, message: 'Sağlayıcı adı boş bırakılamaz!'}]}
                        >
                            <Input placeholder="Sağlayacı adını giriniz." />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="invoice_title"
                            label="Fatura Ünvanı"
                        >
                            <Input placeholder="Fatura ünvanını giriniz." />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="sign_title"
                            label="Tabela Ünvanı"
                        >
                            <Input placeholder="Tabela ünvanını giriniz." />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="author_name"
                            label="Yetkili Adı"
                        >
                            <Input placeholder="Yetkili adını giriniz." />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="author_phone"
                            label="Yetkili Telefon"
                        >
                            <Input placeholder="Yetkili telefonunu giriniz." />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="author_email"
                            label="Yetkili Eposta"
                        >
                            <Input placeholder="Yetkili e-postasını giriniz." />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="description"
                            label="Açıklama"
                        >
                            <TextArea placeholder="Açıklama giriniz." />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default ReservationProviderModalForm;