import axios from '../axios';

const ProviderReservationService = {
    async get(page = 1, pageSize = 10, searchParams = {}) {
        return await axios.get(`/v1/provider-reservations?page=${page}&per_page=${pageSize}&${new URLSearchParams(searchParams).toString()}`);
    },
    async show(id) {
        return await axios.get(`/v1/provider-reservations/${id}`);
    },
    async create(formData){
        return await axios.post(`/v1/provider-reservations`, formData);
    },
    async getPayments(reservationId) {
        return await axios.get(`/v1/provider-reservations/${reservationId}/payments`);
    },
    async createPayment(reservationId, formData) {
        return await axios.post(`/v1/provider-reservations/${reservationId}/payments`, formData);
    },
};

export default ProviderReservationService;