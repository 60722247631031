import React from 'react';
import {Modal, Form, Input, Row, Col, Select} from 'antd';

const {Option} = Select;

const HotelOperatorPriceMultiplexesModal = (props) => {
    return (
        <Modal visible={props.isVisible} title={props.title} closable={false} onOk={() => props.form.submit()} onCancel={props.onCancel}>
            <Form form={props.form} layout="vertical" onFinish={(values) => props.onFinish(values, props.hotelId)}>
                <Row gutter={[16]}>
                    <Col span={24}>
                        <Form.Item name="operator_id" label="Operator" rules={[{
                            required: true,
                            message: 'Operator seçiniz.'
                        }]}>
                            <Select placeholder="Operator">
                                {props?.operators?.map((operator) => (
                                    <Option key={operator.id} value={operator.id}>
                                        {operator.title}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name="whatsapp_number" label="Whatsapp Numarası" rules={[{
                            required: true,
                            message: 'Whatsapp numarası giriniz.'
                        }]}>
                            <Input placeholder="Whatsapp Numarası" />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name="phone_number" label="Telefon Numarası" rules={[{
                            required: true,
                            message: 'Telefon numarası giriniz.'
                        }]}>
                            <Input placeholder="Telefon Numarası" />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name="email" label="E-Posta" rules={[{
                            required: true,
                            message: 'E posta adresi giriniz.'
                        }]}>
                            <Input placeholder="E-Posta" />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal >
    )
};

export default HotelOperatorPriceMultiplexesModal;
