import axios from '../axios';

const HotelContactService = {
    async getByHotel(hotelId) {
        return await axios.get(`/v1/site-manager/hotel-contacts/${hotelId}`);
    },
    async create(hotelId,formData) {
        return await axios.post(`/v1/site-manager/hotel-contacts/${hotelId}`,formData);
    },
};

export default HotelContactService;