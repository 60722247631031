import React from 'react';
import {Breadcrumb, Button, Col, DatePicker, Form, Layout, notification, Row, Space, Table} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileExcel, faHouse, faSearch} from "@fortawesome/free-solid-svg-icons";
import Wrapper from "@/components/Wrapper";
import {ReservationService} from "@services";
import HotelSelect from "@/components/utils/HotelSelect";
import {useTranslation} from "react-i18next";
import moment from "moment";

const {Content} = Layout;
const {RangePicker} = DatePicker;

const ReservationForecast = () => {
    const { t } = useTranslation()
    const [searchForm] = Form.useForm();
    const [isExporting, setIsExporting] = React.useState(false)
    const [data, setData] = React.useState({
        data: [],
        loading: false
    });
    const columns = [
        {
            title: t("general.date"),
            dataIndex: "date",
            key: "date",
            render: (text) => moment(text).format("DD.MM.YYYY")
        },
        {
            title: t("general.reservation_count"),
            dataIndex: "count",
            key: "count"
        }
    ]
    const getData = async (values) => {
        values = {
            hotels: values.hotels,
            start_date: values.date_range[0].format("YYYY-MM-DD"),
            end_date: values.date_range[1].format("YYYY-MM-DD")
        }
        try {
            let {data: response} = await ReservationService.getCheckInForecast(values);
            setData({
                data: response.data,
                loading: false
            })
        }catch (e){
            if (e.response){
                notification.error({
                    description: e.response.data.message
                })
            }
        }
    }

    const onExport = async () => {
        setIsExporting(true)
        let values = searchForm.getFieldsValue();
        values = {
            hotels: values.hotels,
            start_date: values.date_range[0].format("YYYY-MM-DD"),
            end_date: values.date_range[1].format("YYYY-MM-DD")
        }
        try {
            let response = await ReservationService.exportCheckInForecast(values);
            const blob = new Blob([response.data], { type: response.headers['content-type'] });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'checkin_musterileri.xlsx';
            a.click();
            window.URL.revokeObjectURL(url);
            setIsExporting(false)
        }catch (e) {
            if (e.response) {
                notification.error({
                    description: e.response.data.message
                })
            }
            setIsExporting(false)
        }
    }
    return (
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-20">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse} />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        Reservations Forecast
                    </Breadcrumb.Item>

                </Breadcrumb>
                <div className="site-layout-background">
                    <Form form={searchForm} layout="vertical" onFinish={getData}>
                        <Row gutter={[16,16]}>
                            <Col span={6}>
                                <Form.Item
                                    name="hotels"
                                    label={t("general.hotel")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("general.required")
                                        }
                                    ]}
                                >
                                    <HotelSelect multiple={true} placeholder={t("general.hotel")} />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    name="date_range"
                                    label={t("general.date_range")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("general.required")
                                        }
                                    ]}
                                >
                                    <RangePicker
                                        format="DD.MM.YYYY"
                                        placeholder={[t("general.start_date"), t("general.end_date")]}
                                        style={{width: "100%"}}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="&nbsp;"
                                >
                                    <Space direction="horizontal">
                                        <Button type="primary" htmlType="submit">
                                            <FontAwesomeIcon icon={faSearch} />
                                        </Button>
                                        <Button type="primary" htmlType="button" disabled={data.data.length === 0} onClick={onExport} loading={isExporting}>
                                            <FontAwesomeIcon icon={faFileExcel} />
                                        </Button>
                                    </Space>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    <Row gutter={[16,16]}>
                        {data.data.map((item, index) => (
                            <Col span={24}>
                                <h1>{item?.hotel}</h1>
                                <Table
                                    columns={columns}
                                    dataSource={item.dates}
                                    loading={data.loading}
                                    pagination={false}
                                    bordered={true}
                                    size="small"
                                    rowKey={(record) => record.date}
                                />
                            </Col>
                        ))}
                    </Row>
                </div>
            </Content>
        </Wrapper>
    );
};

export default ReservationForecast;