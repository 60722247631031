import React, { useState } from 'react';
import {Modal, Upload, Row, Col, Button, message, Drawer, Image, Space, Card, Tabs} from 'antd';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Authentication } from '@services'
import { faInbox, faUpload } from "@fortawesome/free-solid-svg-icons";

const { TabPane } = Tabs;

const UploadHotelImageModalForm = (props) => {
    const [uploadDrawerVisible, setUploadDrawerVisible] = useState({
        visible: false,
        language: null
    });
    if (props.disabled) {
        return null;
    }
    return (
        <Modal visible={props.isVisible} title={props.title} closable={false} onCancel={props.onCancel}>
            <Tabs>
                {props.hotelLanguages?.map((language, i) => (
                    <TabPane key={i} tab={language?.web_language.name} forceRender={true}>
                        <Row gutter={[16,8]}>
                            <Col span={24}>
                                <Button onClick={() => setUploadDrawerVisible({
                                    visible: true,
                                    language: language?.id
                                })}>
                                    <FontAwesomeIcon icon={faUpload} />
                                    Yükle
                                </Button>
                            </Col>
                            {props.images.data?.filter((image) => image.language === language.web_language['iso_639-1']).map((image) => (
                                <Col key={image.id} xs={12} md={8}>
                                    <Card>
                                        <Image
                                            src={`http://` + image.url + props.selectedHotel + `/` + image.hashed_name}
                                        />
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    </TabPane>
                ))}
            </Tabs>
            <Drawer title="Fotoğraf Yükle" placement="bottom" size="large" visible={uploadDrawerVisible.visible} onClose={() => {setUploadDrawerVisible({
                visible: false,
                language: null
            });props.getImages(props.selectedHotel)}}>
                <Upload.Dragger
                    name="file"
                    multiple={true}
                    action={`${process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_API_URL : process.env.REACT_APP_DEV_API_URL}/v1/hotels/${props.selectedHotel}/images/${uploadDrawerVisible.language}`}
                    headers={{
                        Authorization: `Bearer ${Authentication.getToken()}`
                    }}
                    beforeUpload={(file) => {
                        const isImage = ['image/jpeg', 'image/jpg', 'image/png'].includes(file.type);
                        const isOptimized = file.size < 4194304;
                        if (!isImage) {
                            message.error(`${file.name} adlı dosya bir resim değildir!`);
                            return false;
                        }
                        if (!isOptimized) {
                            message.error(`${file.name} adlı dosyanız 4MB'dan büyük!`);
                            return false;
                        }
                        return true;
                    }}
                >
                    <p className="ant-upload-drag-icon">
                        <FontAwesomeIcon icon={faInbox} />
                    </p>
                    <p className="ant-upload-text">Tıkla veya sürükle!</p>
                </Upload.Dragger>
            </Drawer>
        </Modal>
    )
};

export default UploadHotelImageModalForm;