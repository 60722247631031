import axios from '../axios';

const SystemLogService = {
    async logs(file,page, perPage) {
        return await axios.get(`/v1/logs/logs?file=${file}&page=${page}&per_page=${perPage}`);
    },

    async folders() {
        return await axios.get('/v1/logs/folders');
    },
};

export default SystemLogService;