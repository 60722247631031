import axios from '../axios';
import Cookies from 'js-cookie';
import moment from 'moment';

const Authentication = {
  async check() {
    return await axios.post('/v1/auth/check');
  },

  async login(formData) {
    return await axios.post('/v1/auth/login', formData);
  },

  async resetPassword(formData) {
    return await axios.post('/v1/auth/reset-password', formData);
  },

  async forgotPassword(formData) {
    return await axios.post('/v1/auth/forgot-password', formData);
  },

  hasToken() {
    return this.getToken()?.length ? true : false;
  },
  refreshTokenExpires(){
    this.getToken()?.length &&
      this.setToken(this.getToken())
  },
  getToken() {
    return Cookies.get('_token');
  },

  setToken(token,expire_at) {
    Cookies.set('_token',token,{
      expires: moment(expire_at).toDate(),
      secure:true,
    })
  },

  revokeToken() {
    Cookies.remove('_token');
  }
};

export default Authentication;