import { createSlice } from "@reduxjs/toolkit";

export const hotelSlice = createSlice({
  name: 'hotel',
  initialState: {
    hotels: [
      {
        id: 1,
        hotel_name: 'Maxx Royal Hotel',
        status: 1
      }
    ]
  },
  reducers: {
    setHotels: (state, action) => {
      state.hotels = action.payload;
    }
  }
});

export const { setHotels } = hotelSlice.actions;

export default hotelSlice.reducer;