import React, {useEffect, useState} from 'react';
import {
    Breadcrumb,
    Button,
    Col,
    DatePicker,
    Form,
    Input,
    Layout,
    notification,
    Row,
    Select,
    Table,
    Tag,
    Tooltip
} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileExcel, faHouse, faSearch} from "@fortawesome/free-solid-svg-icons";
import Wrapper from "@components/Wrapper";
import {ReservationService} from "@services";
import moment from "moment/moment";
import {useTranslation} from "react-i18next";
import HotelSelect from "@components/utils/HotelSelect";

const {Content} = Layout;
const {RangePicker} = DatePicker;
const {Option} = Select;

const DeferredReservations = () => {
    const [searchParams, setSearchParams] = useState({
        hotels: "",
        status: "",
        date_range: "",
        check_in_range: "",
    });
    const { t } = useTranslation()
    const [reservationSearchForm] = Form.useForm();
    const [isLoading, setIsLoading] = React.useState(true);
    const [data, setData] = React.useState([]);
    const [page, setPage] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(10);
    const getDeferredReservations = async (page, pageSize, searchParams) => {
        try {
            let {data: response} = await ReservationService.getDeferred(page, pageSize, searchParams);
            setData(response);
            setIsLoading(false);
        }catch (e){
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }
    const exportDeferredReservations = async (searchParams) => {
        try {
            let response = await ReservationService.exportDeferred(searchParams);
            const blob = new Blob([response.data], { type: response.headers['content-type'] });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'vadeli_odemeler.xlsx';
            a.click();
            window.URL.revokeObjectURL(url);
        }catch (e){
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }
    const columns = [
        {
            key: "reservation_number",
            title: <span style={{color:"#1890ff",fontSize:"12px",textAlign:"center"}}>
                {t("general.reservation_no")}
            </span>,
            dataIndex: 'reservation_number',
            render: (text, record) => {
                if(record.status === 6){
                    return <Button
                        type="primary"
                        style={{ "fontSize": "12px", backgroundColor: "#6b7280", borderColor: "#6b7280" }}
                        block='true'
                        href={`/reservation-card/${record.uuid}`}
                        target="_blank"
                    >
                        {record.uuid}
                    </Button>
                }
                return <Button
                    type="primary"
                    style={{ "fontSize": "12px" }}
                    block='true'
                    href={`/reservation-card/${record.uuid}`}
                    target="_blank"
                >
                    {record.uuid}
                </Button>
            }
        },
        {
            key: 'fall_due',
            title: <span style={{ 'fontSize': '12px',textAlign: "center",color: "#1890ff", }}>
                {t("general.due_date")}
            </span>,
            dataIndex: 'fall_due',
            render: (text) => <h1 style={{ 'fontSize': '12px',textAlign:"center" }}>{text}</h1>
        },
        {
            key: 'is_paid',
            title: <span style={{ 'fontSize': '12px',textAlign: "center",color: "#1890ff", }}>
                {t("general.payment_status")}
            </span>,
            dataIndex: 'is_paid',
            render: (text) => <h1 style={{ 'fontSize': '12px',textAlign:"center" }}>
                {text ? <Tag color="green">{t("general.payment_completed")}</Tag> : <Tag color="yellow">{t("general.payment_pending")}</Tag>}
            </h1>
        },
        {
            key: 'amount',
            title: <span style={{ 'fontSize': '12px',textAlign: "center",color: "#1890ff", }}>
                {t("general.deferred_payment_amount")}
            </span>,
            dataIndex: 'amount',
            render: (text, record) => <h1 style={{ 'fontSize': '12px',textAlign:"center" }}>
                {text} {record?.currency_id?.symbol}
            </h1>
        },
        {
            key: 'balance_completion_amount',
            title: <span style={{ 'fontSize': '12px',textAlign: "center",color: "#1890ff", }}>
                {t("general.paid_amount")}
            </span>,
            dataIndex: 'balance_completion_amount',
            render: (text, record) => <h1 style={{ 'fontSize': '12px',textAlign:"center" }}>
                {text} {record?.currency_id?.symbol}
            </h1>
        },
        {
            key: 'reservation_provider',
            title: <span style={{ 'fontSize': '12px',textAlign: "center",color: "#1890ff", }}>
                {t("general.reservation_creator")}
            </span>,
            dataIndex: 'reservation_provider',
            render: (text, record) => {
                if(record?.users){
                    return <Tooltip title={record.users.role.name}><h1 style={{ 'fontSize': '12px',textAlign:"center"}}>{record.users.first_name} {record.users.last_name}</h1></Tooltip>
                }
                return <Tooltip title="Rezervasyonu paymyres üzerinden müşteri kendisi oluşturmuştur."><h1 style={{ 'fontSize': '12px',textAlign:"center"}}>Widget</h1></Tooltip>
            }
        },
        {
            key: 'hotel_name',
            title: <span style={{ 'fontSize': '12px',textAlign: "center",color: "#1890ff", }}>
                {t("general.hotel")}
            </span>,
            dataIndex: 'hotels',
            render: (text) =><h1 style={{ "fontSize": "12px" }}>{text.name}</h1>
        },
        {
            key: 'room_name',
            title: <span style={{ 'fontSize': '12px',textAlign: "center",color: "#1890ff", }}>
                {t("general.room")}
            </span>,
            dataIndex: 'room_detail',
            render: (text) =><h1 style={{ "fontSize": "12px" }}>{text?.active_language?.name}</h1>
        },
        {
            key: 'room_concept',
            title: <span style={{ 'fontSize': '12px',textAlign: "center",color: "#1890ff", }}>
                {t("general.concept")}
            </span>,
            dataIndex: 'concept_detail',
            render: (text) =><h1 style={{ "fontSize": "12px" }}>{text?.active_language?.name}</h1>
        },
        {
            key: 'full_name',
            title: <span style={{ "fontSize": "12px",textAlign: "center",color: "#1890ff", }}>
                {t("general.full_name")}
            </span>,
            dataIndex: 'full_name',
            render: (text, record) => <h1 style={{ "fontSize": "12px" }}>{text}</h1>
        },
        {
            key: 'phone_number',
            title: <span style={{ "fontSize": "12px",textAlign: "center",color: "#1890ff", }}>
                {t("general.contact_number")}
            </span>,
            dataIndex: 'phone_number',
            render: (text, record) => <h1 style={{ "fontSize": "12px" }}>{text}</h1>
        },
        {
            key: 'email',
            title: <span style={{ "fontSize": "12px",textAlign: "center",color: "#1890ff", }}>
                {t("general.email")}
            </span>,
            dataIndex: 'email',
            render: (text) => <h1 style={{ "fontSize": "12px",textAlign:text ? "left":"center" }}>{text ? text: "-"}</h1>
        },
        {
            key: 'check_in',
            title: <span style={{ "fontSize": "12px",textAlign: "center",color: "#1890ff", }}>
                {t("general.check_in")}
            </span>,
            dataIndex: 'check_in',
            render: (text, record) => <h1 style={{ "fontSize": "12px" }}>{moment(text).format('DD.MM.YYYY')}</h1>
        },
        {
            key: 'check_out',
            title: <span style={{ "fontSize": "12px",textAlign: "center",color: "#1890ff", }}>
                {t("general.check_out")}
            </span>,
            dataIndex: 'check_out',
            render: (text, record) => <h1 style={{ "fontSize": "12px" }}>{moment(text).format('DD.MM.YYYY')}</h1>
        },
        {
            key: 'total_fee',
            title: <span style={{ "fontSize": "12px",textAlign: "center",color: "#1890ff", }}>
                {t("general.reservation_price")}
            </span>,
            dataIndex: 'total_fee',
            render: (text, record) => <h1 style={{ "fontSize": "12px" }}>{text} {record?.currency_id?.symbol}</h1>
        },
        {
            key: 'discounted_fee',
            title: <span style={{ "fontSize": "12px",textAlign: "center",color: "#1890ff", }}>
                {t("general.discounted_reservation_price")}
            </span>,
            dataIndex: 'discounted_fee',
            render: (text, record) => <h1 style={{ "fontSize": "12px" }}>{text} {record?.currency_id?.symbol}</h1>
        },
        {
            key: 'cost',
            title: <span style={{ "fontSize": "12px",textAlign: "center",color: "#1890ff", }}>
                {t("general.cost")}
            </span>,
            dataIndex: 'cost',
            render: (text, record) => <h1 style={{ "fontSize": "12px" }}>{text} {record?.currency_id?.symbol}</h1>
        },
        {
            key: 'reservation_create_date',
            title: <span style={{ "fontSize": "12px",textAlign: "center",color: "#1890ff", }}>
                {t("general.reservation_date")}
            </span>,
            dataIndex: 'created_at',
            render: (text, record) => <h1 style={{ "fontSize": "12px" }}>{text}</h1>
        }
    ];
    useEffect(() => {
        getDeferredReservations(page, pageSize, searchParams).finally(() => setIsLoading(false));
    },[page, pageSize])
    return (
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-20">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse} />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {t("general.upcoming_reservations_deferred_payment")}
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background">
                    <Form form={reservationSearchForm} onFinish={() => getDeferredReservations(page, pageSize, searchParams)} layout="vertical">
                        <Row gutter={[16,0]}>
                            <Col span={6}>
                                <Form.Item label={t("general.hotel_name")}>
                                    <HotelSelect
                                        placeholder={t("general.hotel")}
                                        onChange={(value) => setSearchParams(prevState => {
                                            return {
                                                ...prevState,
                                                hotels: value
                                            }
                                        })}
                                        maxTagCount={2}
                                        value={searchParams.hotels || []}
                                        multiple={true}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label={t("general.deferred_date_range")}>
                                    <RangePicker
                                        format="DD.MM.YYYY"
                                        onChange={(date, dateString) => setSearchParams(prevState => {
                                            return {
                                                ...prevState,
                                                date_range: (dateString[0] && dateString[1]) ? `${dateString[0]},${dateString[1]}`: ""
                                            }
                                        })}
                                        placeholder={[t("general.start_date"), t("general.end_date")]}
                                        style={{width:"100%"}}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label={t("general.check_in_out_date_range")}>
                                    <RangePicker
                                        format="DD.MM.YYYY"
                                        onChange={(date, dateString) => setSearchParams(prevState => {
                                            return {
                                                ...prevState,
                                                check_in_range: (dateString[0] && dateString[1]) ? `${dateString[0]},${dateString[1]}`: ""
                                            }
                                        })}
                                        placeholder={[t("general.start_date"), t("general.end_date")]}
                                        style={{width:"100%"}}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label={t("general.payment")}>
                                    <Select
                                        placeholder={t("general.payment")}
                                        onChange={(value) => setSearchParams(prevState => {
                                            return {
                                                ...prevState,
                                                status: value
                                            }
                                        })}
                                    >
                                        <Option value="">{t("general.all")}</Option>
                                        <Option value={1}>{t("general.payment_completed")}</Option>
                                        <Option value={0}>{t("general.payment_pending")}</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label={t("general.reservation_status")}>
                                    <Select
                                        allowClear={true}
                                        placeholder={t("general.reservation_status")}
                                        onChange={(value) => setSearchParams(prevState => {
                                            return {
                                                ...prevState,
                                                reservation_status: value || ""
                                            }
                                        })}
                                    >
                                        <Option key={1} value={1}>Ödeme Bekleniyor</Option>
                                        <Option key={2} value={2}>Confirme Bekleniyor</Option>
                                        <Option key={3} value={3}>Confirme Edildi</Option>
                                        <Option key={4} value={4}>Confirme Reddedildi</Option>
                                        <Option key={5} value={5}>Confirm'e Gönderilecek</Option>
                                        <Option key={6} value={6}>Rezervasyon İptal Edildi</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label={t("general.currency")}>
                                    <Select
                                        allowClear={true}
                                        mode="multiple"
                                        placeholder={t("general.currency")}
                                        onChange={(value) => setSearchParams(prevState => {
                                            return {
                                                ...prevState,
                                                currency: value || ""
                                            }
                                        })}
                                    >
                                        <Option key={1} value={90}>Euro</Option>
                                        <Option key={2} value={242}>Türk Lirası</Option>
                                        <Option key={3} value={249}>Pound Sterling</Option>
                                        <Option key={4} value={251}>US Dollar</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item>
                                    <Button style={{width:"100%"}} type="primary" htmlType="submit" onClick={() => setIsLoading(true)}>
                                        <FontAwesomeIcon icon={faSearch}/>
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    <Button type="primary" htmlType="button" onClick={() => exportDeferredReservations(searchParams)}>
                        <FontAwesomeIcon icon={faFileExcel}/>
                    </Button>
                    <Table
                        columns={columns}
                        scroll={{ x: 2000, y: "100%" }}
                        dataSource={data.data}
                        rowKey={record => record.uuid + Math.random()}
                        loading={isLoading}
                        bordered
                        size="small"
                        pagination={{
                            total: data.meta?.total,
                            defaultPageSize: data.meta?.per_page,
                            onChange: (page, pageSize) => {
                                setIsLoading(true)
                                getDeferredReservations(page, pageSize, searchParams)
                            }
                        }}
                    />
                </div>
            </Content>
        </Wrapper>
    );
};

export default DeferredReservations;