import Wrapper from "@components/Wrapper";
import {Breadcrumb, Col, Input, Layout, Row, Table, Form} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHouse} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect} from "react";
import {IconService} from "@services";

const {Content} = Layout;

function Icons(){
    const [icons,setIcons] = React.useState({
        data: [],
        filteredData: [],
        loading: true,
    });
    const [filterValue,setFilterValue] = React.useState('');
    const getIcons = async () => {
        try {
            let { data: { data: response } } = await IconService.get();
            return response;
        } catch (e) {
            console.log(e);
        }
    };
    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            render: (text, record, index) => index + 1
        },
        {
            title: 'İsim',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'İkon',
            dataIndex: 'icon',
            key: 'icon',
            render: (text, record) => (
                <svg width="24" height="24" viewBox={record.source.split('&&')[1]}>
                    <path d={record.source.split('&&')[0]}></path>
                </svg>
            )
        }
    ]
    useEffect(() => {
        if(filterValue.length > 0) {
            setIcons(prevState => {
                return {
                    ...prevState,
                    filteredData: prevState.data.filter(item => item.name.toLowerCase().includes(filterValue.toLowerCase()))
                }
            })
        }
        else {
            setIcons(prevState => {
                return {
                    ...prevState,
                    filteredData: prevState.data
                }
            })
        }
    },[filterValue])
    useEffect(() => {
        getIcons().then(response => {
            setIcons(prevState => {
                return {
                    ...prevState,
                    data: response,
                    filteredData: response,
                    loading: false
                }
            });
        })
    },[])
    return(
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-20">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse} />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        İkonlar
                    </Breadcrumb.Item>
                </Breadcrumb>

                <div className="site-layout-background">
                    <Row gutter={[16,16]}>
                        <Col span={8}>
                            <Form.Item label="İkon Filtreleme">
                                <Input value={filterValue} onChange={(e) => setFilterValue(e.target.value)} placeholder="Aramak istediğiniz ikonu giriniz."/>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Table
                                columns={columns}
                                pagination={{ pageSize: 20 }}
                                dataSource={icons.filteredData}
                            />
                        </Col>
                    </Row>
                </div>
            </Content>
        </Wrapper>
    )
}
export default Icons;