import React, {useEffect} from 'react';
import {Col, Form, Input, Modal, Row} from "antd";

const PopularFacilityModal = (props) => {

    useEffect(() => {
        props.form.setFieldsValue({
            facility: props.data?.facility
        })
    }, [props.data]);

    return (
        <Modal visible={props.isVisible} title={props.title} closable={false} onOk={() => props.form.submit()} onCancel={props.onCancel}>
            <Form form={props.form} layout="vertical" onFinish={(values) => props.onFinish(values, props.data?.id)}>
                <Row gutter={[16,16]}>
                    <Col span={24}>
                        <Form.Item
                            name="facility"
                            label="Özellik"
                            rules={[{
                                required: true,
                                message: 'Özellik alanı zorunludur!'
                            }]}
                        >
                            <Input
                                placeholder="Özellik giriniz"
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default PopularFacilityModal;