import { useEffect, useState } from 'react';
import { Authentication } from '@services';
import { useNavigate } from 'react-router-dom';
import { setUser } from '@features/userSlice';
import {useDispatch} from 'react-redux';
import Loading from "./Loading";
import {useTranslation} from "react-i18next";
import {setNotification} from "@features/notificationSlice";

const PrivateRoute = ({ children, permissions }) => {
  const { i18n } = useTranslation();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const getCheck = async () => {
      let response = await Authentication.check();
      let user = response.data.user;
      
      if (! response.data.is_logged) {
        navigate('/login');
      }

      if (permissions && ! permissions.every(r => user.role.permissions.includes(r))) {
        navigate('/');
      }
      if (user.locale){
        i18n.changeLanguage(user.locale);
        localStorage.setItem("locale", user.locale);
      }

      let notifications = {
        data: user.notifications || [],
        count: user.notifications_count || 0
      }

      dispatch(setNotification(notifications));
      dispatch(setUser(user));

      setLoading(false);
    };

    getCheck();
  }, []);

  return (
      <>
        {loading ? <Loading/> : children}
      </>
  )
};

export default PrivateRoute;