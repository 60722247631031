import React from 'react';
import {Modal, Form, Row, Col, DatePicker} from 'antd'

const {RangePicker} = DatePicker;

const ExternalPeriodDateModalForm = (props) => {
    const onOK = () => {
        props.form.submit();
    }
    return (
        <Modal visible={props.isVisible} title={props.title} closable={false} onOk={onOK} onCancel={props.onCancel}>
            <Form form={props.form} layout="vertical" onFinish={(values) => props.onFinish(values, props.data?.id)}>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Form.Item
                            name="date_range"
                            label="Başlangıç Tarihi"
                            rules={[{
                                required: true,
                                message: 'Başlangıç tarihi boş bırakılamaz!'
                            }]}
                        >
                            <RangePicker
                                style={{
                                    width: "100%"
                                }}
                                format="DD.MM.YYYY"
                                placeholder={["Başlangıç Tarihi", "Bitiş Tarihi"]}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
};

export default ExternalPeriodDateModalForm;