import React, { useEffect, useState } from 'react';
import { Layout, Breadcrumb, Button, Table, notification, Form, Space, Popconfirm } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse, faTrash, faPlus } from '@fortawesome/free-solid-svg-icons';
import Wrapper from '@components/Wrapper';
import ChildAgeModalForm from '@components/child-ages/ChildAgeModalForm';
import { ChildAgeService } from '@services';
import {useTranslation} from "react-i18next";

const { Content } = Layout;



const ChildAges = () => {
  const { t } = useTranslation()
  const [isLoadingTable, setIsLoadingTable] = useState(true);
  const [childAges, setChildAges] = useState({});

  const columns = [
    {
      title: '#',
      render: (text, record, index) => index + 1
    },
    {
      title: `${t("general.age_definition")}`,
      dataIndex: 'age_type'
    },
    {
      title: `${t("general.minimum_age")}`,
      dataIndex: 'minimum_age'
    },
    {
      title: `${t("general.maximum_age")}`,
      dataIndex: 'maximum_age'
    },
    {
      title: `${t("general.actions")}`,
      render: (text, record) => (
        <Space align="middle">
          <Popconfirm title="Silmek istediğine emin misin?" onConfirm={() => onDelete(record.id)} okText="Sil" cancelText="İptal">
            <Button type="danger">
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </Popconfirm>
        </Space>
      )
    }
  ];

  /* create modal form states */
  const [createModalForm] = Form.useForm();
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);

  useEffect(() => {
    getChildAges();
  }, []);

  const getChildAges = async () => {
    try {
      let { data: response } = await ChildAgeService.get();
      setChildAges(response);
      setIsLoadingTable(false);
    } catch (e) {
      if (e.response) {
        notification.error({
          message: e.response.data.message
        });
      }

      console.log(e);
    }
  };

  const onCreate = async (values) => {
    try {
      let { data: response } = await ChildAgeService.create(values);

      console.log(values);
      if (response.success) {
        setIsLoadingTable(true);
        getChildAges();
        setIsCreateModalVisible(false);
        createModalForm.resetFields();
        notification.success({
          message: response.message
        });
      }
    } catch (e) {
      if (e.response) {
        notification.error({
          message: e.response.data.message
        });
      }

      console.log(e);
    }
  }

  const onDelete = async (id) => {
    try {
      let { data: response } = await ChildAgeService.delete(id);

      console.log(response);

      if (response.success) {
        notification.success({
          message: response.message
        });

        setIsLoadingTable(true);
        getChildAges();
      }
    } catch (e) {
      if (e.response) {
        notification.error({
          message: e.response.data.message
        });
      }

      console.log(e);
    }
  };

  return (
    <Wrapper>
      <Content className="m-25">
        <Breadcrumb className="mb-20">
          <Breadcrumb.Item href="/">
            <FontAwesomeIcon icon={faHouse} />
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            {t("general.child_ages")}
          </Breadcrumb.Item>
        </Breadcrumb>

        <div className="site-layout-background">
          <Button type="primary" className="mb-10" onClick={() => setIsCreateModalVisible(true)}>
            <FontAwesomeIcon icon={faPlus} className="mr-10" />
            {t("general.new_child_age_definition")}
          </Button>

          <Table
            loading={isLoadingTable}
            columns={columns}
            dataSource={childAges.data}
            rowKey="id"
            bordered
          >
          </Table>
        </div>
      </Content>

      <ChildAgeModalForm
        form={createModalForm}
        isVisible={isCreateModalVisible}
        title={t("general.new_child_age_definition")}
        onFinish={onCreate}
        onCancel={() => setIsCreateModalVisible(false)}
      />
    </Wrapper>
  );
};

export default ChildAges;