import axios from '../axios';

const PriceChangesService = {
    async get() {
        return await axios.get(`/v1/price-changes`);
    },
    async getByConceptId(contractId, $contractConceptId) {
        return await axios.get(`/v1/price-changes/${contractId}/${$contractConceptId}`);
    },
    async create(contractId, contractConceptId, formData) {
        return await axios.post(`/v1/price-changes/${contractId}/${contractConceptId}`, formData);
    },
    async createByContractId(contractId, formData) {
        return await axios.post(`/v1/price-changes/${contractId}/dates`, formData);
    },
    async getByDate(params) {
        return await axios.get(`/v1/price-changes/${params.start_date}/${params.end_date}`,{
            params: {
                contracts: params.contracts,
            }
        });
    },
};

export default PriceChangesService;