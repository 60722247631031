import React from "react";
import {Select, notification} from "antd";
import {AirportService} from "@services";
const {Option}=Select;

function AirportDefinitionSelect (props){

    const [airports,setAirports] = React.useState([]);
    const [isLoading,setIsLoading] = React.useState(true);

    React.useEffect(() => {
        const getAirports = async () => {
            try {
                let {data :{data:response}} = await AirportService.get();
                setAirports(response);
                setIsLoading(false);
            } catch (e) {
                if (e.response) {
                    notification.error({
                        message: e.response.data.message
                    });
                }
            }
        };
        getAirports();
    }, []);
    return (
        <Select {...props} loading={isLoading}>
            {airports?.map((airport,i)=>(
                <Option value={airport.id} key={airport.id}>{airport.name}</Option>
            ))}
        </Select>
    )
}

export default AirportDefinitionSelect;