import React, {useEffect} from 'react';
import {Breadcrumb, Button, Col, DatePicker, Input, Layout, notification, Row, Space, Table, Tag} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileExcel, faHouse, faSearch} from "@fortawesome/free-solid-svg-icons";
import Wrapper from "@components/Wrapper";
import {VirtualPosTransactionService} from "@services";
import {Link} from "react-router-dom";
import moment from "moment";
import {useTranslation} from "react-i18next";

const {Content} = Layout;
const { RangePicker } = DatePicker;

const VirtualPosTransactions = () => {
    const { t } = useTranslation()
    const [searchParams, setSearchParams] = React.useState({})
    const getColumnSearchProps = (type) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div style={{ padding: 8 }}>
                <Space direction="vertical">
                    {type === 'search' && (
                        <Input
                            placeholder={t("general.search")}
                            value={selectedKeys[0]}
                            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                            onPressEnter={() => confirm()}
                            style={{ width: 188, marginBottom: 8, display: 'block' }}
                        />
                    )}
                    {type === 'date_range' && (
                        <RangePicker
                            value={selectedKeys[0]}
                            onChange={(e) => setSelectedKeys(e ? [e] : [])}
                            onPressEnter={() => confirm()}
                            placeholder={[`${t("general.start_date")}`, `${t("general.end_date")}`]}
                            format="DD/MM/YYYY"
                        />
                    )}
                    {type === 'number_range' && (
                        <Row gutter={16}>
                            <Col span={12}>
                                <Input
                                    placeholder="Min"
                                    value={selectedKeys[0]}
                                    onChange={e => setSelectedKeys([e.target.value ? parseInt(e.target.value, 10) : null])}
                                    onPressEnter={() => confirm()}
                                    style={{ width: 90, marginBottom: 8, display: 'block' }}
                                />
                            </Col>
                            <Col span={12}>
                                <Input
                                    placeholder="Max"
                                    value={selectedKeys[1]}
                                    onChange={e => setSelectedKeys([selectedKeys[0], e.target.value ? parseInt(e.target.value, 10) : null])}
                                    onPressEnter={() => confirm()}
                                    style={{ width: 90, marginBottom: 8, display: 'block' }}
                                />
                            </Col>
                        </Row>
                    )}
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => confirm()}
                            icon={<FontAwesomeIcon icon={faSearch} />}
                            size="small"
                            style={{
                                width: 90,
                            }}
                        >
                            {t("general.search")}
                        </Button>
                        <Button
                            onClick={() => {
                                clearFilters();
                                confirm();
                            }}
                            size="small"
                            style={{
                                width: 90,
                            }}
                        >
                            {t("general.reset")}
                        </Button>
                    </Space>
                </Space>
            </div>
        ),
    });
    const [data, setData] = React.useState({});
    const [loading, setLoading] = React.useState(true);
    const columns = [
        {
            title: `${t("general.reservation_number")}`,
            dataIndex: 'reservation_uuid',
            key: 'reservation_uuid',
            ...getColumnSearchProps('search'),
            render: (text) => (
                <Link to={`/reservation-card/${text}`} target="_blank">{text}</Link>
            )
        },
        {
            title: `${t("general.hotel_name")}`,
            dataIndex: 'hotel_name',
            key: 'hotel_name',
            ...getColumnSearchProps('search'),
        },
        {
            title: `${t("general.process_date")}`,
            dataIndex: 'created_at',
            key: 'created_at',
            ...getColumnSearchProps('date_range'),
        },
        {
            title: `${t("general.transaction_id")}`,
            dataIndex: 'transaction_id',
            key: 'transaction_id',
            ...getColumnSearchProps('search'),
        },
        {
            title: `${t("general.bank")}`,
            dataIndex: 'bank_name',
            key: 'bank_name',
            ...getColumnSearchProps('search'),
        },
        {
            title: `${t("general.card_number")}`,
            dataIndex: 'card_number',
            key: 'card_number',
            ...getColumnSearchProps('search'),
        },
        {
            title: `${t("general.amount")}`,
            dataIndex: 'amount',
            key: 'amount',
            ...getColumnSearchProps('number_range'),
        },
        {
            title: `${t("general.currency")}`,
            dataIndex: 'currency',
            key: 'currency',
            ...getColumnSearchProps('search'),
        }
    ];
    const handleTableChange = (pagination, filters, sorter) => {
        filters = {
            ...filters,
            created_at: filters.created_at?.[0]?.map(item => moment(item).format('YYYY-MM-DD'))
        }
        setSearchParams(filters)
        getVirtualPosTransactions(pagination.current, pagination.pageSize, filters);
    }
    const getVirtualPosTransactions = async (page = 1, pageSize = 10, filters) => {
        setLoading(true)
        try {
            let {data: response } = await VirtualPosTransactionService.get(page, pageSize, filters);
            setData(response);
            setLoading(false)
        }catch (e){
            if(e.response){
                notification.error({
                    message: e.response.message,
                })
            }
        }
    }

    const clearEmptyKeys = (obj) => {
        for (let key in obj) {
            if (obj[key] === null || obj[key] === undefined || obj[key] === '') {
                delete obj[key];
            }
        }
        return obj;
    }
    const onExport = async (filters) => {
        filters = clearEmptyKeys(filters);
        try {
            let response = await VirtualPosTransactionService.export(filters);
            const blob = new Blob([response.data], { type: response.headers['content-type'] });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'sanal_pos_islemleri.xlsx';
            a.click();
            window.URL.revokeObjectURL(url);
        }catch (e){
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }
    useEffect(() => {
        getVirtualPosTransactions().finally(() => setLoading(false));
    },[])
    return (
        <Wrapper title="Sanal Pos İşlemleri">
            <Content className="m-25">
                <Breadcrumb className="mb-20">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse} />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {t("general.virtual_pos_transactions")}
                    </Breadcrumb.Item>
                </Breadcrumb>

                <div className="site-layout-background">
                    <Button type="primary" htmlType="button" onClick={() => onExport(searchParams)}>
                        <FontAwesomeIcon icon={faFileExcel}/>
                    </Button>
                    <Table
                        columns={columns}
                        dataSource={data.data}
                        rowKey={record => record.id}
                        loading={loading}
                        pagination={{
                            total: data?.meta?.total,
                            pageSize: data?.meta?.per_page,
                            current: data?.meta?.current_page,
                        }}
                        onChange={handleTableChange}
                        footer={() => (
                            <>
                                <b style={{
                                    marginRight: '10px'
                                }}>{t("general.total")}:</b>
                                {data?.total_amount?.map((item,index) => (
                                    <Space key={index} hidden={data?.total_amount?.length === 0}>
                                        <Tag>{item?.amount} {item?.symbol}</Tag>
                                    </Space>
                                ))}
                            </>
                        )}
                    />
                </div>
            </Content>
        </Wrapper>
    );
};

export default VirtualPosTransactions;