import React from 'react';
import {Spin} from "antd";

const Loading = () => {
    return (
        <div style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        }}>
            <Spin size="default" />
        </div>
    );
};

export default Loading;