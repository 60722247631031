import React, { useState, useEffect, useMemo } from 'react';
import {Modal, Form, Input, Row, Col, Select, notification} from 'antd';
import {useParams} from "react-router-dom";
import ExternalCardService from "@services/external/ExternalCard.service";

const { Option } = Select;
const HotelCardModalForm = (props) => {


    const params = useParams();

    
    useEffect(() => {
        if(props.selectedHotel>0){
            getHotelCard();
        }
    }, [props.selectedHotel]);

    const getHotelCard = async () => {

        try {
            let {data: response} = await ExternalCardService.show(props.selectedHotel);
            let initialValues = [];
            response.map((item) => {
                initialValues.push(item.card.id)
            })
            props.form.setFieldsValue({
                name:initialValues
            })
        }
        catch (e){
            if(e.response){
                notification.error({
                    message: 'Hata',
                    description: e.response.data.message
                })
            }
        }

    }



    return (
        <Modal visible={props.isVisible} title={props.title} closable={false} onOk={() => props.form.submit()} onCancel={props.onCancel}>
            <Form form={props.form} layout="vertical" onFinish={(values) => props.onFinish(values, props.data?.name)}>
                <Row gutter={[16]}>
                    <Col span={24}>
                        <Form.Item name="name" label="Kart Eşleştir">
                        <Select mode="multiple">
                        {props.hotelCard?.map((item) => <Option  value={item.id} key={`${item.id}`}>{item.facility}</Option>)}
                        </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal >
    )
};

export default HotelCardModalForm;
