import React from 'react';
import {Col, Form, Input, Modal, Row, Tabs} from "antd";
import {useTranslation} from "react-i18next";

const { TabPane } = Tabs;

const PointModalForm = (props) => {
    const { t } = useTranslation()
    return (
        <Modal visible={props.isVisible} title={props.title} closable={false} onOk={() => props.form.submit()} onCancel={props.onCancel}>
            <Form form={props.form} layout="vertical" onFinish={(values) => props.onFinish(values, props.data?.id)} fields={props.data?.fields}>
                <Tabs>
                    {props.supportedLanguages?.map((language, index) => (
                        <TabPane tab={t(`general.${language}`)} key={index} forceRender>
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        name={['points', language, 'name']}
                                        label="Nokta Adı"
                                        rules={[{ required: true, message: 'Lütfen nokta adını giriniz!' }]}
                                    >
                                        <Input placeholder="Nokta Adı" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </TabPane>
                    ))}
                </Tabs>
            </Form>
        </Modal>
    );
};

export default PointModalForm;