import React, { useEffect, useState } from 'react';
import {Layout, Breadcrumb, notification, Form, Table, Space, Switch, Tag, Button} from 'antd';
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faHouse} from '@fortawesome/free-solid-svg-icons';
import { ExternalContractService } from '@services';
import Wrapper from '@components/Wrapper';
import {useTranslation} from "react-i18next";
import ContractModalForm from '@/views/external/contracts/components/contracts/ContractModalForm';
const { Content } = Layout;

const ExternalContracts = () => {
    const { t } = useTranslation()
    const [createForm] = Form.useForm();
    const params = useParams();
    const [contracts, setContracts] = useState([]);
    const [isLoadingTable, setIsLoadingTable] = useState(true);
    const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
    const columns = [
        {
            title: '#',
            render: (text, record, index) => index + 1
        },
        {
            title: `${t("general.room_name")}`,
            dataIndex: 'title',
            render: (text, record) => <Link to={`${record.id}/details`}>{record.hotel_room.room_definition.active_language.name}</Link>
        },
        {
            title: `${t("general.concepts")}`,
            dataIndex: 'concepts',
            render:(text) => {
                return text.map((concept, index) => {
                    return <Tag key={index} color="green">{concept?.concept?.active_language?.name}</Tag>
                })
            }
        },
        {
            title: `${t("general.active")}/${t("general.passive")}`,
            dataIndex: 'status',
            render: (text, record)=> (
                <Switch
                    checkedChildren={t("general.active")}
                    defaultChecked={record.status === 1}
                    unCheckedChildren={t("general.passive")}
                    id={record.id}
                    onChange={e=> statusChange(params.hotelId, params.contractGroupId, record.id, record.status !== 1)}
                />
            )
        },
        {
            title: `${t("general.actions")}`,
            render: (text, record) => (
                <Space align="middle">
                </Space>
            )
        }
    ];

    useEffect(() => {
        getContractsByGroup(params.contractGroupId);
    }, []);

    const getContractsByGroup = async () => {
        try {
            let { data: {data:response} } = await ExternalContractService.get(params.hotelId, params.contractGroupId);
            setContracts(response)
            setIsLoadingTable(false);
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    };

    const statusChange = async (hotelId, contractGroupId, contractId ,status) =>
    {
        try {
            let { data: response } = await ExternalContractService.setStatus(hotelId, contractGroupId, contractId ,status);
            if (response.success) {
                setIsLoadingTable(true);
                getContractsByGroup(params.hotelId, params.contractGroupId);
                notification.success({
                    message: response.message
                });
            }
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
            console.log(e);
        }
    }

    const onCreate = async (values) => {
        try {
            let { data: response } = await ExternalContractService.create(params.hotelId, params.contractGroupId, values);
            if (response.success) {
                setIsLoadingTable(true);
                getContractsByGroup();
                setIsCreateModalVisible(false);
                createForm.resetFields();
                notification.success({
                    message: response.message
                });
            }
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
            console.log(e);
        }
    }

    return (
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-20">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse} />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href="/contract-hotels">
                        Kontrat Otelleri
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href={`/contract-hotels/${params.hotelId}/contract-groups`}>
                        Kontrat Grupları
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        Odalar
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background">
                    <Button type="primary" onClick={() => setIsCreateModalVisible(true)}>
                        {t("general.add")}
                    </Button>
                    <Table
                        loading={isLoadingTable}
                        dataSource={contracts}
                        rowKey="id"
                        columns={columns}
                        bordered
                    />
                </div>
                <ContractModalForm
                    isVisible={isCreateModalVisible}
                    title={t("general.contracts")}
                    form={createForm}
                    onCancel={() => {
                        setIsCreateModalVisible(false)
                        createForm.resetFields()
                    }}
                    onFinish={onCreate}
                />
            </Content>
        </Wrapper>
    );
};

export default ExternalContracts;