import React, {useEffect, useState} from 'react';
import {Breadcrumb, Button, Col, DatePicker, Input, Layout, notification, Row, Space, Table} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileExcel, faHouse, faSearch} from "@fortawesome/free-solid-svg-icons";
import Wrapper from "@components/Wrapper";
import {BankTransactionService} from "@services";
import moment from "moment/moment";
import {useTranslation} from "react-i18next";

const { RangePicker } = DatePicker;
const { Content } = Layout;

const BankTransactions = () => {
    const { t } = useTranslation()
    const getColumnSearchProps = (type) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div style={{ padding: 8 }}>
                <Space direction="vertical">
                    {type === 'search' && (
                        <Input
                            placeholder={t("general.search")}
                            value={selectedKeys[0]}
                            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                            onPressEnter={() => confirm()}
                            style={{ width: 188, marginBottom: 8, display: 'block' }}
                        />
                    )}
                    {type === 'date_range' && (
                        <RangePicker
                            value={selectedKeys[0]}
                            onChange={(e) => setSelectedKeys(e ? [e] : [])}
                            onPressEnter={() => confirm()}
                            placeholder={[`${t("general.start_date")}`, `${t("general.end_date")}`]}
                            format="DD/MM/YYYY"
                        />
                    )}
                    {type === 'number_range' && (
                        <Row gutter={16}>
                            <Col span={12}>
                                <Input
                                    placeholder="Min"
                                    value={selectedKeys[0]}
                                    onChange={e => setSelectedKeys([e.target.value ? parseInt(e.target.value, 10) : null])}
                                    onPressEnter={() => confirm()}
                                    style={{ width: 90, marginBottom: 8, display: 'block' }}
                                />
                            </Col>
                            <Col span={12}>
                                <Input
                                    placeholder="Max"
                                    value={selectedKeys[1]}
                                    onChange={e => setSelectedKeys([selectedKeys[0], e.target.value ? parseInt(e.target.value, 10) : null])}
                                    onPressEnter={() => confirm()}
                                    style={{ width: 90, marginBottom: 8, display: 'block' }}
                                />
                            </Col>
                        </Row>
                    )}
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => confirm()}
                            icon={<FontAwesomeIcon icon={faSearch} />}
                            size="small"
                            style={{
                                width: 90,
                            }}
                        >
                            {t("general.search")}
                        </Button>
                        <Button
                            onClick={() => {
                                clearFilters();
                                confirm();
                            }}
                            size="small"
                            style={{
                                width: 90,
                            }}
                        >
                            {t("general.reset")}
                        </Button>
                    </Space>
                </Space>
            </div>
        ),
    });
    const handleTableChange = (pagination, filters, sorter) => {
        filters = {
            ...filters,
            process_date: filters.process_date?.[0]?.map(item => moment(item).format('YYYY-MM-DD')),
            approval_date: filters.approval_date?.[0]?.map(item => moment(item).format('YYYY-MM-DD'))
        }
        setIsExporting({
            ...exporting,
            filters: filters
        })
        getBankTransactions(pagination.current, pagination.pageSize, filters);
    }
    const columns = [
        {
            key: 'hotel',
            title: `${t("general.hotel")}`,
            dataIndex: 'hotel',
            ...getColumnSearchProps('search'),
            render: (text) => text
        },
        {
            key: 'process_date',
            title: `${t("general.process_date")}`,
            dataIndex: 'process_date',
            ...getColumnSearchProps('date_range'),
            render: (text) => text
        },
        {
            key: 'approver',
            title: `${t("general.approver")}`,
            dataIndex: 'approver',
            ...getColumnSearchProps('search'),
            render: (text) => text
        },
        {
            key: 'approval_date',
            title: `${t("general.approval_date")}`,
            dataIndex: 'approval_date',
            ...getColumnSearchProps('date_range'),
            render: (text) => text
        },
        {
            key: 'reservation_id',
            title: `${t("general.reservation_number")}`,
            dataIndex: 'reservation_id',
            ...getColumnSearchProps('search'),
            render: (text) => text
        },
        {
            key: 'bank',
            title: `${t("general.bank")}`,
            dataIndex: 'bank',
            ...getColumnSearchProps('search'),
            render: (text) => text
        },
        {
            key: 'process_type',
            title: `${t("general.process_type")}`,
            dataIndex: 'process_type',
            ...getColumnSearchProps('search'),
            render: (text) => text
        },
        {
            key: 'amount',
            title: `${t("general.process_amount")}`,
            dataIndex: 'amount',
            ...getColumnSearchProps('search'),
            render: (text) => text
        },
        {
            key: 'guest',
            title: `${t("general.guest")}`,
            dataIndex: 'guest',
            ...getColumnSearchProps('search'),
            render: (text) => text
        }
    ];
    const [bankTransactions, setBankTransactions] = useState({
        data:[],
        meta:{},
        isLoading:true,
    })

    const [exporting, setIsExporting] = useState({
        isExporting: false,
        filters: {},
    });

    const onExport = async () => {
        setIsExporting({
            ...exporting,
            isExporting: true
        });
        try {
            let response = await BankTransactionService.exportExcel(exporting.filters);
            const blob = new Blob([response.data], { type: response.headers['content-type'] });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'banka_hareketleri.xlsx';
            a.click();
            window.URL.revokeObjectURL(url);
            setIsExporting({
                ...exporting,
                isExporting: false
            });
        }catch (e){
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
            setIsExporting({
                ...exporting,
                isExporting: false
            });
        }
    }

    const getBankTransactions = async (page, per_page, filters) => {
        setBankTransactions({
            ...bankTransactions,
            isLoading: true
        })
        console.log(filters)
        try {
            let {data: response} = await BankTransactionService.get(page, per_page, filters)
            setBankTransactions({
                ...bankTransactions,
                data: response.data,
                meta: response.meta,
                isLoading: false
            })
        }catch (e){
            if(e.response){
                notification.error({
                    message: e.response.data.message
                });
            }
            setBankTransactions({
                ...bankTransactions,
                isLoading: false
            })
        }
    }

    useEffect(() => {
        getBankTransactions()
    },[])

    return (
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-20">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse} />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {t("general.bank_transactions")}
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background">
                    <Table
                        columns={columns}
                        title={() => (<Row>
                            <Col span={24}>
                                <Button type="primary" title="Excel Olarak Dışa Aktar" loading={exporting.isExporting} onClick={() => onExport()}>
                                    <FontAwesomeIcon icon={faFileExcel} style={{marginRight: "10px"}}/>
                                    Excel
                                </Button>
                            </Col>
                        </Row>)}
                        dataSource={bankTransactions.data}
                        rowKey={record => record.id}
                        loading={bankTransactions.isLoading}
                        bordered
                        pagination={{
                            total: bankTransactions.meta?.total,
                            defaultPageSize: bankTransactions.meta?.per_page
                        }}
                        onChange={handleTableChange}
                    />
                </div>
            </Content>
        </Wrapper>
    );
};

export default BankTransactions;