import React, {useEffect, useState} from 'react';
import {Button, Col, Form, Input, Modal, notification, Row, Select, Tabs, Upload} from "antd";
import {WebLanguageService} from "@services";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUpload} from "@fortawesome/free-solid-svg-icons";

const {TabPane} = Tabs;
const {Option} = Select;

const HotelSaleAgreementModal = (props) => {
    const languages = Form.useWatch('languages', props.form);
    const saleAgreement = Form.useWatch('sale_agreement', props.form);
    const [webLanguage, setWebLanguage] = useState([]);
    const getWebLanguage = async () => {
        try {
            let response = await WebLanguageService.get();
            setWebLanguage(response.data.data);

        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    };

    useEffect(() => {
        getWebLanguage()
    }, []);
    return (
        <Modal visible={props.isVisible} title={props.title} closable={false} onOk={() => props.form.submit()} onCancel={props.onCancel} okText="Kaydet" cancelText="İptal">
            <Form form={props.form} layout="vertical" onFinish={props.onFinish}>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="languages"
                            label="Diller"
                            rules={[{required: true, message: 'Lütfen dil seçiniz!'}]}
                        >
                            <Select
                                mode="multiple"
                                style={{ width: "100%" }}
                            >
                                {webLanguage.map((item) => <Option  value={item['iso_639-1']} key={`${item['iso_639-1']}`}>{item.name}</Option>)}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Tabs>
                    {languages?.map((item) =>
                        <TabPane tab={webLanguage.find(x => x['iso_639-1'] === item).name} key={item}>
                            <Form.Item
                                name={['sale_agreement', webLanguage.find(x => x['iso_639-1'] === item)['iso_639-1'], 'title']}
                                label="E-Posta Başlığı"
                                rules={[{required: true, message: 'Lütfen başlık giriniz!'}]}
                            >
                                <Input placeholder="Başlık"/>
                            </Form.Item>
                            <Form.Item
                                name={['sale_agreement', webLanguage.find(x => x['iso_639-1'] === item)['iso_639-1'], 'content']}
                                label="E-Posta İçerik"
                                rules={[{required: true, message: 'Lütfen içerik giriniz!'}]}
                            >
                                <Input placeholder="Başlık"/>
                            </Form.Item>
                            <Form.Item
                                hidden={true}
                                noStyle
                                name={['sale_agreement', webLanguage.find(x => x['iso_639-1'] === item)['iso_639-1'], 'file_path']}
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                name={['sale_agreement', webLanguage.find(x => x['iso_639-1'] === item)['iso_639-1'], 'file']}
                                label="Dosya"
                                rules={[
                                    { validator: (rule, value, data) => {
                                            if (!value && !saleAgreement[webLanguage.find(x => x['iso_639-1'] === item)['iso_639-1']]?.file_path) {
                                                return Promise.reject('Dosya seçimi zorunludur!');
                                            }
                                            return Promise.resolve()
                                        }}
                                ]}
                                getValueFromEvent={(e) => e.file}
                            >
                                <Upload
                                    beforeUpload={() => false}
                                    showUploadList={false}
                                    fileList={[]}
                                >
                                    <Button icon={<FontAwesomeIcon icon={faUpload} style={{ marginRight: "10px" }}/>}>
                                        Dosya Seç
                                        {(saleAgreement &&
                                                (saleAgreement?.[webLanguage.find(x => x?.['iso_639-1'] === item)?.['iso_639-1']]?.file) || saleAgreement?.[webLanguage.find(x => x?.['iso_639-1'] === item)?.['iso_639-1']]?.file_path)
                                            && " (Seçildi)"
                                        }
                                    </Button>
                                </Upload>
                            </Form.Item>
                        </TabPane>
                    )}
                </Tabs>
            </Form>
        </Modal>
    );
};

export default HotelSaleAgreementModal;