import React, {useEffect} from 'react';
import {Breadcrumb, Collapse, Layout, notification, Pagination, Table, Tabs} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHouse} from "@fortawesome/free-solid-svg-icons";
import Wrapper from "@/components/Wrapper";
import {useTranslation} from "react-i18next";
import {WidgetsService} from "@/services";
import moment from "moment";

const {Panel} = Collapse;
const {Content} = Layout;
const WidgetActivities = () => {
    const [loading, setLoading] = React.useState(false);
    const [widgets, setWidgets] = React.useState([]);
    const [activities, setActivities] = React.useState({
        data: [],
        meta: {}
    });
    const {t} = useTranslation();
    const getWidgets = async () => {
        try {
            let {data: response} = await WidgetsService.get()
            setWidgets(response.data)
            if (response.data.length > 0){
                await getActivities(response.data[0].id)
            }
        }catch (e) {
            if (e.response){
                notification.error({
                    message: "HATA",
                    description: e.response.data.message
                });
            }
        }
    }
    const getActivities = async (widgetId, page = 1) => {
        setLoading(true)
        try {
            let {data: response} = await WidgetsService.activities(widgetId, page)
            setActivities({
                data: response.data,
                meta: response.meta
            })
            setLoading(false)
        }catch (e) {
            if (e.response){
                notification.error({
                    message: "HATA",
                    description: e.response.data.message
                });
            }
            setLoading(false)
        }
    }
    useEffect(() => {
        getWidgets();
    }, []);
    return (
        <Wrapper title="Sanal Pos İşlemleri">
            <Content className="m-25">
                <Breadcrumb className="mb-20">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse} />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {t("general.widget_activities")}
                    </Breadcrumb.Item>
                </Breadcrumb>

                <div className="site-layout-background">
                    <Tabs onChange={(tab) => {
                        setActivities({
                            data: [],
                            meta: {}
                        })
                        getActivities(
                            widgets.find(widget => widget.subdomain_name === tab).id
                        )
                    }}>
                        {widgets.map((widget, index) => (
                            <Tabs.TabPane tab={widget?.widget_name} key={widget?.subdomain_name}>
                                {(activities.data.length === 0 && !loading) && (
                                    <p>
                                        İşlem bulunamadı.
                                    </p>
                                )}
                                {loading && (
                                    <p>
                                        Yükleniyor...
                                    </p>
                                )}
                                {activities.data.length > 0 && (
                                    <Collapse>
                                        {activities.data.map((activity, index) => (
                                            <Panel header={`${activity.ip} (${moment(activity.created_at).format('DD.MM.YYYY HH:mm:ss')})`} key={index}>
                                                {activity.properties.map((property, index) => (
                                                    <p key={index}>
                                                        <strong>{property.name}:</strong> {property.value}
                                                    </p>
                                                ))}
                                            </Panel>
                                        ))}
                                    </Collapse>
                                )}
                                <Pagination
                                    pageSize={activities.meta.per_page || 10}
                                    total={activities.meta.total || 0}
                                    current={activities.meta.current_page || 1}
                                    showSizeChanger={false}
                                    onChange={(page) => getActivities(widget.id, page)}
                                    style={{
                                        textAlign: "center",
                                        marginTop: "20px"
                                    }}
                                />
                            </Tabs.TabPane>
                        ))}
                    </Tabs>
                </div>
            </Content>
        </Wrapper>
    );
};

export default WidgetActivities;