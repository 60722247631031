import React from 'react';
import {Modal, Form, Row, Col, Select} from 'antd'

const {Option} = Select;

const ExternalHotelRoomAppendModal = (props) => {
    const onOK = () => {
        props.form.submit();
    }
    return (
        <Modal visible={props.isVisible} title={props.title} closable={false} onOk={onOK} onCancel={props.onCancel} okText="Ata" cancelText="İptal">
            <Form form={props.form} layout="vertical" onFinish={(values) => props.onFinish(values, props.data?.id)}>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Form.Item
                            name="room_definition_id"
                            label="Oda Adı"
                        >
                            <Select placeholder="Oda Adı">
                                {props.roomDefinitions?.map((roomDefinition) => (
                                    <Option key={roomDefinition.id} value={roomDefinition.id}>{roomDefinition.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
};

export default ExternalHotelRoomAppendModal;