import axios from '../axios';

const CurrenciesService = {
    async get() {
        return await axios.get(`/v1/currencies`);
    },
    async create(data) {
        return await axios.post(`/v1/currencies`, data);
    },
    async getByGroupId(id) {
        return await axios.get(`/v1/currencies/${id}`);
    }
}

export default CurrenciesService;