import React from 'react';
import { Modal, Form, Input, Row, Col } from 'antd'

const ExternalRoomDefinitionFormModal = (props) => {
    const onOK = () => {
        props.form.submit();
    }
    return (
        <Modal visible={props.isVisible} title={props.title} closable={false} onOk={onOK} onCancel={props.onCancel}>
            <Form form={props.form} layout="vertical" onFinish={(values) => props.onFinish(values, props.data?.id)}>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Form.Item
                            name="name"
                            label="Konsept Adı"
                            rules={[{
                                required: true,
                                message: 'Konsept adı boş bırakılamaz!'
                            }]}
                        >
                            <Input
                                placeholder="Konsept Adı"
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
};

export default ExternalRoomDefinitionFormModal;