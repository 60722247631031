import axios from '../../axios';

const TourService = {
    async get(page = 1, per_page = 10){
        return await axios.get(`/lara-beach/tours?page=${page}&per_page=${per_page}`);
    },
    async show(id){
        return await axios.get(`/lara-beach/tours/${id}`);
    },
    async delete(id){
        return await axios.delete(`/lara-beach/tours/${id}`);
    },
    async create(formData){
        return await axios.post('/lara-beach/tours', formData);
    },
    async update(id, formData){
        return await axios.post(`/lara-beach/tours/${id}`, formData);
    },
    async changePublishStatus(id){
        return await axios.post(`/lara-beach/tours/${id}/change-published`);
    },
};

export default TourService;