import axios from '../axios';

const AdditionalService = {
    async get(page = 1, per_page = 10) {
        return await axios.get(`/v1/additional-services?page=${page}&per_page=${per_page}`);
    },
    async create(formData) {
        return await axios.post(`/v1/additional-services`, formData);
    },
};

export default AdditionalService;