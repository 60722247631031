import axios from '../axios';

const RuspoApiService = {

    async ruspoGetSession(ruspoId) {
        return await axios.get(`/russianSearchSet?COUNTRY=108&CITY=1206&ModuleID=42705&LOGIN=bybaras%40gmail.com&PASSWORD=CE92C8768ABC813583811D983E132553&DATEFROM=2022-09-24&DATETO=2022-09-30&NIGHTSFROM=7&NIGHTSTO=7&ADULT=2&CHILD=2&CHILD1=2&CHILD2=2&HOTELS=${ruspoId}&LIGHT=1&async=1`);
    },
    async packageSearch(formData) {

        return await axios.get(`/russianSearchSet?COUNTRY=${formData.COUNTRY}&
        CITY=${formData.CITY}&STARS=${formData.STARS}&FOODS=${formData.FOODS}&MINPRICE=${formData.MINPRICE}&MAXPRICE=${formData.MAXPRICE}&
        DATEFROM=${formData.DATEFROM}&DATETO=${formData.DATETO}&NIGHTSFROM=${formData.NIGHTSFROM}&NIGHTSTO=${formData.NIGHTSTO}&
        ADULT=${formData.ADULT}&CHILD=${formData.CHILD}&CURRENCY=${formData.currency}&RESORTS=${formData.resorts}&HOTELS=${formData.hotels}&
        LIGHT=${formData.LIGHT}&async=${formData.async}&aquapark=${formData.aquapark}&nopromo=${formData.nopromo}&hotelAvailability=${formData.hotelAvailability}
        &ticketAvailability=${formData.ticketAvailability}&AGENCY=${formData.AGENCY}`);
    },

    async ruspoGetData(sessionId,type) {
        return await axios.get(`/russianSearchGet/${sessionId}/${type}`);
    },

};

export default RuspoApiService;