import React, {useEffect} from 'react';
import {
    Breadcrumb,
    Col,
    Layout,
    Row,
    Form,
    Select,
    Button,
    InputNumber,
    Table,
    notification,
    Input,
    Space,
    DatePicker
} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHouse, faMoneyBillWave, faSave, faSearch} from "@fortawesome/free-solid-svg-icons";
import Wrapper from "@components/Wrapper";
import {CurrentDeficitService, SafeBankCariTransactionService} from '@services';
import SafeBankSelect from "@components/utils/SafeBankSelect";
import TextArea from "antd/es/input/TextArea";
import moment from "moment";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

const {Content} = Layout;
const {Option} = Select;
const {RangePicker} = DatePicker;

const SafeBankTransactions = () => {
    const { t } = useTranslation()
    const [safeBankTransactionForm] = Form.useForm()
    const [cariSearchValue, setCariSearchValue] = React.useState('')
    const [selectedSafeBank, setSelectedSafeBank] = React.useState({})
    const [safeBankCariTransactions, setSafeBankCariTransactions] = React.useState({
        data: [],
        meta: {}
    })
    const [caris, setCaris] = React.useState([])
    const [loading, setLoading] = React.useState(false)
    const [isCariSearchLoading, setIsCariSearchLoading] = React.useState(false)
    const columns = [
        {
            title: `${t('general.hotel_name')}`,
            dataIndex: 'safe_bank',
            key: 'hotel',
            render: (text) => text?.hotel?.name
        },
        {
            title: `${t('general.case_and_bank')}`,
            dataIndex: 'safe_bank',
            key: 'safe_bank',
            render: (text,record) => <Link to={`/safe-bank-transactions/${text.id}?currency=${record?.currency?.id}`}>{text?.bank_name} - {text?.title}</Link>
        },
        {
            title: `${t('general.cari')}`,
            dataIndex: 'cari',
            key: 'cari',
            render: (text) => text?.cari?.name + ' | ' + text?.company_title + ' - ' + text?.bill_title
        },
        {
            title: `${t('general.currency')}`,
            dataIndex: 'currency',
            key: 'currency',
            render: (text) => `${text?.currency} (${text?.symbol})`
        },
        {
            title: `${t('general.income_2')} (${t('general.total')})`,
            dataIndex: 'total_income',
            key: 'total_income',
            render: (text,record) => text + ' ' + record?.currency?.symbol
        },
        {
            title: `${t('general.disbursement_2')}`,
            dataIndex: 'total_expense',
            key: 'total_expense',
            render: (text,record) => text + ' ' + record?.currency?.symbol
        },
        {
            title: `${t('general.balance')} (${t('general.total')})`,
            dataIndex: 'balance',
            key: 'balance',
            render: (text,record) => text + ' ' + record?.currency?.symbol
        }
    ]
    useEffect(() => {
        if(cariSearchValue === '') return
        setIsCariSearchLoading(true)
        const timer = setTimeout(() => {
            onSearchCari(cariSearchValue)
        }, 600);
        return () => clearTimeout(timer);
    },[cariSearchValue])

    const onSearchCari = async (value) => {
        try {
            let {data: response} = await CurrentDeficitService.search(value)
            setCaris(response.data)
            setIsCariSearchLoading(false)
        }catch (e){
            //
        }
    }

    const getSafeBankCariTransactions = async (page = 1, per_page = 10) => {
        setLoading(true)
        try {
            let {data: response} = await SafeBankCariTransactionService.get(page, per_page);
            setSafeBankCariTransactions({
                data: response.data,
                meta: response.meta
            })
            setLoading(false)
        }catch (e){
            if(e.response){
                notification.error({
                    message: e.response.data.message
                })
            }
        }
    }

    const onCreate = async (values) => {
        try {
            let {data: response} = await SafeBankCariTransactionService.create(values);
            if(response.success){
                await getSafeBankCariTransactions().then(() => setLoading(false))
                notification.success({
                    message: response.message
                })
            }

        }catch (e){
            if(e.response){
                notification.error({
                    message: e.response.data.message
                })
            }
        }
    }

    useEffect(() => {
        getSafeBankCariTransactions().then(() => setLoading(false))
    },[])

    return (
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-20">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse} />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {t('general.safe_bank_transactions')}
                    </Breadcrumb.Item>
                </Breadcrumb>

                <div className="site-layout-background">
                    <Row gutter={[16,16]}>
                        <Col span={24}>
                            <Form layout="vertical" form={safeBankTransactionForm} onFinish={onCreate}>
                                <Row gutter={[16,8]}>
                                    <Col span={5}>
                                        <Form.Item
                                            name="safe_bank"
                                            label={t('general.case_and_bank')}
                                            rules={[{required: true, message: 'Lütfen bir kasa banka seçiniz!'}]}
                                        >
                                            <SafeBankSelect
                                                placeholder="Kasa & Banka seçiniz"
                                                setSelectedSafeBank={setSelectedSafeBank}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            name="cari"
                                            label={t('general.cari')}
                                            rules={[{required: true, message: 'Lütfen bir cari seçiniz!'}]}
                                        >
                                            <Select
                                                showSearch
                                                defaultActiveFirstOption={false}
                                                loading={isCariSearchLoading}
                                                filterOption={false}
                                                value={cariSearchValue}
                                                onSearch={(value) => setCariSearchValue(value)}
                                                placeholder="Arama yapmak için yazmaya başlayınız"
                                                notFoundContent="Sonuç bulunamadı"
                                            >
                                                {caris.map(cari => (
                                                    <Option key={cari.id} value={cari.id}>
                                                        <span style={{
                                                            fontWeight: 'bold',
                                                            fontSize: '12px',
                                                        }}>
                                                            ({cari?.currency?.code}) &nbsp;
                                                        </span>
                                                        <span style={{
                                                            whiteSpace: 'normal',
                                                            fontSize: '12px',
                                                        }}>
                                                            {cari?.cari?.name} | {cari?.company_title} - {cari?.bill_title}
                                                        </span>
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={5}>
                                        <Form.Item
                                            name="type"
                                            label={t('general.process_type')}
                                            rules={[{required: true, message: 'Lütfen bir işlem tipi seçiniz!'}]}
                                        >
                                            <Select
                                                placeholder="İşlem tipi seçiniz"
                                            >
                                                <Option value="1">{t('general.income_2')}</Option>
                                                <Option value="2">{t('general.disbursement_2')}</Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item
                                            name="amount"
                                            label={t('general.amount')}
                                            rules={[{required: true, message: 'Lütfen bir tutar giriniz!'}]}
                                        >
                                            <InputNumber
                                                addonBefore={(
                                                    <Form.Item
                                                        name="currency"
                                                        noStyle
                                                        rules={[
                                                            {
                                                                validator: async (_, value) => {
                                                                    if(selectedSafeBank?.currencies?.find(currency => currency?.currency?.id === value)){
                                                                        return Promise.resolve()
                                                                    }
                                                                    return Promise.reject(new Error('Lütfen geçerli bir para birimi seçiniz!'))
                                                                }
                                                            },
                                                            {required: true, message: 'Lütfen bir para birimi seçiniz!'}
                                                        ]}
                                                    >
                                                        <Select
                                                            style={{width: 60}}
                                                            placeholder={<FontAwesomeIcon icon={faMoneyBillWave} />}
                                                        >
                                                            {selectedSafeBank?.currencies?.map((currency,index) => (
                                                                <Option key={index} value={currency?.currency?.id}>{currency?.currency?.symbol}</Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                )}
                                                min={0}
                                                style={{width: "100%"}}
                                                placeholder="Tutar giriniz"
                                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            name="description"
                                            label={t('general.description')}
                                            rules={[{required: true, message: 'Lütfen bir açıklama giriniz!'}]}
                                        >
                                            <TextArea
                                                placeholder="Açıklama giriniz"
                                                autoSize={{ minRows: 2, maxRows: 6 }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Button type="primary" htmlType="submit" style={{width:"100%"}} icon={<FontAwesomeIcon icon={faSave} style={{marginRight:"8px"}}/>}>
                                            {t('general.save')}
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>

                        <Col span={24}>
                            <Table
                                columns={columns}
                                rowKey={record => record.id}
                                dataSource={safeBankCariTransactions.data}
                                pagination={{
                                    pageSize: safeBankCariTransactions.meta.per_page,
                                    total: safeBankCariTransactions.meta.total,
                                    current: safeBankCariTransactions.meta.current_page,
                                    showSizeChanger: true,
                                    onChange: (page, pageSize) => getSafeBankCariTransactions(page, pageSize),
                                }}
                                loading={loading}
                            />
                        </Col>
                    </Row>

                </div>
            </Content>
        </Wrapper>
    );
};

export default SafeBankTransactions;