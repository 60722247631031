import React, {useEffect} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHouse} from "@fortawesome/free-solid-svg-icons";
import Wrapper from "@components/Wrapper";
import moment from "moment";
import {DiscountService} from '@services';
import {Breadcrumb, Button, Col, Form, Input, Layout, notification, Row, Select, Table, Tag} from "antd";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

const {Content} = Layout;
const {Option} = Select;

function Discounts(){
    const { t } = useTranslation()
    const [searchForm] = Form.useForm();
    const [discounts, setDiscounts] = React.useState({
        data: [],
        isLoading: true,
        meta: {},
    });
    const columns = [
        {
            title: "#",
            dataIndex: 'id',
            key: 'id',
            render: (text, record, index) => index + 1
        },
        {
            title: `${t('general.status')}`,
            dataIndex: 'status',
            key: 'status',
            render: (text, record, index) => {
                if (record.status === 1) {
                    return <Tag color="green">Aktif</Tag>
                } else {
                    return <Tag color="red">Pasif</Tag>
                }
            }
        },
        {
            title: `${t('general.hotel_name')}`,
            dataIndex: 'hotel',
            key: 'hotel',
            render: (text) => text
        },
        {
            title: `${t('general.discount_name')}`,
            dataIndex: 'name',
            key: 'name',
            render: (text, record, index) => {
                return (
                    <Link to={`/${record.type}`}>{text}</Link>
                )
            },
        },
        {
            title: `${t('general.contract')}`,
            dataIndex: 'contract',
            key: 'contract',
            render: (text, record, index) => {
                return (
                    <span>{record.contract.name}</span>
                )
            }
        },
        {
            title: `${t('general.availability_date')}`,
            dataIndex: 'date',
            key: 'date',
            render: (text, record, index) => {
                return (
                    <span>{moment(record.date.start).format('DD.MM.YYYY')} - {moment(record.date.end).format('DD.MM.YYYY')}</span>
                )
            }
        },
        {
            title: `${t('general.rate')}`,
            dataIndex: 'rate',
            key: 'rate',
            render: (text, record, index) => {
                if (record.rate.type === '1') {
                    return <span>%{record.rate.value}</span>
                } else {
                    return <span>{record.rate.value} {t('general.unit')}</span>
                }
            }
        }
    ];
    const getDiscounts = async (page = 1,searchParams) => {
        try {
            let { data: response } = await DiscountService.get(page, searchParams);
            return response;
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    }
    const onFilter = (values) => {
        setDiscounts(discounts => {
            return {
                ...discounts,
                isLoading: true,
            }
        });
        let searchParams = {
            hotel_name: values.hotel_name === '' ? null : values.hotel_name,
            status: values.status === '' ? null : values.status,
        }
        getDiscounts(1,searchParams).then((data) => {
            setDiscounts(discounts => {
                return {
                    ...discounts,
                    data: data.data,
                    isLoading: false,
                    meta: data.meta
                }
            });
        })
    }
    useEffect(() => {
        getDiscounts().then((data) => {
            setDiscounts(discounts => {
                return {
                    ...discounts,
                    data: data.data,
                    isLoading: false,
                    meta: data.meta
                }
            });
        })
    },[])
    return(
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-20">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse} />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        Tüm İndirimler
                    </Breadcrumb.Item>
                </Breadcrumb>

                <div className="site-layout-background">
                    <Form form={searchForm} layout="vertical" onFinish={onFilter}>
                        <Row gutter={[16,0]}>
                            <Col span={6}>
                                <Form.Item
                                    label={t('general.status')}
                                    name="status"
                                    initialValue=""
                                >
                                    <Select>
                                        <Option key={0} value="">{t('general.all')}</Option>
                                        <Option key={1} value={1}>{t('general.active')}</Option>
                                        <Option key={2} value={0}>{t('general.passive')}</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label={t('general.hotel')}
                                    name="hotel_name"
                                >
                                    <Input placeholder={t('general.hotel')}/>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Button type="primary" htmlType="submit" style={{width:"100%"}}>
                                    {t('general.filter')}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                    <Table
                        columns={columns}
                        dataSource={discounts.data}
                        rowKey={record => record.id}
                        loading={discounts.isLoading}
                        pagination={{
                            total: discounts.meta?.total,
                            defaultPageSize: discounts.meta?.per_page,
                            onChange: (page, pageSize) => {
                                setDiscounts(discounts => {
                                    return {
                                        ...discounts,
                                        isLoading: true
                                    }
                                });
                                getDiscounts(page).then((data) => {
                                    setDiscounts(discounts => {
                                        return {
                                            ...discounts,
                                            data: data.data,
                                            isLoading: false,
                                            meta: data.meta
                                        }
                                    });
                                });
                            }
                        }}
                    />
                </div>
            </Content>
        </Wrapper>
    )
}
export default Discounts