import axios from '../axios';

const WebLanguageService = {
  async get() {
    return await axios.get('/v1/web-language');
  },
  async getSelectedLanguage(contract_group_id) {
    return await axios.get(`/v1/web-language/${contract_group_id}`);
},
  async delete(webLanguageId) {
    return await axios.delete(`/v1/web-language/${webLanguageId}`);
  },
  async getHotelLanguage(hotelId) {
    return await axios.get(`/v1/hotelLanguage/${hotelId}`);
  },
  async update(webLanguageId) {
    return await axios.delete(`/v1/web-language/${webLanguageId}`);
  },
  async create(forms) {
    return await axios.post(`/v1/web-language`,forms);
  }


};

export default WebLanguageService;