import React from 'react';
import {Col, Form, Input, InputNumber, Modal, Row, Switch} from "antd";
import TextArea from "antd/es/input/TextArea";

const CmRoomCreateModalForm = (props) => {
    return (
        <Modal visible={props.isVisible} title={props.title} closable={false} onOk={() => props.form.submit()} onCancel={props.onCancel}>
            <Form form={props.form} layout="vertical" onFinish={(values) => props.onFinish(values, props.data?.id)} initialValues={{ is_active: true }} fields={props.data?.fields}>
                <Row gutter={[16,0]}>
                    <Col span={12}>
                        <Form.Item
                            name="name"
                            label="Oda Adı"
                            rules={[{ required: true, message: 'Lütfen oda adını giriniz.' }]}
                        >
                            <Input placeholder="Oda adını giriniz"/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="min_quota"
                            label="Min. Kontenjan"
                            rules={[{ required: true, message: 'Lütfen minimum konaklama sayısını giriniz.' }]}
                        >
                            <InputNumber style={{ width: "100%" }} placeholder="Minimum konaklama sayısını giriniz"/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="max_quota"
                            label="Maks. Kontenjan"
                            rules={[{ required: true, message: 'Lütfen maksimum konaklama sayısını giriniz.' }]}
                        >
                            <InputNumber style={{ width: "100%" }} placeholder="Maksimum konaklama sayısını giriniz"/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="m2"
                            label="M2"
                            rules={[{ required: true, message: 'Lütfen oda m2 ölçüsünü giriniz.' }]}
                        >
                            <InputNumber style={{ width: "100%" }} placeholder="Oda m2 ölçüsünü giriniz"/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="description"
                            label="Oda Açıklaması"
                            rules={[{ required: true, message: 'Lütfen oda açıklamasını giriniz.' }]}
                        >
                            <TextArea
                                autoSize={{ minRows: 3, maxRows: 5 }}
                                placeholder="Oda açıklamasını giriniz"/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="is_active"
                            label="Aktiflik Durumu"
                            valuePropName="checked"
                        >
                            <Switch
                                checkedChildren="Aktif"
                                unCheckedChildren="Pasif"
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default CmRoomCreateModalForm;