import React from 'react';
import {Modal, Form, Input, Row, Col} from 'antd';
import {useTranslation} from "react-i18next";

const PeriodModalForm = (props) => {
  const { t } = useTranslation();
  const onOK = () => {
    props.form.submit();
  }

  return (
    <Modal visible={props.isVisible} title={props.title} closable={false} onOk={onOK} onCancel={props.onCancel}>
      <Form form={props.form} layout="vertical" onFinish={(values) => props.onFinish(values, props.data?.id)} fields={props.data?.fields}>
        <Row>
          <Col span={24}>
            <Form.Item name="title" label={t("general.period_name")}>
              <Input placeholder={t("general.period_name")} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
};

export default PeriodModalForm;