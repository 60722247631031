import axios from '../axios';

const CMPeriodDatesService = {
    async create(periodId, formData) {
        return await axios.post(`/v1/channel-management/periods/${periodId}/dates/create`, formData);
    },
    async get(periodId, hotelId, page = 1) {
        return await axios.get(`/v1/channel-management/periods/${periodId}/dates/list?page=${page}`,{
            params: {
                hotel_id: hotelId
            }
        });
    }
};

export default CMPeriodDatesService;