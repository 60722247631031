import axios from "../axios";

const HotelRoomAttributeService = {
    async getById(hotelId, roomId, hotelRoomAttributeId) {
        return await axios.get(`/v1/hotels/${hotelId}/rooms/${roomId}/attributes/${hotelRoomAttributeId}`);
    },
    async create(hotelId, roomId, formData) {
        return await axios.post(`/v1/hotels/${hotelId}/rooms/${roomId}/attributes`,formData);
    },
    async update(hotelId, roomId, hotelRoomAttributeId, formData) {
        return await axios.post(`/v1/hotels/${hotelId}/rooms/${roomId}/attributes/${hotelRoomAttributeId}`,formData);
    },
    async delete(hotelId,roomId,hotelRoomAttributeId) {
        return await axios.delete(`/v1/hotels/${hotelId}/rooms/${roomId}/attributes/${hotelRoomAttributeId}`);
    },
};

export default HotelRoomAttributeService;