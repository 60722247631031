import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, Row, Col } from 'antd';
import {useTranslation} from "react-i18next";

const ConditionGroupModalForm = (props) => {
  const { t } = useTranslation();
  const [fields, setFields] = useState([
    {
      name: 'adult',
      value: 1
    }
  ]);
  useEffect(() => {
    if (props.data && props.data.fields) {
      setFields([
        ...fields,
        ...props.data.fields
      ]);
    }
    console.log(props.data)
  }, [props.data]);

  const onOK = () => {
    props.form.submit();
  }
  return (
    <Modal visible={props.isVisible} title={props.title} closable={false} onOk={onOK} onCancel={props.onCancel}>
      <Form form={props.form} layout="vertical" onFinish={(values) => props.onFinish(values, props.data?.id)} fields={fields}>
        <Row gutter={[16]}>
          <Col span={24}>
            <Form.Item name="title" label={t('general.condition_group_name')}>
              <Input placeholder={t('general.condition_group_name')} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal >
  )
};

export default ConditionGroupModalForm;