import axios from "../axios";

const HotelEventService = {
    async create(hotelId, formData) {
        return await axios.post(`/v1/hotels/${hotelId}/events`,formData);
    },
    async getAllByHotel(hotelId) {
        return await axios.get(`/v1/hotels/${hotelId}/events`);
    },
    async delete(hotelId,hotelEventId) {
        return await axios.delete(`/v1/hotels/${hotelId}/events/${hotelEventId}`);
    }
};

export default HotelEventService;