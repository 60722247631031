import axios from '../axios';

const ConditionService = {
  async findById(conditionGroupId, conditionId) {
    return await axios.get(`/v1/condition-groups/${conditionGroupId}/conditions/${conditionId}`);
  },

  async get(conditionGroupId) {
    return await axios.get(`/v1/condition-groups/${conditionGroupId}/conditions`);
  },

  async create(conditionGroupId, formData) {
    return await axios.post(`/v1/condition-groups/${conditionGroupId}/conditions`, formData);
  },

  async update(conditionGroupId, conditionId, formData) {
    return await axios.post(`/v1/condition-groups/${conditionGroupId}/conditions/${conditionId}`, formData);
  },

  async delete(conditionGroupId, conditionId) {
    return await axios.delete(`/v1/condition-groups/${conditionGroupId}/conditions/${conditionId}`);
  }
};

export default ConditionService;