import React, { useEffect } from 'react';
import { Button, Form, Input, notification } from 'antd';
import {useNavigate, useSearchParams} from 'react-router-dom';

import { Authentication } from '@services';
import './forgot-password.less';
import {useTranslation} from "react-i18next";

const ForgotPassword = () => {
  const { t } = useTranslation()
  const [loginForm] = Form.useForm();
  const navigate = useNavigate();

  useEffect(() => {
    if (Authentication.hasToken()) {
      navigate('/');
    }
  }, []);

  const onFinishForgotPassword = async (values) => {
    try {
      let {data: response} = await Authentication.forgotPassword(values);
      if (response.success){
        notification.success({
          message: response.message
        });
      }
    } catch (e) {
      if (e.response) {
        notification.error({
          message: e.response.data.message
        });
      }
    }
  };

  return (
    <div className="reset-password-wrapper">
      <div className="reset-password-form">
        <h1>{t('general.forgot_password')}</h1>
        <Form form={loginForm} layout="vertical" onFinish={onFinishForgotPassword}>
          <Form.Item
              label={t('general.email')}
              name="email"
              rules={[
                {required: true, message: 'E-posta alanı zorunludur.'},
                {type: 'email', message: 'E-Posta formatı yanlış.'},
              ]}
          >
            <Input
                placeholder="example@example.com"
            />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              {t('general.forgot_password')}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
};

export default ForgotPassword;