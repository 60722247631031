import React, {useEffect, useState} from 'react';
import {Modal, Form, Select, notification} from 'antd';
import {RuspoApiService, RoomDefinitionService} from '@services'
import HotelService from "../../services/hotels/Hotel.service";
const { Option } = Select;


const HotelRoomMatchModalForm = (props) => {
    const [ruspoData,setRuspoData]=useState([])
    const [ruspoDataAll,setRuspoDataAll]=useState([])
    const [isRuspoDataLoading,setIsRuspoDataLoading]=useState(true)
    const [isLoading,setIsLoading]=useState(true)
    const [roomDefinitions, setRoomDefinitions] = useState([]);

    const onOK = () => {
        props.form.submit();
    }
    const getRooms = async () => {
        try {
            let { data: { data: items } } = await HotelService.getRooms(props.hotelId);
            setRoomDefinitions(items);
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.message
                });
            }
            console.log(e);
        }
    };

    useEffect(()=>{
        getRooms()
    },[])
    return (
        <Modal visible={props.isVisible} title={props.title} closable={false} onOk={onOK} onCancel={props.onCancel}>
            <Form form={props.form} layout="vertical" onFinish={(values) => props.onFinish(values)} fields={props.data?.fields}>
                <Form.Item name="hotel_room_name" label="Oda Adı">
                    <Select>
                        {roomDefinitions.map((room, index) => <Option key={index} value={room.id+"-"+room.name}>{room.name}</Option>)}
                    </Select>
                </Form.Item>
                <Form.Item name="operator_room_name" label="Operatör Oda Adı">
                    <Select
                        mode="multiple"
                        allowClear
                        style={{
                            width: '100%',
                        }}
                        placeholder="Please select"
                    >
                        {
                            props.ruspoData.map((ruspo,index) => (
                                <Option key={index} value={ruspo.operator_id +"-"+ruspo.roomName}>{ruspo.operatorsName} - {ruspo.roomName}</Option>
                            ))
                        }
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    )
};

export default HotelRoomMatchModalForm;