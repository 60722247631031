import React, { useEffect, useState } from "react";
import { Layout, Breadcrumb, notification, Row, Col, Image,Skeleton } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse } from "@fortawesome/free-solid-svg-icons";
import Wrapper from "@components/Wrapper";
import { ReservationDetailService } from "@services";
import { useParams } from "react-router-dom";

const { Content } = Layout;

const ReservationForm = () => {
  const [reservation_form, setReservations] = useState([]);
  const params = useParams();
  const [isLoading, setIsLoading] = useState(true);

  /*useEffect(() => {
    getReservations();
  }, [params.reservationId]);

  const getReservationForm = async () => {
    try {
      let { data: response } = await ReservationDetailService.getReservationForm(
        params.reservationId
      );
      setReservations(response.data);
      setIsLoading(false);
      return response.data;
    } catch (e) {
      if (e.response) {
        notification.error({
          message: e.response.data.message,
        });
      }
    }
  };*/
  return (
    <Wrapper>
      <Content className="m-25">
        <Breadcrumb className="mb-20">
          <Breadcrumb.Item href="/">
            <FontAwesomeIcon icon={faHouse} />
          </Breadcrumb.Item>
          <Breadcrumb.Item href={`/reservation-card/${params.reservationId}`}>
            Rezervasyon Kartı
          </Breadcrumb.Item>
          <Breadcrumb.Item>Rezervasyon Form</Breadcrumb.Item>
        </Breadcrumb>
        <div className="site-layout-background">
        {/*{isLoading ? (
            <Skeleton />
          ) : (
            <iframe
                title="Rezervasyon Form"
                src={`${
                  process.env.NODE_ENV === "production"
                    ? process.env.REACT_APP_PROD_API_URL
                    : process.env.REACT_APP_DEV_API_URL
                }/storage/uploads/pdf/${reservation_form}`}
                style={{ width: "100%", height: "calc(100vh - 148px)" }}
            />
          )}*/}
        </div>
      </Content>
    </Wrapper>
  );
};

export default ReservationForm;
