import axios from '../axios';

const ReservationProviderService = {
    async get(page = 1, per_page = 10) {
        return await axios.get(`/v1/reservation-providers?page=${page}&per_page=${per_page}`);
    },
    async create(formData) {
        return await axios.post(`/v1/reservation-providers`, formData);
    },
    async delete(id) {
        return await axios.delete(`/v1/reservation-providers/${id}`);
    },
};

export default ReservationProviderService;