import React, { useState } from 'react';
import { Modal, Button } from 'antd';
import HotelSelect from '@components/utils/HotelSelect';
import { faCopy } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';




const GenerateCodeModalForm = (props) => {
    const [isCopied, setIsCopied] = useState(false);
    const onOK = () => {
        props.form.submit();

    }

    let widgetForm = `<form action="http://${props.subdomain}.paymyres.com/data" method="get"> 
    <input type="hidden" name="currency" value="2" >
    <label for="checkin">Giriş</label><br/>
    <input type="text" name="checkin"><br/> 
    <label for="checkout">Çıkış</label><br/> 
    <input type="text" name="checkout" ><br/> 
    <label for="adultNumber">Yetişkin Sayısı</label><br/> 
    <input type="text" name="adultNumber"><br/>
    <input type="hidden" name="childNumber"><br/>
    <input type="submit" value="gonder">
</form>`

    const copyToClipboard = async () => {
        await navigator.clipboard.writeText(widgetForm);
        setIsCopied(true);
    }
    return (

        <Modal visible={props.isVisible} title={props.title} closable={false} onCancel={props.onCancel} footer={[
            <Button key="back" type="primary" onClick={props.onCancel}>
                Close
            </Button>
        ]}>
            <pre>{widgetForm}</pre>
            <Button onClick={copyToClipboard}><FontAwesomeIcon icon={faCopy} /></Button> {isCopied && <span>Kopyalandı</span>}
        </Modal>
    )
};
export default GenerateCodeModalForm;