import React from 'react';
import {
    Alert, Button, Col,
    Collapse, Divider,
    Form, Input, Row,
    Tabs,
} from "antd";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";
import IconSelect2 from "@/components/utils/IconSelect2";

const { TabPane } = Tabs;
const { Panel } = Collapse;

const HotelCreateRoomsStep = ({ form }) => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = React.useState(1);
    const rooms = Form.useWatch('hotel_rooms', form) || [];
    const hotel_languages = Form.useWatch('hotel_languages', form) || [];
    return (
        <>
            <Collapse activeKey={activeTab} onChange={(e, b) => {
                setActiveTab(e.length > 1 ? e[1] : e[0])
            }}>
                {rooms.length > 0 && rooms.map((room, index) => (
                    <Panel header={room?.translations?.[hotel_languages[0]?.value]?.room_name || t("general.room") + " " + (index + 1)} key={index + 1}>
                        <Form.List
                            name={['hotel_rooms', index, 'room_attributes']}
                        >
                            {(fields, { add, remove }, { errors }) => (
                                <>
                                    {fields.map((field, index) => (
                                        <div style={{ position: "relative" }} key={field.key}>
                                            <Form.Item
                                                label={<h4 style={{ fontSize: "14px" }}>{index + 1}. {t("general.attribute")}</h4>}
                                                required={false}
                                            >
                                                <Tabs>
                                                    {hotel_languages.map((language) => (
                                                        <TabPane tab={language.label} key={language.value} forceRender>
                                                            <Row gutter={[16,0]}>
                                                                <Col span={12}>
                                                                    <Form.Item
                                                                        name={[field.name, 'icon']}
                                                                        label={t("general.icon")}
                                                                        rules={[{
                                                                            required: true,
                                                                            message: `${t("general.icon")} ${t("general.cannot_be_empty")}`
                                                                        }]}
                                                                    >
                                                                        <IconSelect2
                                                                            value={form.getFieldValue([field.name, 'icon'])}
                                                                            onChange={(value) => form.setFieldsValue({ [field.name]: { icon: value } })}
                                                                            placeholder={t("general.search_icon_placeholder_example")}
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col span={12}>
                                                                    <Form.Item
                                                                        name={[field.name, 'translations', language.value, 'title']}
                                                                        label={t("general.room_attribute_name")}
                                                                        rules={[{
                                                                            required: true,
                                                                            message: `${t("general.room_attribute_name")} ${t("general.cannot_be_empty")}`
                                                                        }]}
                                                                    >
                                                                        <Input placeholder={t("general.room_attribute_name_placeholder_example")} />
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                        </TabPane>
                                                    ))}
                                                </Tabs>
                                            </Form.Item>
                                            <Button type="dashed" size="small" style={{
                                                position: 'absolute',
                                                right: '0',
                                                top: '0',
                                            }} onClick={() => remove(field.name)}>
                                                <FontAwesomeIcon icon={faMinus}/>
                                            </Button>
                                        </div>
                                    ))}
                                    <Divider />
                                    <Form.Item>
                                        <Button
                                            type="dashed"
                                            onClick={() => add()}
                                            style={{
                                                width: '100%',
                                            }}
                                            icon={<FontAwesomeIcon icon={faPlus} style={{ marginRight: 8 }}/>}
                                        >
                                            {t("general.add_room_attribute")}
                                        </Button>
                                        <Form.ErrorList errors={errors} />
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </Panel>
                ))}
            </Collapse>
            {rooms.length === 0 && (
                <Alert
                    message={t("general.warning")}
                    description={t("general.define_at_least_one_room")}
                    type="warning"
                    showIcon
                />
            )}
        </>
    )
};

export default HotelCreateRoomsStep;