import axios from '../axios';

const ExternalContractService = {
    async get(hotelId, contractGroupId) {
        return await axios.get(`/v1/external/hotels/${hotelId}/contract-groups/${contractGroupId}/contracts`);
    },
    async getDetails(hotelId, contractGroupId, contractId) {
        return await axios.get(`/v1/external/hotels/${hotelId}/contract-groups/${contractGroupId}/contracts/${contractId}/details`);
    },
    async saveDetails(hotelId,contractGroupId,contractId,contractConceptId,formData) {
        return await axios.post(`/v1/external/hotels/${hotelId}/contract-groups/${contractGroupId}/contracts/${contractId}/details/${contractConceptId}`, formData);
    },
    async create(hotelId, contractGroupId, formData) {
        return await axios.post(`/v1/external/hotels/${hotelId}/contract-groups/${contractGroupId}/contracts`, formData);
    },
    async setStatus(hotelId, contractGroupId, contractId, status) {
        return await axios.post(`/v1/external/hotels/${hotelId}/contract-groups/${contractGroupId}/contracts/${contractId}/status`, {status});
    }
};

export default ExternalContractService;