import axios from '../axios';

const PeriodService = {
  async findById(id) {
    return await axios.get(`/v1/periods/${id}`);
  },

  async get(page = 1) {
    return await axios.get(`/v1/periods?page=${page}`);
  },

  async create(formData) {
    return await axios.post(`/v1/periods`, formData);
  },

  async update(id, formData) {
    return await axios.post(`/v1/periods/${id}`, formData);
  },

  async delete(id, formData) {
    return await axios.delete(`/v1/periods/${id}`, formData);
  }
};

export default PeriodService;