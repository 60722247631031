import React, {useState} from 'react';
import {
    Breadcrumb,
    Button,
    Col, DatePicker,
    Divider,
    Form,
    Input,
    InputNumber,
    Layout, notification, Radio,
    Row,
    Select,
    Steps,
    Tabs,
    Upload
} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHouse, faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";
import Wrapper from "@/components/Wrapper";
import {useTranslation} from "react-i18next";
import TextArea from "antd/es/input/TextArea";
import { LaraBeachTourService, LaraBeachFileService, LaraBeachSettingsService } from '@/services'
import {Link} from "react-router-dom";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const { Content } = Layout
const { Step } = Steps;
const { TabPane } = Tabs;
const { Option } = Select;

const TourFormPage = () => {
    const { t } = useTranslation()
    const [supportedLanguages, setSupportedLanguages] = useState([])
    const [current, setCurrent] = React.useState(0);
    const next = () => setCurrent(current + 1);
    const prev = () => setCurrent(current - 1);
    const [form] = Form.useForm();
    const steps = [
        {
            id: 0,
            title: 'Tur Bilgileri',
            content: <TourFormStep1 supportedLanguages={supportedLanguages} t={t}/>,
            code: "general"
        },
        {
            id: 1,
            title: 'Medyalar',
            content: <TourFormStep2 files={form.getFieldValue("files")} form={form} />,
            code: "files"
        },
        {
            id: 2,
            title: 'Detaylar',
            content: <TourFormStep3 supportedLanguages={supportedLanguages} t={t}/>,
            code: "details"
        }
    ];
    const getSupportedLanguages = async () => {
        try {
            let {data: response} = await LaraBeachSettingsService.show('supported_languages')
            setSupportedLanguages(response.data.value.split(',') || [])
        }catch (e){
            if (e.response){
                notification.error({
                    message: e.response.data.message
                })
            }
        }
    }
    const onHandleSubmit = () => {
        form.validateFields().then(values => {
           form.submit()
        }).catch(errors => {
            let fieldErrors = errors.errorFields
            fieldErrors = fieldErrors.filter((error) => error.errors.length > 0 || error.warnings.length > 0)
            if (fieldErrors.length > 0) {
                let step = steps.find((step) => step.code === fieldErrors[0].name[0]).id
                setCurrent(step)
            }
        })
    }
    const onCreate = async (values) => {
        try {
            let files = await uploadFiles(values.files)
            values = {
                ...values,
                files: files.map((file) => file.id)
            }
            let {data: response} = await LaraBeachTourService.create(values)
            if (response.success){
                notification.success({
                    message: response.message
                })
            }
        }catch (e){
            if (e.response){
                notification.error({
                    message: e.response.data.message
                })
            }
        }
    }
    const uploadFiles = async (files) => {
        try {
            let formData = new FormData()
            files.forEach((file, key) => {
                formData.append("files[]", file.originFileObj)
            })
            let {data: response} = await LaraBeachFileService.uploadFiles(formData)
            return response.data
        }catch (e) {
            throw e
        }
    }
    React.useEffect(() => {
        getSupportedLanguages()
    }, [])
    return (
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-20">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse} />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to="/lara-beach-excursions/tours">{t("general.tours")}</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {t("general.create_tour")}
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background">
                    <Steps current={current}>
                        {steps.map((item) => (
                            <Step key={item.id} title={item.title} onClick={() => setCurrent(item.id)} style={{ cursor: "pointer" }}/>
                        ))}
                    </Steps>
                    <Form form={form} layout="vertical" style={{ padding: "16px 0" }} onFinish={(values) => onCreate(values)} initialValues={{
                        general: {currency_id: "EUR", duration_type: "hours"}
                    }}>
                        {steps.map((item) => (
                            <div key={item.id} style={{ display: current === item.id ? 'block' : 'none' }}>
                                {item.content}
                            </div>
                        ))}
                    </Form>
                    <div
                        style={{
                            marginTop: 24,
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}
                    >
                        {current > 0 && (
                            <Button
                                style={{
                                    margin: '0 8px',
                                }}
                                onClick={() => prev()}
                            >
                                Geri
                            </Button>
                        )}
                        {current < steps.length - 1 && (
                            <Button type="primary" onClick={() => next()}>
                                İleri
                            </Button>
                        )}
                        {current === steps.length - 1 && (
                            <Button type="primary" onClick={() => onHandleSubmit()}>
                                Kaydet
                            </Button>
                        )}
                    </div>
                </div>
            </Content>
        </Wrapper>
    );
};

const TourFormStep1 = ({ supportedLanguages, t }) => {
    return (
        <>
            <Tabs>
                {supportedLanguages.map((language) => (
                    <TabPane tab={t(`general.${language}`)} key={language} forceRender>
                        <Row gutter={[16,2]}>
                            <Col span={12}>
                                <Form.Item
                                    label={`Tur Adı (${language})`}
                                    name={['general', 'translations', language, 'title']}
                                    rules={[{ required: true, message: 'Lütfen tur adını giriniz!' }]}
                                >
                                    <Input placeholder="Tur Adı" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label={`Lokasyon (${language})`}
                                    name={['general', 'translations', language, 'location']}
                                    rules={[{ required: true, message: 'Lütfen lokasyon giriniz!' }]}
                                >
                                    <Input placeholder="Lokasyon" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name={['general', 'translations', language, 'description']}
                                    label={`Açıklama (${language})`}
                                    valuePropName='data'
                                    getValueFromEvent={(event, editor) => {
                                        return editor.getData();
                                    }}
                                    rules={[{ required: true, message: 'Lütfen açıklama giriniz!' }]}
                                >
                                    <CKEditor
                                        editor={ ClassicEditor }
                                        config={{
                                            toolbar: [ 'heading', '|', 'sourceEditing', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'insertTable', 'undo', 'redo'],
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </TabPane>
                ))}
            </Tabs>
            <Divider />
            <Row gutter={[16,2]}>
                <Col span={12}>
                    <Form.Item
                        label="Fiyat"
                        name={['general', 'price']}
                        rules={[{ required: true, message: 'Lütfen fiyatı giriniz!' }]}
                    >
                        <InputNumber placeholder="Fiyat" style={{ width: "100%" }} addonAfter={
                            <Form.Item noStyle name={['general','currency_id']}>
                                <Select placeholder={t("general.select")}>
                                    <Option value="TRY">TRY</Option>
                                    <Option value="USD">USD</Option>
                                    <Option value="EUR">EUR</Option>
                                    <Option value="GBP">GBP</Option>
                                </Select>
                            </Form.Item>
                        } />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label="Tur Süresi"
                        name={['general', 'duration']}
                        rules={[{ required: true, message: 'Lütfen süreyi giriniz!' }]}
                    >
                        <InputNumber placeholder="Tur Süresi" style={{ width: "100%" }} addonBefore={
                            <Form.Item noStyle name={['general','duration_type']}>
                                <Select placeholder={t("general.select")}>
                                    <Option value="month">Ay</Option>
                                    <Option value="day">Gün</Option>
                                    <Option value="hours">Saat</Option>
                                </Select>
                            </Form.Item>
                        }/>
                    </Form.Item>
                </Col>
            </Row>
            <Divider />
            <Form.List
                name={['child_prices']}
            >
                {(fields, { add, remove }, { errors }) => (
                    <>
                        {fields.map((field, index) => (
                            <div style={{ position: "relative" }} key={field.key}>
                                <Form.Item
                                    label={`Çocuk Fiyatlandırması ${index + 1}`}
                                    required={false}
                                >
                                    <Row gutter={[16,0]}>
                                        <Col span={6}>
                                            <Form.Item
                                                name={[field.name, 'max_age']}
                                                label="Maks. Yaş (Girilen yaş ve altı)"
                                                rules={[{
                                                    required: true,
                                                    message: "Lütfen tavan yaşı giriniz!"
                                                }]}
                                            >
                                                <InputNumber placeholder="Maks. Yaş" style={{ width: "100%" }}/>
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item
                                                name={[field.name, 'price']}
                                                label="Fiyat"
                                                rules={[{
                                                    required: true,
                                                    message: "Lütfen fiyat giriniz!"
                                                }]}
                                            >
                                                <InputNumber placeholder="Fiyat" style={{ width: "100%" }}/>
                                            </Form.Item>
                                        </Col>

                                    </Row>
                                </Form.Item>
                                <Button type="dashed" size="small" style={{
                                    position: 'absolute',
                                    right: '0',
                                    top: '0',
                                }} onClick={() => remove(field.name)}>
                                    <FontAwesomeIcon icon={faMinus}/>
                                </Button>
                            </div>
                        ))}
                        <Form.Item>
                            <Button
                                type="dashed"
                                onClick={() => add()}
                                style={{
                                    width: '100%',
                                }}
                                icon={<FontAwesomeIcon icon={faPlus} style={{ marginRight: 8 }}/>}
                            >
                                Çocuk Fiyatlandırması Ekle
                            </Button>
                            <Form.ErrorList errors={errors} />
                        </Form.Item>
                    </>
                )}
            </Form.List>
        </>
    )
}

const TourFormStep2 = ({ form }) => {
    const files = Form.useWatch('files', form) || [];
    const getFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };
    return (
        <>
            <Form.Item style={{ display: 'none' }} name="files"><Input/></Form.Item>
            <Form.Item name='files' getValueFromEvent={getFile} rules={[{
                required: true,
                message: 'Lütfen en az bir dosya yükleyiniz!'
            }]}>
                <Upload
                    beforeUpload={() => {
                        return false;
                    }}
                    listType="picture-card"
                    fileList={files}
                    showUploadList={{
                        showPreviewIcon:false
                    }}
                >
                    <button
                        style={{
                            border: 0,
                            background: 'none',
                        }}
                        type="button"
                    >
                        <FontAwesomeIcon icon={faPlus} style={{ fontSize: '24px' }} />
                        <div
                            style={{
                                marginTop: 8,
                            }}
                        >
                            Yükle
                        </div>
                    </button>
                </Upload>
            </Form.Item>
        </>
    )
}

const TourFormStep3 = ({ supportedLanguages, t }) => {
    return (
        <Form.List
            name={['details']}
            rules={[{
                validator: async (_, details) => {
                    if (!details || details.length < 1) {
                        return Promise.reject(new Error('En az bir genel bilgi girmelisiniz.!'));
                    }
                }
            }]}
        >
            {(fields, { add, remove }, { errors }) => (
                <>
                    {fields.map((field, index) => (
                        <div style={{ position: "relative" }} key={field.key}>
                            <Form.Item
                                label={<h4 style={{ fontSize: "24px" }}>{index + 1}. Genel Bilgi</h4>}
                                required={false}
                            >
                                <Tabs>
                                    {supportedLanguages.map((language) => (
                                        <TabPane tab={t(`general.${language}`)} key={language} forceRender>
                                            <Row gutter={[16,0]} key={language}>
                                                <Col span={24}>
                                                    <Form.Item
                                                        name={[field.name, language, 'title']}
                                                        label="Başlık"
                                                        rules={[{
                                                            required: true,
                                                            message: "Lütfen genel bilgi başlığını giriniz!"
                                                        }]}
                                                    >
                                                        <Input placeholder="Başlık" />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={24}>
                                                    <Form.Item
                                                        name={[field.name, language, 'content']}
                                                        label="İçerik"
                                                        valuePropName='data'
                                                        getValueFromEvent={(event, editor) => {
                                                            return editor.getData();
                                                        }}
                                                        rules={[{
                                                            required: true,
                                                            message: "Lütfen genel bilgi içeriğini giriniz!"
                                                        }]}
                                                    >
                                                        <CKEditor
                                                            editor={ ClassicEditor }
                                                            config={{
                                                                toolbar: [ 'heading', '|', 'sourceEditing', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'insertTable', 'undo', 'redo'],
                                                            }}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </TabPane>
                                    ))}
                                </Tabs>
                            </Form.Item>
                            <Button type="dashed" size="small" style={{
                                position: 'absolute',
                                right: '0',
                                top: '0',
                            }} onClick={() => remove(field.name)}>
                                <FontAwesomeIcon icon={faMinus}/>
                            </Button>
                        </div>
                    ))}
                    <Divider />
                    <Form.Item>
                        <Button
                            type="dashed"
                            onClick={() => add()}
                            style={{
                                width: '100%',
                            }}
                            icon={<FontAwesomeIcon icon={faPlus} style={{ marginRight: 8 }}/>}
                        >
                            Genel Bilgi Ekle
                        </Button>
                        <Form.ErrorList errors={errors} />
                    </Form.Item>
                </>
            )}
        </Form.List>
    )
}

export default TourFormPage;