import React, {useEffect} from "react";
import {Button, Col, DatePicker, Form, Input, InputNumber, Modal, Row, Select} from "antd";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";

const {Option} = Select;
const {RangePicker} = DatePicker;

function ContractCommissionModalForm(props){
    const { t } = useTranslation()
    const onSelectAll = (option) => {
        const allValues = props?.contracts?.data.map((contract) => (
            contract.id
        ))
        if(option === "all"){
            return props.form.setFieldsValue({
                contracts:allValues
            })
        }
        return false;
    }
    return(
        <Modal visible={props.isVisible} title={props.title} closable={false} onOk={() => props.form.submit()} onCancel={props.onCancel}>
            <Form form={props.form} layout="vertical" onFinish={(values) => props.onFinish(values, props.contracts?.data?.[0].contract_group_id)} fields={props.data?.fields}>
                <Row gutter={[16,16]}>
                    <Col span={24}>
                        <Form.Item name="contracts" label={t("general.contracts")}>
                            <Select placeholder={t("general.contracts")} mode="multiple" showSearch onSelect={(option) => onSelectAll(option)}>
                                <Option key="all" value="all">
                                    {t("general.select_all")}
                                </Option>
                                {props?.contracts?.data?.map((contract,i)=>(
                                    <Option key={i} value={contract.id}>{contract.title}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.List
                            name={['commissions']}
                        >
                            {(fields, { add, remove }, { errors }) => (
                                <>
                                    {fields.map((field, index) => (
                                        <Form.Item
                                            label={index === 0 ? `${t("general.commissions")}` : ''}
                                            required={false}
                                            key={field.key}
                                        >
                                            <Row gutter={[16,16]}>
                                                <Col span={5}>
                                                    <Form.Item
                                                        name={[field.name, 'type']}
                                                        validateTrigger={['onChange', 'onBlur']}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Lütfen hizmet seçimi yapınız!",
                                                            },
                                                        ]}
                                                        noStyle
                                                    >
                                                        <Select placeholder={t("general.choose")}>
                                                            <Option value="1">{t("general.percent")}</Option>
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={5}>
                                                    <Form.Item
                                                        name={[field.name, 'commission']}
                                                        validateTrigger={['onChange', 'onBlur']}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Lütfen hizmet fiyatını giriniz!",
                                                            },
                                                        ]}
                                                        noStyle
                                                    >
                                                        <InputNumber
                                                            placeholder={t("general.commission")}
                                                            style={{width:"100%"}}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item
                                                        name={[field.name, 'date_range']}
                                                        validateTrigger={['onChange', 'onBlur']}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Lütfen tarih aralığı seçiniz!",
                                                            },
                                                        ]}
                                                        noStyle
                                                    >
                                                        <RangePicker
                                                            placeholder={[t("general.start_date"),t("general.end_date")]}
                                                            format="DD.MM.YYYY"
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={2}>
                                                    <Button
                                                        icon={<FontAwesomeIcon icon={faMinus}/>}
                                                        type="dashed"
                                                        onClick={() => remove(field.name)}
                                                    />
                                                </Col>
                                            </Row>
                                        </Form.Item>
                                    ))}
                                    <Form.Item>
                                        <Button
                                            type="dashed"
                                            onClick={() => add()}
                                            style={{
                                                width: '100%',
                                            }}
                                            icon={<FontAwesomeIcon icon={faPlus}/>}
                                        >
                                            {t("general.add_commission")}
                                        </Button>
                                        <Form.ErrorList errors={errors} />
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}
export default ContractCommissionModalForm