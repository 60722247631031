import React, { useEffect, useState } from 'react';
import {
  Layout,
  Breadcrumb,
  Button,
  Table,
  notification,
  Form,
  Space,
  Popconfirm,
  Row,
  Col,
  Input,
  InputNumber
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse, faEdit, faTrash, faPlus, faSave } from '@fortawesome/free-solid-svg-icons';
import Wrapper from '@components/Wrapper';
import PeriodModalForm from '@components/periods/PeriodModalForm';
import { PeriodService } from '@services';
import { Link, useNavigate } from 'react-router-dom';
import {useTranslation} from "react-i18next";

const { Content } = Layout;

const Period = () => {
  const { t } = useTranslation()
  const navigate = useNavigate();

  const [isLoadingTable, setIsLoadingTable] = useState(true);
  const [periods, setPeriods] = useState({});

  const [createForm] = Form.useForm();
  const [createModalVisible, setCreateModalVisible] = useState(false);

  const [selectedPeriod, setSelectedPeriod] = useState({});

  const [editForm] = Form.useForm();
  const [editModalVisible, setEditModalVisible] = useState(false);

  const columns = [
    {
      key: 'index',
      title: '#',
      render: (text, record, index) => index + 1
    },
    {
      key: 'title',
      title: `${t('general.period')}`,
      dataIndex: 'title',
      render: (text, record) => (
        <Link to={`/periods/${record.id}/dates`}>{record.title}</Link>
      )
    },
    {
      key: 'actions',
      title: `${t('general.actions')}`,
      render: (text, record) => (
        <Space align="middle">
          <Button type="primary" onClick={() => onShow(record.id)}>
            <FontAwesomeIcon icon={faEdit} />
          </Button>

          <Popconfirm title={t("general.are_you_sure_delete")} onConfirm={() => onDelete(record.id)} okText="Sil" cancelText="İptal">
            <Button type="danger">
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </Popconfirm>
        </Space>
      )
    }
  ];

  useEffect(() => {
    getPeriods();
  }, []);

  const getPeriods = async (page = 1) => {
    try {
      let { data: response } = await PeriodService.get(page);
      setPeriods(response);
      setIsLoadingTable(false);
    } catch (e) {
      if (e.response) {
        notification.error({
          message: e.response.data.message
        });
      }
    }
  }

  const onCreate = async (values) => {
    try {
      let { data: response } = await PeriodService.create(values);

      if (response.success) {
        notification.success({
          message: response.message
        });
        setIsLoadingTable(true);
        getPeriods();
        setCreateModalVisible(false);
        createForm.resetFields();
        navigate(`/periods/${response.data.id}/dates`,false);
      }
    } catch (e) {
      if (e.response) {
        notification.error({
          message: e.response.data.message
        });
      }

      console.log(e);
    }
  }

  const onShow = async (id) => {
    try {
      let { data: response } = await PeriodService.findById(id);

      let preparedInputs = [
        {
          name: 'title',
          value: response.data.title
        }
      ];

      setSelectedPeriod({
        ...response.data,
        fields: preparedInputs
      });
      setEditModalVisible(true);
    } catch (e) {
      if (e.response) {
        notification.error({
          message: e.response.data.message
        });
      }

      console.log(e);
    }
  };

  const onUpdate = async (values, id) => {
    try {
      let { data: response } = await PeriodService.update(id, values);

      if (response.success) {
        setIsLoadingTable(true);
        getPeriods();
        setEditModalVisible(false);

        notification.success({
          message: response.message
        });
      }
    } catch (e) {
      if (e.response) {
        notification.error({
          message: e.response.data.message
        });
      }

      console.log(e);
    }
  };

  const onDelete = async (id) => {
    try {
      let { data: response } = await PeriodService.delete(id);

      if (response.success) {
        setIsLoadingTable(true);
        getPeriods();

        notification.success({
          message: response.message
        });
      }
    } catch (e) {
      if (e.response) {
        notification.error({
          message: e.response.data.message
        });
      }

      console.log(e);
    }
  };

  return (
    <Wrapper>
      <Content className="m-25">
        <Breadcrumb className="mb-20">
          <Breadcrumb.Item href="/">
            <FontAwesomeIcon icon={faHouse} />
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            {t("general.periods")}
          </Breadcrumb.Item>
        </Breadcrumb>

        <div className="site-layout-background">
          <Button type="primary" className="mb-10" onClick={() => setCreateModalVisible(true)}>
            <FontAwesomeIcon icon={faPlus} className="mr-10" />
            {t("general.create_new_period")}
          </Button>

          <Table
            columns={columns}
            dataSource={periods.data}
            rowKey="id"
            loading={isLoadingTable}
            bordered
            pagination={{
              total: periods.meta?.total,
              defaultPageSize: periods.meta?.per_page,
              onChange: (page, pageSize) => {
                setIsLoadingTable(true);
                getPeriods(page);
              }
            }}
          />
        </div>
      </Content>

      <PeriodModalForm
        form={createForm}
        isVisible={createModalVisible}
        title={t("general.create_new_period")}
        onFinish={onCreate}
        onCancel={() => {
          setCreateModalVisible(false);
          createForm.resetFields();
        }}
      />

      <PeriodModalForm
        form={editForm}
        isVisible={editModalVisible}
        title={t("general.edit_period")}
        onFinish={onUpdate}
        onCancel={() => {
          setEditModalVisible(false);
          editForm.resetFields();
        }}
        data={selectedPeriod}
      />
    </Wrapper>
  );
};

export default Period;