import React, {useState} from 'react';
import {Button, Col, Form, Input, Modal, Row, Select, Spin, Upload} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";

const { Option } = Select;

const ReservationVoucherModal = (props) => {
    const { t } = useTranslation();
    return (
        <Modal visible={props.isVisible} title={props.title} closable={false} onOk={() => props.form.submit()} onCancel={props.onCancel}>
            {props.loading && <div style={{
                position: "absolute",
                inset: 0,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "rgba(0,0,0,0.2)",
                zIndex: 9999
            }}>
                <Spin />
            </div>}
            <Form form={props.form} layout="vertical" onFinish={props.onFinish}>
                <Row gutter={[16,16]}>
                    <Col span={24}>
                        <Form.Item
                            name="language"
                            label="Dil"
                            rules={[{ required: true, message: 'Dil seçimi yapılmalıdır.' }]}
                        >
                            <Select
                                placeholder="Dil seçiniz"
                            >
                                {props?.translations?.translations?.map((item, index) => (
                                    <Option key={index} value={item.language}>{t(`general.${item.language}`)}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="email"
                            label="Email"
                            rules={[{ required: true, message: 'Email is required' }]}
                            initialValue={props.email || ""}
                        >
                            <Input placeholder={`Email ${!props?.email && `(Tanımlı Değil)`}`} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default ReservationVoucherModal;