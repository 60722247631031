import React, { useState, useEffect } from 'react';
import { Layout, Breadcrumb, Table, Space, Button, Form, Popconfirm, notification } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse, faEdit, faTrash, faPlus } from '@fortawesome/free-solid-svg-icons';
import { RoomDefinitionService, WebLanguageService} from '@services';
import Wrapper from '@components/Wrapper';
import RoomDefinitionModalForm from '@components/room-definitions/RoomDefinitionModalForm';
import { useSelector } from 'react-redux';
import {useParams} from "react-router-dom";

const { Content } = Layout;

const RoomDefinitionList = () => {
  const params = useParams()
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [roomDefinitions, setRoomDefinitions] = useState([]);
  const [webLanguages, setWebLanguages] = useState([]);
  const [isLoadingTable, setIsLoadingTable] = useState(true);

  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [roomDefinition, setRoomDefinition] = useState({});

  const [newForm] = Form.useForm();
  const [editForm] = Form.useForm();

  const columns = [
    {
      title: '#',
      key: 'id',
      render: (text, record, index) => index + 1
    },
    {
      title: 'Oda Adı',
      dataIndex: ['name'],
      key: 'names'
    },
    {
      title: 'İşlemler',
      key: 'actions',
      render: (text, record) => (
        <Space align="middle">
          <Button type="primary" onClick={() => editRoomDefinition(record.id)}>
            <FontAwesomeIcon icon={faEdit} />
          </Button>

          <Popconfirm title="Silmek istediğine emin misin?" onConfirm={() => onDelete(record)} okText="Sil" cancelText="İptal">
            <Button type="danger">
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </Popconfirm>
        </Space>
      )
    }
  ];

  useEffect(() => {
    getRoomDefinitions();
    getWebLanguages();
  }, []);

  const getWebLanguages = async () => {
    try {
      let { data: { data: response } } = await WebLanguageService.getHotelLanguage(params.hotelId);
      setWebLanguages(response);
    } catch (e) {
      console.log(e);
    }
  };

  const getRoomDefinitions = async () => {
    try {
      let { data: { data: items } } = await RoomDefinitionService.get(params.hotelId);
      setIsLoadingTable(false);
      setRoomDefinitions(items);
    } catch (e) {
      if (e.response) {
        notification.error({
          message: e.response.message
        });
      }

      console.log(e);
    }
  };

  const onNewSave = async (values) => {
    setIsLoadingTable(true);
    try {
      let response = await RoomDefinitionService.create(params.hotelId, values);

      getRoomDefinitions();
      setIsAddModalVisible(false);
      newForm.resetFields();
      notification.success({
        message: response.data.message
      });
    } catch (e) {
      if (e.response) {
        notification.error({
          message: e.response.message
        });
      }

      console.log(e);
    }
  }

  const onDelete = async (record) => {
    try {
      let { data: response } = await RoomDefinitionService.delete(params.hotelId, record.id);

      if (response.success) {
        notification.success({
          message: response.message
        });
        setIsLoadingTable(true);
        getRoomDefinitions();
      }
    } catch (e) {
      if (e.response) {
        notification.error({
          message: e.response.message
        });
      }

      console.log(e);
    }
  }

  const editRoomDefinition = async (roomDefinitionId) => {
    try {
      let { data: { data: roomDefinition } } = await RoomDefinitionService.findById(params.hotelId, roomDefinitionId);

      let preparedInputs = webLanguages.map((language) => {
        return {
          name: ['room_definitions', language.web_language["iso_639-1"], 'name'],
          value: roomDefinition.names.filter((item) => item.language === language.web_language["iso_639-1"])[0]?.name || ''
        };
      });

      setRoomDefinition({
        ...roomDefinition,
        fields: preparedInputs
      });
      setIsEditModalVisible(true);
    } catch (e) {
      if (e.response) {
        notification.error({
          message: e.response.message
        });
      }

      console.log(e);
    }
  }

  const onEditSave = async (values, roomDefinitionId) => {

    try {
      let response = await RoomDefinitionService.update(params.hotelId, roomDefinitionId, values);

      getRoomDefinitions();
      setIsEditModalVisible(false);
      editForm.resetFields();
      notification.success({
        message: response.data.message
      });
    } catch (e) {
      if (e.response) {
        notification.error({
          message: e.response.message
        });
      }
      console.log(e);
    }
  };

  return (
    <Wrapper>
      <Content className="m-25">
        <Breadcrumb className="mb-20">
          <Breadcrumb.Item href="/">
            <FontAwesomeIcon icon={faHouse} />
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            Oda Tanımlama
          </Breadcrumb.Item>
        </Breadcrumb>

        <div className="site-layout-background">
          <Button type="primary" className="mb-10" onClick={() => setIsAddModalVisible(true)}>
            <FontAwesomeIcon icon={faPlus} className="mr-10" />
            Yeni Oda Tanımla
          </Button>

          <Table columns={columns} dataSource={roomDefinitions} rowKey="id" loading={isLoadingTable} bordered></Table>
        </div>
      </Content>

      <RoomDefinitionModalForm
        title="Yeni Oda Tanımla"
        isVisible={isAddModalVisible}
        form={newForm}
        onFinish={onNewSave}
        onCancel={() => setIsAddModalVisible(false)}
        languages={webLanguages}
      />

      <RoomDefinitionModalForm
        title="Oda Tanımı Düzenle"
        isVisible={isEditModalVisible}
        form={editForm}
        onFinish={onEditSave}
        onCancel={() => setIsEditModalVisible(false)}
        data={roomDefinition}
        languages={webLanguages}
      />
    </Wrapper>
  );
};

export default RoomDefinitionList;