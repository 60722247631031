import React from "react";
import { Col, Image, Row, Select } from "antd";

const { Option } = Select

function IconSelect(props) {
    const [selectedIcon, setSelectedIcon] = React.useState()
    return (
        <Row>
            {selectedIcon &&
                <Col span={3} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox={props.data === "source" ? props.icons?.find(icon => icon.source === selectedIcon).source.split('&&')[1] : props.icons?.find(icon => icon.id === selectedIcon).source.split('&&')[1]} height="20px" width="20px" xmlns="http://www.w3.org/2000/svg">
                        {props.data === "source" ?
                            <path d={props.icons?.find(icon => icon.source === selectedIcon).source.split('&&')[0]}></path> :
                            <path d={props.icons?.find(icon => icon.id === selectedIcon).source.split('&&')[0]}></path>
                        }
                    </svg>
                </Col>
            }
            <Col span={selectedIcon ? 21 : 24}>
                <Select
                    showSearch
                    filterOption={(input, option) =>
                        (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    loading={props?.loading}
                    disabled={props?.loading}
                    onSelect={(e) => setSelectedIcon(e)}
                    {...props}
                    placeholder="İkon seçimi yapınız!"
                >
                    {props.icons?.map((icon, i) => {
                        if (props.data === "source") {
                            return (
                                <Option key={i} value={icon.source}>
                                    {icon.name}
                                </Option>
                            )
                        }
                        return (
                            <Option key={i} value={icon.id}>
                                {icon.name}
                            </Option>
                        )
                    })}
                </Select>
            </Col>
        </Row>
    );
}
export default IconSelect