import React from 'react';
import {Col, Form, Modal, Row, DatePicker, InputNumber, Select, Switch} from "antd";
import moment from "moment/moment";

const {RangePicker} = DatePicker;
const {Option} = Select;

const ContractDateDiscountModalForm = (props) => {
    return (
        <Modal visible={props.isVisible} title={props.title} closable={false} onOk={() => props.form.submit()} onCancel={props.onCancel}>
            <Form form={props.form} layout="vertical" onFinish={(values) => props.onFinish(values, props.data?.id)}>
                <Row gutter={[16,16]}>
                    <Col span={12}>
                        <Form.Item
                            name="date_range"
                            label="Başlangıç & Bitiş Tarihi"
                            rules={[
                                {
                                    required:true,
                                    message:"Başlangıç ve bitiş tarihi seçilmelidir!"
                                }
                            ]}
                        >
                            <RangePicker
                                format="DD.MM.YYYY"
                                placeholder={["Başlangıç Tarihi", "Bitiş Tarihi"]}
                                disabledDate={(currentDate) => {
                                    return currentDate.isBefore(moment().subtract(1,'days'))
                                }}
                                style={{width:"100%"}}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="discount"
                            label="İndirim"
                            rules={[
                                {
                                    required:true,
                                    message:"Maks. gece sayısı seçilmelidir!"
                                }
                            ]}
                        >
                            <InputNumber addonBefore={
                                <Form.Item
                                    name="discount_value_type"
                                    noStyle={true}
                                    rules={[
                                        {
                                            required:true,
                                            message:"İndirim tipi seçilmelidir!"
                                        }
                                    ]}
                                    initialValue={1}
                                >
                                    <Select>
                                        <Option value={1}>Yüzdelik</Option>
                                        <Option value={2}>Birim</Option>
                                    </Select>
                                </Form.Item>
                            } min={1} style={{width:"100%"}}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="min_night"
                            label="Minimum Gece Sayısı"
                            rules={[
                                {
                                    required:true,
                                    message:"Min. gece sayısı seçilmelidir!"
                                }
                            ]}
                        >
                            <InputNumber style={{width:"100%"}} min={1}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="max_night"
                            label="Maksimum Gece Sayısı"
                            rules={[
                                {
                                    required:true,
                                    message:"Maks. gece sayısı seçilmelidir!"
                                }
                            ]}
                        >
                            <InputNumber style={{width:"100%"}} min={1}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="is_cost_include"
                            label="Maliyet'e Dahil Edilsin Mi?"
                            valuePropName="checked"
                        >
                            <Switch />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default ContractDateDiscountModalForm;