import axios from '../../axios';

const TransferService = {
    async get(page = 1, per_page = 10) {
        return await axios.get(`/lara-beach/transfers?page=${page}&per_page=${per_page}`);
    },
    async create(formData){
        return await axios.post('/lara-beach/transfers', formData);
    },
    async changePublishStatus(id){
        return await axios.post(`/lara-beach/transfers/${id}/change-published`);
    }
};

export default TransferService;