import React from 'react';
import {Modal, Form, Input, Tabs, Row, Col, Switch, Space, InputNumber, Button} from 'antd';
import { useSelector } from 'react-redux';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";

const { TabPane } = Tabs;

const ConceptModalForm = (props) => {
  const onOK = () => {
    props.form.submit();
  }

  return (
    <Modal visible={props.isVisible} title={props.title} closable={false} onOk={onOK} onCancel={props.onCancel}>
      <Form form={props.form} layout="vertical" onFinish={(values) => props.onFinish(values, props.data?.id)} initialValues={{ is_active: true }} fields={props.data?.fields}>
        <Tabs>
          {props.languages.map((language, index) => (
            <TabPane tab={language.web_language.name} key={index} forceRender>
              <Row>
                <Col span={24}>
                  <Form.Item
                      name={['concepts', language.web_language["iso_639-1"], 'name']}
                      label="Konspet Adı"
                      rules={[{ required: true, message: 'Lütfen konsept adı giriniz!' }]}
                  >
                    <Input placeholder="Konsept Adı" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.List
                      name={['concepts', language.web_language["iso_639-1"], 'features']}
                  >
                    {(fields, { add, remove }, { errors }) => (
                        <>
                          {fields.map((field, index) => (
                              <Form.Item
                                  required={false}
                                  key={field.key}
                              >
                                <Row gutter={[8,0]}>
                                  <Col span={6}>
                                    <Form.Item
                                        name={[field.name, 'color']}
                                        validateTrigger={['onChange', 'onBlur']}
                                        rules={[
                                          {
                                            required: true,
                                            message: "Lütfen özellik için renk seçiniz.",
                                          }
                                        ]}
                                        initialValue="#000000"
                                        label="Arkaplan Rengi"
                                    >
                                      <Input
                                          type="color"
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col span={6}>
                                    <Form.Item
                                        name={[field.name, 'text_color']}
                                        validateTrigger={['onChange', 'onBlur']}
                                        rules={[
                                          {
                                            required: true,
                                            message: "Lütfen özellik için yazı renk seçiniz.",
                                          }
                                        ]}
                                        initialValue="#000000"
                                        label="Yazı Rengi"
                                    >
                                      <Input
                                          type="color"
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col span={6}>
                                    <Form.Item
                                        name={[field.name, 'name']}
                                        validateTrigger={['onChange', 'onBlur']}
                                        rules={[
                                          {
                                            required: true,
                                            message: "Lütfen özellik adını giriniz.",
                                          }
                                        ]}
                                        label="Özellik Adı"
                                    >
                                      <Input
                                          style={{
                                            width:"100%"
                                          }}
                                          placeholder="Özellik adı"
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col span={6}>
                                    <Form.Item
                                      label="Sil"
                                    >
                                      <Button
                                          icon={<FontAwesomeIcon icon={faMinus}/>}
                                          type="dashed"
                                          onClick={() => remove(field.name)}
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </Form.Item>
                          ))}
                          <Form.Item>
                            <Button
                                type="dashed"
                                onClick={() => add()}
                                style={{
                                  width: '100%',
                                }}
                                icon={<FontAwesomeIcon icon={faPlus}/>}
                            >
                              Özellik Ekle
                            </Button>
                            <Form.ErrorList errors={errors} />
                          </Form.Item>
                        </>
                    )}
                  </Form.List>
                </Col>
              </Row>
            </TabPane>
          ))}
        </Tabs>

        <Row>
          <Col span={24}>
            <Form.Item name="is_active" label="Aktif / Pasif" valuePropName="checked">
              <Switch checkedChildren="Aktif" defaultChecked unCheckedChildren="Pasif" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ConceptModalForm;