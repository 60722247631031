import React from 'react';
import {Col, Form, Input, Modal, Row, Upload} from "antd";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";

const VehicleFileModalForm = (props) => {
    const { t } = useTranslation()
    const files = Form.useWatch('files', props.form);
    const getFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };
    return (
        <Modal visible={props.isVisible} title={props.title} closable={false} onOk={() => props.form.submit()} onCancel={props.onCancel}>
            <Form form={props.form} layout="vertical" onFinish={(values) => props.onFinish(values, props.data?.id)} fields={props.data?.fields}>
                <Form.Item name='files' getValueFromEvent={getFile} rules={[{
                    required: true,
                    message: 'Lütfen en az bir dosya yükleyiniz!'
                }]}>
                    <Upload
                        beforeUpload={() => {
                            return false;
                        }}
                        listType="picture-card"
                        fileList={files || []}
                        showUploadList={{
                            showPreviewIcon:false
                        }}
                        accept="image/png, image/jpeg, image/jpg, image/webp"
                    >
                        <button
                            style={{
                                border: 0,
                                background: 'none',
                            }}
                            type="button"
                        >
                            <FontAwesomeIcon icon={faPlus} style={{ fontSize: '24px' }} />
                            <div
                                style={{
                                    marginTop: 8,
                                }}
                            >
                                Yükle
                            </div>
                        </button>
                    </Upload>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default VehicleFileModalForm;