import React from "react";
import {Select, notification} from "antd";
import {CountryService} from "@services";
const {Option}=Select;

function CountryDefinitionSelect (props){

    const [countries,setCountries] = React.useState([]);
    const [isLoading,setIsLoading] = React.useState(true);

    React.useEffect(() => {
        const getCountries = async () => {
            try {
                let {data :{data:response}} = await CountryService.get();
                setCountries(response);
                setIsLoading(false);
            } catch (e) {
                if (e.response) {
                    notification.error({
                        message: e.response.data.message
                    });
                }
            }
        };
        getCountries();
    }, []);
    return (
        <Select {...props} loading={isLoading}>
            {countries?.map((country,i)=>(
                <Option value={country.id} key={country.id}>{country.name}</Option>
            ))}
        </Select>
    )
}

export default CountryDefinitionSelect;