import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Select, Spin} from "antd";
import debounce from 'lodash/debounce';
import {RegionServiceV2} from "@services";


const RegionSelect = ({ onChange }) => {
    async function fetchUserList(value) {
        try {
            let {data: response} = await RegionServiceV2.search(value)
            return response.data.map((item) => ({
                label: item.name,
                value: item.id,
            }));
        }catch (e){
            return [];
        }
    }
    return (
        <DebounceSelect
            onChange={onChange}
            mode="multiple"
            placeholder="select"
            fetchOptions={fetchUserList}
            style={{
                width: '100%',
            }}
        />
    );
};
const DebounceSelect = ({ fetchOptions, debounceTimeout = 800, ...props }) => {
    const [fetching, setFetching] = useState(false);
    const [options, setOptions] = useState([]);
    const fetchRef = useRef(0);
    const debounceFetcher = useMemo(() => {
        const loadOptions = (value) => {
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            setOptions([]);
            setFetching(true);
            fetchOptions(value).then((newOptions) => {
                if (fetchId !== fetchRef.current) {
                    return;
                }
                setOptions(newOptions);
                setFetching(false);
            });
        };
        return debounce(loadOptions, debounceTimeout);
    }, [fetchOptions, debounceTimeout]);
    return (
        <Select
            labelInValue
            filterOption={false}
            onSearch={debounceFetcher}
            notFoundContent={fetching ? <Spin size="small" /> : null}
            {...props}
            onChange={(newValue, option) => props.onChange(newValue, option)}
            options={options}
        />
    );
}

export default RegionSelect;