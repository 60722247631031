import React from 'react';
import {Col, Form, Input, InputNumber, Modal, Row} from "antd";

const HotelSmtpSettingsModal = (props) => {
    return (
        <Modal visible={props.isVisible} title={props.title} closable={false} onOk={() => props.form.submit()} onCancel={props.onCancel} okText="Kaydet" cancelText="İptal">
            <Form form={props.form} layout="vertical" onFinish={props.onFinish}>
                <Row gutter={[16,16]}>
                    <Col span={12}>
                        <Form.Item
                            name="host"
                            label="Host"
                            rules={[{required: true, message: 'Lütfen host adresini giriniz!'}]}
                        >
                            <Input
                                placeholder="smtp.gmail.com"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="port"
                            label="Port"
                            rules={[{required: true, message: 'Lütfen port numarasını giriniz!'}]}
                        >
                            <InputNumber
                                placeholder="587"
                                min={1}
                                style={{width:"100%"}}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="encryption"
                            label="Encryption"
                            rules={[{required: true, message: 'Lütfen encryption tipini giriniz!'}]}
                        >
                            <Input
                                placeholder="tls"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="username"
                            label="Kullanıcı Adı"
                            rules={[{required: true, message: 'Lütfen kullanıcı adını giriniz!'}]}
                        >
                            <Input
                                placeholder="example@example.com"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="password"
                            label="Şifre"
                            rules={[{required: true, message: 'Lütfen şifrenizi giriniz!'}]}
                        >
                            <Input
                                placeholder="********"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="from_address"
                            label="Gönderen Adresi"
                            rules={[{required: true, message: 'Lütfen gönderen adresini giriniz!'}]}
                        >
                            <Input
                                placeholder="example@example.com"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="from_name"
                            label="Gönderen Adı"
                            rules={[{required: true, message: 'Lütfen gönderen adını giriniz!'}]}
                        >
                            <Input
                                placeholder="Example Hotel"
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default HotelSmtpSettingsModal;