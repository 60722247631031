import React from 'react';
import {Col, Form, InputNumber, Modal, Row} from "antd";
function SectionOrderModalForm(props){
    return(
        <Modal visible={props.isVisible} onCancel={props.onCancel} title={props.title} onOk={() => props.form.submit()}>
            <Form form={props.form} onFinish={props.onFinish} layout="vertical">
                <Row gutter={[16,16]}>
                    {props.hotelCategorySections?.map((section,i)=>(
                        <Col span={24} key={i}>
                            <Form.Item label={section?.section[0].name} name={section.id} tooltip="Aynı sıra numarası verilenler rastgele sıralanacaktır!" initialValue={section?.order}>
                                <InputNumber min={1} max={props.hotelCategorySections?.length} style={{width:"100%"}}/>
                            </Form.Item>
                        </Col>
                    ))}
                </Row>
            </Form>
        </Modal>
    )
}
export default SectionOrderModalForm