import axios from '../axios';

const CurrentDeficitService = {
  async get(page, per_page, filters) {
    return await axios.get(`/v1/current-deficit?page=${page}&per_page=${per_page}`, {
        params: filters
    });
  },
  async create(formData) {
    return await axios.post('/v1/current-deficit', formData);
  },
  async update(formData, cariId) {
    return await axios.post(`/v1/current-deficit/${cariId}`, formData);
  },
  async getByCariId(cariId) {
    return await axios.get(`/v1/current-deficit/${cariId}`);
  },
  async delete(cariId) {
    return await axios.delete(`/v1/current-deficit/${cariId}`);
  },
  async appendCari(formData, safeBankId) {
    return await axios.post(`/v1/safe-bank/${safeBankId}/append-cari`, formData);
  },
  async search(search,currency) {
    return await axios.get(`/v1/current-deficit/search?search=${search}`,{
        params: {
            currency: currency
        }
    });
  }
}


export default CurrentDeficitService;