import axios from '../axios';

const RoomDefinitionService = {
  async findById(hotelId, id) {
    return await axios.get(`/v1/hotels/${hotelId}/room-definitions/${id}`);
  },

  async get(hotelId) {
    return await axios.get(`/v1/hotels/${hotelId}/room-definitions`);
  },

  async create(hotelId, formData) {
    return await axios.post(`/v1/hotels/${hotelId}/room-definitions`, formData);
  },

  async update(hotelId, roomDefinitionId, formData) {
    return await axios.post(`/v1/hotels/${hotelId}/room-definitions/${roomDefinitionId}`, formData);
  },

  async delete(hotelId, roomDefinitionId) {
    return await axios.delete(`/v1/hotels/${hotelId}/room-definitions/${roomDefinitionId}`);
  }
};

export default RoomDefinitionService;