import React, { useEffect } from 'react';
import { Button, Checkbox, Form, Input, notification } from 'antd';
import useRouteHistory from '@hooks/useRouteHistory';
import { useNavigate } from 'react-router-dom';

import { Authentication } from '@services';
import './login.less';
import {useTranslation} from "react-i18next";

const Login = () => {
  const { t } = useTranslation()
  const [loginForm] = Form.useForm();
  const navigate = useNavigate();
  const { getLatestRoute } = useRouteHistory();

  useEffect(() => {
    if (Authentication.hasToken()) {
      navigate('/');
    }
  }, []);

  const onFinishLogin = async (values) => {
    try {
      let response = await Authentication.login({
        email: values.email,
        password: values.password
      });
      Authentication.setToken(response.data.token, response.data.expire_at);
      navigate(getLatestRoute());
    } catch (e) {
      if (e.response) {
        notification.error({
          message: e.response.data.message
        });
      }
    }
  };

  return (
    <div className="login-wrapper">
      <div className="login-form">
        <h1>{t('general.login')}</h1>
        <Form form={loginForm} layout="vertical" onFinish={onFinishLogin}>
          <Form.Item label={t('general.email')} name="email" rules={[
            { required: true, message: 'E-posta alanı zorunludur.' },
            { type: 'email', message: 'E-Posta formatı yanlış.' },
          ]}>
            <Input placeholder="example@rationaltour.com" />
          </Form.Item>

          <Form.Item label={t('general.password')} name="password" rules={[
            { required: true, message: 'Şifre alanı zorunludur.' },
            { min: 6, message: 'Şifreniz minimum 6 karakter olmalıdır.' }
          ]}>
            <Input type="password" placeholder="********" />
          </Form.Item>

          <div className="forgot-password">
            <a href="/forgot-password">{t('general.forgot_password')}</a>
          </div>

          <Form.Item name="remember" valuePropName="checked">
            <Checkbox>{t('general.rememberMe')}</Checkbox>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              {t('general.login')}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
};

export default Login;