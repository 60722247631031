import React, { useEffect, useState } from 'react';
import {Layout, Breadcrumb, Button, notification, Input, Form, Row, Skeleton, Select} from 'antd';
import { useParams, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse } from '@fortawesome/free-solid-svg-icons';
import { ContractDetailsService, ContractQuotaService, PriceChangesService, DiscountService } from '@services';
import Wrapper from '@components/Wrapper';
import moment from "moment";
import linq from "linq";

const { Content } = Layout;
const { Option } = Select;


const ContractDays = () => {

    const params = useParams()
    const locations = useLocation()
    const [updateForm] = Form.useForm();
    const [selectedDate,setSelectedDate] = useState([]);
    const [contractDetails, setContractDetails] = useState([]);
    const [discounts,setDiscounts] = useState([]);
    const [contractQuotas, setContractQuotas] = useState([]);
    const [priceChanges, setPriceChanges] = useState([]);

    const [isLoadingPriceChanges,setIsLoadingPriceChanges] = useState(true);
    const [isLoadingContractDetails,setIsLoadingContractDetails] = useState(true);
    const [isLoadingContractQuotas,setIsLoadingContractQuotas] = useState(true);
    const [isLoadingDiscounts,setIsLoadingDiscounts] = useState(true);

    const getContractDetails = async () => {
        try {
            let { data: response } = await ContractDetailsService.get(params.hotelId, params.contractId);
            setContractDetails(response)
            setIsLoadingContractDetails(false);
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    };
    const getContractQuotas = async () => {
        try {
            let { data: response } = await ContractQuotaService.get();
            setContractQuotas(response)
            setIsLoadingContractQuotas(false)
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    };
    const getPriceChanges = async () => {
        try {
            let { data: response } = await PriceChangesService.get();
            setPriceChanges(response)
            setIsLoadingPriceChanges(false)
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    };
    const getDiscounts = async () => {
        try {
            let { data: response } = await DiscountService.get();
            setDiscounts(response)
            setIsLoadingDiscounts(false)
        } catch (e) {
            if (e.response) {
                notification.error({
                    message: e.response.data.message
                });
            }
        }
    };

    const onSubmit = async (values) =>{
        let quotas = [];
        let prices = [];
        let discounts = [];
        for(let i = 0;i < Object.keys(values).length-1;i++){
            values[`discount-${i}`] !== undefined &&
            discounts.push({
                contract_id:params.contractId,
                date:moment(selectedDate[i]).format('YYYY-MM-DD'),
                type:values[`discount-type-${i}`],
                value:values[`discount-${i}`],
            })
            values[`price-${i}`] !== undefined &&
            prices.push({
                contract_id:params.contractId,
                date:moment(selectedDate[i]).format('YYYY-MM-DD'),
                per_person:values[`price-${i}`]
            })
            values[`quota-${i}`] !== undefined &&
            quotas.push({
                contract_id:params.contractId,
                date:moment(selectedDate[i]).format('YYYY-MM-DD'),
                quota:values[`quota-${i}`]
            })
        }
        if(quotas.length <= 0 && prices.length <= 0 && discounts.length <= 0 ){
            notification.error({
                message: "Değerlerde değişiklik yapılmadı!"
            });
        }else{
            if(prices.length > 0){
                /* Fiyat için API isteği */
                try {
                    let { data: priceResponse } = await PriceChangesService.create(params.contractId, prices);
                    if (priceResponse.success) {
                        //setIsLoadingTable(true);
                        //getContractDetails(params.hotelId, params.contractId);
                        notification.success({
                            message: priceResponse.message
                        });
                    }
                } catch (e) {
                    if (e.response) {
                        notification.error({
                            message: e.response.message
                        });
                    }
                    console.log(e);
                }
            }
            if(quotas.length > 0 ){
                /* Kota için API isteği */
                try {
                    let { data: quotasResponse } = await ContractQuotaService.create(params.contractId, quotas);
                    if (quotasResponse.message) {
                        //setIsLoadingTable(true);
                        //getContractDetails(params.hotelId, params.contractId);
                        notification.success({
                            message: quotasResponse.message
                        });
                    }
                } catch (e) {
                    if (e.response) {
                        notification.error({
                            message: e.response.message
                        });
                    }
                    console.log(e);
                }
            }
            if(discounts.length > 0 ){
                /* İndirim için API isteği */
                try {
                    let { data: discountResponse } = await DiscountService.create(params.contractId, discounts);
                    if (discountResponse.message) {
                        //setIsLoadingTable(true);
                        //getContractDetails(params.hotelId, params.contractId);
                        notification.success({
                            message: discountResponse.message
                        });
                    }
                } catch (e) {
                    if (e.response) {
                        notification.error({
                            message: e.response.message
                        });
                    }
                    console.log(e);
                }
            }
        }
    }

    const changeAllPrices = (value) =>{
        let items = document.querySelectorAll('[id^=price]')
        for(let i = 0;i < items.length;i++){
            updateForm.setFieldsValue({
                [`price-${i}`]:value
            })
        }
    }
    const changeAllQuotas = (value) =>{
        let items = document.querySelectorAll('[id^=quota]')
        for(let i = 0;i < items.length;i++){
            updateForm.setFieldsValue({
                [`quota-${i}`]:value
            })
        }
    }
    const changeAllDiscount = (value) =>{
        let items = document.querySelectorAll('[id^=discount]')
        for(let i = 0;i < items.length;i++){
            updateForm.setFieldsValue({
                [`discount-${i}`]:value
            })
        }
    }

    const changeAllDiscountType = (value) =>{
        let items = document.querySelectorAll('[id^=discount-type]')
        for(let i = 0;i < items.length;i++){
            updateForm.setFieldsValue({
                [`discount-type-${i}`]:value
            })
        }
    }


    const onReset = () => {
        getContractDetails()
        getContractQuotas()
        getDiscounts()
        getPriceChanges()
        updateForm.resetFields();
    };
    const showDiscount = (e,days) =>{
        let target = `finalPrice-${e.target.id.substring(e.target.id.indexOf('-') + 1)}`
        let price = `price-${e.target.id.substring(e.target.id.indexOf('-') + 1)}`
        let discount = `discount-${e.target.id.substring(e.target.id.indexOf('-') + 1)}`
        let discountType = `discount-type-${e.target.id.substring(e.target.id.indexOf('-') + 1)}`
        let priceElem = document.getElementById(price)
        let discountElem = document.getElementById(discount)
        /* İndirim Tipi Sorgulanıyor */
        if(updateForm.getFieldValue(discountType)){
            if(updateForm.getFieldValue(discountType) == 1){
                document.getElementById(target).innerHTML = (priceElem.value) - priceElem.value * (discountElem.value / 100)
            }
            if(updateForm.getFieldValue(discountType) == 2){
                document.getElementById(target).innerHTML = (priceElem.value) - discountElem.value
            }
        }else{
            /* İndirim Tipi Yok İse Default İşlem Sağlanıyor */
            document.getElementById(target).innerHTML = (priceElem.value) - priceElem.value * (discountElem.value / 100)
        }
    }
    useEffect(()=>{
        let start_date = moment(locations.state.start_date);
        let end_date = moment(locations.state.end_date);
        let dates = []
        for (let d = new Date(moment(start_date).format('YYYY-MM-DD')); d <= new Date(moment(end_date).format('YYYY-MM-DD')); d.setDate(d.getDate() + 1)) {
            dates.push(new Date(d));
        }
        setSelectedDate(dates);
    },[locations.state])
    useEffect(()=>{
        onReset();
    },[])
    const showAllDiscount = value =>{
        for(let i = 0;i < selectedDate.length;i++){
            document.getElementById(`finalPrice-${i}`).innerHTML = value - document.getElementById(`discount-${i}`).value;
        }
    }
    return (
        <Wrapper>
            <Content className="m-25">
                <Breadcrumb className="mb-20">
                    <Breadcrumb.Item href="/">
                        <FontAwesomeIcon icon={faHouse} />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href="/contract-groups">
                        Kontrat Otelleri
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href={`/contract-groups/${params.hotelId}/languages`}>
                        Kontrat Dilleri
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href={`/contract-groups/${params.hotelId}/languages/${params.languageId}/contracts`}>
                        Kontratlar
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href={`/contract-groups/${params.hotelId}/languages/${params.languageId}/contracts/${params.contractId}/details`}>
                        Kontrat Detayları
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {moment(locations.state.start_date).format('DD-MM-YYYY')} - {moment(locations.state.end_date).format('DD-MM-YYYY')}
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background">
                    {(isLoadingPriceChanges && isLoadingContractDetails && isLoadingContractQuotas && isLoadingDiscounts ) && <Skeleton/>}
                    {(!isLoadingContractDetails && !isLoadingContractQuotas && !isLoadingDiscounts && !isLoadingPriceChanges) &&
                    <Form form={updateForm} onFinish={values => onSubmit(values)} style={{overflow:"scroll"}}>
                        <table style={{ width: "100%"}}>
                            <thead className="ant-table-thead">
                            <tr>
                                <th className="ant-table-cell" style={{minWidth:"120px"}}>#</th>
                                {selectedDate?.map((days,index) => (
                                    <th className="ant-table-cell" key={index} style={{minWidth:"120px"}}>{moment(days).format('DD-MM-YYYY')}</th>
                                ))}
                            </tr>
                            </thead>
                            <tbody className="ant-table-tbody">
                             {/*Fiyat Satırı*/}
                            <tr className="ant-table-row ant-table-row-level-0">
                                <td className="ant-table-cell">
                                    Fiyat
                                </td>
                                {selectedDate?.map((days,index) => (
                                    <td className="ant-table-cell" key={index}>
                                        <Row>
                                            <div>
                                                {linq.from(priceChanges.data).where(a => a.date === moment(days).format('YYYY-MM-DD')).toArray()[0]?.per_person ? linq.from(priceChanges.data).where(a => a.date === moment(days).format('YYYY-MM-DD')).toArray()[0]?.per_person : linq.from(contractDetails.per_person).where(a => new Date(a.period_dates.start_date).getTime() <= days.getTime() && new Date(a.period_dates.end_date).getTime() >= days.getTime()).toArray()[0]?.price} ->
                                            </div>
                                            <Form.Item name={`finalPrice`} noStyle>
                                                <div id={`finalPrice-${index}`} style={{color:"green"}}>
                                                    {
                                                        linq.from(discounts.data).where(a => a.date === moment(days).format('YYYY-MM-DD')).toArray()[0]?.type === 1 &&
                                                        (linq.from(priceChanges.data).where(a => a.date === moment(days).format('YYYY-MM-DD')).toArray()[0]?.per_person ? linq.from(priceChanges.data).where(a => a.date === moment(days).format('YYYY-MM-DD')).toArray()[0]?.per_person : linq.from(contractDetails.per_person).where(a => new Date(a.period_dates.start_date).getTime() <= days.getTime() && new Date(a.period_dates.end_date).getTime() >= days.getTime()).toArray()[0]?.price) - (linq.from(priceChanges.data).where(a => a.date === moment(days).format('YYYY-MM-DD')).toArray()[0]?.per_person ? linq.from(priceChanges.data).where(a => a.date === moment(days).format('YYYY-MM-DD')).toArray()[0]?.per_person : linq.from(contractDetails.per_person).where(a => new Date(a.period_dates.start_date).getTime() <= days.getTime() && new Date(a.period_dates.end_date).getTime() >= days.getTime()).toArray()[0]?.price) * (linq.from(discounts.data).where(a => a.date === moment(days).format('YYYY-MM-DD')).toArray()[0]?.value / 100)
                                                    }
                                                    {
                                                        linq.from(discounts.data).where(a => a.date === moment(days).format('YYYY-MM-DD')).toArray()[0]?.type === 2 &&
                                                        (linq.from(priceChanges.data).where(a => a.date === moment(days).format('YYYY-MM-DD')).toArray()[0]?.per_person ? linq.from(priceChanges.data).where(a => a.date === moment(days).format('YYYY-MM-DD')).toArray()[0]?.per_person : linq.from(contractDetails.per_person).where(a => new Date(a.period_dates.start_date).getTime() <= days.getTime() && new Date(a.period_dates.end_date).getTime() >= days.getTime()).toArray()[0]?.price) - (linq.from(discounts.data).where(a => a.date === moment(days).format('YYYY-MM-DD')).toArray()[0]?.value)

                                                    }
                                                </div>
                                            </Form.Item>
                                        </Row>
                                    </td>
                                ))}
                            </tr>
                             {/*Fiyat Güncelleme Satırı*/}
                            <tr className="ant-table-row ant-table-row-level-0">
                                <td className="ant-table-cell">
                                    <Form.Item name="price-all" label={'Yeni Fiyat'} style={{marginBottom:0,minWidth:"220px"}}>
                                        <Input type="text" placeholder="Fiyat Güncelle" onChange={(e) => {changeAllPrices(e.target.value);showAllDiscount(e.target.value)}}>
                                        </Input>
                                    </Form.Item>
                                </td>
                                {selectedDate?.map((days,index) => (
                                    <td className="ant-table-cell" key={index}>
                                        <Form.Item name={`price-${index}`} noStyle>
                                            <Input type="text" onChange={showDiscount} defaultValue={linq.from(priceChanges.data).where(a => a.date === moment(days).format('YYYY-MM-DD')).toArray()[0]?.per_person ? linq.from(priceChanges.data).where(a => a.date === moment(days).format('YYYY-MM-DD')).toArray()[0]?.per_person : linq.from(contractDetails.per_person).where(a => new Date(a.period_dates.start_date).getTime() <= days.getTime() && new Date(a.period_dates.end_date).getTime() >= days.getTime()).toArray()[0]?.price}></Input>
                                        </Form.Item>
                                    </td>
                                ))}
                            </tr>
                             {/*Kota Güncelleme Satırı*/}
                            <tr className="ant-table-row ant-table-row-level-0">
                                <td className="ant-table-cell">
                                    <Form.Item name="quota-all" label={'Kota'} style={{marginBottom:0,minWidth:"220px"}}>
                                        <Input type="text" placeholder="Kota Güncelle" onChange={(e) => {changeAllQuotas(e.target.value)}}>
                                        </Input>
                                    </Form.Item>
                                </td>
                                {selectedDate?.map((days,index) => (
                                    <td className="ant-table-cell" key={index}>
                                        <Form.Item name={`quota-${index}`} noStyle>
                                            <Input type="text" defaultValue={linq.from(contractQuotas.data).where(a => a.date === moment(days).format('YYYY-MM-DD')).toArray()[0]?.quota ? linq.from(contractQuotas.data).where(a => a.date === moment(days).format('YYYY-MM-DD')).toArray()[0]?.quota : contractDetails.hotelRooms?.quota}></Input>
                                        </Form.Item>
                                    </td>
                                ))}
                            </tr>
                             {/*İndirim Güncelleme Satırı*/}
                            <tr className="ant-table-row ant-table-row-level-0">
                                <td className="ant-table-cell">
                                    <Form.Item name={`discount-type-all`} label={'İndirim Tipi'} style={{marginBottom:"8px",minWidth:"220px"}}>
                                        <Select placeholder="İndirim Tipi Seçin" onChange={e => changeAllDiscountType(e)}>
                                            <Option value="1">Yüzdelik İndirim</Option>
                                            <Option value="2">Fiyat İndirimi</Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item name={`discount-all`} label={'İndirim Değeri'} style={{marginBottom:0,minWidth:"220px"}}>
                                        <Input placeholder="İndirim Değeri" onChange={e => changeAllDiscount(e.target.value)}/>
                                    </Form.Item>
                                </td>
                                {selectedDate?.map((days,index) => (
                                    <td className="ant-table-cell" key={index}>
                                        <Input.Group compact>
                                            <Form.Item name={`discount-type-${index}`} noStyle>
                                                <Select placeholder="İndirim Tipi Seçin">
                                                    <Option value="1">Yüzdelik İndirim</Option>
                                                    <Option value="2">Fiyat İndirimi</Option>
                                                </Select>
                                            </Form.Item>
                                            <Form.Item name={`discount-${index}`} noStyle>
                                                <Input placeholder="İndirim Değeri" defaultValue={linq.from(discounts.data).where(a => a.date === moment(days).format('YYYY-MM-DD')).toArray()[0]?.value} onChange={e => showDiscount(e,days)}/>
                                            </Form.Item>
                                        </Input.Group>
                                    </td>
                                ))}
                            </tr>
                            </tbody>
                        </table>
                    </Form>
                    }
                    <Button type={'primary'} style={{width:"100%",marginTop:"1rem"}} onClick={() => updateForm.submit()}>Kaydet</Button>
                </div>
            </Content>
        </Wrapper>
    );
};

export default ContractDays;