import axios from '../../axios';

const SettingService = {
    async get() {
        return await axios.get(`/lara-beach/settings`);
    },
    async show(key){
        return await axios.get(`/lara-beach/settings/${key}`);
    }
};

export default SettingService;