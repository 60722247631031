import React from "react";
import {Select, notification} from "antd";
import {CityService} from "@services";
const {Option}=Select;

function CityDefinitionSelect (props){


    return (
        <Select {...props} loading={props.isLoading}>
            {props.cities?.map((city,i)=>(
                <Option value={city.id} key={city.id}>{city.name}</Option>
            ))}
        </Select>
    )
}

export default CityDefinitionSelect;