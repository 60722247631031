import axios from '../axios';

const ConditionGroupService = {
    async findById(id) {
        return await axios.get(`/v1/condition-groups/${id}`);
    },

    async get() {
        return await axios.get('/v1/condition-groups');
    },

    async create(formData) {
        return await axios.post('/v1/condition-groups', formData);
    },

    async update(id, formData) {
        return await axios.post(`/v1/condition-groups/${id}`, formData);
    },

    async delete(id) {
        return await axios.delete(`/v1/condition-groups/${id}`);
    }
};

export default ConditionGroupService;